import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App.js";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import axios from 'axios';
import { msalConfig, loginRequest } from "./authConfig";
import { jwtDecode  } from 'jwt-decode';
import appConfig from './Config.json'


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode (token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    return expirationTime - currentTime < 1 * 60 * 1000;
};

axios.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        let idToken = localStorage.getItem("idtoken");
        if (account && isTokenExpired(idToken)) {
            try {
                const msalResponse = await msalInstance.acquireTokenSilent({
                    ...loginRequest,
                    account: account,
                    forceRefresh: true
                });
                idToken = msalResponse.idToken;
                localStorage.setItem("idtoken", idToken);
                localStorage.setItem("accesstoken", msalResponse.accessToken);
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const msalResponse = await msalInstance.acquireTokenRedirect({
                        ...loginRequest,
                        account: account
                    });
                    idToken = msalResponse.idToken;
                    localStorage.setItem("idtoken", idToken);
                    localStorage.setItem("accesstoken", msalResponse.accessToken);
                } else {
                    console.error("Token acquisition failed", error);
                }
            }

        }
        if (appConfig?.environment?.toUpperCase() == 'DEV' && response.url.includes('/plan') && !response.url.includes('/backend')) {
            let apiToExclude = ['/addorderComments', '/search', '/addLocation', '/addtrailer', '/removetrailer', '/updatetrailer', '/edit_actuals/check', '/pcmiller/distance', '/terminal/movement']

            if (!apiToExclude.some((el)=>response.url.includes(el))) {
                let callNewPlanningApi = localStorage.getItem('callNewPlanningAPI')
                if (callNewPlanningApi && callNewPlanningApi == 'true') {
                    response.url = response.url.replace('/plan', '/plan/v2')
                }
            }
        }
        response.headers = response.headers || {};
        response.headers.Authorization = `Bearer ${idToken}`;
           
        return response

    },
    (err) => {
        return Promise.reject(err);
    }
);
const Root = () => {
const [isReloadRequire,setIsReloadRequire] = useState(false)

axios.interceptors.response.use(
    (response) => {
        const appVersion = response.headers["app-version"];
        if( response.config.url?.includes('/backend')){
        let localAppVersion=localStorage.getItem('appVersion')
        if (appVersion !== localAppVersion) {
            localStorage.setItem("appVersion",appVersion);
            setIsReloadRequire(true);
        }
    }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
return (
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App isReloadRequire={isReloadRequire} setIsReloadRequire={setIsReloadRequire}/>
        </MsalProvider>
    </React.StrictMode>
);
};

ReactDOM.render(<Root />, document.getElementById("root"));
