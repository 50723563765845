import React, { useState } from 'react'
import AppBar from '../../components/appbar';
import DriverPay from '../../components/driverPay/DriverPay';
import DriverPayHeader from '../../components/driverPay/DriverPayHeader';
import Header from '../../components/header';
import { useEffect } from 'react';
import { getAllPlanningProfiles, getPlanningProfileById } from '../../services/userService';
import { useContext } from 'react';
import { ContextData } from '../../components/appsession';

const driverPay = () => {
  const [userData, setUserData] = useContext(ContextData);
  const[planningprofile,setPlanningProfile]=useState([]) 
  
  const [usersPlanningProfile, setusersPlanningProfile] = useState([])
  const[selectedTerminal,setSelectedTerminal]=useState("");
  const[isplanningProfileChange, setisplanningProfileChange]=useState(0); 

  const [driverPayConfigClicked, setDriverPayConfigCliked] = useState(false);
  const [allDriverPayConfig, setAllDriverPayConfig] = useState([]); 
  const [refresh, setRefresh] = useState(false);
  const [selectedTerminalIDs, setSelectedTerminalIDs] = useState([]);
  
  const handleRefresh = () => {
		setRefresh(!refresh);
	};
  const handleChildStateChange = (terminalid) => {
    setSelectedTerminal(terminalid)
    setSelectedTerminalIDs(terminalid)
  };
  
  useEffect(() => {

    if (userData.id) {

      const getallPlanningProfile = async () => {

        // Getting all planning profiles
        let PlanningProfile = await getAllPlanningProfiles();

        // Getting planning profiles by user id
        let planningProfileData = await getPlanningProfileById(userData.id);
        setusersPlanningProfile(planningProfileData);
        setPlanningProfile(PlanningProfile);

        let assignedProfile = [...PlanningProfile].filter((profile) =>
          planningProfileData.map((assign) => assign.planningprofile_id)
            .includes(profile.id)
        );
        if(assignedProfile.length > 0) {
          const terminalId = assignedProfile.map((e)=>e.terminal_id);
          setSelectedTerminal(terminalId);
          setSelectedTerminalIDs(terminalId);
        }
        else {
          setSelectedTerminal(PlanningProfile[0]?.terminal_id);
          setSelectedTerminalIDs([]);
        }
      }

      getallPlanningProfile();
    }
  }, [userData.id]);


  const handleSelectedTerminalIDs = (selectedTerminalIDs) => {
    // selectedTerminalIDs in the parent component
    setSelectedTerminalIDs(selectedTerminalIDs);
  };

    
    return (
        <div id='wrapper'>
            <Header
                userclicked={driverPayConfigClicked}
            // parentcallback={handelcallbackFromHeader}
            ></Header>
            <AppBar />
            <div className="content-page-driverpay">
        <div className="content">
          <div className="container-fluid">
            {!driverPayConfigClicked ?
              <>
                <DriverPayHeader planningprofile={planningprofile} selectedTerminal={selectedTerminal} isplanningProfileChange={isplanningProfileChange} onStateChange={handleChildStateChange} usersPlanningProfile={usersPlanningProfile} setisplanningProfileChange={setisplanningProfileChange} handleRefresh={handleRefresh} onSelectedTerminalIDsChange={handleSelectedTerminalIDs} />
                <DriverPay allDriverPayConfig={allDriverPayConfig} selectedTerminal={selectedTerminal} isplanningProfileChange={isplanningProfileChange} setisplanningProfileChange={setisplanningProfileChange} refresh={refresh} handleRefresh={handleRefresh} selectedTerminalIDs={selectedTerminalIDs} />
              </>
              : ""}
          </div>
        </div>
      </div>

    </div>
  )
}

export default driverPay;