export const getEncodedURI=(data)=>{
    if(!data){
       data={}
    }
    return Object.keys(data)?.map((el)=>{
       return encodeURIComponent(el)+'='+encodeURIComponent(data[el])
    }).join('&')
   }
   
   
   export const getDecodedURI=(data)=>{
       if(!data){
           data=""
       }
       if(data.startsWith("?")){
           data=data.substring(1);
       }
       if(data.trim()==''){
           return {}
       }
       let decodeData=decodeURIComponent(data);
       if(decodeData){
           return Object.fromEntries(decodeData.split('&').map((el)=>el.split('=')));
       }
   }