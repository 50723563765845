import { useCallback,useContext, useEffect, useState,useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Autocomplete } from "@mui/lab";
import { ContextData } from "../../components/appsession";
import { ToastContainer, toast } from "react-toastify";
import {getActivelocations} from "../../services/locationService";
import {getAllActiveTrailers} from '../../services/trailerService';
import { getallactivedriverspaybase } from "../../services/driverPayCodesMasterService.js";
import filterOperators from '../../utils/filterOperators.js'
import debounce from 'lodash/debounce';
import { getAllEquipmenttype,getallordertype,GetAllCommodityWithName,GetAllTerminalWithName,DeleteDriverPayByException, AddDriverPayByDistanceExceptionLaneOverride, UpdateDriverPayByDistanceExceptionLaneOverride,searchDriverpayKendo} from "../../services/driverPayServer";
import AuditLogModalComponent from "./auditLogModal";
import errorMessageDisplay from "../../utils/errorMessageDisplay.js";


const DriverPayEXceptionTable = (props) => {
  const { accessDisabled,isEditDisable } = props;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen,setIsDeleteModalOPen]=useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [masterData,setMasterData] = useState({
    paycodeArr:[],
    shipperCodeArr:[],
    consigneeCodeArr:[],
    commodityArr:[],
    driverClassesArr:[],
    orderTypeArr:[],
    tarailersArr:[],
    orgDestCode:[],
    terminalArr:[],
    moveTypes:['pickup','delivery','split pickup','split delivery'],
    booleanValues:['True','False'],
    driverloadunload:['Y','N','TL','NB']
  });
  const [allField,setallField]=useState({
    payCode:"",
    orderTerminal:"",
    driverTerminal:"",
    driverLoad:"",
    driverUnload:"",
    shipperCode:"",
    consigneeCode:"",
    commodity:"",
    origdestMatch:"",
    moveTypes:"",
    driverClasses:[],
    trailers:[],
    minWeight:"",
    maxWeight:"",
    otherChargeCode:"",
    originCode:"",
    destinationCode:"",
    orderTypes:[],
    cancelPayment:"",
    additionalUnit:"",
    additionalRate:""
  })
  const[selectedId,setSelectedId]=useState("");
  const [userData, setUserData] = useContext(ContextData);

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [searchTerms, setSearchTerms] = useState([]);
  const [sortData,setSortData] = useState([{field: "id",dir: "asc"}]);

  const [data, setData] = useState(null);

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: sortData,
  });
  const [dataResult, setDataResult] = useState([]);

  const fieldsToField = [
    "id",
    "orderterminal_fullname",
    "driverterminal_fullname",
    "driver_pay_code",
    "driverload",
    "driverunload",
    "shippercode_location_fullname",
    "consigneecode_location_fullname",
    "commodity_fullname",
    "origdestmatch",
    "movetypes",
    "driverclasses",
    "trailers",
    "minweight",
    "maxweight",
    "origin_location_fullname",
    "destination_location_fullname",
    "ordertypes",
    "cancelpayment",
    "additionalunits",
    "additionalrate",
];
 
const getData=async()=>{
  let resssall=await Promise.all([getActivelocations(),getallactivedriverspaybase(),getAllEquipmenttype(),getAllActiveTrailers(),getallordertype(),GetAllTerminalWithName(),GetAllCommodityWithName()])
    let shipperCodeArr=[];
    let consigneeCodeArr=[];
    let orgDestCodeArr=[];
    let driverClassArr=resssall[2]?.length>0 && resssall[2]?.map(el=>el.equipment_type_id && el.equipment_type_id.trim());
    let trailerArr=resssall[3]?.length>0 && resssall[3]?.map(el=>el.trailer_id && el.trailer_id.trim());
    let payCodeArr=resssall[1]?.length>0 && resssall[1]?.map((el)=>`${el.pay_code.trim()}-${el.pay_description.trim()}`);
    let terminalArr=resssall[5]?.length>0 && resssall[5]?.map((el)=>el.treminal_name && el.treminal_name.trim());
    let commodityArr=resssall[6]?.length>0 && resssall[6]?.map((el)=>el.commodity_name && el.commodity_name.trim())
     let orderArr=resssall[4]?.length>0 && resssall[4]?.map((el)=>el.order_type_id && el.order_type_id.trim());
    resssall[0]?.forEach(ele => {
      if(ele.isShipper){
        shipperCodeArr.push(`${ele.code} - ${ele.name}`)
      }
      if(ele.isConsignee){
        consigneeCodeArr.push(`${ele.code} - ${ele.name}`)
      }
      orgDestCodeArr.push(`${ele.code} - ${ele.name}`)
    });
    let obj={...masterData};
    obj.orderTypeArr=[...new Set(orderArr)].sort();
    obj.commodityArr=[...new Set(commodityArr)].sort();
    obj.consigneeCodeArr=[...new Set(consigneeCodeArr)].sort();
    obj.shipperCodeArr=[...new Set(shipperCodeArr)].sort();
    obj.orgDestCode=[...new Set(orgDestCodeArr)].sort();
    obj.driverClassesArr=[...new Set(driverClassArr)].sort();
    obj.tarailersArr=[...new Set(trailerArr)].sort();
    obj.paycodeArr=[...new Set(payCodeArr)].sort();
    obj.terminalArr=[...new Set(terminalArr)].sort();
    setMasterData(obj)
}
  useEffect( () => {
    getData();
  }, [])

  useEffect(() => {
    setDataResult(data);
  }, [data]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
  };

  // this useeffect will run on adding something in filter on removing in filter
 useEffect(() => {
  // it will run when there is no filter in kendo
  if (searchTerms.length <= 0 && sortData.length<=0) {
      let newsearchTerms = [];
      let newSort=[];
      debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
  }else  if (searchTerms.length <= 0 && sortData.length>0) {
    let newsearchTerms = [];
    debouncedSearch(newsearchTerms, sortData, pageNumber, takeNumberOfResult);
}

  // it will run when there is filter in kendo
  if (searchTerms.length > 0) {

      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
          if (
              fieldsToField.includes(obj.field) &&
              (obj.value === null || obj.value === undefined || obj.value === "")
          ) {
              return false; // remove the object
          }
          return true; // keep the object
      });

      setDataState({
          skip: skipNumberOfResult,
          take: takeNumberOfResult,
          filter: {
              logic: "and",
              filters: searchTerm
          },
          sort:sortData,
      })
      debouncedSearch(searchTerm, sortData,pageNumber, takeNumberOfResult);
  }

}, [searchTerms, pageNumber, takeNumberOfResult,sortData]);

const debouncedSearch = useCallback(
  debounce(async (searchTerms, sortData, page, size) => {
       let arr=searchTerms?.map((el)=>{
        let obj={};
        obj[el?.field]=el?.value
        obj.operator=el?.operator;
        return obj
       })
       let tableName="vw_driver_pay_exceptions"
       const search = await searchDriverpayKendo(tableName, arr, sortData, page, size);
      if (search.data.length <= 0) {
          setData(search.data);
          setTotalResult(Number(search?.count[0]?.count));
      }
      if (search.data.length > 0) {
          setData(search.data);
      }
      setTotalResult(Number(search?.count[0]?.count));
  }, 1000),
  []
);

  const clearStateValues=()=>{
    setallField({
      payCode:"",
      orderTerminal:"",
      driverTerminal:"",
      driverLoad:"",
      driverUnload:"",
      shipperCode:"",
      consigneeCode:"",
      commodity:"",
      origdestMatch:"",
      moveTypes:"",
      driverClasses:[],
      trailers:[],
      minWeight:"",
      maxWeight:"",
      otherChargeCode:"",
      originCode:"",
      destinationCode:"",
      orderTypes:[],
      cancelPayment:"",
      additionalUnit:"",
      additionalRate:""
    })
  }

  const openAddModal=()=>{
    clearStateValues();
    setIsAddModalOpen(true);
    }
    const handleEditClose = () => {
      setIsEditModalOpen(false);
      clearStateValues();
    };
    const handleAddClose=()=>{
      setIsAddModalOpen(false);
      clearStateValues();
    }
    const rowClickHandler=(item)=>{
      let obj={...allField}
      masterData?.paycodeArr?.forEach((el)=>{
        if(item?.pay_code!==null && el?.split("-")[0]?.trim() === item?.pay_code){     
          obj.payCode=el;
        }
      })
      masterData?.orgDestCode?.forEach((el)=>{
        if(item?.origincode!==null && el?.split("-")[0]?.trim() === item?.origincode){
          obj.originCode=el
        }else if(item?.destinationcode!==null && el?.split("-")[0]?.trim() === item?.destinationcode){
          obj.destinationCode=el
        }
      })
      masterData?.terminalArr?.forEach((el)=>{
        if(item?.orderterminal!==null && el?.split("-")[0]?.trim() === item?.orderterminal){
          obj.orderTerminal=el;
        }else if(item?.driverTerminal !== null && el?.split("-")[0]?.trim() === item?.driverterminal){

          obj.driverTerminal=el
        }
      })
      masterData?.shipperCodeArr?.forEach((el)=>{
        if(item?.shippercode!==null && el?.split("-")[0]?.trim() === item?.shippercode){     
          obj.shipperCode=el;
        }
      })
      masterData?.consigneeCodeArr?.forEach((el)=>{
        if(item?.consigneeCode!==null && el?.split("-")[0]?.trim() === item?.consigneecode){   
          obj.consigneeCode=el
        }
      })
    
      masterData?.commodityArr?.forEach((el)=>{
        if(item?.commodity!==null && el?.split("-")[0]?.trim() === item?.commodity){     
          obj.commodity=el;
        }
      })

      obj.driverLoad=(item.driverload!==null ? item.driverload :"");
      obj.driverUnload=(item.driverunload!==null ? item.driverunload :"");
      obj.origdestMatch=(item.origdestmatch!==null ? item.origdestmatch :"");
      obj.moveTypes=(item.movetypes!==null ? item.movetypes :"");
      obj.driverClasses=(item.driverclasses!==null && item.driverclasses?.split(",")[0] ? item.driverclasses?.split(",") :[]);
      obj.minWeight=(item.minweight!==null ? item.minweight :"");
      obj.maxWeight=(item.maxweight!==null ? item.maxweight :"");
      obj.orderTypes=(item.ordertypes!==null && item?.ordertypes?.split(",")[0] ? item?.ordertypes?.split(",") :[]);
      obj.additionalRate=(item.additionalrate!==null ? item.additionalrate :"");
      obj.additionalUnit=(item.additionalunits!==null ? item.additionalunits :"");
      obj.cancelPayment=(item.cancelpayment!==null ? item.cancelpayment :"");
      obj.trailers=(item.trailers!==null && item.trailers?.split(",")[0] ? item.trailers?.split(",") :[])
      setallField(obj)
      setSelectedId(item.id);
       setIsEditModalOpen(true)
      }

      const editDriverPayConfigHandler=async()=>{
        const payload={
          pay_code:allField.payCode?.split("-")[0].trim() ,
          orderterminal:allField.orderTerminal?.split("-")[0].trim() ,
          driverterminal:allField.driverTerminal?.split("-")[0].trim() ,
          driverload :allField.driverLoad ,
          driverunload:allField.driverUnload ,
          shippercode :allField.shipperCode?.split("-")[0].trim() ,
          consigneecode:allField.consigneeCode?.split("-")[0].trim() ,
          commodity:allField.commodity?.split("-")[0].trim() ,
          origdestmatch:allField.origdestMatch,
          movetypes:allField.moveTypes,
          driverclasses:allField.driverClasses?.join(","),
          trailers:allField.trailers?.join(","),
          minweight:Number(allField.minWeight),
          maxweight:Number(allField.maxWeight),
          origincode:allField.originCode?.split("-")[0].trim(),
          destinationcode:allField.destinationCode?.split("-")[0].trim(),
          ordertypes:allField.orderTypes?.join(","),
          cancelpayment:allField.cancelPayment,
         updated_by: userData?.email.split('@')[0],
         }

         if(allField?.cancelPayment==='False'){
          if(allField?.additionalRate && allField?.additionalUnit){
            toast.error("Please Fill Either Additional Unit Or Additional Rate", "Error");
            return
          }
          if((allField?.additionalRate && allField?.additionalRate!=="") || (allField?.additionalUnit && allField?.additionalUnit!=="")){
            allField?.additionalRate ? payload.additionalrate=Number(allField?.additionalRate) : ""
            allField?.additionalUnit ? payload.additionalunits=Number(allField?.additionalUnit) : ""
          }else{
            toast.error("Please Fill Either Additional Unit Or Additional Rate Fields.", "Error");
            return
          }
         }

        if (payload.pay_code && payload.cancelpayment) {
         try {
          let tabname = "driver_pay_exceptions"
           const response = await UpdateDriverPayByDistanceExceptionLaneOverride(tabname,payload,selectedId);
           if (response.status === 200) {
             toast.success("Driver pay Lane Exception has been updated successfully.", "Success");
             setIsEditModalOpen(false);
             clearStateValues();
             handleRefresh()
           } else {
             toast.error("Failed to edit driver pay config", "Error");
           }
         } catch(err){
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          }else{
          toast.error("Failed to edit driver pay config", { autoClose: false } );
          }
        }
       } else {
         toast.error("Please fill all fields.", "Error");
       }
      }
  

  const AdddriverpayconfigHandler=async()=>{
    const payload={
      pay_code:allField?.payCode?.split("-")[0].trim() ,
      orderterminal:allField?.orderTerminal?.split("-")[0].trim() ,
      driverterminal:allField?.driverTerminal?.split("-")[0].trim() ,
      driverload :allField?.driverLoad ,
      driverunload:allField?.driverUnload ,
      shippercode :allField?.shipperCode?.split("-")[0].trim() ,
      consigneecode:allField?.consigneeCode?.split("-")[0].trim() ,
      commodity:allField?.commodity?.split("-")[0].trim() ,
      origdestmatch:allField?.origdestMatch,
      movetypes:allField?.moveTypes,
      driverclasses:allField?.driverClasses?.join(","),
      trailers:allField?.trailers?.join(","),
      minweight:Number(allField.minWeight),
      maxweight:Number(allField.maxWeight),
      origincode:allField?.originCode?.split("-")[0].trim(),
      destinationcode:allField?.destinationCode?.split("-")[0].trim(),
      ordertypes:allField?.orderTypes?.join(","),
      cancelpayment:allField?.cancelPayment,
      created_by: userData?.email.split('@')[0],
      
     }
       if(allField?.cancelPayment==='False'){
        if(allField?.additionalRate && allField?.additionalUnit){
          toast.error("Please Fill Either Additional Unit Or Additional Rate", "Error");
          return
        }
        if((allField?.additionalRate && allField?.additionalRate!=="") || (allField?.additionalUnit && allField?.additionalUnit!=="")){
          allField?.additionalRate ? payload.additionalrate=Number(allField?.additionalRate) : ""
          allField?.additionalUnit ? payload.additionalunits=Number(allField?.additionalUnit) : ""
        }else{
          toast.error("Please Fill Either Additional Unit Or Additional Rate Fields.", "Error");
          return
        }
       }
     
     if (payload.pay_code && payload.cancelpayment) {
      try {
       let tabname = "driver_pay_exceptions"
        const response = await AddDriverPayByDistanceExceptionLaneOverride(tabname,payload);
        if (response.status === 200) {
          toast.success("Driver pay Lane Exception has been created successfully.", "Success");
          setIsAddModalOpen(false);
          clearStateValues();
          handleRefresh()
        } else {
          toast.error("Failed to add driver pay config", "Error");
        }
      }catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error("Failed to add driver pay config", { autoClose: false } );
        }
      }
    } else {
      toast.error("Please fill all mandatory fields.", "Error");
    }
   }

   const deletedriverpayconfighandler=async()=>{
    
    try {
    
       let tabname = "driver_pay_exceptions"
      
      const response = await DeleteDriverPayByException(tabname,selectedId);
      if (response.status === 200) {
        toast.success("Driver pay Lane Exception Data has been Deleted successfully.", "Success");
        setIsEditModalOpen(false);
        setIsDeleteModalOPen(false);
        clearStateValues();
        handleRefresh()
      } else {
        toast.error("Failed to delete driver pay config", "Error");
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error("Failed to delete driver pay config", { autoClose: false } );
      }
    }
   }

   const handleChange=(value,name)=>{
  setallField({...allField,[name]:value})
   }

    // when we edit or add this function will fetch all latest data
 const handleRefresh=()=>{
  debouncedSearch(dataState?.filter?.filters ?? [], dataState.sort ?? [], pageNumber, takeNumberOfResult);
 }
 const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay_exceptions");
  };

  return (
    <>

    <div className='row special_row_flex'>
      <div className='col-xl-12'>
        <div className='card card_shadow'>
          <div className='card-body'>

          <div className='row'>
                <div className='col-md-5 textadjust_terminal'>
                </div>
                <div className='col-md-7 textadjust_terminaladd_button '>
                  <div className='add_icon'>
                    <button
                      type='button'
                      className='btn_blue btn-blue mr_10 mb-20 '
                      onClick={openAddModal}
                      disabled={isEditDisable}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>

            <div className='table-responsive'>
              {data?.length >= 0 ?
                <Grid
                filter={dataState.filter}
                filterable={true}
                filterOperators={filterOperators}
                expandField="expanded"
                sort={dataState.sort}
                sortable={true}
                pageable={{
                 pageSizes: [25, 50, 100],
                 info: true,
                 previousNext: true,
                 buttonCount: 10,
                }}
               resizable={true}
               skip={dataState.skip}
               take={dataState.take}
               data={dataResult}
               onDataStateChange={dataStateChange}
               onRowClick={(e) => rowClickHandler(e.dataItem)}
               total={totalResult}
                >
                   <GridColumn
                    field='id'
                    title='ID'
                    filterable={true}
                    filter='numeric'
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.id
                            ? e.dataItem.id
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='orderterminal_fullname'
                    title='Order Terminal'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.orderterminal_fullname
                            ? e.dataItem.orderterminal_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='driverterminal_fullname'
                    title='Driver Terminal'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driverterminal_fullname
                            ? e.dataItem.driverterminal_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='driver_pay_code'
                    title='Pay Code'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driver_pay_code
                            ? e.dataItem.driver_pay_code
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='driverload'
                    title='Driver Load'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driverload
                            ? e.dataItem.driverload
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='driverunload'
                    title='Driver Unload'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driverunload
                            ? e.dataItem.driverunload
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='shippercode_location_fullname'
                    title='Shipper Code'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.shippercode_location_fullname
                            ? e.dataItem.shippercode_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='consigneecode_location_fullname'
                    title='Consignee Code'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.consigneecode_location_fullname
                            ? e.dataItem.consigneecode_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='commodity_fullname'
                    title='Commodity'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.commodity_fullname
                            ? e.dataItem.commodity_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='origdestmatch'
                    title='Origin Destination Match'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.origdestmatch
                            ? e.dataItem.origdestmatch
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='movetypes'
                    title='Move Types'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.movetypes
                            ? e.dataItem.movetypes
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='driverclasses'
                    title='Driver Classes'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driverclasses
                            ? e.dataItem.driverclasses
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='trailers'
                    title='Trailers'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.trailers
                            ? e.dataItem.trailers
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='minweight'
                    title='Min Weight'
                    filter='numeric'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.minweight
                            ? e.dataItem.minweight
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='maxweight'
                    title='Max Weight'
                    filterable={true}
                    filter='numeric'
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.maxweight
                            ? e.dataItem.maxweight
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='origin_location_fullname'
                    title='Origin Code'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.origin_location_fullname
                            ? e.dataItem.origin_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='destination_location_fullname'
                    title='Destination Code'
                    filterable={true}
                    width="200px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.destination_location_fullname
                            ? e.dataItem.destination_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='ordertypes'
                    title='Order Types'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.ordertypes
                            ? e.dataItem.ordertypes
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='cancelpayment'
                    title='Cancel Payment'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.cancelpayment
                            ? e.dataItem.cancelpayment
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='additionalunits'
                    title='Additional Units'
                    filter='numeric'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.additionalunits
                            ? e.dataItem.additionalunits
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='additionalrate'
                    title='Additional Rate'
                    filter='numeric'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.additionalrate
                            ? e.dataItem.additionalrate
                            : ""}
                        </td>
                      );
                    }}
                  />
                   <GridColumn
                         field='Action'
                         title='Action'
                         width='150px'
                         cell={(e) => {
                           return(
                             <td>
                               <button
                                 type="button"
                                 className="btn-blue_schedule "
                                 onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                               >
                                 Audit Log
                               </button>
                             </td>
                           );
                         }}
                        />

                </Grid> :
                <div>
                  <ProgressBar animated now={100} />
                  <div className='middle loader--text1'> </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>

    <AuditLogModalComponent ref={auditLogComponentRef} />

     {/* Add Driver Pay Exception */}
     <Modal
        show={isAddModalOpen}
        onHide={handleAddClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add Driver Pay Exception
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"750px",overflowY:"auto"}}>
          <div className="mt_10">
          <div className="d-flex justify-content-center gap-4 mt_10">
            <div className='mt_10 w-50'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Order Terminal</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.terminalArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"orderTerminal")}
                    value={allField?.orderTerminal}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Order Terminal"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Origin Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.orgDestCode}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"originCode")}
                    value={allField?.originCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Origin Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Shipper Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.shipperCodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"shipperCode")}
                    value={allField?.shipperCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Shipper Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <div className='mt_10'>
              <Autocomplete
                    id="combo-box-demo"
                    options={masterData.paycodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"payCode")}
                    value={allField?.payCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Pay Code"
                        variant="outlined"
                      />
                    )}
                  />
              </div>
            </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Load</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.driverloadunload}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverLoad")}
                    value={allField?.driverLoad}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Load"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Min Weight</label>
              <div className='mt_10'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.minWeight}
                  type='number'
                  name='minutes'
                  placeholder='Enter Min Weight'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"minWeight") }}
                  disabled={isEditDisable}
                />
                </div>
            </div>     
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Origin Destination Match</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.booleanValues}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"origdestMatch")}
                    value={allField?.origdestMatch}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            {allField?.cancelPayment === 'False' ? 
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Addtional Units</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.additionalUnit}
                  type='number'
                  name='minutes'
                  placeholder='Enter Additional Unit'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"additionalUnit") }}
                  disabled={isEditDisable || allField?.cancelPayment === 'True'}
                />
              </div>
            </div>:<></>
             }
            </div>

            <div className='mt_10 w-50'>         
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Terminal</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.terminalArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverTerminal")}
                    value={allField?.driverTerminal}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Driver Terminal"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>       
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Destination Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.orgDestCode}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"destinationCode")}
                    value={allField?.destinationCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Destination Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Consignee Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.consigneeCodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"consigneeCode")}
                    value={allField?.consigneeCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Consignee Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Move Types</label>
            <div className='mt_10'>
                 <Autocomplete
                    id="combo-box-demo"
                    options={masterData.moveTypes}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"moveTypes")}
                    value={allField?.moveTypes}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Move Types"
                        variant="outlined"
                      />
                    )}
                  />
                  </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Driver Unload</label>
              <div className='mt_10'>
              <Autocomplete
                id="combo-box-demo"
                options={masterData.driverloadunload}
                getOptionLabel={(option) => option }
                onChange={(e,option)=>handleChange(option,"driverUnload")}
                value={allField?.driverUnload}
                disabled={isEditDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    placeholder="Choose Driver Unload"
                    variant="outlined"
                    disabled={isEditDisable}
                  />
                )}
              />
              </div>
            </div>
            </div>    
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Max Weight</label>
              <div className='mt_10'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.maxWeight}
                  type='number'
                  name='minutes'
                  placeholder='Enter Max Weight'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"maxWeight") }}
                  disabled={isEditDisable}
                />
              </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Cancel Payment*</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.booleanValues}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"cancelPayment")}
                    value={allField?.cancelPayment}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            {allField?.cancelPayment === 'False' ? 
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Additional Rate</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.additionalRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Additional Rate'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"additionalRate") }}
                  disabled={isEditDisable || allField?.cancelPayment === 'True'}
                />
              </div>
            </div>:<></>
             }
            </div>
          </div>
          <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Commodity</label>
                <div className='mt_10'>
                 <Autocomplete
                id="combo-box-demo"
                options={masterData.commodityArr}
                getOptionLabel={(option) => option }
                onChange={(e,option)=>handleChange(option,"commodity")}
                value={allField?.commodity}
                disabled={isEditDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    placeholder="Enter Commodity"
                    variant="outlined"
                    disabled={isEditDisable}
                  />
                )}
              />
                </div>
            </div>
            </div>
                 
          <div className='mt_10'>
          <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Trailers</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData.tarailersArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"trailers")}
                    value={[...allField?.trailers]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Trailer"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
          </div>
            </div>

            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Order Types</label>
                <div className='mt_10'>                  
                 <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData.orderTypeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>{handleChange(option,"orderTypes")}}
                    value={[...allField?.orderTypes]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Order Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
           </div>

           <div className='mt_10'>
          <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Driver Classes</label>
                <div className='mt_10'>
                <Autocomplete
                    multiple 
                    limitTags={1} 
                    id="multiple-limit-tags"
                    options={masterData.driverClassesArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverClasses")}
                    value={[...allField?.driverClasses]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Driver Classes"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddClose}>Close</Button>
          <Button
            type='submit'
             onClick={AdddriverpayconfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      {/* EDit Driver Pay Exception */}

      <Modal
        show={isEditModalOpen}
        onHide={handleEditClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Driver Pay Exception
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"750px",overflowY:"auto"}}>
          <div className="mt_10">
          <div className="d-flex justify-content-center gap-4 mt_10">
            <div className='mt_10 w-50'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Order Terminal</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.terminalArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"orderTerminal")}
                    value={allField?.orderTerminal}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Order Terminal"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Origin Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.orgDestCode}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"originCode")}
                    value={allField?.originCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Origin Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Shipper Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.shipperCodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"shipperCode")}
                    value={allField?.shipperCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Shipper Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <div className='mt_10'>
              <Autocomplete
                    id="combo-box-demo"
                    options={masterData.paycodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"payCode")}
                    value={allField?.payCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Pay Code"
                        variant="outlined"
                      />
                    )}
                  />
              </div>
            </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Load</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.driverloadunload}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverLoad")}
                    value={allField?.driverLoad}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Load"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Min Weight</label>
              <div className='mt_10'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.minWeight}
                  type='number'
                  name='minutes'
                  placeholder='Enter Min Weight'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"minWeight") }}
                  disabled={isEditDisable}
                />
                </div>
            </div>     
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Origin Destination Match</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.booleanValues}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"origdestMatch")}
                    value={allField?.origdestMatch}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            {allField?.cancelPayment === 'False' ? 
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Addtional Units</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.additionalUnit}
                  type='number'
                  name='minutes'
                  placeholder='Enter Additional Unit'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"additionalUnit") }}
                  disabled={isEditDisable || allField?.cancelPayment === 'True'}
                />
              </div>
            </div>:<></>
             }
            </div>

            <div className='mt_10 w-50'>         
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Terminal</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.terminalArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverTerminal")}
                    value={allField?.driverTerminal}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Driver Terminal"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>       
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Destination Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.orgDestCode}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"destinationCode")}
                    value={allField?.destinationCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Destination Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Consignee Code</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    options={masterData.consigneeCodeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"consigneeCode")}
                    value={allField?.consigneeCode}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Consignee Code"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Move Types</label>
            <div className='mt_10'>
                 <Autocomplete
                    id="combo-box-demo"
                    options={masterData.moveTypes}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"moveTypes")}
                    value={allField?.moveTypes}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Move Types"
                        variant="outlined"
                      />
                    )}
                  />
                  </div>
            </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Driver Unload</label>
              <div className='mt_10'>
              <Autocomplete
                id="combo-box-demo"
                options={masterData.driverloadunload}
                getOptionLabel={(option) => option }
                onChange={(e,option)=>handleChange(option,"driverUnload")}
                value={allField?.driverUnload}
                disabled={isEditDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    placeholder="Choose Driver Unload"
                    variant="outlined"
                    disabled={isEditDisable}
                  />
                )}
              />
              </div>
            </div>
            </div>    
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Max Weight</label>
              <div className='mt_10'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.maxWeight}
                  type='number'
                  name='minutes'
                  placeholder='Enter Max Weight'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"maxWeight") }}
                  disabled={isEditDisable}
                />
              </div>
            </div>
            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Cancel Payment*</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={masterData.booleanValues}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"cancelPayment")}
                    value={allField?.cancelPayment}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
            {allField?.cancelPayment === 'False' ? 
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Additional Rate</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.additionalRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Additional Rate'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"additionalRate") }}
                  disabled={isEditDisable || allField?.cancelPayment === 'True'}
                />
              </div>
            </div>:<></>
             }
            </div>
          </div>
          <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Commodity</label>
                <div className='mt_10'>
                 <Autocomplete
                id="combo-box-demo"
                options={masterData.commodityArr}
                getOptionLabel={(option) => option }
                onChange={(e,option)=>handleChange(option,"commodity")}
                value={allField?.commodity}
                disabled={isEditDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    placeholder="Enter Commodity"
                    variant="outlined"
                    disabled={isEditDisable}
                  />
                )}
              />
                </div>
            </div>
            </div>
                 
          <div className='mt_10'>
          <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Trailers</label>
                <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData.tarailersArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"trailers")}
                    value={[...allField?.trailers]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Trailer"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
          </div>
            </div>

            <div className='mt_10'>
            <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Order Types</label>
                <div className='mt_10'>                  
                 <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData.orderTypeArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>{handleChange(option,"orderTypes")}}
                    value={[...allField?.orderTypes]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Order Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
           </div>

           <div className='mt_10'>
          <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Driver Classes</label>
                <div className='mt_10'>
                <Autocomplete
                    multiple 
                    limitTags={1} 
                    id="multiple-limit-tags"
                    options={masterData.driverClassesArr}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"driverClasses")}
                    value={[...allField?.driverClasses]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Driver Classes"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
            </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleEditClose}>Close</Button>
          <Button
            type='submit'
             onClick={editDriverPayConfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
          <Button onClick={()=>setIsDeleteModalOPen(true)} color="error">Delete</Button>
        </Modal.Footer>
      </Modal>

        
        {/* Delete Driver Pay Exception */}

        <Modal
        show={isDeleteModalOpen}
        onHide={()=>setIsDeleteModalOPen(false)}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Delete Driver Pay Exception
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize:"18px",color:"red",textAlign:"center",height:"80px",paddingTop:"30px"}}>
          Do You Want To Delete This Data?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setIsDeleteModalOPen(false)}>Close</Button>
          <Button
            type='submit'
             onClick={deletedriverpayconfighandler}
            disabled={isEditDisable}
          >
            Delete
          </Button>
        </Modal.Footer>
        </Modal>

    </>
  )
}

export default DriverPayEXceptionTable