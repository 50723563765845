import { useState, useEffect, useContext } from 'react';
import { toast } from "react-toastify";
import { requestForToken, useFirebaseMessageAlert } from '../../firebase';
import { ContextData } from "../../components/appsession";
import { updateFirebaseToken } from "../../services/userService.js";
import errorMessageDisplay from '../../utils/errorMessageDisplay.js';

const PushNotification = () => {
  const [userData] = useContext(ContextData);
  const [totalAssignedPlanningProfiles, setTotalAssignedPlanningProfiles] = useState({});
  const { notificationData } = useFirebaseMessageAlert()

  const handleClick = () => {
    window.location.href = notificationData?.data?.url;
  };

  const notify = () => toast.info(<ToastDisplay />, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    icon: false,
    onClick: handleClick
  });

  function ToastDisplay() {
    return (
      <>
        <div style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>{notificationData?.title}</div>
        <div style={{fontSize: "16px"}}>{notificationData?.body}</div>
      </>
    );
  };

  useEffect(() => {
    if (notificationData?.data) {
      if (!notificationData?.isSilentNotification) {
        notify();
      }
    }
  }, [notificationData]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setTotalAssignedPlanningProfiles(userData?.planningprofiles);
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      try {
        if (Object.keys(userData).length > 0 && Object.keys(totalAssignedPlanningProfiles).length > 0) {
          // Getting firebase client token
          const token = await requestForToken();
          
          if (totalAssignedPlanningProfiles.length !== userData.planningprofiles.length || token !== userData?.push_notification_token?.fbClientToken) {
            const payload = {
              fbClientToken: token,
              planningProfiles: userData.planningprofiles
            };

            await updateFirebaseToken(userData?.id, payload);
          }
        }
      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to store token', { autoClose: false } );
        }
      }
    })();


  }, [userData, totalAssignedPlanningProfiles]);

  return (
    <>
      {/* <ToastContainer /> */}
    </>
  )
}

export default PushNotification;
