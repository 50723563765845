import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import TankwashprocedureHeader from "../../components/tankwashprocedureHeader/tankwashprocedureHeader";
import Tankwashproceduretable from "../../components/tankwashproceduretable/tankwashproceduretable";
import { ContextData } from "../../components/appsession";


function Tankwashprocedure() {
  const [tankwashprocedureClicked, settankwashprocedureClicked] = useState(false);
  const [tankwashprocedureById, settankwashprocedureById] = useState({});
  const [userData, setuserData] = useContext(ContextData);
  const [isDisabled, setIsDisabled] = useState(false);
 
  const handelcallback = (childdata, tankwashdata) => {
    settankwashprocedureById(tankwashdata);
    settankwashprocedureClicked(childdata);
  };

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission;
    permission?.map((permit) => {
      if (
        permit?.permissiondetail.code === "WashTypeProcedures" &&
        permit?.can_edit === false
      ) {
        setIsDisabled(true);
      }
    });
  };

  useEffect( () => {
    checkAccess()
  }, [userData]);



  return (

    <div id="wrapper">

      <Header
       userclicked={tankwashprocedureClicked}
       //parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
   
    {!tankwashprocedureClicked ? (
              <>
              
               <TankwashprocedureHeader
            parentcallback={handelcallback}
            tankwashprocedureById={tankwashprocedureById}
            accessDisabled={isDisabled}
               />
               <Tankwashproceduretable accessDisabled={isDisabled} userData={userData}/>
              
               
              </>
            ) : (
              
             ""
             
            )}
    </div>
    </div>

    </div>
    </div>

  );

}
export default Tankwashprocedure;