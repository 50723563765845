import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
import Button from "react-bootstrap/Button";




const DriverPayModalEditApproval = (props) => {
    const { showMovementModal, handleCloseEditModal, MovementData, setDestination, setOrigin, Setmovementid, setOrderId ,setidMovementEdited} = props;
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [],
        },
        sort: [],
    });
    const [dataResult, setDataResult] = useState(
        process(MovementData, dataState)
    );

    const dataStateChange = (event) => {
        setDataResult(process(MovementData, event.dataState));
        setDataState(event.dataState);
    };


    useEffect(() => {
        setDataResult(process(MovementData, dataState))
    }, [MovementData])

    const style = {
        position: 'absolute',
        height: 'max-content',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        bgcolor: 'background.paper',
        p: 2,
    };

    const rowClickHandler = (dataItem) => {
        console.log(dataItem)
        setidMovementEdited(true);
        setOrigin({
            actual_arrive_origin: dataItem?.actual_arrive_origin,
            actual_depart_origin: dataItem?.actual_depart_origin,
            duration_at_origin_stop: dataItem?.duration_at_origin_stop,
            drive_time: dataItem?.drive_time,
            pickuptimezone: dataItem?.pickuptimezone
        });
        setDestination({
            actual_arrive_destination: dataItem?.actual_arrive_destination,
            actual_depart_destination: dataItem?.actual_depart_destination,
            duration_at_destination_stop: dataItem?.duration_at_destination_stop,
            total_time: dataItem?.total_time,
            deliverytimezone: dataItem?.deliverytimezone
        });
        setOrderId(dataItem.order_id)
        Setmovementid(dataItem.load_id)
        handleCloseEditModal();
    }

    const handleclosemodal = () => {
        setDataState({
            skip: 0,
            take: 25,
            filter: {
                logic: "and",
                filters: [],
            },
            sort: [],
        })
        handleCloseEditModal();
    }

    const convertdatetime = (duration) => {
        if (duration) {
            let date;
            if (isNaN(duration)) {
                date = Date.parse(duration);
            } else {
                date = parseInt(duration);
            }
            return DateTime.fromMillis(date).toFormat("MM-dd-yyyy").toString();
        } else {
            return "No Data";
        }
    };
    const formatDate = (epoch_date, tzone) => {
        console.log(tzone)
        if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
            tzone = "America/New_York"
        }
        if (!tzone || tzone === "Central Standard Time" || tzone === "CDT" || tzone === "CST" || tzone === "America/Chicago") {
            tzone = "America/Chicago"
        }
        if (epoch_date > 0) {
            return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
        }
        else {
            return "No Data"
        }

    }


    return (
        <>
            <Modal
                open={showMovementModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                        <div className="modal-title h4" id="contained-modal-title-vcenter" style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', textDecoration: 'none' }}>
                            Driver Pay Movement
                        </div>
                        <IconButton sx={{ ml: 'auto' }}>
                            <CloseIcon onClick={handleclosemodal} />
                        </IconButton>
                    </Typography>
                    <Typography>
                        <div>
                            <Grid
                                filter={dataState.filter}
                                sort={dataState.sort}
                                filterable={true}
                                sortable={true}
                                expandField="expanded"
                                pageable={{
                                    pageSizes: [25, 50, 100],
                                    info: true,
                                    previousNext: true,
                                    buttonCount: 10,
                                }}
                                resizable={true}
                                skip={dataState.skip}
                                take={dataState.take}
                                data={dataResult}
                                onDataStateChange={dataStateChange}
                                onRowClick={() => { }}
                            >

                                <GridColumn
                                    title="Action"
                                    filterable={false}
                                    cell={(cellProps) => {
                                        return (
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => rowClickHandler(cellProps.dataItem)}
                                                >
                                                    Select
                                                </Button>
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='order_id'
                                    title='Order ID'
                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.order_id ? e.dataItem.order_id : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                 <GridColumn
                                    field='order_id'
                                    title='Order Type'
                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.order_type_id ? e.dataItem.order_type_id : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='bol1'
                                    title='BOL'
                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.bol1 ? e.dataItem.bol1 : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='load_id'
                                    title='Movement ID'
                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.load_id ? e.dataItem.load_id : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                 <GridColumn
                                    field='movetype'
                                    title='Loaded'

                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                 {e.dataItem.movetype ? e.dataItem.movetype : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='origin'
                                    title='Origin'
                                    filterable={true}
                                    cell={(e) => {
                                        // const Origin = `${e.dataItem.pay_code ? e.dataItem.pay_code : "No Data"} - ${e.dataItem.pay_description ? e.dataItem.pay_description.toUpperCase() : "No Data"}`
                                        return (
                                            <td style={{ padding: '25px' }}>
                                                {e.dataItem.origin ? `${e.dataItem.origin}` : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='destination'
                                    title='Destination'
                                    filterable={true}
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.destination ? `${e.dataItem.destination}` : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='origin_actual_arrival'
                                    title='Origin Actual Arrival'
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.origin_actual_arrival ? formatDate(e.dataItem.actual_arrive_origin, e.dataItem.pickuptimezone) : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='origin_actual_depart'
                                    title='Origin Actual Depart'
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.origin_actual_depart ? formatDate(e.dataItem.actual_depart_origin, e.dataItem.pickuptimezone) : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='destination_actual_arrival'
                                    title='Destination Actual Arrival'
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.destination_actual_arrival ? formatDate(e.dataItem.actual_arrive_destination, e.dataItem.deliverytimezone) : "No Data"}
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    field='destination_actual_depart'
                                    title='Destination Actual Depart'
                                    cell={(e) => {
                                        return (
                                            <td>
                                                {e.dataItem.destination_actual_depart ? formatDate(e.dataItem.actual_depart_destination, e.dataItem.deliverytimezone) : "No Data"}
                                            </td>
                                        );
                                    }}
                                />


                            </Grid>
                        </div>

                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography>
                            <Box marginTop={2} marginBottom={2} marginLeft="auto">
                                <Button variant="primary" color="primary" onClick={handleclosemodal}>
                                    Cancel
                                </Button>
                            </Box>
                        </Typography>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default DriverPayModalEditApproval