import React, { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";


const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: 'none',
    backgroundColor: "#4267B2",
    borderColor: "#2C4F95",
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
    padding: "12px",
    borderRadius: "10px"
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px"
  },
}));
const formatDate = (epoch_date, tzone) => {
  if (!tzone) {
    tzone = "America/New_York"
  }
  return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
}

const Tankwashtooltip = (props) => {
  const onClickHandler = 'onClick' in props ? props.onClick : () => { };
  const classes = useStyles();
  return (
    <Tooltip title={
      <React.Fragment>
        <Typography color="inherit">
          {props?.tooltip}
        </Typography>
      </React.Fragment>
    }
      onClick={onClickHandler}
      placement="top"
      classes={{
        tooltip: classes?.customTooltip,
        arrow: classes?.customArrow,
      }}
      arrow
    >
      {props?.children}
    </Tooltip>
  );
}

const TankwasdataTooltip = (props) => {
  const it = props.it;
  let timeZone = props.selectedLocation.timezone
  return (
    <span className="tooltip-adjust">
      {/* LEFT SIDE  */}
      <div className="tankwash_main_tooltip_section_other">
        <div className="tooltip_tankwash_inner_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Work Order : </div>
            <div className="tooltip_text_right">   {it?.id !== null ? it?.id : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">{it?.trailerid? "Trailer ID" : "Tractor ID"} : </div>
            <div className="tooltip_text_right">{it?.trailerid ? it?.trailerid : it?.tractorid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Start Time : </div>
            <div className="tooltip_text_right"> {it?.start != null ? formatDate(it?.start, timeZone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">End Time : </div>
            <div className="tooltip_text_right"> {it?.end != null ? formatDate(it?.end, timeZone) : "No Data"}</div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">In Time : </div>
            <div className="tooltip_text_right"> {it.in_date_utc? it.in_date_utc : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Out Time : </div>
            <div className="tooltip_text_right">   {it.out_date_utc?it.out_date_utc : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Needed Time : </div>
            <div className="tooltip_text_right"> {it.needed_date?it.needed_date : "No Data"}</div>
            <div className="tooltip_text_right"> {it?.start !== null ? formatDate(it?.start) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">End Time : </div>
            <div className="tooltip_text_right">   {it?.end !== null ? formatDate(it?.end) : "No Data"}</div>
          </div> */}
        </div>
        {/* RIGHT SIDE  */}
        <div className="tooltip_tankwash_inner_right">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Int wash Code : </div>
            {/* <div className="tooltip_text_right"> {it?.int_wash_description ? it?.int_wash_description : "No Data"}<span>({it?.int_wash_code})</span></div> */}
            {/* <div className="tooltip_text_right">{it?.int_wash_description ? `${it.int_wash_description} (${it.int_wash_code})` : "No Data"}</div> */}
            <div className="tooltip_text_right">
              {it?.int_wash_description && it?.int_wash_code
                ? `${it.int_wash_description} (${it.int_wash_code})`
                : it?.int_wash_description
                ? it.int_wash_description
                : it?.int_wash_code
                ? it.int_wash_code
                : "No Data"}
            </div>

          </div>
          {it?.needed_date?(
            <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Needed By : </div>
            <div className="tooltip_text_right"> {it?.needed_date ? formatDate(it?.needed_date, timeZone) : "No Data"}</div>
          </div>
          ):(
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Needed By : </div>
            <div className="tooltip_text_right"> {it?.needed_date_utc}</div>
          </div>
          )}
          {it?.in_date?(
            <div className="tooltip_single_sec">
            <div className="tooltip_text_left">In Time : </div>
            <div className="tooltip_text_right"> {it?.in_date ? formatDate(it?.in_date, timeZone) : "No Data"}</div>
          </div>
          ):(
            <div className="tooltip_single_sec">
            <div className="tooltip_text_left">In Time : </div>
            <div className="tooltip_text_right"> {it?.in_date_utc}</div>
          </div>
          )
        }
          {it?.out_date?(
         <div className="tooltip_single_sec">
         <div className="tooltip_text_left">Out Time : </div>
         <div className="tooltip_text_right">{it?.out_date ? formatDate(it?.out_date, timeZone) : "No Data"}</div>
       </div>
          ):(
            <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Out Time : </div>
            <div className="tooltip_text_right">{it?.out_date_utc}</div>
          </div>
          )}
          
        </div>
      </div>
    </span>
  );
}

export {
  TankwasdataTooltip
};
export default Tankwashtooltip;