

// Application Specific
import Yard from '../models/yardModel';
import { BaseConfig } from "../baseConfig";

import axios from 'axios';



export const addYard = async (data) => {

    const yard = new Yard();
    let yardObject = []
    const url = BaseConfig.ApiEndPoint + "/addyardcheck";
    const yardApiData = await axios.post(url, data);
    return Promise.resolve(yardApiData);
}
export const addYardChkTrailer = async (data) => {
    const yard = new Yard();
    let yardObject = []
    try {
        if (!this.isIntegrated) {
            // do
        }
        else {

            const url = BaseConfig.ApiEndPoint + "/addyardchecktrailer";

            const yardApiData = await axios.post(url, data);
            //yardObject = yardApiData.data.data.map(data => yard.parseApiYardObject(data));
            let Addyardchecktrailer = yardApiData.data
            yardObject = Addyardchecktrailer

        }
    }
    catch (err) {
        return Promise.reject(" This Trailer Has Already Been Added");
    }
    return Promise.resolve(yardObject);
}


export const submitYardChkTrailer = async (data) => {
    const yard = new Yard();
    let yardObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/submityardcheck";
        const yardApiData = await axios.post(url, data);
        yardObject = yardApiData.data.data.map(data => yard.parseApiYardObject(data));
        if (yardObject.length > 0) {
            return Promise.resolve(yardObject);
        }
    } catch (error) {
        return Promise.resolve(yardObject);
    }


}
export const updateYardChkTrailer = async (data) => {
    const yard = new Yard();
    let yardObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/updateyardcheck";
        const yardApiData = await axios.post(url, data);
        let updatedList = yardApiData.data.data
        yardObject = updatedList
    }
    catch (error) {
    }
    return Promise.resolve(yardObject);

}
export const getbyyards = async (terminalId) => {
    const yard = new Yard();
    let yardObject = [];
    const url = BaseConfig.ApiEndPoint + "/yardchecks";
    const data = {
        "terminalid": terminalId,
    }
    try {
        const yardApiData = await axios.post(url, data);
        yardObject = yardApiData.data.data.map(data => yard.parseApiYardObject(data));
        return Promise.resolve(yardObject);
    }

    catch (error) {
    }
}

export const getYardCheckInformation = async(id) => {
    const yard = new Yard();
    let yardCheckObject = []
    const url = BaseConfig.ApiEndPoint + "/yardchecks/" + id;
    try {
        yardCheckObject = await axios.get(url);
        return Promise.resolve(yardCheckObject?.data);
    }
    catch (error) {
    }

}