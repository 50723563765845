import React, { useState, useEffect, useContext, useRef } from "react";
import { Navbar } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import { toast } from "react-toastify";
import { getTrailerWashWorkOrdersforplanningboard, getAllDataByGenericAPI } from "../../services/tankWashService";
import { useQuery } from "../../hooks";
import { ContextData } from '../../components/appsession';
import { updateSelectedPlanningProfileOfUser } from "../../services/userService";

const TankwashPage = () => {
  const [userData] = useContext(ContextData);
  const [columnorder, setcolumnorder] = useState([]);
  const [columnorderExcel, setcolumnorderExcel] = useState([]);
  const [orders, setOrders] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    sort: [
      {
        field: "wash_id",
        dir: "asc",
      },
    ],
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const query = useQuery();
  const terminalid = query.get("terminal_id");
  const washLocationId = query.get("wash_location_id");
  const tabSelected = query.get("tab_selected");

  const _export = useRef(null);

  let columns = [
    { id: 0, name: "expanded", title: "" },
    { id: 1, name: "wash_location_id", title: "Wash Location Id", width: "280px" },
    { id: 2, name: "wash_id", title: "Wash Id", width: "280px" },
    { id: 3, name: "status", title: "Status", width: "280px" },
    { id: 4, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 5, name: "commodity_id", title: "Commodity Id", width: "280px" },
    { id: 6, name: "int_wash_code", title: "Int Wash Code", width: "280px" },
    { id: 7, name: "ext_wash_code", title: "Ext Wash Code", width: "280px" },
    { id: 8, name: "needed_date_utc", title: "Needed Date", width: "280px" },
    { id: 9, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    { id: 10, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
    { id: 11, name: "company_id", title: "Company Id", width: "280px" },
    { id: 12, name: "charge_type", title: "Charge Type", width: "280px" },
    { id: 13, name: "customer_id", title: "Customer Id", width: "280px" },
    { id: 14, name: "driver_id", title: "Driver Id", width: "280px" },
    { id: 15, name: "entered_user_id", title: "Entered User Id", width: "280px" },
    { id: 16, name: "equip_type", title: "Equip Type", width: "280px" },
    { id: 17, name: "ext_wash_amount", title: "Ext Wash Amount", width: "280px" },
    { id: 18, name: "ext_wash_amount_c", title: "Ext_Wash_Amount C", width: "280px" },
    { id: 19, name: "ext_wash_amount_d", title: "Ext wash Amount D", width: "280px" },
    { id: 20, name: "ext_wash_amount_n", title: "Ext wash Amount N", width: "280px" },
    { id: 21, name: "ext_wash_amount_r", title: "Ext wash Amount R", width: "280px" },
    { id: 22, name: "hazmat_code", title: "Hazmat Code", width: "280px" },
    { id: 23, name: "heel", title: "Heel", width: "280px" },
    { id: 24, name: "heel_unit_type", title: "Heel Unit Type", width: "280px" },
    { id: 25, name: "int_wash_amount", title: "Int Wash Amount", width: "280px" },
    { id: 26, name: "int_wash_amount_c", title: "Int Wash Amount C", width: "280px" },
    { id: 27, name: "int_wash_amount_d", title: "Int Wash Amount D", width: "280px" },
    { id: 28, name: "int_wash_amount_n", title: "Int Wash Amount N", width: "280px" },
    { id: 29, name: "int_wash_amount_r", title: "Int Wash Amount R", width: "280px" },
    { id: 30, name: "intra_co_seg_code", title: "Intra Co Seg Code", width: "280px" },
    { id: 31, name: "invoice_po_date", title: "Invoice Po Date", width: "280px" },
    { id: 32, name: "invoice_po_num", title: "Invoice Po Num", width: "280px" },
    { id: 33, name: "movement_id", title: "Movement Id", width: "280px" },
    { id: 34, name: "order_id", title: "Order Id", width: "280px" },
    { id: 35, name: "other_equip", title: "Other Equip", width: "280px" },
    { id: 36, name: "ready_to_xfer", title: "Ready To Xfer", width: "280px" },
    { id: 37, name: "seg_alloc_code", title: "Seg Alloc Code", width: "280px" },
    { id: 38, name: "tractor_id", title: "Tractor Id", width: "280px" },
    { id: 39, name: "vendor_id", title: "Vendor Id", width: "280px" },
    { id: 40, name: "void", title: "Void", width: "280px" },
    { id: 41, name: "voucher_id", title: "Voucher Id", width: "280px" },
    { id: 42, name: "wash_date_utc", title: "Wash Date", width: "280px" },
    { id: 43, name: "wash_location_id", title: "Wash Location Id", width: "280px" },
    { id: 44, name: "washed_for", title: "Washed For", width: "280px" },
    { id: 45, name: "tank_wash_assigned", title: "Tank Wash Assigned", width: "280px" },
    { id: 46, name: "trailer_wash_assigned", title: "Trailer Wash Assigned", width: "280px" },
    { id: 47, name: "commodity_desc2", title: "Commodity Desc2", width: "280px" },
    { id: 48, name: "text", title: "Text", width: "280px" },
    { id: 49, name: "bay", title: "Bay", width: "280px" },
    { id: 50, name: "source", title: "Source", width: "280px" },
    { id: 51, name: "resource", title: "Resource", width: "280px" },
    { id: 52, name: "history", title: "History", width: "280px" },
    { id: 53, name: "start_time_utc", title: "Start Time Utc", width: "280px" },
    { id: 54, name: "end_time_utc", title: "End Time Utc", width: "280px" },
];

  const fetchOrders = async () => {
    try {
      if (terminalid) {
        const payload = { terminal_id: terminalid };
        const result = await getTrailerWashWorkOrdersforplanningboard(payload);

        result.forEach(function (result, index) {
          result.history = result.history ? "true" : "false";
        });

        setOrders(result);
        setDataResult(process(result, dataState));
        
      } else if (washLocationId && tabSelected) {
        let payload;
        
        if (tabSelected === 'Pending') {
          payload = [{ wash_location_id: washLocationId}, {status: 'Pending' }, {sortData: [{field: 'needed_date_utc', dir: 'asc'}] }];
        } else if (tabSelected === 'In progress') {
          payload = [{ wash_location_id: washLocationId}, {status: 'In progress' }, {sortData: [{field: 'in_date_utc', dir: 'asc'}] }];
        } else if (tabSelected === 'Completed') {
          payload = [{ wash_location_id: washLocationId}, {status: 'Completed' }, {sortData: [{field: 'out_date_utc', dir: 'asc'}] }];
        } else if (tabSelected === 'Scheduled') {
          payload = [{ wash_location_id: washLocationId}, {status: 'Scheduled'}, {sortData: [{field: 'start_time_utc', dir: 'asc'}] }];
        } else {
          payload = [{ wash_location_id: washLocationId }, {sortData: [{field: 'needed_date_utc', dir: 'asc'}] }]; // for All or Pending
        }
        
        const result = await getAllDataByGenericAPI('trailer_wash_wo', payload);

        result.forEach(function (result, index) {
          result.history = result.history ? "true" : "false";
        });

        setOrders(result);
        setDataResult(process(result, dataState));
      }
    } catch (err) {
      toast.error("Error fetching data.");
    }
  };

  useEffect(() => {
    if (userData?.userpreferences?.tankwashtabsequence) {
      setcolumnorder(userData.userpreferences.tankwashtabsequence);
      setcolumnorderExcel(userData.userpreferences.tankwashtabsequence);
    } else {
      setcolumnorder(columns);
      setcolumnorderExcel(columns);
    }
  }, [userData]);
  

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      setDataResult(process(orders, dataState));
    }
  }, [orders, dataState]);

  const dataStateChange = (event) => {
    setDataState(event.dataState);
    setDataResult(process(orders, event.dataState));
  };

  const exportExcel = () => {
    if (_export.current) {
      const originalDataState = { ...dataState };
  
      setDataState({ ...dataState, skip: 0, take: orders.length });
  
      const processedData = process(orders, { ...dataState, skip: 0, take: orders.length }).data;
      _export.current.save(processedData);
  
      setDataState(originalDataState);
    }
  };  

  const columnReorderChange = async function (event) {
    const newTankwashColumnOrder = [];
    event.target._columns.forEach((it) => {
      if (it.field !== "expanded" && it.field !== 'Action') {
        newTankwashColumnOrder.push({
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width,
        });
      }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    if (userData.userpreferences) {
      // If userpreferences has already tankwashtabsequence data and we are changing sequence.
      if (userData.userpreferences.tankwashtabsequence) {
        payload.userpreferences.tankwashtabsequence = newTankwashColumnOrder;
      } else {
        // If userpreferences has already some data, but not tankwashtabsequence data
        payload.userpreferences.tankwashtabsequence = newTankwashColumnOrder;
      }
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = userData.userpreferences.trailertabsequence;
      }
      
      if (userData.userpreferences.currentlySelectedPlanningProfile) {
        payload.userpreferences.currentlySelectedPlanningProfile = userData.userpreferences.currentlySelectedPlanningProfile;
      }
    } else {
      // If userpreferences has not any data.
      payload.userpreferences.tankwashtabsequence = newTankwashColumnOrder;
    }

    setcolumnorderExcel(newTankwashColumnOrder);

    try {
      const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
    } catch (err) {
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };


  return (
    <div id="wrapper">
      <Navbar expand="lg" variant="light" bg="light" className="navbar-custom">
        <div className="container-fluid">
          <div className="logo_logout_wrapper">
            <div className="logo-box df">
              <img className="back_icon hide_left_icon" alt="logo" />
              <p className="logo_font">BULKMATIC TRANSPORT</p>
            </div>
            <div className="signoutsection">
              <button
                type="button"
                onClick={() => window.close()}
                className="btn_signout"
              >
                <i className="fa fa-sign-out log_out_icon"></i>Close Window
              </button>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </Navbar>

      <div className="row df adjustrow mt_30">
        <div className="col-xl-12">
          <div className="card special_bg">
            <div className="card-body">
              <div className="row top_adjust">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="col-md-2">
                    <h2 className="text-light">Tankwash</h2>
                  </div>
                  <div className="tab_inside_section_top_right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row adjustrow1">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body">
              <div className="table-responsive">
                <div className="tab_inside_section">
                  <div className="tab_inside_section_top">
                    <div className="tab_inside_section_top_left"></div>
                    <div className="tab_inside_section_top_right">
                      <button
                        title="Export Excel"
                        className="btn_white_sm ml_10"
                        onClick={exportExcel}
                      >
                        Export to Excel
                      </button>
                      <button
                        type="button"
                        className="btn_white_sm ml_10"
                        onClick={() =>
                          setDataState({
                            ...dataState,
                            filter: { logic: "and", filters: [] },
                            skip: 0,
                            take: 25,
                          })
                        }
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="tab_content">
                    <LocalizationProvider language="en">
                      <IntlProvider locale="en">
                        <ExcelExport data={dataResult.data} ref={_export}>
                          {columnorderExcel.map((col) => (
                            <ExcelExportColumn
                              key={col.id}
                              field={col.name}
                              title={col.title}
                              width={col.width}
                            />
                          ))}
                        </ExcelExport>

                        <Grid
                          data={dataResult}
                          filterable={true}
                          pageable={{
                            pageSizes: [25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10,
                          }}
                          sortable={true}
                          sort={dataState.sort}
                          filter={dataState.filter}
                          onDataStateChange={dataStateChange}
                          skip={dataState.skip}
                          take={dataState.take}
                          resizable={true}
                          reorderable={true}
                          onColumnReorder={columnReorderChange}
                        >
                          {columnorder.map((col) => (
                            <GridColumn
                              key={col.id}
                              field={col.name}
                              title={col.title}
                              width={col.width}
                              filterable={true}
                            />
                          ))}
                        </Grid>
                      </IntlProvider>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TankwashPage;
