import React, { useState, useEffect,useCallback } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { makeStyles } from "@mui/styles";
import debounce from 'lodash/debounce';
import {searchlocation} from "../../services/terminalService";

const LocationTable = (props) => {
  const { isLocationLoaded, refresh, debouncedSearch, locationPageData, totalResult } = props;
  console.log('locationPageData',locationPageData)
  console.log('totalResult',totalResult)
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false); 
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      fontWeight: "bold",
      textAlign: "center !important",
      fontSize: 15,
    },
  }));
  const classes = useStyles();
  const [dataResult, setDataResult] = useState(process(locationPageData, dataState));

  const dataStateChange = (event) => {
    setIsLoading(true);
    setDataResult(process(locationPageData, event.dataState));
    setDataState(event.dataState);
    const filters = event?.dataState?.filter?.filters || [];
    
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    console.log('terms',terms)
    setSearchTerms(terms);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    let searchTerms=terms;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    debouncedSearch(searchTerms,page, take, event?.dataState?.sort);
    setIsNextPageLoading(true);
  };

//   const debouncedSearch = useCallback(
//     debounce(async (searchTerms, page, size) => {        
//         const search = await searchlocation(searchTerms, page, size);
//         if (search.data.data.length <= 0) {
//             setIsPageLoading(false);
//             setDriverPageData(search.data.data);
//             setTotalResult(search.data.count);               
//         }
//         if (search.data.data.length > 0) {
//             setDriverPageData(search.data.data);
//         }
//         setTotalResult(search.data.count);
//         setIsPageLoading(false);
//         setIsLoading(false);
//     }, 1000),
//     [refresh]
// );

useEffect(() => {
  // if (isLocationLoaded) {
    // setIsLoading(false); 
    setDataResult(locationPageData, dataState);
  // }
}, [locationPageData, dataState]);

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body ">
            <div className="table-responsive">
            { isLocationLoaded ? (
                <Grid
                  filter={dataState.filter}
                  filterable={true}
                  sort={dataState.sort}
                  sortable={true}
                  total={totalResult}
                  pageable={{
                    pageSizes: [ 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  loading={isNextPageLoading}
                >
                  <GridColumn
                    field="isActive"
                    sortable={true}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isActive ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.isActive ? "True" : "False"}
                        </td>
                      );
                    }}
                    title="Is Active"
                    width="150px"
                    filterable={true}
                    filter={"boolean"}
                  />
                  <GridColumn
                    field="name"
                    title="Name"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.name ? e.dataItem.name : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    field="code"
                    title="Code"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.code ? e.dataItem.code : ""}</td>
                      );
                    }}
                  />

                  <GridColumn
                    field="address1"
                    title="Address"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.address1
                            ? e.dataItem.address1
                            : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="city"
                    title="City"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.city ? e.dataItem.city : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    field="state"
                    title="State"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.state ? e.dataItem.state : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="zip"
                    title="Zip"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.zip ? e.dataItem.zip : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isConsignee"
                    title="Consignee"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isConsignee
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.isConsignee ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isDroplot"
                    title="Droplot"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isDroplot ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.isDroplot ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn field="state" title="State" />
                  <GridColumn
                    field="isRailyard"
                    title="Railyard"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isRailyard
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.isRailyard ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isShipper"
                    title="Shipper"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isShipper ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.isShipper ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isShop"
                    title="Shop"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isShop ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.isShop ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isTerminal"
                    title="Terminal"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isTerminal
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.isTerminal ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="isWash"
                    title="Wash"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.isWash ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.isWash ? "True" : "False"}
                        </td>
                      );
                    }}
                  />
                </Grid>
              ) : (
                <div>
                  <ProgressBar animated now={100} />
                    <div className="loader">
                      <div className="loader--text"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationTable;
