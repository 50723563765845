import axios from 'axios';

// Application Specific
import LUTRule from "../../src/models/loadunloadruleModel";
import { BaseConfig } from "../baseConfig";


  export const getAllLUTRules = async() => {
    const lutrule = new LUTRule();
    let ruleObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/lutrulesbyallcgs";
      const rulesApiData = await axios.get(url);
      ruleObject = rulesApiData.data.data.map((data) =>
        lutrule.parseApiLUTCGRuleObject(data)
      );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving commodity group data. Please try again!"
      );
    }

    return Promise.resolve(ruleObject);
  }

  export const getLUTRule = async(id) => {
    const lutrule = new LUTRule();
    let ruleObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/lutrulesbyallcgs/" + id;
      const rulesApiData = await axios.get(url);
      ruleObject = lutrule.parseApiLUTCommodityRuleObject(
        rulesApiData.data.data
      );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving terminal data. Please try again!"
      );
    }

    return Promise.resolve(ruleObject);
  }

  //end api integrate

  export const getLutrulesbyshipper = async(locationId, search) => {
    let allShipper = [];
    const shipper = new LUTRule();
    try {
      let data = {
        locationId: locationId.toString(),
        search: search,
        isActive:true
      };
      const url = BaseConfig.ApiEndPoint + "/lutrulesbyshipper";
      const rulesApiData = await axios.post(url, data);
      allShipper = rulesApiData.data.data.map((data) =>
        shipper.parseApiLUTCommodityRuleObject(data)
      );
    } catch (err) {
      return Promise.reject(
        "There is a problem on retrieving All Shipper data. Please try again!"
      );
    }

    return Promise.resolve(allShipper);
  }

  export const createLUTRule = async(data) => {
    let sPools = [];
    const shipper = new LUTRule();
    try {
      const url = BaseConfig.ApiEndPoint + "/lutrules";
      const apiData = {
        actiontype: data.actiontype,
        loadflag: data.loadflag,
        cgid: data.cgid,
        // consignee_id:data.consignee_id === undefined? null :data.consignee_id,
        commodityid: data.commodityid === undefined ? null : data.commodityid,
        // shipperid: data.shipperid === undefined ? null : data.shipperid,
        // loadtime: data.loadtime === undefined ? null : data.loadtime,
        // unloadtime: data.unloadtime === undefined ? null : data.unloadtime,
        duration : data.duration === undefined? null: data.duration
      };
      if(data.shipperid){
        apiData.shipperid = data.shipperid
      }else if(data.consignee_id){
        apiData.consignee_id = data.consignee_id
      }
      const sApiData = await axios.post(url, apiData);
      sPools = sApiData.data.data.map((data) =>
        shipper.parseApiLUTCommodityRuleObject(data)
      );
    } catch (error) {
      return Promise.reject("Error: Unable to add the shipper.");
    }
    return Promise.resolve(sPools);
  }


export const updateLUTRule = async (id, payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/updatelutrules/" + id;
    const spApiData = await axios.put(url, payload);
    return spApiData.data;
  }catch (error) {
    throw error
   }
};

  export const deleteLUTRule = async(data) => {
    let sPool = [];
    const ruleObject = new LUTRule();
    try {
      const url = BaseConfig.ApiEndPoint + "/lutrules/" + data.id;
      const spApiData = await axios.delete(url, data);
      sPool = spApiData.data.data.map((data) =>
        ruleObject.parseApiLUTCommodityRuleObject(data)
      );
    } catch (error) {
      
    }
    return Promise.resolve(sPool);
  }

  export const getAllDefaultLoadUnload = async() => {
    
    try {
      const url = BaseConfig.ApiEndPoint + "/getalldefaultloadunload";
      const spApiData = await axios.get(url);
      return spApiData.data.data
    } catch (error) {
      return error
    }
   
  }