import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import errorMessageDisplay from "../src/utils/errorMessageDisplay"
import { createContext, useEffect, useState, useContext } from 'react';

const appConfig = require("./Config.json");

initializeApp(appConfig.firebaseConfig);
const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const firebaseClientToken = await getToken(messaging, { vapidKey: appConfig.fbVapidKey});
    return firebaseClientToken;
  }catch(err){
    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
      errorMessageDisplay(err?.response?.data?.errors);
    }
  }
};

const FirebaseMessageListenerContext = createContext(null);

export const FirebaseMessageListenerProvider = ({ children }) => {
  const [notificationData, setNotificationData] = useState(Object.create(null));

  useEffect(() => {
    const unsubscribeToListener = onMessage(messaging, (payload) => {
      console.log({
        message: "Raw firebase message data",
        payload
      })

      if (payload?.data) {
        const firebaseMessageData = payload?.data?.data ? JSON.parse(payload?.data?.data) : payload?.data;
        const firebaseMessageConfig = payload?.data?.config ? JSON.parse(payload?.data?.config) : null;

        const isSilentNotification = firebaseMessageConfig?.isSilent ? firebaseMessageConfig?.isSilent === "true" : false;

        console.log({
          message: `${isSilentNotification ? 'Silent' : 'Normal'} Notification received`,
          payload: firebaseMessageData,
          config: firebaseMessageConfig
        })

        setNotificationData({
          title: payload?.data?.title,
          body: payload?.data?.body,
          data: firebaseMessageData,
          isSilentNotification
        });
      }
    });

    return () => unsubscribeToListener;
  }, []);

  return (
    <FirebaseMessageListenerContext.Provider value={{ notificationData }}>
      { children }
    </FirebaseMessageListenerContext.Provider>
  )
}

export const useFirebaseMessageAlert = () => {
  return useContext(FirebaseMessageListenerContext);
}