import React, { useContext, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { DropContext } from "../../pages/planner/planner";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: 'none',
    backgroundColor: "#4267B2",
    borderColor: "#2C4F95",
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
    padding: "12px",
    borderRadius: "10px"
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px"
  },
}));

const formatDate = (epoch_date, tzone) => {

  if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST"|| tzone === "EDT" || tzone === "America/New_York") {
    tzone = "America/New_York"
  }
  if (!tzone ||tzone === "Central Standard Time"||tzone === "CDT"||tzone === "CST" || tzone === "America/Chicago") {
    tzone = "America/Chicago"
  }
  if (epoch_date > 0) {
    return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
  }
  else {
    return ""
  }

}
const handleStopPropogation = (e)=>{
  e.stopPropagation();
}

const PlannerBoardTractorTooltip = (props) => {
  const onClickHandler = 'onClick' in props ? props.onClick : () => { };
  const classes = useStyles();
  return (
    <Tooltip title={
      <React.Fragment>
        <Typography color="inherit">
          {props?.tooltip}
        </Typography>
      </React.Fragment>
    }
      onClick={onClickHandler}
      placement="top"
      classes={{
        tooltip: classes?.customTooltip,
        arrow: classes?.customArrow,
      }}
      arrow
    >
      {props?.children}
    </Tooltip>
  );
}
const SamsaraPBToolTip = (props) => {
  const driver = props.driver;
  const msToTime = (duration) => {
    if (duration) {
      let seconds = Math.floor((duration / 1000) % 60)
      let minutes = Math.floor((duration / (1000 * 60)) % 60)
      let hours = Math.floor(duration / (1000 * 60 * 60))

      hours = (hours < 10) ? "0" + hours : hours
      minutes = (minutes < 10) ? "0" + minutes : minutes
      seconds = (seconds < 10) ? "0" + seconds : seconds
      return `${hours}:${minutes}:${seconds}`
    }
    else {
      return null
    }
  }
  return (
    <span className='tooltip-adjust' onClick={handleStopPropogation}>
      <div className='main_tooltip_section'>
        <div className='tooltip_left_driver'>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Driver :
            </div>
            <div className='tooltip_text_right'>
              {
                driver?.driverId
              }
              -
              {driver?.driver_full_name
                ??  "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Terminal :
            </div>
            <div className='tooltip_text_right'>
              {(driver?.terminal?.terminal_fullName || driver?.terminal_city) ?? "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec pt_20'>
            <div className='driver_special_text'>
              Samsara Data:
            </div>
            <div className="tooltip_text_right">{driver?.samsara_id ? driver.samsara_id : "No Data"}</div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Vehicle :
            </div>
            <div className='tooltip_text_right'>
              {(driver?.hosclock?.currentVehicle?.name) ?? "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Duty Status
              :
            </div>
            <div className='tooltip_text_right'>
              {(driver?.hosclock?.currentDutyStatus?.hosStatusType) ?? "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Drive
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {driver?.hosclock?.clocks?.drive?.driveRemainingDurationMs
                ? msToTime(driver.hosclock.clocks.drive.driveRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Shift
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {driver?.hosclock?.clocks?.shift?.shiftRemainingDurationMs
                ? msToTime(driver.hosclock.clocks.shift.shiftRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Cycle
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {driver?.hosclock?.clocks?.cycle?.cycleRemainingDurationMs
                ? msToTime(driver.hosclock.clocks.cycle.cycleRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
        </div>

        <div className='tooltip_middle_driver'>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Time in
              Status :
            </div>
            <div className='tooltip_text_right'>
              {"No Data"}
            </div>
          </div>
        </div>

        <div className='tooltip_rightPu'>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Type :
            </div>
            <div className='tooltip_text_right'>
              {driver?.driver_type_class
                ? driver.driver_type_class
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Hired:
            </div>
            <div className='tooltip_text_right'>
              {driver?.hire_date
                ? driver.hire_date
                : "No Data"}
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}
const TractorPBToolTip = (props) => {
  const it = props.it;
  const renderTerminalInfo=(it)=>{
    if (it.tractor_id) {
      return `${it.terminal_id}`;
    } 
    else {
      return null; // or any default value you want
    }
    }
 
  
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>
      <div className="main_tooltip_section_other">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Tractor : </div>
            <div className="tooltip_text_right">{it?.tractor_id ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Description : </div>
            <div className="tooltip_text_right">{it?.description ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Company Id: </div>
            <div className="tooltip_text_right">
            {it?.company_id ?? "No Data"}
              </div>

          </div>
          <div className="tooltip_single_sec">
                  <div className="tooltip_text_left">Terminal : </div>
                  <div className="tooltip_text_right">
                      {renderTerminalInfo(it)}
                  </div>
                </div>
         
        </div>
        <div className="tooltip_middle">
        </div>
        
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Make : </div>
            <div className="tooltip_text_right">
            {it?.make ?? "No Data"}
            </div>
          </div>    
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Model : </div>
            <div className="tooltip_text_right">{it?.model ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
                  <div className="tooltip_text_left">Mileage : </div>
                  <div className="tooltip_text_right">
                      {"No Data"}
                  </div>
                </div>

                <div className="tooltip_single_sec">
                  <div className="tooltip_text_left">Engine HRs : </div>
                  <div className="tooltip_text_right">
                  {"No Data"}
                  </div>
                </div>
            </div>

           

      </div>

      {/* <div className="main_tooltip_section_other">
        <div className="tooltip_left">
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Wheelbase : </div>
            <div className="tooltip_text_right">{it?.wheelbase?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Tare Weight: </div>
            <div className="tooltip_text_right">{it?.tare_weight ?? "No Data"}</div>
          </div>

         
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Sleeper/DayCab:</div>
            <div className="tooltip_text_right">{it?.sleeper_daycab ?? "No Data"}</div>
          </div>
          
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Has Blower:</div>
            <div className="tooltip_text_right">{it?.blower ?? "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Double Certification:</div>
            <div className="tooltip_text_right">{it?.double_certification ?? "No Data"}</div>
          </div>
        </div>


        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Gross Weight: </div>
            <div className="tooltip_text_right">{it?.gross_weight ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Trannsmission Description: </div>
            <div className="tooltip_text_right">{it?.tran_description ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Has WetKit: </div>
            <div className="tooltip_text_right">{it?.wetkit ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Transmission Auto/Manual:</div>
            <div className="tooltip_text_right">{it?.transmission_auto_manual ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Overweight Permit:</div>
            <div className="tooltip_text_right">{it?.overweight_permit ?? "No Data"}</div>
          </div>
        </div>

      </div>
      */}
     

      <div className="main_tooltip_section_other">
        <div className="tooltip_left">
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">License Plate : </div>
            <div className="tooltip_text_right">{(it?.license_plate || it?.license) ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Region : </div>
            <div className="tooltip_text_right">{it?.region ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">license State : </div>
            <div className="tooltip_text_right">{it?.license_state ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">license Country : </div>
            <div className="tooltip_text_right">{it?.license_country ?? "No Data"}</div>
          </div>
          
         
        </div>
        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
       
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">License Renewal Date:: </div>
            <div className="tooltip_text_right">{it?.license_renewal_date ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Year: </div>
            <div className="tooltip_text_right">{(it?.model_year || it?.year) ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Vin: </div>
            <div className="tooltip_text_right">{it?.vin ?? "No Data"}</div>
          </div>
         
         
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Tare Weight: </div>
            <div className="tooltip_text_right">{it?.tare_weight ?? "No Data"}</div>
          </div>
        </div>

      </div>
    </span>
  );
}


const OrderTooltip = (props) => { 
 
  const it = props.it;
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>
      <div className="main_tooltip_section1">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Customer: </div>
            <div className="tooltip_text_right"> {it?.movementData.order.customer_id?it?.movementData.order.customer_id:""}-{it?.movementData.order.customer_name?it?.movementData.order.customer_name:""} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">  {it?.orderId || it?.orderId2} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Hold: </div>
            <div className="tooltip_text_right">   {it?.movementData.order.on_hold ? it?.movementData.order.on_hold : "No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type: </div>
            <div className="tooltip_text_right"> {it?.movement_type ?? "No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal: </div>
            <div className="tooltip_text_right">   {(it?.terminalId || it?.terminal_id)} - {(it?.terminal_city || it?.city) ?? "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right">  {it?.movementData.order?.status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Reason: </div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">BOL: </div>
            <div className="tooltip_text_right">   {it?.movementData.order?.bol_received?it?.movementData.order.bol_received:"No Data"}  </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Consignee Ref#: </div>
            <div className="tooltip_text_right">  {it?.movementData.order?.consignee_refno ?it?.movementData.order?.consignee_refno: "No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Shipper:  </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it?.movementData?.order?.shipper?.lm_location_id ?
                  (`${it?.movementData?.order?.shipper?.lm_location_id} - ${it?.movementData?.order?.shipper?.lm_location_name}`)
                  : "No Data"
                }
                <br />
                {it?.movementData?.order?.shipper?.address ?
                  (`${it.movementData?.order?.shipper?.address}`)
                  : null
                }
                <br />
                {it?.movementData?.order?.shipper?.city ?
                  (`${it?.movementData?.order?.shipper?.city}, ${it?.movementData?.order?.shipper?.state}, ${it?.movementData?.order?.shipper?.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Consignee: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it?.movementData?.order?.consignee?.lm_location_id ?
                  (`${it.movementData?.order?.consignee?.lm_location_id} - ${it.movementData?.order?.consignee?.lm_location_name}`)
                  : "No Data"
                }
                <br />
                {it?.movementData?.order?.consignee?.address ?
                  (`${it?.movementData?.order?.consignee?.address}`)
                  : null
                }
                <br />
                {it?.movementData?.order?.consignee?.city ?
                  (`${it?.movementData?.order?.consignee?.city}, ${it?.movementData?.order?.consignee?.state}, ${it?.movementData?.order?.consignee?.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap1">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Load : </div>
            <div className="tooltip_text_right">   {it?.movementData.pickup.driver_load_unload} </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Unload: </div>
            <div className="tooltip_text_right">   { it?.movementData.delivery.driver_load_unload} </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Pickup Appt:  </div>
            {/* <div className="tooltip_text_right">     {formatDate(it.pickup_sched_arrive_early_utc, it.location_timezone)}</div> */}
            <div className="tooltip_text_right">
              {formatDate(it?.movementData?.pickup.sched_arrive_early_utc, it?.movementData?.pickup.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Pickup Appt:</div>
            <div className="tooltip_text_right">
              {formatDate(it?.movementData?.pickup.sched_arrive_late_utc, it?.movementData?.pickup.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right">
              {it?.pickup_appt_required ? it.pickup_appt_required : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right">
              {it?.picup_confirmed ? it.picup_confirmed : "No Data"}  </div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Delivery Appt: </div>
            <div className="tooltip_text_right">
              {formatDate(it?.movementData?.delivery?.sched_arrive_early_utc, it?.movementData?.delivery?.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Delivery Appt:</div>
            <div className="tooltip_text_right">
              {formatDate(it?.movementData?.delivery?.sched_arrive_late_utc, it?.movementData?.delivery?.delivery_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planning Comment:</div>
            <div className="tooltip_text_right">   {it?.movementData?.order?.planning_comment} </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 ">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity Group:</div>
            <div className="tooltip_text_right">
            {it?.movementData.order.order_commodity[0].CG.commodityid ? it?.movementData.order.order_commodity[0].CG.commodityid : "No Data"}
              
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Product:</div>
            <div className="tooltip_text_right">   {it?.movementData.order?.commodity_desc ? it?.movementData.order?.commodity_desc: "No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity:</div>
            <div className="tooltip_text_right">  { it?.movementData.order.commodity_id ? `${it?.movementData.order.commodity_id} - ${it?.movementData.order.commodity}` : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Ordered Weight: </div>
            <div className="tooltip_text_right">   {it?.movementData.order.weight ? it?.movementData.order.ordered_wt : "No Data"} </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
    </span>
  );
}


// This function is for Stops
const OrderPBToolTip = (props) => {

 
  
  const context = useContext(DropContext);
  const plannerTimezone = context.plannerTimezone;
  const it = props.it;
  const displayfororderid = (it) => {
    // console.log("stop: ", it);
    if (it.orderId === null) {

      return it.orderId2
    }
    else {
      return it.orderId
    }
  }
  
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">

              {(displayfororderid(it)) ? (displayfororderid(it)) : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Previous Move : </div>
            <div className="tooltip_text_right">{it?.stopData?.linkedMvmts[0]?.move_id}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Next Move : </div>
            <div className="tooltip_text_right">{it?.stopData?.linkedMvmts[1]?.move_id}</div>
          </div>
        </div>

        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Stop Type:</div>
            <div className="tooltip_text_right">  {it?.type}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Previous Move Type:</div>
            <div className="tooltip_text_right"> {it?.stopData?.linkedMvmts[0]?.type} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Next Move Type:</div>
            <div className="tooltip_text_right"> {it?.stopData?.linkedMvmts[1]?.type} </div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it?.movementData?.order?.status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it?.stopData?.linkedMvmts[0]?.status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Status:</div>
            <div className="tooltip_text_right"> {it?.stopData?.linkedMvmts[1]?.status}</div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec  ">
         <div className="tooltip_text_left fs_20">{`${it?.stopData?.stop_type ? it?.stopData?.stop_type.toUpperCase(): "No Data"}`} : </div> 
          </div>
          <div className="tooltip_single_sec">
            <p className="tooltip_text_right">
              {it?.stopData?.lm_location_id ?
                (`${it?.stopData?.lm_location_id ? `${it?.stopData?.lm_location_id} -` : ""}  ${it?.stopData?.lm_location_name ? it?.stopData?.lm_location_name : ""}`)
                : ""
              }
              <br />
              {it?.stopData?.address ?
                (`${it?.stopData?.address ? `${it?.stopData?.address}` : ""}`)
                : ""
              }
              <br />
              {it?.stopData?.city ?
                (`${it.stopData?.city ? `${it.stopData?.city},` : ""} ${it?.stopData?.state ? `${it?.stopData?.state},` : ""} ${it?.stopData?.zip ? `${it?.stopData?.zip}` : ""} `)
                : ""
              }
            </p>
          </div>
        </div>
        <div className="tooltip_middle">

        </div>
      </div>

      <div className="main_tooltip_section tooltip_inner_gap1">
        <div className="tooltip_left">



          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Early Appt: </div>
            <div className="tooltip_text_right">  {it?.stopData?.sched_arrive_early_utc ?
              formatDate(it.stopData?.sched_arrive_early_utc, it.stopData.delivery_timezone?it.stopData.delivery_timezone:it?.stopData.pickup_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Late Appt: </div>
            <div className="tooltip_text_right">  {it?.stopData?.sched_arrive_late_utc ?
              formatDate(it.stopData?.sched_arrive_late_utc, it.stopData.delivery_timezone?it.stopData.delivery_timezone:it?.stopData.pickup_timezone) : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt Rq'd:</div>
            <div className="tooltip_text_right">  {it?.pickup_appt_required ? it.pickup_appt_required : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt confirmed:</div>
            <div className="tooltip_text_right">  {it?.picup_confirmed ? it.picup_confirmed : "No Data"}</div>
          </div>
        </div>
        
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Driver Load Unload: </div>
            <div className="tooltip_text_right">
              {it?.stopData?.driver_load_unload ? it?.stopData?.driver_load_unload : "No Data"}
            </div>
          </div>

        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap" >
        <div className="tooltip_left">
          <div className="tooltip_single_sec mt_5 ">
            <div className="tooltip_text_left">Planned Arrive : </div>
            <div className="tooltip_text_right">  {it?.movementData.pickup.planned_arrive_utc ? formatDate(it?.movementData.pickup.planned_arrive_utc,it?.movementData.delivery.delivery_timezone?it.movementData.delivery.delivery_timezone:it.movementData.pickup.pickup_timezone) : "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart : </div>
            <div className="tooltip_text_right">  {it?.movementData.pickup.planned_depart_utc ? formatDate(it?.movementData.pickup.planned_depart_utc,it?.movementData.delivery.delivery_timezone?it.movementData.delivery.delivery_timezone:it.movementData.pickup.pickup_timezone) : "No Data"}</div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {it?.movementData.pickup.eta_utc ? formatDate(it?.movementData.pickup.eta_utc,it?.movementData.delivery.delivery_timezone?it.movementData.delivery.delivery_timezone:it.movementData.pickup.pickup_timezone) : "No Data"}</div>
          </div> */}

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Arrival Time: </div>
            <div className="tooltip_text_right"> {it.movementData?.pickup?.eta_arrival_utc ? formatDate(it?.movementData?.pickup?.eta_arrival_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Departure Time: </div>
            <div className="tooltip_text_right"> {it.movementData?.pickup?.eta_departure_utc ? formatDate(it?.movementData?.pickup?.eta_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival : </div>
            <div className="tooltip_text_right">  {it?.movementData.pickup.actual_arrival_utc ? formatDate(it?.movementData.pickup.actual_arrival_utc,it?.movementData.delivery.delivery_timezone?it.movementData.delivery.delivery_timezone:it.movementData.pickup.pickup_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart : </div>
            <div className="tooltip_text_right">  {it?.movementData.pickup.actual_departure_utc ? formatDate(it?.movementData.pickup?.actual_departure_utc,it?.movementData.delivery.delivery_timezone?it.movementData.delivery.delivery_timezone:it.movementData.pickup.pickup_timezone) : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
    </span>
  );
}

// This function is for Shifts
const ShiftToolTip = (props) => {
  const it = props.it;
  console.log("ShiftToolTip",it)
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">
              {it?.shiftType === 'start' ? "Start Shift" :  it?.shiftType === 'end' ? "End Shift" : "No Data"}
            </div>
            <div className="tooltip_text_right">
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move:</div>
            <div className="tooltip_text_right">{it?.move_id ? it?.move_id : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Stop Type:</div>
            <div className="tooltip_text_right">
              {it.shiftType === "start" ? "SS" : it.shiftType === "end" ? "ES" : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">{it?.shiftType === "start" ? "Next Move Type:" : it?.shiftType === "end" ? "Previous Move Type:" : "Move Type:"}</div>
            <div className="tooltip_text_right">
              {it?.movementData?.movement_type ? it?.movementData?.movement_type : "No Data"}
            </div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order Status:</div>
            <div className="tooltip_text_right">{it.movementData.order.status ? it.movementData.order.status : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Movement Status:</div>
            <div className="tooltip_text_right">{it.movementData.status ? it.movementData.status : "No Data"}</div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec  ">
            <div className="tooltip_text_left fs_20">
              {
                it.shiftType === 'end' ?
                  it?.movementData?.delivery?.lm_location_id === 'LAYOVER' ? `${it.shiftType.toUpperCase()} - (Layover)` : it.shiftType.toUpperCase()
                  :
                  it.shiftType === 'start' ?
                    it?.movementData?.pickup?.lm_location_id === 'LAYOVER' ? `${it.shiftType.toUpperCase()} - (Layover)` : it.shiftType.toUpperCase()
                    : ''
              }
            </div>
          </div>
          <div className="tooltip_single_sec">
            <p className="tooltip_text_right">
              {it?.shiftType === "start"
                ? it?.movementData?.pickup?.location?.code
                  ? `${it?.movementData?.pickup?.location?.code ? `${it?.movementData?.pickup?.location?.code} -` : ""} ${it?.movementData?.pickup?.location?.location_name ? `${it?.movementData?.pickup?.location?.location_name}` : ""}`
                  : ""
                : it?.shiftType === "end" ?
                  `${it?.movementData?.delivery?.location?.code ? `${it?.movementData?.delivery?.location?.code} -` : ""} ${it?.movementData?.delivery?.location?.location_name ? `${it?.movementData?.delivery?.location?.location_name}` : ""}`
                  : "No Data"
              }
              <br />
              {
                it?.shiftType === "start"
                  ? it?.movementData?.pickup?.location?.address1
                    ? `${it?.movementData?.pickup?.location?.address1}`
                    : it?.movementData?.pickup?.address
                      ? `${it?.movementData?.pickup?.address}`
                      : ""
                  : it?.shiftType === "end"
                    ? it?.movementData?.delivery?.location?.address1
                      ? `${it?.movementData?.delivery?.location?.address1}`
                      : it?.movementData?.delivery?.address
                        ? `${it?.movementData?.delivery?.address}`
                        : ""
                    : ""
              }
              <br />
              {it?.shiftType === "start"
                ? it?.movementData?.pickup?.location?.city
                  ? `${it.movementData.pickup.location.city ? `${it.movementData.pickup.location.city},` : ""} ${it.movementData.pickup.location.state ? `${it.movementData.pickup.location.state},` : ""} ${it.movementData.pickup.location.zip ? `${it.movementData.pickup.location.zip}` : ""}`
                  : "No Data"
                : it.shiftType === "end"
                  ? it.movementData.delivery.city
                    ? `${it.movementData.delivery.location.city ? `${it.movementData.delivery.location.city},` : ""} ${it.movementData.delivery.location.state ? `${it.movementData.delivery.location.state},` : ""} ${it.movementData.delivery.location.zip ? `${it.movementData.delivery.location.zip}` : ""}`
                    : "No Data"
                  : ""
              }
            </p>
          </div>
        </div>
        <div className="tooltip_middle"></div>
      </div>

      <div className="main_tooltip_section tooltip_inner_gap1">
        <div className="tooltip_left">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Early Appt: </div>
            <div className="tooltip_text_right">
              
              {it?.movementData?.pickup?.sched_arrive_early_utc
                ? formatDate(
                  it?.movementData?.pickup?.sched_arrive_early_utc,
                  it?.pickup_timezone
                )
                : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Late Appt: </div>
            <div className="tooltip_text_right">
              
              {it.movementData?.pickup?.sched_arrive_late_utc
                ? formatDate(
                  it?.movementData?.pickup?.sched_arrive_late_utc,
                  it?.pickup_timezone
                )
                : "No Data"}
            </div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Appt Rq'd:</div>
            <div className="tooltip_text_right">
              
              {it?.pickup_appt_required ? it?.pickup_appt_required : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Appt confirmed:</div>
            <div className="tooltip_text_right">
              
              {it?.picup_confirmed ? it?.picup_confirmed : "No Data"}
            </div>
          </div>
        </div>
        <div className="tooltip_rightPu"></div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec mt_5 ">
            <div className="tooltip_text_left">Planned Arrive : </div>
            <div className="tooltip_text_right">
              
              {it.shiftType === "start"
                ?   it?.movementData?.pickup?.planned_arrive_utc
                  ? formatDate(
                    it?.movementData?.pickup?.planned_arrive_utc,
                    it?.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data"
                : it?.shiftType === "end"
                  ?  it?.movementData?.delivery?.planned_arrive_utc
                    ? formatDate(
                      it?.movementData?.delivery?.planned_arrive_utc,
                      it?.movementData?.delivery?.delivery_timezone? it.movementData?.delivery?.delivery_timezone:it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data"
                  : "No Data"}
            </div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart : </div>
            <div className="tooltip_text_right">
              
              {it?.shiftType === "start"
                ? it?.movementData?.pickup?.planned_depart_utc
                  ? formatDate(
                    it?.movementData?.pickup?.planned_depart_utc,
                    it?.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data"
                : it?.shiftType === "end"
                  ? it?.movementData?.delivery?.planned_depart_utc
                    ? formatDate(
                      it?.movementData?.delivery?.planned_depart_utc,
                      it?.movementData?.delivery?.delivery_timezone? it.movementData?.delivery?.delivery_timezone:it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data"
                  : "No Data"}
            </div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right">


              
              {it?.shiftType === "start"
                ? it.movementData?.pickup?.eta_utc
                  ? formatDate(
                    it.movementData?.pickup?.eta_utc,
                    it.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data"
                : it.shiftType === "end"
                  ? it.movementData?.delivery?.eta_utc
                    ? formatDate(
                      it.movementData?.delivery?.eta_utc,
                      it.movementData?.delivery?.delivery_timezone? it.movementData?.delivery?.delivery_timezone:it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data"
                  : "No Data"}
            </div>
          </div> */}

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Arrival : </div>
            <div className="tooltip_text_right">
              
              {it?.shiftType === "start"
                ? it.movementData?.pickup?.eta_arrival_utc
                  ? formatDate(
                    it.movementData?.pickup?.eta_arrival_utc,
                    it.movementData?.pickup?.eta_timezone
                  )
                  : "No Data"
                : it.shiftType === "end"
                  ? it.movementData?.delivery?.eta_arrival_utc
                    ? formatDate(
                      it.movementData?.delivery?.eta_arrival_utc,
                      it.movementData?.delivery?.delivery_timezone? it.movementData?.delivery?.delivery_timezone: it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data"
                  : "No Data"}
            </div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Departure : </div>
            <div className="tooltip_text_right">
              
              {it?.shiftType === "start"
                ? (it.movementData?.pickup?.eta_departure_utc
                  ? formatDate(
                    it.movementData?.pickup?.eta_departure_utc,
                    it.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data")
                : it?.shiftType === "end"
                  ? (it.movementData?.delivery?.eta_departure_utc
                    ? formatDate(
                      it.movementData?.delivery?.eta_departure_utc,
                      it.movementData?.delivery?.delivery_timezone ? it.movementData?.delivery?.delivery_timezone : it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data")
                  : "No Data"}
            </div>
          </div>
          
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival : </div>
            <div className="tooltip_text_right">
              
              {it?.shiftType === "start"
                ? it.movementData?.pickup?.actual_arrival_utc
                  ? formatDate(
                    it.movementData?.pickup?.actual_arrival_utc,
                    it.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data"
                : it.shiftType === "end"
                  ? it.movementData?.delivery?.actual_arrival_utc
                    ? formatDate(
                      it.movementData?.delivery?.actual_arrival_utc,
                      it.movementData?.delivery?.delivery_timezone? it.movementData?.delivery?.delivery_timezone: it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data"
                  : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart : </div>
            <div className="tooltip_text_right">
              
              {it?.shiftType === "start"
                ? (it.movementData?.pickup?.actual_departure_utc
                  ? formatDate(
                    it.movementData?.pickup?.actual_departure_utc,
                    it.movementData?.pickup?.pickup_timezone
                  )
                  : "No Data")
                : it?.shiftType === "end"
                  ? (it.movementData?.delivery?.actual_departure_utc
                    ? formatDate(
                      it.movementData?.delivery?.actual_departure_utc,
                      it.movementData?.delivery?.delivery_timezone ? it.movementData?.delivery?.delivery_timezone : it.movementData?.pickup?.pickup_timezone
                    )
                    : "No Data")
                  : "No Data"}
            </div>
          </div>
        </div>

        <div className="tooltip_middle"></div>
      </div>
    </span>
  );
};

// This function is for Movements
const Order2PBToolTip = (props) => {

  const cancelEditHandler = (e, item, type) => {
    if (type === "open") {
      const arr = openDaysArr.filter((e) => e.day != item.day)
      item.isopen = false
      arr.push(item)
      arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
      setopenDaysArr(arr)
      setEditDisabled(true)
    }
    if (type === "close") {
      const arr = closedDayArr.filter((e) => e.day != item.day)
      item.isopen = false
      arr.push(item)
      arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
      setclosedDayArr(arr)
      setEditDisabled(true)
    }

  }
  
  const [editDisabled, setEditDisabled] = useState(true)
  const handleEdit = () => {
    setEditDisabled(false)
  }

 
  const it = props.it;
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>

      <div className="main_tooltip_section_other_t">
        <div className="tooltip_left_pu1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">{it?.orderId || it?.orderId2}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move: </div>
            <div className="tooltip_text_right">{it?.move_id}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Brokerage: </div>
            <div className="tooltip_text_right">{it?.brokerage}</div>
          </div>
        </div>
        <div className="tooltip_middle1">
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Type: </div>
            <div className="tooltip_text_right">{ }</div>
          </div> */}

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Type: </div>
            <div className="tooltip_text_right">{it?.type}</div>
          </div>


        </div>
        <div className="tooltip_rightPu1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order Status: </div>
            <div className="tooltip_text_right">{it?.movementData?.order?.status ? it?.movementData?.order?.status : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Movement Status: </div>
            <div className="tooltip_text_right">{it?.movement_status ? it?.movement_status : "No Data"}</div>
          </div>
        </div>
      </div>

      <div className="main_tooltip_section_other_t">
        <div className="tooltip_left_pu1">
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left fs_20">{`${it?.movementData?.pickup?.lm_location_id !== "LAYOVER" ? "Pickup" : "Pickup-(Layover)"}`}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {
                  (`${it?.movementData.pickup.lm_location_id ? `${it?.movementData.pickup.lm_location_id} -` : ""} ${it?.movementData?.pickup?.lm_location_name ?
                    it?.movementData?.pickup?.lm_location_name : "No Data"}`)
                }
                <br />
                {(it.movementData?.pickup?.address || it.movementData?.pickup?.location?.address1 ?
                  (`${it.movementData?.pickup?.address || it.movementData?.pickup?.location?.address1}`)
                  : "No Data")
                }
                <br />
                {it.movementData?.pickup?.city ?
                  (`${it.movementData?.pickup?.city ? `${it.movementData?.pickup?.city},` : ""
                    } ${it.movementData?.pickup?.state ? `${it.movementData?.pickup?.state},` : ""} ${it.movementData?.pickup?.zip ? `${it.movementData?.pickup?.zip}` : ""} `)
                  : "No Data"
                }
              </p>
            </div>
          </div>

        </div>
        <div className="tooltip_middle1">

        </div>
        <div className="tooltip_rightPu1">
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left fs_20">{`${it?.movementData?.delivery?.lm_location_id !== 'LAYOVER' ? "Delivery" : "Delivery-(Layover)"}`}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">         
                {it.movementData?.delivery?.lm_location_id
                  ? `${it.movementData.delivery.lm_location_id ? `${it.movementData.delivery.lm_location_id} -` : ""} ${it.movementData.delivery.lm_location_name || it.movementData.delivery.location?.location_name || ""}`
                  : "No Data"}

                <br />
                {(it.movementData?.delivery?.address || it.movementData?.delivery?.location?.address1) ?
                  (it.movementData?.delivery?.address || it.movementData?.delivery?.location?.address1)
                  : "No Data"
                }
                <br />
                {(it.movementData?.delivery?.city || it.movementData?.delivery?.location.city) ?
                  (`${(it.movementData?.delivery?.city ? `${it.movementData?.delivery?.city},` : it.movementData?.delivery?.location.city ? `${it.movementData?.delivery?.location.city},` : "")} 
                    ${(it.movementData?.delivery?.state ? `${it.movementData?.delivery?.state},` : it.movementData?.delivery?.location.state ? `${it.movementData?.delivery?.location.state},` : "")} 
                    ${(it.movementData?.delivery?.zip || it.movementData?.delivery?.location.zip || "")}`)
                  : "No Data"
                }
              </p>
            </div>
          </div>


        </div>

      </div>

      <div className="main_tooltip_section_other_t">

        <div className="tooltip_left_pu1">
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left">Planned Arrival Time: </div>
            <div className="tooltip_text_right">{it.planned_arrive_utc ? formatDate(it?.planned_arrive_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart Time: </div>
            <div className="tooltip_text_right">{it.planned_depart_utc ? formatDate(it?.planned_depart_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {it.eta_utc ? formatDate(it?.eta_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div> */}
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Arrival Time: </div>
            <div className="tooltip_text_right"> {it.eta_arrival_utc ? formatDate(it?.eta_arrival_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Departure Time: </div>
            <div className="tooltip_text_right"> {it.eta_departure_utc ? formatDate(it?.eta_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival Time: </div>
            <div className="tooltip_text_right df">            
            {it.actual_arrival_utc ? formatDate(it?.actual_arrival_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}

          

              {/* <div onClick={(e) => handleEdit()}><i className="fa fa-pencil tooltip_edit_icon" type="button" aria-hidden="true"></i></div> */}

              

            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart Time: </div>
            <div className="tooltip_text_right">{it.actual_departure_utc ? formatDate(it?.actual_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Distance: </div>
            <div className="tooltip_text_right">{it.movementData.pcmiler_distance ? it?.movementData?.pcmiler_distance+" Miles": "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle1">



        </div>
        <div className="tooltip_rightPu1">
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left">Planned Arrive: </div>
            <div className="tooltip_text_right">{it?.movementData.delivery.planned_arrive_utc ? formatDate(it?.movementData.delivery.planned_arrive_utc, it?.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart: </div>
            <div className="tooltip_text_right">{it?.movementData.delivery.planned_depart_utc ? formatDate(it?.movementData?.delivery?.planned_depart_utc, it?.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {it.movementData.delivery.eta_utc ? formatDate(it?.movementData?.delivery.eta_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div> */}

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Arrival Time: </div>
            <div className="tooltip_text_right"> {it.movementData.delivery.eta_arrival_utc ? formatDate(it?.movementData?.delivery.eta_arrival_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Departure Time: </div>
            <div className="tooltip_text_right"> {it.movementData.delivery.eta_departure_utc ? formatDate(it?.movementData?.delivery.eta_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival Time: </div>
            <div className="tooltip_text_right">{it.movementData.delivery.actual_arrival_utc ? formatDate(it?.movementData.delivery.actual_arrival_utc, it?.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart Time: </div>
            <div className="tooltip_text_right">{it.movementData.delivery.actual_departure_utc ? formatDate(it?.movementData?.delivery?.actual_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
        </div>

      </div>
    </span>
  );
}
const Order3PBToolTip = (props) => {
  const context = useContext(DropContext);
  const plannerTimezone = context.plannerTimezone;
  const it = props.it;
  const displayfororderid = (it) => {
    if (it.orderId === null) {

      return it.orderId2
    }
    else {
      return it.orderId
    }
  }
  return (
    <span className="tooltip-adjust" onClick={handleStopPropogation}>
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">{displayfororderid(it)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move: </div>
            <div className="tooltip_text_right">{it?.move_id}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {it?.type}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {it?.movementData?.movement_type}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it?.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it?.movement_status}</div>
          </div>
        </div>
      </div>

      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec  ">
            <div className="tooltip_text_left fs_20">{it?.type ? it.type?.toUpperCase() : "No Data"} : </div>
          </div>
          <div className="tooltip_single_sec">
            <p className="tooltip_text_right address_set">
              <p className="tooltip_text_right">
                {it.movementData?.delivery?.location?.code ?
                  (`${it.movementData?.delivery?.location?.code} - ${it.movementData?.delivery?.location?.location_name}`)
                  : "No Data"
                }
                <br />
                {it.movementData?.delivery?.location?.address1 ?
                  (`${it.movementData?.delivery?.location?.address1}`)
                  : null
                }
                <br />
                {it.movementData?.delivery?.location?.city ?
                  (`${it.movementData?.delivery?.location?.city}, ${it.movementData?.delivery?.location?.state}, ${it.movementData?.delivery?.location?.zip} `)
                  : null
                }
              </p>
            </p>
          </div>
        </div>
        <div className="tooltip_middle">
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap1">
        <div className="tooltip_left">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Early Pickup Appt: </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.sched_arrive_early_utc ?
              formatDate(it.movementData?.delivery?.sched_arrive_early_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Late Pickup Appt: </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.sched_arrive_late_utc ?
              formatDate(it.movementData?.delivery?.sched_arrive_late_utc, it.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt Rq'd:</div>
            <div className="tooltip_text_right">  {"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt confirmed:</div>
            <div className="tooltip_text_right">  {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap" >
        <div className="tooltip_left">
          <div className="tooltip_single_sec mt_5 ">
            <div className="tooltip_text_left">Planned Arrive : </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.planned_arrive_utc ?
              formatDate(it.movementData?.delivery?.planned_arrive_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart : </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.planned_depart_utc ? formatDate(it.movementData?.delivery?.planned_depart_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          {/* <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.eta_utc ? formatDate(it.movementData?.delivery?.eta_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div> */}

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Arrival Time: </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.eta_arrival_utc ? formatDate(it?.movementData?.delivery?.eta_arrival_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA Departure Time: </div>
            <div className="tooltip_text_right"> {it.movementData?.delivery?.eta_departure_utc ? formatDate(it?.movementData?.delivery?.eta_departure_utc, it?.movementData?.delivery?.delivery_timezone) : "No Data"}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival : </div>
            <div className="tooltip_text_right">  {it.movementData?.delivery?.actual_arrival_utc ? formatDate(it.movementData?.delivery?.actual_arrival_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart : </div>
            <div className="tooltip_text_right">  {it.movementData?.delivery?.actual_departure_utc ? formatDate(it.movementData?.delivery?.actual_departure_utc, it.movementData.delivery.delivery_timezone) : "No Data"}</div>
          </div>
        </div>

        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
    </span>
  );
}
export {
  SamsaraPBToolTip,
  TractorPBToolTip,
  OrderTooltip,
  OrderPBToolTip,
  Order2PBToolTip,
  Order3PBToolTip,
  ShiftToolTip
};
export default PlannerBoardTractorTooltip;