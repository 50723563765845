import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@mui/styles";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import {getAllTerminals} from "../../services/terminalService";
import { Autocomplete, TreeView, TreeItem } from "@mui/lab";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {getAllPlanningProfiles, planningProfileByTerminalId, updateplanningprofile,
  getplanningprofilebyid, deletePlanningProfileById} from "../../services/userService";
import { ContextData } from "../../components/appsession";
//import PlanningProfileList from "../planningProfileList/planningProfileList";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PlanningProfile from "../planningProfileTable/planningProfile";
import ProgressBar from "react-bootstrap/ProgressBar";
import { toast } from 'react-toastify';

const PlanningProfileList = (props) => {
  // const {planningProfile, setPlanningProfile} = props
  const user = props.user;
  const updateUser = props.updateUser;

  const planningdata = useContext(ContextData);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [allTerminal, setAllTerminal] = useState([]);
  const [terminal_id, setTerminal_id] = useState()
  const [planningDataCg, setPlanningDataCg] = useState([])
  const [planningDataShippers, setPlanningDataShippers] = useState([])
  const [planningProfile, setPlanningProfile] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planningprofileName, setPlanningprofileName] = useState();
  const [selectedShippers, setSelectedShippers] = useState([]);
  const [editplannigdata, setPlanningdata] = useState([]);

  const [isOpenDeleteModalForPF, setIsOpenDeleteModalForPF] = useState(false)
  const [planningProfileDataForDelete, setPlnningProfileDataForDelete] =useState("")
  const [editeddefaultplanner, setediteddefaultplanner] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserData] = useContext(ContextData);

  const permission = userData?.roles?.rolespermission?.find(planningpermission =>
    planningpermission?.permissiondetail.code === "PlanningProfile")


  useEffect(() => {
    const terminal = getAllTerminals().then(res => setAllTerminal(res))
    getAllPlanningProfiles().then(res => setPlanningProfile(res));
  }, [planningProfile.length])

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    }
  });
  const [dataResult, setDataResult] = useState(process(planningProfile, dataState));
  const dataStateChange = (event) => {
    setDataResult(process(planningProfile, event.dataState));
    setDataState(event.dataState);
    setIsLoading(false);
  };

  useEffect(() => {
    setDataResult(process(planningProfile, dataState));
  }, [planningProfile]);

  const created_atplanningtable = (props) => {
    let date = Date.parse(props.dataItem.created_at)

    if (isNaN(date)) {
      return (
        <td>      { }
        </td>)
    }
    else {
      return (
        <td>      {DateTime.fromMillis(parseInt(date)).toFormat("MM-dd-yyyy,hh:mm a").toString()}
        </td>)
    }

  }

  const handleProfileNameChange = (event) => {
    setPlanningprofileName(event.target.value);
  }

  const handleChange = async (e, value) => {
    setTerminal_id(value.code);
    const response = await planningProfileByTerminalId(value.code)
    const commodity_groups = response.data.data.commodity_groups;
    const shippers = response.data.data.shippers.slice(0, 100);
    setPlanningDataCg(commodity_groups);
    setPlanningDataShippers(shippers);
    setModalOpen(true)
  }
  const defaultplannerHandlerForEdit = (e) => {
    setediteddefaultplanner(e.target.checked)
}
const commodityData = {
  id: "cg_top",
  name: "Commodity",
  children: planningDataCg.map((cg_item) => {
    return {
      id: cg_item.code,
      name: cg_item.description,
    }
  }),
}
function getChildById(node, id) {
  let array = []

  function getAllChildCommodity(nodes) {
    if (nodes === null) return []
    array.push(nodes.id)
    if (Array.isArray(nodes.children)) {
      nodes.children.forEach((node) => {
        array = [...array, ...getAllChildCommodity(node)]
        array = array.filter((v, i) => array.indexOf(v) === i)
      })
    }
    return array
  }
  function getNodeById(nodes, id) {
    if (nodes.id === id) {
      return nodes
    } else if (Array.isArray(nodes.children)) {
      let result = null
      nodes.children.forEach((node) => {
        if (!!getNodeById(node, id)) {
          result = getNodeById(node, id)
        }
      })
      return result
    }

    return null
  }

  return getAllChildCommodity(getNodeById(node, id))
}
function getOnChange(checked, nodes,length) {
  const allNode = getChildById(commodityData, nodes.id)
  let array = checked
  ? [...selected, ...allNode]
  : selected.filter((value) => !allNode.includes(value))
  array = array.filter((v, i) => {
    return array.indexOf(v) === i
  })
  if(nodes.id != 'cg_top' && !checked){
    array = array.filter(el => !(el=='cg_top'))
  }
  if(!(array.includes('cg_top'))){
    if(array.length == length){
      array.push('cg_top')
    }
  }
  setSelected(array)
  
}
const renderCommodityTree = (nodes,length) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={selected.some((item) => item === nodes.id)}
            onChange={(event) =>{getOnChange(event.currentTarget.checked, nodes,length)}
            }
            onClick={(e) => e.stopPropagation()}
            />
        }
        label={<>{nodes.name}</>}
        key={nodes.id}
      />
    }
    >
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderCommodityTree(node,nodes.children.length))
      : null}
  </TreeItem>
)
const shipperData = {
  id: "shipper_top",
  name: "Shipper",
  children: planningDataShippers.map((item) => {

    const obj = {

      id: item.company_location_id,

      name: item.location_name,

      children: [
        {
          id: item.company_location_id+"_"+"COMMODITY",
          name: "Commodity Group",
          children: planningDataCg.map(cg_item=>{
            return {
              id: item.company_location_id+"_"+"COMMODITY"+"-" +cg_item.code,
              name: cg_item.description,
            }
          })
        }

      ]
    }
      return obj;

  }),
}

const areAllChildrenSelected = (children) => {
  if (!children || children.length === 0) {
    return false;
  }

  return children.every((child) => selectedShippers.includes(child.id));
};

function getShipperChildById(node, id) {
  let array = []

    function getAllChildShipper(nodes) {
       array = []; // Define the array variable within the function scope
      
      if (nodes === null) return array;
      
      array.push(nodes.id);
      
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChildShipper(node)];
        });
      }
      
      array = array.filter((v, i) => array.indexOf(v) === i); // Filter out duplicate elements
      
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      }
    
      if (Array.isArray(nodes.children)) {
        for (let i = 0; i < nodes.children.length; i++) {
          const result = getNodeById(nodes.children[i], id);
          if (result !== null) {
            return result;
          }
        }
      }
    
      return null;
    }

  return getAllChildShipper(getNodeById(node, id))
}
function getOnChangeShipper(checked, nodes, length) {
  let commodityParent;
  let shipperChild;
  const allNode = getShipperChildById(shipperData, nodes.id)
  let array = checked
    ? [...selectedShippers, ...allNode]
    : selectedShippers.filter((value) => !allNode.includes(value)) 
  array = array.filter((v, i) => array.indexOf(v) === i)
  commodityParent = nodes.id.split("-")[0]
  shipperChild = commodityParent.split("_")[0]
  
  if(nodes.id != shipperChild && !checked){
    array = array.filter(el => !(el == shipperChild))
    if(nodes.id != commodityParent && !checked){
      array = array.filter(el => !(el == commodityParent))
    }
  }
  let newArr = [] 
  array.map(el => {
    if(el.includes(commodityParent + '-')){
      newArr.push(el)
    }
  })
  if(newArr?.length == length){
    array = [...array,commodityParent,shipperChild]
  }
  if(array.includes(commodityParent)){
    array.push(shipperChild)
  }
    setSelectedShippers(array)
}
const renderShipperTree = (nodes, length) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={selectedShippers.some((item) => item === nodes.id)}
              onChange={(event) =>
                getOnChangeShipper(event.currentTarget.checked, nodes, length)
              }
            onClick={(e) => e.stopPropagation()}
            indeterminate={
              selectedShippers.includes(nodes.id) &&
              !areAllChildrenSelected(nodes.children)
            }
          />
        }
        label={<>{nodes.name}</>}
        key={nodes.id}
      />
    }>
      {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderShipperTree(node, nodes.children.length))
      : null}
  </TreeItem>
)
  const updatePlanningProfile = async () => {

    if (!planningprofileName) {
      toast.error("Enter Planning Profile Name", "Error");
    }
    else {
      // let cg_to_save = selected.filter(x => x.includes("cgel_"));
      // cg_to_save = cg_to_save.map(x => x.replace("cgel_", ""));
      let cg_to_save = selected;
      // cg_to_save = cg_to_save.map(x => x.replace("cgel_", ""));
        let planningprofile_json = {
          "terminal_id": terminal_id,
          "commodity_groups": cg_to_save,
          "shippers": selectedShippers
        }
        let request_data = {
          "id": editplannigdata[0].id,
          "name": planningprofileName,
          "terminal_id": terminal_id,
          "planningprofile_json": JSON.stringify(planningprofile_json),
          "created_by": planningdata.userId,
          "is_default_planner":editeddefaultplanner
        }
        let response = await updateplanningprofile(request_data);
      
        if(response?.response?.status==500||response?.response?.status==409){
          setOpenConfirmationModal(false);
          if(response?.response?.status==500){
            toast.error("Failed to update planning profile", "Error");
           }
          if(response?.response?.status==409){
            toast.error(response.response.data.message, "Error");
          }
          getAllPlanningProfiles().then(res => setPlanningProfile(res));

          setPlanningDataCg([]);
          setPlanningDataShippers([]);
          setPlanningprofileName([]);
          setExpanded([]);
          setSelected([]);
          setSelectedShippers([]);
          return;
        }
        if (response.data.data[0]) {
          // setPlanningProfile(refreshkendotable)
          setOpenConfirmationModal(false);
          toast.success("Planning profile updated successfully", "success");
          getAllPlanningProfiles().then(res => setPlanningProfile(res));

          setPlanningDataCg([]);
          setPlanningDataShippers([]);
          setPlanningprofileName([]);
          setExpanded([]);
          setSelected([]);
          setSelectedShippers([]);

        }
    }
  }


  const editplanningprofile = async (data) => {
    const getresponse =[]
    getresponse.push(data)
    setPlanningprofileName(getresponse[0].name)

    const planningjs = JSON.parse(getresponse[0].planningprofile_json);
    const selectedcommodity = planningjs?.commodity_groups
    const selectedshipper = [...new Set((planningjs?.shippers || []).map(e => e.split("_")[0]))];
   
    const childlevelselectedshipper = (planningjs?.shippers || []).map(e => e.split("-")[1]);

    const allselectedshipper = [...childlevelselectedshipper];
  

    for (let i = 0; i < selectedshipper.length; i++) {
      allselectedshipper.push("shipper_top", "shipper_cg_top", ...selectedshipper[i]);
    }


    // setSelected([...selectedcommodity, ...allselectedshipper]);
    setSelected([...selectedcommodity])
    setSelectedShippers(planningjs.shippers)
    setPlanningdata(getresponse);
    const response = await planningProfileByTerminalId(data.terminal_id)
  
    const commodity_groups = response.data.data.commodity_groups;
  
    const shippers = (response?.data?.data?.shippers || []).slice(0, 100);

    setPlanningDataCg(commodity_groups);
    setPlanningDataShippers(shippers);   
    setOpenConfirmationModal(true);
  };
  
  const deletePlanningprofileModalOpen = (data) => {
    setIsOpenDeleteModalForPF(true);
    setPlnningProfileDataForDelete(data);
  };
  const deletePlanningprofile = async (data) => {
    if(data.is_default_planner){
    toast.error("Default Planning Profile cannot be deleted")
    }
    else{
   const response = await deletePlanningProfileById(data.id);
    
     if (response.status == 200) {
     
      toast.success('The Planning Profile Has Been Deleted', 'Success');
    getAllPlanningProfiles().then(res => setPlanningProfile(res));
      setIsOpenDeleteModalForPF(false);
    } else {
      toast.error(
        "This Planning Profile is Already Assigned to a User , Please Unassign Before Deleting.", Error
      );
    }
    }

   

   
  }
	const ActionPlanningProfile = (props) => {
		return (
			<>
				<td className="adjustbutton">
          {permission?.can_edit?(
            <button
						type="button"
              className="btn_blue_smadjust btn-blue ml_10"
						onClick={() => editplanningprofile(props.dataItem)}>
              <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
						EDIT
					</button>
          ):(
            <button
						type="button"
                className="btn_blue_smadjust btn-blue ml_10"
            disabled
						>
                <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
						EDIT
					</button>
          )
        }
					{permission?.can_edit?(
            <button
						type="button"
              className="btn_blue_smadjust btn-blue ml_10"
						onClick={() => {
							deletePlanningprofileModalOpen(props.dataItem)
						}}>
              <i className="fa fa-trash mr_5 del_icon" aria-hidden="true"></i>
						Delete
					</button>
          ):(
            <button
						type="button"
                className="btn_blue_smadjust btn-blue ml_10"
            disabled
					>
                <i className="fa fa-trash mr_5 del_icon" aria-hidden="true"></i>
						Delete
					</button>
          )
        }
					
				</td>
			</>
		)
	}
  const parentcallback=(data)=>{
    setPlanningProfile(data)
  }
	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="card card_shadow">
        
						<div className="card-body ">
            <PlanningProfile parentcallback={parentcallback}/>
           
							<div className="table-responsive">
             
                {
                  planningProfile && planningProfile?.length > 0 ? (
                    <React.Fragment>
                      <Grid
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        filterable={true}
                        pageable={{
                          pageSizes: [5, 10, 20, 25, 50, 100],
                          info: true,
                          previousNext: true,
                          buttonCount: 10
                        }}
                        resizable={true}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult && dataResult}
                        onRowClick={(e) => props.parentcallback(true, e.dataItem)}
											onDataStateChange={dataStateChange}>
											<GridColumn title="Action" cell={ActionPlanningProfile} />
											<GridColumn field="name" title="Name" />
											<GridColumn
												field="terminal.terminal"
												title="Terminal"
											/>
                        <GridColumn
                    field="is_default_planner"
                    sortable={true}
                    cell={(e) => {
                      return (
                        <td>                      
                          <FormControlLabel 
                          control=
                          {

                          // <Checkbox color="black" checked={e.dataItem.is_default_planner ? true : false}  />
                          <div className="squareDisabled">
                          <input
                                  name="roleradio"
                                  type="checkbox"
                                  checked={e.dataItem.is_default_planner ? true : false}
                                  disabled
                                />
                                </div>
                        
                        }

                          labelPlacement="start" />
                        </td>
                      );
                    }}
                    title="Default Planning Profile"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                  />

                     
                      </Grid>
                    </React.Fragment>
                  )
                    : isLoading ? (
                      <div>
                        <ProgressBar animated now={100} />
                      </div>
                    ) : (
                      <div className="text-center">No Data Found</div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <Modal
        show={openConfirmationModal}
        onHide={() => setOpenConfirmationModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Planning profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="exampleFormControlInput1">Terminal</label>
          <div className="meterial_autocomplete">
            <Autocomplete
              disablePortals
              id="terminals"
              options={allTerminal}
              value={{ full_terminal_name: editplannigdata[0]?.terminal.terminal }}
              //defaultValue={ editplannigdata[0]?.terminal?.terminal}
              // defaultValue={editplannigdata[0]?.name}

              // value={planningprofileName}

              getOptionLabel={(options) => `${options.full_terminal_name}`}
              sx={{ width: 300 }}
              onChange={handleChange}
              disabled
              renderInput={(params) => <TextField {...params} variant="outlined"
                fullWidth={true} placeholder="Select a Terminal" />}
            />
          </div>
          {/* <div className="planning_profile_popup_continue">
                        <Button onClick={() => populateAccessTree(terminal_id)}>Continue</Button>
                    </div> */}


          <label for="exampleFormControlInput1">Planning Profile Name</label>

          <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
              <TextField id="outlined-basic"
                placeholder="Enter Planning Profile Name"
                variant="outlined"
                defaultValue={editplannigdata[0]?.name}
                value={planningprofileName}
                onChange={handleProfileNameChange}
              />
            </div>
          </div>
          <div className="mt_10 ml_5 ">
            <label for="defaultplanner">Default Planning Profile</label>
            <input type="checkbox" className='ml_6' defaultChecked={editplannigdata[0]?.is_default_planner ? true : false}
              onChange={(e) => defaultplannerHandlerForEdit(e)}
            />
          </div>



          <div className="plnning_treeview_section">
            <>
              {planningDataCg && planningDataCg.length > 0 ? (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {renderCommodityTree(commodityData)}
                </TreeView>
              ) : null}
              {planningDataShippers && planningDataShippers.length > 0 ? (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}>
                  {renderShipperTree(shipperData)}
                </TreeView>
              ) : null}
            </>
          </div>
          <div className="planning_profile_popup_continue">
            <button
              type="button"
              className="btn_blue btn-blue ml_10" onClick={updatePlanningProfile} >
              Save
            </button>
          </div>
        </Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
			<Modal
				show={isOpenDeleteModalForPF}
				onHide={() => setIsOpenDeleteModalForPF(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Delete Planning profile
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure to delete?</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn_blue btn-blue ml_10" onClick={()=>{deletePlanningprofile(planningProfileDataForDelete)}}>
						Delete
					</button>
					<button
						type="button"
						className="btn_blue btn-blue ml_10"
						onClick={() => setIsOpenDeleteModalForPF(false)}>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>
		</>

  )
}

export default PlanningProfileList
