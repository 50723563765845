import React, { useEffect, useState, } from "react";
import AlertHeader from "../components/alerts/AlertsHeader";
import Header from "../components/header";
import AppBar from "../components/appbar";
import AlertsTable from "../components/alerts/AlertTables";




const Alerts = (props) => {

    return (<div id='wrapper'>
        <Header
        // userclicked={driverPayConfigClicked}
        // parentcallback={handelcallbackFromHeader}
        ></Header>
        <AppBar />
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">

                    <>
                        <AlertHeader />
                        <AlertsTable />
                    </>

                </div>
            </div>
        </div>
    </div>)

}

export default Alerts;