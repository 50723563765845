import React, { useState, useEffect, useContext } from "react";
import {getTerminalByTerminalIds} from "../../services/terminalService";
import {getAppFilter} from "../../services/appFilterService";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import TractorHeader from "../../components/tractorHeader/tractorHeader";
import TractorTable from "../../components/tractorTable/tractorTable";
import {getAllTractors, getTractor} from "../../services/tractorService";
import TractorByIdHeader from "../../components/tractorByIdHeader/tractorByIdHeader";
import TractorBodyForDetails from "../../components/tractorBodyForDetails/tractorBodyForDetails";
import TractorBodyForSpecification from "../../components/tractorBodyForSpecification/tractorBodyForSpecification";
import { toast } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import { ContextData } from "../../components/appsession";
import {  useParams } from "react-router-dom";
import DriverAndTractoryHistory from "../../components/driverAndTractorHistory/driverAndTractorHistory";
import { getDriverOrTractorHistoryById } from "../../services/trailerService";
import {DateTime} from "luxon";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

function Tractors() {
  const [userData, setuserData] = useContext(ContextData);
  const { id } = useParams();
  const [allTractors, setallTractors] = useState([]);
  const [allTractorsCount, setallTractorsCount] = useState("");
  const [tractorClicked, settractorClicked] = useState(false);
  const [tractorById, settractorById] = useState({});
  const [headerTabName, setheaderTabName] = useState("details");
  const [terminalsOptions, setTerminalsOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [regionOptions, setregionOptions] = useState([]);
  const [tractorslistData, setTractorslistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tractorHistory, setTractorHistory] = useState([]);

 
  const loadData = async () => {
    const planning_terminal_ids = userData.planningprofiles?.map((it)=>it.userPlanningprofile?.terminal_id);
    if (planning_terminal_ids?.length > 0) {
      setIsLoading(true)
      let terminalinformationlist = await getTerminalByTerminalIds(
        planning_terminal_ids
        );
        
        let filterData = {
          terminal_id: planning_terminal_ids,
        };
        setTerminalsOptions(terminalinformationlist);

      const tractorService = getAllTractors(filterData)
        .then(function (tractorslist) {
          setallTractors(tractorslist);
          setTractorslistData(tractorslist);
          setallTractorsCount(tractorslist.length);
          setIsLoading(false)
        })
        .catch(function (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [userData]); 


  const handelcallbackFromHeader = (childdata) => {
    settractorClicked(childdata);
    setheaderTabName("details");
  };

  
  const formatDate = (epoch_date, tzone) => {
    if (!tzone || tzone === "Eastern Standard Time") {
      tzone = "America/New_York"
    }
    if (tzone === "Central Standard Time") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }
  }

  const handelcallback = async (childdata, tractor) => {
    if (tractor) {
      let newData = [];
      setIsLoading(true);

      const payload = {
        tractor_id : tractor?.tractor_id
      };

      let response = await getDriverOrTractorHistoryById(payload);

      for (let i = 0; i < response.length; i++) {
        let temp = response[i];
        temp.planned_arrive_utc = formatDate(temp.planned_arrive_utc, temp.location_timezone);
        temp.planned_depart_utc = formatDate(temp.planned_depart_utc, temp.location_timezone);
        temp.actual_arrival_utc = formatDate(temp.actual_arrival_utc, temp.location_timezone);
        temp.actual_departure_utc = formatDate(temp.actual_departure_utc, temp.location_timezone)
        newData.push(temp);
      }


      setTractorHistory(newData);
      setIsLoading(false);
    }

    settractorById(tractor);
    settractorClicked(childdata);
  };

  useEffect(() => {
    if (id !== undefined) {
      const t = getTractor(id)
        .then(function (tractor) {
          // settractorById(tractor);
          // handelcallback(true, tractor);
        })
        .catch(function (err) {
          toast.error(err, "Error");
        });
    }
  }, [id]);

  const handelcallbackFromLocationHeader = (childdata) => {
    setheaderTabName(childdata);
  };
  const handelcallbackFromTractors = (childData) => {
    if (childData.length > 0) {
      const tempallTractors = [...tractorslistData];
      const tempArray = [];
      tempallTractors.map((el) => {
        if (childData.indexOf(el.terminal_id) > -1) {
          tempArray.push(el);
        }
      });
      setallTractors(tempArray);
    } else {
      setallTractors(tractorslistData);
    }
  };

  useEffect(() => {
    const appFilter = getAppFilter();
    setStateOptions(appFilter.state);
    setregionOptions(appFilter.region);
  }, []);

  const convertDateTime = (epoch_date)=>{
    return (
      <td>
        {DateTime.fromMillis(parseInt(epoch_date * 1000)).toFormat("MM-dd-yyyy, hh:mm").toString()}
      </td>
    )
  }

  return (
    <div id="wrapper">
      <Header
        userclicked={tractorClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!tractorClicked ? (
              <>
                <TractorHeader
                  allTractors={allTractors}
                  // parentCallBackForTractorFilter={
                  //   parentCallBackForTractorFilter
                  // }
                  // tractorDropdown={tractorDropdown}
                  parentCallback={handelcallbackFromTractors}
                  terminalsOptions={terminalsOptions}
                  stateOptions={stateOptions}
                  regionOptions={regionOptions}
                 
                />
                <TractorTable
                  allTractors={allTractors}
                  setallTractors={setallTractors}
                  isLoading = {isLoading}
                  parentcallback={handelcallback}
                  convertDateTime={convertDateTime}
                />
              </>
            ) : (
              <>
                <TractorByIdHeader
                  tractorById={tractorById}
                  parentcallback={handelcallbackFromLocationHeader}
                  convertDateTime={convertDateTime}
                 
                />
                {headerTabName === "details" ? (
                  <TractorBodyForDetails tractor={tractorById} />
                ) : headerTabName === "Specifications" ? (
                  <TractorBodyForSpecification tractor={tractorById} />
                ) : headerTabName === "history" ? (
                  <DriverAndTractoryHistory       
                    history={tractorHistory}
                    isLoading={isLoading}
                    historyof={'tractor'}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tractors;
