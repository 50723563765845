

// Application Specific
import Terminal from '../models/terminalModel';
import TractorForTerminal from "../models/tractorModel"
import Driver from '../models/driverModel';
import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';
import axios from 'axios';

export const getAllTerminals = async () => {
    const terminal = new Terminal();
    let terminalObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/terminals";
        const terminalApiData = await axios.get(url);
        terminalObject = terminalApiData.data.data.map(data => terminal.parseApiTerminalObject(data));
    } catch (err) {
        return Promise.reject("Failure: Unable to retrieve terminal list. Please try refreshing again!");; //"There is a problem on retrieving terminal data. Please try again!");
    }

    return Promise.resolve(terminalObject);
}

export const getAllUsersForTerminalById = async (terminalId) => {
    try {
        if (!terminalId) {
            throw new Error("Terminal Id not provided");
        }
        const url = BaseConfig.ApiEndPoint + `/terminals/users/${terminalId}`;
        const { data: { data: terminalUsers } } = await axios.get(url);
        return terminalUsers;
    } catch (err) {
        throw err;
    }
}

export const upadteTerminalofOrderOrTrailer = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/updateterminaloforderortrailer";
        const response = await axios.put(url, payload);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getTerminalByIds = async (ids) => {
    const terminal = new Terminal();
    let terminalObject = []
    let response = []
    // Call API using Await
    try {
        let data = {
            "terminal_ids": ids
        };
        const url = BaseConfig.ApiEndPoint + "/terminalbyIds";
        const terminalApiData = await axios.post(url, data);
        terminalObject = terminalApiData.data.data.map(data => terminal.parseApiTerminalObject(data));
    } catch (err) {
        return Promise.reject("Failure: Unable to retrieve terminal list. Please try refreshing again!");; //"There is a problem on retrieving terminal data. Please try again!");
    }

    return Promise.resolve(terminalObject);
}

export const getTractorsByTerminalID = async (terminalId,searchData) => {
    let allTractors = []
    const tractor = new TractorForTerminal()
    try {
        let data = {
            "terminal_id": terminalId,
            "search": searchData
        }
        const url = BaseConfig.ApiEndPoint + "/gettractorbyterminalid";
        const tractorApiData = await axios.post(url, data);
        allTractors = tractorApiData.data.data.map(data => tractor.parseApiTractorObject(data));

    } catch (error) {

    }
    return Promise.resolve(allTractors);
}

export const getTractorByTerminalID = async (terminalId) => {
    let allTractors = []
    const tractor = new TractorForTerminal()
    try {
        let data = {
            "terminal_id": [terminalId],
            // "search": searchData
        }
        const url = BaseConfig.ApiEndPoint + "/gettractorbyterminalid";
        const tractorApiData = await axios.post(url, data);
        allTractors = tractorApiData.data.data.map(data => tractor.parseApiTractorObject(data));

    } catch (error) {

    }
    return Promise.resolve(allTractors);
}

export const getDriversByTerminalID = async (terminalId, searchText) => {
    let allDrivers = []
    const driver = new Driver()

    try {
        const data = {
            terminalId: terminalId,
            search: searchText,
        };
        const url = BaseConfig.ApiEndPoint + "/getDriversbyTerminalId";
        const driverApiData = await axios.post(url, data);
        allDrivers = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));

    } catch (error) {

    }
    return Promise.resolve(allDrivers);
}
export const getAssigneddriversbyplanningprofile = async (id) => {
    let allDrivers = []
    const driver = new Driver()

    try {
        const data = {
            planning_profile_id: id,
        };
        const url = BaseConfig.ApiEndPoint + "/getassigneddriversbyplanningprofile";
        const driverApiData = await axios.post(url, data);
        allDrivers = driverApiData.data.data.drivers;

    } catch (error) {

    }
    return Promise.resolve(allDrivers);


}
export const getUsersbyPlanningprofileid = async (planning_profileid) => {
    let allUsers = []
    try {
        const data = {
            planning_profileid: planning_profileid,
        };
        const url = BaseConfig.ApiEndPoint + "/getusersbyplanningprofileid";
        const usersApiData = await axios.post(url, data);

        allUsers = usersApiData.data.data;

    } catch (error) {
    }
    return Promise.resolve(allUsers);
}

export const getTerminalByTerminalIds = async (ids) => {
    const terminal = new Terminal();
    let terminalObject = []
    // Call API using Await
   let Id = ids.filter(id => id)
    try {
        // API object call.
        let data = {
            "terminal_id": Id
        };
        const url = BaseConfig.ApiEndPoint + "/getterminalsbyterminalids";
        const terminalApiData = await axios.post(url, data);
        terminalObject = terminalApiData.data.data;

    } catch (err) {
        return Promise.reject("Failure: Unable to retrieve terminal list. Please try refreshing again!");; //"There is a problem on retrieving terminal data. Please try again!");
    }
    return Promise.resolve(terminalObject);
}


export const searchlocation = async (searchTerms, page, size, sort) => {
    let decompressedObject;
    let payload_data = {
        searchdata: [],
        sortdata: []
    };
    const fieldMappings = {
        name: 'location_name',
        isActive: 'is_active',
        isTerminal:'is_terminal',
        isShop:'is_shop',
        isWash:'is_wash',
        isShipper:'is_shipper',
        isConsignee:'is_consignee',
        isRailyard:'is_rail_yard',
        isDroplot:'is_drop_lot',
        // newaddress:'address1'
       
        // Add more mappings for other field transformations if needed
    };

  
    searchTerms?.forEach((term) => {
        const { field, value, operator } = term;
        const transformedField = fieldMappings[field] || field;
    
        payload_data.searchdata.push({
            [transformedField]: value,
            operator: operator,
        });
    });

    sort?.forEach((sortQuery) => {
        const { field, dir } = sortQuery;
        const transformedField = fieldMappings[field] || field;
        
        payload_data.sortdata.push({
            field: transformedField,
            dir
        })
    })
    
    console.log('payload_data', payload_data)
    const url = `${BaseConfig.ApiEndPoint}/searchLocation?page=${page}&size=${size}`;
    try {
        const response = await axios.post(url, payload_data);
        console.log("response", response);
        if (response?.data && response?.data?.data) {
            const strData = atob(response.data.data);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);
            console.log('charData', binData)
            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
    } catch (error) {
        console.error("An error occurred while performing search:", error);
        throw error;
    }

    return decompressedObject;
};

export const upadteTerminalofOrder = async (payload) => {
    try {
        const url = BaseConfig.PlanningApiEndPoint + "/terminal/movement";
        const response = await axios.patch(url, payload);
        return response;
    } catch (err) {
        throw err;
    }
}


