import React, { useContext, useEffect, useState } from 'react'
import AppBar from '../../components/appbar';
import Header from '../../components/header';
import DriverPayCodesMasterHeader from '../../components/DriverPayCodesMasterHeader/DriverPayCodesMasterHeader';
import DriverPayCodesMasterTable from '../../components/DriverPayCodesMasterHeader/DriverPayCodesMasterTable';
import { ContextData } from '../../components/appsession';

const DriverPayCodesMaster = () => {
  const [driverPayConfigClicked, setDriverPayConfigCliked] = useState(false);
  const [allDriverPayConfig, setAllDriverPayConfig] = useState([]);
  const [userData, setuserData] = useContext(ContextData);
  const [isEditDisable,setEditDisable]=useState(false)

  const checkAccess =async () => {
    const permission = userData?.roles?.rolespermission;

    permission?.map((permit) => {
      if (
        permit?.permissiondetail.code === "DriverpayCodesMaster" &&
        permit?.can_edit === false
      ) {
        setEditDisable(true);
      }
    });
  };
  useEffect( () => {
    checkAccess()
  }, [userData]);

  return (
    <div id='wrapper'>
      <Header
        userclicked={driverPayConfigClicked}
      // parentcallback={handelcallbackFromHeader}
      />
      <AppBar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!driverPayConfigClicked ?
              <>
                <DriverPayCodesMasterHeader isEditDisable={isEditDisable} setEditDisable={setEditDisable} />
                <DriverPayCodesMasterTable allDriverPayConfig={allDriverPayConfig} isEditDisable={isEditDisable} setEditDisable={setEditDisable} />
              </>
              : ""}
          </div>
        </div>
      </div>

    </div>
  )
}

export default DriverPayCodesMaster;