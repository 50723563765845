import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";

const DriverAndTractoryHistory = (props) => {
    const { history, isLoading,historyof } = props;
    const gridWidth = 1800;

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const [dataResult, setDataResult] = useState(
        process(history, dataState)
    );

    const setPercentage = (percentage) => {
        return Math.round(gridWidth / 100) * percentage;
    };

    const dataStateChange = (event) => {
        setDataResult(process(history, event.dataState));
        setDataState(event.dataState);
    };

    useEffect(() => {
        setDataResult(process(history, dataState));
    }, [history]);

    return (
        <div className="row special_row_flex">
            <div className="col-xl-12">
                <div className="card card_shadow">
                    <div className="card-body">
                        <h2 className="header-title">Detailed History</h2>
                        <div className="container special_container_padding"></div>

                        {history?.length > 0 ? (
                            <Grid
                                filter={dataState.filter}
                                filterable={true}
                                sort={dataState.sort}
                                sortable={true}
                                pageable={{
                                    pageSizes: [5, 10, 20, 25, 50, 100],
                                    info: true,
                                    previousNext: true,
                                    buttonCount: 10
                                }}
                                resizable={true}
                                skip={dataState.skip}
                                take={dataState.take}
                                data={dataResult}
                                onDataStateChange={dataStateChange}
                            >
                                {
                                    historyof=='driver' ? 
                                    <GridColumn field="driver_id" title="Driver Id" width="200px" /> :
                                    <GridColumn
                                    field="tractor_id"
                                    title="Tractor Id"
                                    width="200px"
                                />
                                }
                                <GridColumn field="stop_type" title="Stop Type" width="200px" />
                                <GridColumn
                                    field="loaded"
                                    title="Loaded"
                                    width="200px"
                                    filter={"boolean"}
                                />
                                <GridColumn
                                    field="driver_load"
                                    title="Driver Load Unload Flag"
                                    width="200px"
                                />
                                <GridColumn field="commodity" title="Commodity" width="200px" />
                                <GridColumn field="distance" title="Distance" width="200px" />
                                <GridColumn field="order_id" title="Order Id" width="200px" />
                                <GridColumn field="order_status" title="Order Status" width="200px" />
                                <GridColumn field="movement_id" title="Movement ID" width="200px" />
                                <GridColumn field="movement_status" title="Movement Status" width="200px" />
                                <GridColumn
                                    field="location_code"
                                    title="Location Code"
                                    width="200px"
                                />
                                <GridColumn
                                    field="location_name"
                                    title="Location Name"
                                    width="200px"
                                />
                                <GridColumn
                                    field="location_address"
                                    title="Address"
                                    width="200px"
                                />
                                <GridColumn field="location_city" title="City" width="200px" />
                                <GridColumn
                                    field="location_state"
                                    title="State"
                                    width="200px"
                                />
                                <GridColumn
                                    field="responsible_terminal_name"
                                    title="Responsible Terminal Name"
                                    width="200px"
                                />
                                <GridColumn
                                    field="planned_arrive_utc"
                                    title="Planned Arrive"

                                    width={setPercentage(15)}

                                />
                                <GridColumn
                                    field="planned_depart_utc"
                                    title="Planned Depart"
                                    width={setPercentage(15)}

                                />
                                <GridColumn
                                    field="actual_arrival_utc"
                                    title="Actual Arrive"
                                    width={setPercentage(15)} />
                                <GridColumn
                                    field="actual_departure_utc"
                                    title="Actual Depart"
                                    width={setPercentage(15)}


                                />
                                 <GridColumn
                                    field="trailer_id"
                                    title="Trailer Id"
                                    width="200px"
                                />
                               
                                <GridColumn
                                    field="driver_first_name"
                                    title="Driver First Name Id"
                                    width="200px"
                                />
                                <GridColumn
                                    field="driver_last_name"
                                    title="Driver Last Name"
                                    width="200px"
                                />
                            </Grid>
                        ) : isLoading ? (
                            <div>
                                <ProgressBar animated now={100} />
                                <div className="middle loader--text1"> </div>
                            </div>
                        ) : (
                            <div className="header_paragraph">No Data Found</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverAndTractoryHistory;
