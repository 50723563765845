import Header from "../../components/header";
import AppBar from "../../components/appbar";
import React, { useState, useEffect, useContext, useRef } from "react";
import { ContextData } from "../../components/appsession";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getAlerts, dismissAlert } from "../../services/planningBoardService";
import { getTerminalByIds } from "../../services/terminalService";
import { getAllAlert, getAllAlertType } from "../../services/alertService";
import AlertHeader from "../../components/alerts/AlertsHeader";
import AlertsTable from "../../components/alerts/AlertTables";
import { getAllPlanningProfiles, getPlanningProfileById } from "../../services/userService";
import { Picky } from "react-picky";
import { useLocation } from "react-router-dom";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const alerts = () => {
  // Create a ref to hold the child component instance
  const alertTableRef = useRef(null);  
  const location = useLocation();

  const [userData, setuserData] = useContext(ContextData);
  const [isalertClicked, setisAlertClicked] = useState(false);
  const [is_reload, setisReload] = useState(false);
  const [allAlerts, setAllAlerts] = useState([]);
  // const [alerts, setAlerts] = useState([]);
  const [unreadCount, setUnreadCount] = useState([]);
  const [userTerminals, setUserTerminals] = useState([]);
  const [allTerminaldropdown, setallTerminaldropdown] = useState([]);
  const [selectedterminalsOptions, setSelectedTerminalsOptions] = useState([]);
  const [allRegiondropdown, setallRegiondropdown] = useState([]);
  const [selectedRegionOptions, setSelectedRegionOptions] = useState([]);
  const [allCommodityGroupDropdown, setAllCommodityGroupDropdown] = useState([]);
  const [selectedCommodityGroupOptions, setSelectedCommodityGroupOptions] = useState([]);
  const [allConsigneeDropdown, setAllConsigneeDropdown] = useState([]);
  const [allShipperDropdown, setAllShipperDropdown] = useState([]);
  const [allAlertTypeDropdown, setAllAlertTypeDropdown] = useState([]);
  const [selectedConsigneeOptions, setSelectedConsigneeOptions] = useState([]);
  const [selectedShipperOptions, setSelectedShipperOptions] = useState([]);
  const [selectedAlertTypeOptions, setSelectedAlertTypeOptions] = useState([]);

  const [headerName, setHeaderName] = useState("Alerts")
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [alertsType, setAllAlertType] = useState([])
  const [itemSelected, setItemSelected] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false)
  const [planningprofile, setPlanningProfile] = useState([])
  const [usersPlanningProfile, setusersPlanningProfile] = useState([])
  const [filteredAlert, setFilteredAlerts] = useState([])
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [selectedTerminalIDs, setSelectedTerminalIDs] = useState([]);
  const [isplanningProfileChange, setisplanningProfileChange] = useState(0);
  const [unassignedPlanningProfileOptions, setUnassignedPlanningProfileOptions] = useState([]);
  const [assignedPlanningProfileOptions, setAssignedPlanningProfileOptions] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedTerminalList, setSelectedTerminalList] = useState([]);

  const getInitialData = async (terminals) => {
    let terminalsData = await getTerminalByIds(terminals)
    setUserTerminals(terminalsData);
    let regionList = []
    let terminalList = []
    let commodityGroupList = []
    let consigneeList = []
    let shipperList = []
    let alertTypeList = []

    let alertsResponse = await getAlerts();
    let alertsResponseData = alertsResponse.data;
    setAllAlerts(alertsResponseData);

    // setAlerts(alertsResponseData);
    let unreadAlerts = alertsResponseData.filter(alert => !alert.isdismissed);
    setUnreadCount(unreadAlerts.length)
    let allCommoditygroups = [...new Set(alertsResponseData.map(alert => alert.commodity_group_description))]
    allCommoditygroups.map((commodityGroup, index) => {
      if (commodityGroup && commodityGroup !== "NULL" && commodityGroup !== "null") {
        let commodityGroupObj = {};
        commodityGroupObj.label = commodityGroup
        commodityGroupObj.value = index;
        commodityGroupList.push(commodityGroupObj)
      }
    })
    setAllCommodityGroupDropdown(commodityGroupList);
    setSelectedCommodityGroupOptions(commodityGroupList);
    let uniRegion = [...new Set(terminalsData.map(r => r.region))]
    let uniConsignee = [...new Set(alertsResponseData.map(c => c.consignee))]
    let uniShipper = [...new Set(alertsResponseData.map(s => s.shipper))]
    let uniAlert = [...new Set(alertsResponseData.map(a => a.alert_type))]
    uniRegion.map((regi, index) => {
      let regionObj = {};
      regionObj.label = regi;
      regionObj.value = index;
      regionList.push(regionObj)
    })
    setallRegiondropdown(regionList)
    setSelectedRegionOptions(regionList)
    userTerminals.map(term => {
      let terminalObj = {}
      terminalObj.label = `${term.code}-${term.city}`,
        terminalObj.value = term.code
      terminalList.push(terminalObj)
    })
    setallTerminaldropdown(terminalList)

    uniConsignee.map((consi, index) => {
      let consigneeObj = {}
      consigneeObj.label = consi;
      consigneeObj.value = index;
      consigneeList.push(consigneeObj)
    })
    setAllConsigneeDropdown(consigneeList)
    setSelectedConsigneeOptions(consigneeList)
    uniShipper.map((ship, index) => {
      let shipperObj = {}
      shipperObj.label = ship;
      shipperObj.value = index;
      shipperList.push(shipperObj)
    })
    setAllShipperDropdown(shipperList)
    setSelectedShipperOptions(shipperList)
    uniAlert.map((alert, index) => {
      let alertObj = {}
      alertObj.label = alert;
      alertObj.value = index;
      alertTypeList.push(alertObj)
    })
    setAllAlertTypeDropdown(alertTypeList)
    setSelectedAlertTypeOptions(alertTypeList)
  };

  const handelcallbackFromHeader = async (childdata) => {
    setisAlertClicked(childdata);
  };

  const dismissAlert = async (e) => {
    const alertId = e.currentTarget.id;
    const dismissAlertResponse = await dismissAlert(
      alertId
    );
    await refreshAlerts();
  };

  const refreshAlerts = async () => {
    const alertsResponse = await getAlerts();
    let filteredAlert = alertsResponse.data.filter((alert => selectedterminalsOptions?.map(o => o.value).includes(alert.terminal?.terminal_id)
      && selectedConsigneeOptions?.map(o => o.label).includes(alert.consignee)
      && selectedShipperOptions?.map(o => o.label).includes(alert.shipper)
      && selectedAlertTypeOptions?.map(o => o.label).includes(alert.alert_type)
      && selectedCommodityGroupOptions?.map(o => o.label).includes(alert.commodity_group_description)
    ));
    // setAlerts(filteredAlert);
    let unreadAlerts = filteredAlert.filter(alert => !alert.isdismissed);
    setUnreadCount(unreadAlerts.length)
  };
  const fetchTerminalData = async () => {
    const userTerminalIds = await userData.terminals;
    if (userTerminalIds?.length > 0) {
      await getInitialData(userData.terminals);
    }
  }

  useEffect( () => {
    fetchTerminalData();
  }, [userData.terminals]);

  useEffect(() => {
    let filteredAlert = allAlerts.filter((alert => selectedterminalsOptions?.map(o => o.value).includes(alert.terminal?.terminal_id)
      && selectedConsigneeOptions?.map(o => o.label).includes(alert.consignee)
      && selectedShipperOptions?.map(o => o.label).includes(alert.shipper)
      && selectedAlertTypeOptions?.map(o => o.label).includes(alert.alert_type)
      && selectedCommodityGroupOptions?.map(o => o.label).includes(alert.commodity_group_description)
    ));

    // setAlerts(filteredAlert);
    let unreadAlerts = filteredAlert.filter(alert => !alert.isdismissed);
    setUnreadCount(unreadAlerts.length)
  }, [selectedterminalsOptions.length, selectedConsigneeOptions.length, selectedShipperOptions.length, selectedAlertTypeOptions.length, selectedCommodityGroupOptions.length]);

  useEffect(() => {
    let terminals = []
    let filterTerminal = userTerminals.filter(terminal => selectedRegionOptions.map(o => o.label).includes(terminal.region))
    for (let i = 0; i < filterTerminal.length; i++) {
      let terminalObj = {
        label: `${filterTerminal[i].code}-${filterTerminal[i].city}`,
        value: filterTerminal[i].code
      }
      terminals.push(terminalObj)
    }
    setallTerminaldropdown(terminals)
    setSelectedTerminalsOptions(terminals)
  }, [selectedRegionOptions.length])

  const handleSelectedTerminalIDs = (selectedTerminalIDs) => {
    // selectedTerminalIDs in the parent component
    setSelectedTerminalIDs(selectedTerminalIDs);
  };

  const planningProfileChangeHandler = (selectedTerminals) => {
    const totalprofiles = uniqueobjectsFromData([...assignedPlanningProfileOptions, ...unassignedPlanningProfileOptions])
    if (totalprofiles.length == selectedTerminals.length) {
      setSelectAllChecked(true);
    }
    else {
      setSelectAllChecked(false);
    }

    setSelectedTerminalList(selectedTerminals);
    const terminalIDs = selectedTerminals.map((terminal) => terminal.value);
    const uniqueSelectedTerminal = [...new Set(terminalIDs)];


    handleSelectedTerminalIDs(uniqueSelectedTerminal);
    setisplanningProfileChange(isplanningProfileChange + 1);
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    // If "Select all" is checked, all checkboxes in both assigned and unassigned lists to be checked
    if (checked) {
      // remove duplicates from terminalids
      const allTerminalIDs = [
        ...assignedPlanningProfileOptions,
        ...unassignedPlanningProfileOptions,
      ].map((terminal) => terminal.value);

      setSelectedTerminalList([...assignedPlanningProfileOptions, ...unassignedPlanningProfileOptions]);
    } else {

      setSelectedTerminalList([]);
    }
    // Calling the callback function
    let allSelectedTerminal = [
      ...assignedPlanningProfileOptions,
      ...unassignedPlanningProfileOptions
    ].map((terminal) => terminal.value);
    const uniqueSelectedTerminal = [...new Set(allSelectedTerminal)];
    handleSelectedTerminalIDs(checked ? uniqueSelectedTerminal : []);
    setisplanningProfileChange(isplanningProfileChange + 1);
  };

  const handleClearAll = () => {
    setSelectedTerminalList([]);
    setSelectAllChecked(false);
    handleSelectedTerminalIDs([]);
    setisplanningProfileChange(isplanningProfileChange + 1);
  };

  const fetchData = async (payload) => {
    try {
      setIsPageLoading(true);
      if (payload.terminals.length > 0) {
        const all_alert = await getAllAlert(payload);
        setAllAlerts(all_alert);

        const response = await getAllAlertType(payload);
        setAllAlertType(response);

        if (location?.search.length > 0 && response.length > 0) {
          const search = location?.search; 

          // Function to parse URL parameters
          function parseUrlParams(url) {
            const params = {};
            const urlParams = new URLSearchParams(url);
            for (const [key, value] of urlParams.entries()) {
              params[key] = value;
            }
            return params;
          }

          // Parse URL parameters
          const params = parseUrlParams(search);

          // Extract type and id values
          const type = params['type'].replace("_", " ");
          const id = params['id'];

          const alertTypeObj = response.find((el) => el.alert_type.toLowerCase() === type);
          setItemSelected(alertTypeObj.alert_type);
          
          const data = all_alert.find((el) => el?.alert_type == alertTypeObj?.alert_type && el?.id == id);

          if (alertTableRef.current && data) {
            // Invoke rowClickHandler from child component using ref
            alertTableRef.current.rowClickHandler(data, "alert");
          }
        } else {
          setItemSelected(prev => {
            return prev ? prev : response[0]?.alert_type
          });
        }
        setIsPageLoading(false);
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to fetch alert types', { autoClose: false } );
      }
    }
  };
  
  useEffect(() => {
    let payload = {
      terminals: selectedTerminalIDs
    }
    fetchData(payload)
  }, [selectedTerminalIDs]);

  useEffect(() => {
    let payload = {
      terminals: selectedTerminalIDs
    }
    if (isUpdated){
      fetchData(payload)
      setIsUpdated(false);
    }
  }, [isUpdated]);

  useEffect(() => {
    const newAlertData = allAlerts.filter(item => item.alert_type == itemSelected)
    setFilteredAlerts(newAlertData)
    setHeaderName(itemSelected)
  }, [itemSelected, allAlerts]);

  const parentCallbackHandler = (data) => {
    setIsUpdated(data);
  };

  useEffect(() => {
    if (userData.id) {
      const getallPlanningProfile = async () => {

        // Getting all planning profiles
        let PlanningProfile = await getAllPlanningProfiles();

        // Getting planning profiles by user id
        let planningProfileData = await getPlanningProfileById(userData.id);
        setusersPlanningProfile(planningProfileData);
        setPlanningProfile(PlanningProfile);

        let assignedProfile = [...PlanningProfile].filter((profile) =>
          planningProfileData.map((assign) => assign.planningprofile_id)
            .includes(profile.id)
        );
        if (assignedProfile.length > 0) {
          const terminalId = assignedProfile.map((e) => e.terminal_id);
          setSelectedTerminal(terminalId);
          setSelectedTerminalIDs(terminalId);
        }
        else {
          setSelectedTerminal(PlanningProfile[0]?.terminal_id);
          setSelectedTerminalIDs([]);
        }
      }

      getallPlanningProfile();
    }
  }, [userData.id]);

  const alertCategoryClickHandler = (alert) => {
    setItemSelected(alert.alert_type === itemSelected ? null : alert.alert_type);
    setHeaderName(alert.alert_type);
    
  }

  useEffect(() => {
    let assigned = [];
    let unassigned = [];
    let assignedProfile = [...planningprofile].filter((profile) =>
      usersPlanningProfile.map((assign) => assign.planningprofile_id).includes(profile.id)
    );

    assignedProfile.map((select) => {
      let assignedProfileObj = {
        label: select?.name,
        value: select?.terminal_id,
        id: select?.id,
        timezone: select?.terminal?.timezone,
      };
      assigned.push(assignedProfileObj);
    });

    setAssignedPlanningProfileOptions(assigned);

    // Checking if there are any assigned terminals, if so, set the initially selected terminal(s)
    if (assigned.length > 0) {
      const initialAssignedTerminalIDs = assigned.map((terminal) => terminal.value);

      setSelectedTerminalList(assigned);
      handleSelectedTerminalIDs(initialAssignedTerminalIDs);
    }

    const unassignedProfile = planningprofile.filter((el) => {
      return !usersPlanningProfile.find((element) => {
        return element.planningprofile_id === el.id;
      });
    });

    unassignedProfile.map((select) => {
      let unassignedProfileObj = {
        label: select?.name,
        value: select?.terminal_id,
        id: select?.id,
        timezone: select?.terminal?.timezone,
      };
      unassigned.push(unassignedProfileObj);
    });

    setUnassignedPlanningProfileOptions(unassigned);

  }, [planningprofile, usersPlanningProfile]);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedChangeHandler = debounce(planningProfileChangeHandler, 500);
  const uniqueobjectsFromData = (data) => {
    const propertyName = "value";
    const uniqueData = {};
    data.forEach((item) => {
      if (!uniqueData[item[propertyName]]) {
        uniqueData[item[propertyName]] = item;
      }
    });
    const filteredData = Object.values(uniqueData);
    return filteredData;
  };
  return (
    <div>
      <div id="wrapper">
        <Header
          userclicked={isalertClicked}
          parentcallback={handelcallbackFromHeader}
        ></Header>
        <AppBar unreadCount={unreadCount} />
        <div className="sidebar-main-menu adjustwidth">
          <div className="twocolumn-menu-item d-block">
            <div className="alert_title_box">
              <h5 className="alert_title">Alerts</h5>
              <div style={{ backgroundColor: 'white', }}>
                <div className="dropdownadjust">
                  <Picky
                    options={[
                      {
                        label: 'Select all',
                        value: 'select-all',
                      },
                      {
                        label: 'Assigned Planning Profiles',
                        options: assignedPlanningProfileOptions,
                      },
                      {
                        label: 'Unassigned Planning Profiles',
                        options: unassignedPlanningProfileOptions,
                      },
                    ]}
                    labelKey="label"
                    valueKey="id"
                    placeholder="Planning Profiles"
                    multiple={true}
                    includeSelectAll={false}
                    value={selectedTerminalList}
                    numberDisplayed={1}
                    manySelectedPlaceholder="Planning Profiles: %s"
                    allSelectedPlaceholder="Planning Profiles: All"
                    onChange={debouncedChangeHandler}
                    render={({ style, item, selectValue, labelKey, valueKey, isSelected, multiple }) => {
                      const { label, options } = item;

                      return (
                        <div style={{ ...style, padding: '8px' }}>
                          {label === 'Select all' ? (
                            <div className="driverPay_dropdown_buttons">
                              <div className="option" onClick={(e) => e.stopPropagation()}>
                              <div class="form-group">
                                <input
                                  type="checkbox"
                                  id="selectalert"
                                  checked={selectAllChecked}
                                  onChange={handleSelectAllChange}
                                />
                                <label for="selectalert">{label}</label>
                                </div>
                              </div>
                              <div className="option" onClick={(e) => e.stopPropagation()}>
                                <label onClick={() => handleClearAll()}>Clear All</label>
                              </div>
                            </div>
                          ) : (
                            <>
                              <strong>{label}</strong>
                              {
                                options && options.map((option) => (
                                  <div
                                    key={option[valueKey]}
                                    className={`option ${selectedTerminalList.some((selected) => selected[valueKey] === option[valueKey]) ? 'selected' : ''}`}
                                    onClick={() => {
                                      selectValue(option);
                                    }}
                                  >
                                    <div class="form-group">
                                    <input
                                      type="checkbox"
                                      checked={selectedTerminalList.some((selected) => selected[valueKey] === option[valueKey])}
                                      readOnly
                                    />
                                    <label>{option[labelKey]}</label>
                                    </div>
                                  </div>
                                ))

                              }
                            </>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {alertsType?.length > 0 ? (
              <div className="alert_scroll">
                {alertsType.map((alert, index) => (
                  <div
                    key={index}
                    className={`alert_card ${alert.alert_type === itemSelected ? 'sidebar_seleted' : ''}`}
                    onClick={() => { alertCategoryClickHandler(alert) }}
                  >
                    <div className="alert_item">
                      <div className="alert_label">{alert.alert_type}</div>
                      <div className="alert_count"><span class="count">{alert?.alert_count}</span></div>

                    </div>

                  </div>
                ))}
              </div>

            ) : isPageLoading ? (
              <div>
                {/* <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div> */}
              </div>
            ) : (
              <div className='text-center'>No Alerts</div>
            )}
          </div>
        </div>
        <div className="content-page">
          <div className="content">
            <div className="container-fluid"> 
              <>
                <AlertHeader title={headerName} allAlertData={filteredAlert} />
                <AlertsTable ref={alertTableRef} title={headerName} allAlertData={filteredAlert} isPageLoading={isPageLoading} parentCallback={parentCallbackHandler} />
              </>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default alerts;
