
import axios from 'axios';

// Application Specific
import Permission from '../models/permissionModel';
import { BaseConfig } from "../baseConfig";

    export const getAllPermission = async() => {
        const permission = new Permission();
        let permissionObject = [];   

        try{
            const url = BaseConfig.ApiEndPoint + "/getallppermission";
            const permApiData = await axios.get(url);
         
             var response=permApiData.data.data
            // permissionObject = permApiData.data.data.map(data => permission.parseApiPermissionObject(data));
        } catch (err){
       
            return Promise.reject("There is a problem on retrieving permission data. Please try again!");
        }       

        return Promise.resolve(response);
    }