import React, { useState, useEffect, useContext  } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {getAllTrailersbyTerminalID} from "../../services/trailerService";
import {getYardCheckInformation, addYardChkTrailer, submitYardChkTrailer,updateYardChkTrailer} from "../../services/yardService";
import "./yardCheckList.css";
import { DateTime } from "luxon";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Autocomplete } from "@mui/lab";
import { MenuItem, TextField } from "@mui/material";
import { ContextData } from "../../components/appsession";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

const errors = {
  color: "red",
};

const YardCheckList = (props) => {
  const { terminalById, notifyParent, accessDisabled, allTrailers, setallTrailer } = props;
  const [userData, setuserData] = useContext(ContextData);

  const [terminalByIdData, setterminalByIdData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowForAdd, setmodalShowForAdd] = useState(false);
  const [yardCheckInformation, setyardCheckInformation] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [hide, setHide] = useState(false);
  const [editRedtagDisable, setEditRedtagDisable] = useState(true);

  const permission = userData?.roles?.rolespermission.find(permit => 
    permit?.permissiondetail.code === "YardCheck")

  const [dataForBoolean, setdataForBoolean] = useState([
    {
      is_confirmation: "Yes",
    },
    {
      is_confirmation: "No",
    },
  ]);
  const [dataForRedtag, setdataForRedtag] = useState([
    {
      redflag: "Yes",
    },
    {
      redflag: "No",
    },
  ]);

  const [dataForStatus, setdataForStatus] = useState([
    {
      status: "Active",
    },
    {
      status: "In-Shop",
    },
    {
      status: "Sold",
    },
    {
      status: "Sale-Pending",
    },
    {
      status: "Collision",
    },
    {
      status: "On Order",
    },
  ]);

  const [dataForAdd, setdataForAdd] = useState({});
  const [dataForUpdate, setdataForUpdate] = useState({});
  const [trailerDataForEdit, settrailerDataForEdit] = useState({});

 

  const printTableRef = useRef(null);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "is_active",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(
    process(yardCheckInformation, dataState)
  );
  const dataStateChange = (event) => {
    setDataResult(process(yardCheckInformation, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(yardCheckInformation, dataState));
  }, [yardCheckInformation]);

  const fetchyardData = async () => {
    let yardCheckTrailerAPI = await getYardCheckInformation(
      terminalById.id
    );

    yardCheckTrailerAPI = yardCheckTrailerAPI.data;

    for (let i = 0; i < yardCheckTrailerAPI.length; i++) {
      //For Trailer On Site
      if (yardCheckTrailerAPI[i].is_confirmation === null) {
      } else if (yardCheckTrailerAPI[i].is_confirmation) {
        yardCheckTrailerAPI[i].is_confirmation = "Yes";
      } else {
        yardCheckTrailerAPI[i].is_confirmation = "No";
      }
      //For RedTag
      if (yardCheckTrailerAPI[i].is_redflag === null) {
      } else if (yardCheckTrailerAPI[i].is_redflag) {
        yardCheckTrailerAPI[i].is_redflag = "Yes";
      } else {
        yardCheckTrailerAPI[i].is_redflag = "No";
      }
    }

    setyardCheckInformation(yardCheckTrailerAPI);
  }

  useEffect( () => {
    fetchyardData();
  }, [terminalById, isReload]);

  const fetchData = async () => {
    let filterData = {
      terminal_id: [terminalById?.terminalid], 
    };
    const trailers = await getAllTrailersbyTerminalID(filterData);
    setallTrailer(trailers);

  }
  useEffect( () => {
    fetchData();
  }, []);

  const statusChangeHandlerForEdit = (value) => {
    if (value.length > 0) {
      setdataForUpdate((prev) => ({
        ...prev,
        is_status: value[0].status,
      }));
    }
  };

  const statusChangeHandlerForAdd = (value) => {
    if (value) {
      setdataForAdd((prev) => ({
        ...prev,
        is_status: value.status,
      }));
    }
  };
  const trailerChangeHandlerForAdd = (value) => {
    if (value) {
      setdataForAdd((prev) => ({
        ...prev,
        terminal_id: terminalById.terminalid,
        yardcheck_id: terminalById.id,
        trailer_id: value.trailer_id,
      }));
    }
  };

  const confirmChangeHandlerForAdd = (value) => {
    if (value) {
      setdataForUpdate((prev) => ({
        ...prev,
        is_confirmation: value.is_confirmation,
      }));
    }
  };

  const redTagChangeHandlerForAdd = (value) => {
    if (value) {
      setdataForUpdate((prev) => ({
        ...prev,
        is_redflag: value.redflag,
      }));
    }
  };

  const commentsChangeHandlerForAdd = (e) => {
    setdataForAdd((prev) => ({
      ...prev,
      check_comments: e.target.value,
    }));
  };

  ///edit part
  const confirmChangeHandlerForEdit = (e, value) => {
    if (value) {
      settrailerDataForEdit((prev) => ({
        ...prev,
        is_confirmation: value.is_confirmation,
      }));
      if (value.is_confirmation === "Yes") {
        setEditRedtagDisable(false);
      } else {
        setEditRedtagDisable(true);
        settrailerDataForEdit((prev) => ({
          ...prev,
          is_redflag: value.redflag,
        }));
      }
    } else {
      settrailerDataForEdit((prev) => ({
        ...prev,
        is_confirmation: null,
      }));

      settrailerDataForEdit((prev) => ({
        ...prev,
        is_redflag: dataForUpdate.redflag,
      }));
      setEditRedtagDisable(true);
    }
  };

  const commentsChangeHandlerForEdit = (e) => {
    settrailerDataForEdit((prev) => ({
      ...prev,
      comment: e.target.value,
    }));
  };

  const redTagChangeHandlerForEdit = (e, value) => {
    if (value) {
      settrailerDataForEdit((prev) => ({
        ...prev,
        is_redflag: value.redflag,
      }));
    } else {
      settrailerDataForEdit((prev) => ({
        ...prev,
        is_redflag: null,
      }));
    }
  };
  ///edit part end
  /// end
  const addTrailer = async () => {
    try {
      const addTrailers = await addYardChkTrailer(dataForAdd);
      setmodalShowForAdd(false);
      setIsReload(!isReload);
      return toast.success(
        "Trailer Added Successfully",
        "success"
      );
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to add trailer', { autoClose: false } );
      }
    }
  };
  const submitDataHandler = async (yardCheckInformation) => {
    const submityard = yardCheckInformation;
    const yardsubmit = submityard.filter(
      (yard) => yard.is_confirmation === null
    );

    if (yardsubmit.length > 0) {
      toast.error(
        "Please Review All Trailers In the Yard Check",
        "Error"
      );
    } else {
      try {
        let data = {
          id: terminalById.id,
          endts: DateTime.now().toMillis(),
        };
        const Submission = await submitYardChkTrailer(data);
        notifyParent(Submission);
        //window.location.reload();
        return toast.success("Submitted", "Success");
      }catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to submit data to server', { autoClose: false } );
        }
      }
    }
  };

  useEffect(() => {
    setterminalByIdData(terminalById);
  }, []);

  const updateTrailerHandler = async () => {

    if (!trailerDataForEdit.is_confirmation) {
      return toast.error(
        "Trailer On Site must not be blank",
        "Error"
      );
    }
    if (
      trailerDataForEdit.is_redflag === "Yes" &&
      !trailerDataForEdit.comment
    ) {
      return toast.error("Comments Cannot be blank", "Error");
    } else {
      try {
        const editTrailers = await updateYardChkTrailer(
          trailerDataForEdit
        );

        if (editTrailers.length > 0) {
          setModalShow(false);
          setIsReload(!isReload);
          return toast.success("Trailer Updated", "Success");
        }
      }catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to update trailer', { autoClose: false } );
        }
      }
    }
  };

  const openEditModal = (item) => {
    setModalShow(true);
    setdataForUpdate(item);
    settrailerDataForEdit((prev) => ({
      ...prev,
      comment: item.check_comments,
      is_status: item.status,
      yardcheck_id: item.yardcheck_id,
      trailer_id: item.trailer_id,
      id: item.id,
      is_confirmation: item.is_confirmation,
      is_redflag: item.is_redflag,
    }));
    if (item.is_confirmation === "Yes") {
      setEditRedtagDisable(false);
    } else {
      setEditRedtagDisable(true);
    }
  };
  const Edityardchecks = (props) => {
    return (
      <td className="adjustbutton">
        { permission.can_edit?(
         <button
         type="button"
            className="btn_blue_smadjust btn-blue ml_10"
         onClick={() => openEditModal(props.dataItem)}
       
        
       >
            <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
         EDIT
       </button>
        ):(
          <button
          type="button"
              className="btn_blue_smadjust btn-blue ml_10"
         
          disabled
          
        >
              <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
          EDIT
        </button>
        )
      }
       
      </td>
    );
  };
  const Edityardcheck = (props) => (
    <Edityardchecks {...props} openEditModal={openEditModal} />
  );
  const handlePrint = useReactToPrint({
    contentRef :printTableRef
  });

  return (
    <div className="row mt_30">
      <div className="col-xl-12">
        <div className="card card_shadow ">
          <div className="card-body ">
            <div className="table_header_section">
              <div className="table_header">Yard Checklist </div>
              <div className="df">
                {/* <button type="button" className="btn_blue btn-blue ml_10"  onClick={() => setModalShow(true)}>ADD</button> */}
                {terminalByIdData?.status?.toUpperCase() === "IN PROGRESS" ? (
                  <>
                  {permission?.can_edit?(
                       <button
                       type="button"
                       className="btn_blue btn-blue ml_10"
                       onClick={() => submitDataHandler(yardCheckInformation)}
                     >
                       SUBMIT
                     </button>
                  ):(
                    <button
                    type="button"
                    className="btn_blue btn-blue ml_10"
                    disabled
                  >
                    SUBMIT
                  </button>
                  )

                  }
                   
                    {  permission?.can_edit?(
                       <button
                       type="button"
                       className="btn_blue btn-blue ml_10"
                       onClick={() => setmodalShowForAdd(true)}

                     >
                       ADD
                     </button>
                    ):(
                      <button
                       type="button"
                       className="btn_blue btn-blue ml_10"
                       
                      disabled
                     >
                       ADD
                     </button>
                    )
                  
                  }
                    
                  </>
                ) : (
                  ""
                )}
                <button type="button" className="btn_blue btn-blue ml_10" onClick={handlePrint}> PRINT </button>
              </div>
            </div>
            <div className="row yard_chk_list_res">
              {yardCheckInformation && yardCheckInformation?.length > 0 ? (
                <Grid
                  filter={dataState.filter}
                  sort={dataState.sort}
                  sortable={true}
                  filterable={true}
                  //   filterOperators={filterOperators}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                >
                  <GridColumn
                    field="trailer_id"
                    sortable={true}
                    title="Trailer Id"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {window.innerHeight > window.innerWidth
                            ? "Trailer Id - "
                            : ""}
                          {e.dataItem.trailer_id}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="is_confirmation"
                    title="Trailer On Site"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {window.innerHeight > window.innerWidth
                            ? "Trailer On Site - "
                            : ""}
                          {e.dataItem.is_confirmation}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="status"
                    title="Status"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {window.innerHeight > window.innerWidth
                            ? "Status - "
                            : ""}
                          {e.dataItem.status}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="is_redflag"
                    title="RedTag"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {window.innerHeight > window.innerWidth
                            ? "RedTag - "
                            : ""}
                          {e.dataItem.is_redflag}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="check_comments"
                    title="Comments"
                    width="150px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {window.innerHeight > window.innerWidth
                            ? "Comments - "
                            : ""}
                          {e.dataItem.check_comments}
                        </td>
                      );
                    }}
                  />
                  {terminalByIdData?.status?.toUpperCase() === "IN PROGRESS" ? (
                    <GridColumn title="Action" cell={Edityardcheck} />
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            EDIT TRAILER
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div>
              <label for="typeHeadEditStatus">
                <b>Status:</b> {dataForUpdate?.status}{" "}
              </label>
            </div>
            <div className="meterial_autocomplete">
              <label for="typeHeadAddStatus">Trailer On Site*</label>

              <Autocomplete
                id="combo-box-demo"
                options={dataForBoolean}
                getOptionLabel={(option) => `${option.is_confirmation}`}
                defaultValue={{
                  is_confirmation: dataForUpdate?.is_confirmation
                    ? dataForUpdate?.is_confirmation
                    : [""],
                }}
                onChange={confirmChangeHandlerForEdit}
                disabled={accessDisabled ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Trailer On Site..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="meterial_autocomplete">
              {/* <label for="typeHeadAddStatus">RedTag</label> */}
              {editRedtagDisable ? <label for="typeHeadAddStatus">RedTag</label> : <label for="typeHeadAddStatus">RedTag*</label>}
              <Autocomplete
                id="combo-box-demo"
                options={dataForRedtag}
                getOptionLabel={(option) => `${option.redflag}`}
                defaultValue={{
                  redflag: dataForUpdate?.is_redflag
                    ? dataForUpdate?.is_redflag
                    : [""],
                }}
                onChange={redTagChangeHandlerForEdit}
                disabled={editRedtagDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose RedTag..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="mt_10">
              {trailerDataForEdit.is_redflag == "Yes" ? <label for="typeHeadAddStatus">Comments*</label> : <label for="typeHeadAddStatus">Comments</label>}
              <textarea
                  className="form-control"
                  name="comments"
                  id="exampleEditFormControlTextarea1"
                  rows="3"
                  defaultValue={
                    dataForUpdate?.check_comments
                      ? dataForUpdate?.check_comments
                      : ""
                  }
                  onChange={(e) => commentsChangeHandlerForEdit(e)}
                  placeholder="Enter comments"
                  disabled={trailerDataForEdit.is_redflag == "Yes" ? false : true}
                />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setModalShow(false)}>Close</Button>
          <Button onClick={updateTrailerHandler}>Save</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShowForAdd}
        onHide={() => setmodalShowForAdd(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            ADD TRAILER
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="meterial_autocomplete">
              <label for="exampleFormControlInput1">Trailer</label>
              <Autocomplete
                id="combo-box-demo"
                options={allTrailers}
                getOptionLabel={(option) =>
                  `${option.trailer_id} : ${option.eqmodel} `
                }
                onChange={trailerChangeHandlerForAdd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Trailer Id..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="meterial_autocomplete">
              <label for="typeHeadAddStatus">Status</label>
              <Autocomplete
                id="combo-box-demo"
                options={dataForStatus}
                getOptionLabel={(option) => `${option.status}`}
                onChange={statusChangeHandlerForAdd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose status..."
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="meterial_autocomplete">
              <label for="typeHeadAddStatus">Trailer On Site</label>

              <Autocomplete
                id="combo-box-demo"
                options={dataForBoolean}
                getOptionLabel={(option) => `${option.is_confirmation}`}
                onChange={confirmChangeHandlerForAdd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Trailer On Site..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="meterial_autocomplete">
              <label for="typeHeadAddStatus">RedTag</label>

              <Autocomplete
                id="combo-box-demo"
                options={dataForRedtag}
                getOptionLabel={(option) => `${option.redflag}`}
                onChange={redTagChangeHandlerForAdd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose RedTag..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="mt_10">
              <label for="exampleAddFormControlTextarea1">Comments</label>
              <textarea
                className="form-control"
                placeholder="Comments...."
                id="exampleAddFormControlTextarea1"
                rows="3"
                onChange={(e) => commentsChangeHandlerForAdd(e)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setmodalShowForAdd(false)}>Close</Button>
          <Button onClick={addTrailer}>Save</Button>
        </Modal.Footer>
      </Modal>

      {/* table for print */}

      <div className="yardprint table_hidden" ref={printTableRef}>
        <table>
          <thead>
            <tr>
              <td className="out_table">
                <div className="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="out_table">
                <div className="content">
                  <table ref={printTableRef}>
                    <tr>
                      <th width="12%" className="text-center">
                        Trailer Id
                      </th>
                      <th width="10%" className="text-center">
                        Status
                      </th>
                      <th width="12%" className="text-center">
                        On Site
                      </th>
                      <th width="10%" className="text-center">
                        RedTag
                      </th>
                      <th className="text-center">Comments</th>
                    </tr>
                    {yardCheckInformation.length > 0 ? (
                      <>
                        <>
                          {yardCheckInformation.map((item) => (
                            <tr>
                              <td className="text-center">{item.trailer_id}</td>

                              <td className="text-center">{item.status}</td>

                              <td className="text-center">
                                {/* {item.is_confirmation == null
              ? "Y / N"
              : item.is_confirmation
                ? "Y / N"
                : "Y / N"} */}

                                {item.is_confirmation === "Yes" ? (
                                  <>
                                    <span className="printable">Y</span>
                                    <span>/ N</span>
                                  </>
                                ) : item.is_confirmation === "No" ? (
                                  <>
                                    <span>Y/ </span>
                                    <span className="printable"> N</span>
                                  </>
                                ) : (
                                  <>
                                    <span>Y </span>
                                    <span>/ N</span>
                                  </>
                                )}
                              </td>

                              <td className="text-center">
                                {/* {item.is_redflag == null
              ? "Y / N"
              : item.is_redflag
                ? "Y / N"
                : "Y / N"} */}
                                {/* {item.is_redflag == null ? "" : item.is_redflag} */}

                                {item.is_redflag === "Yes" ? (
                                  <>
                                    <span className="printable">Y</span>
                                    <span>/ N</span>
                                  </>
                                ) : item.is_redflag === "No" ? (
                                  <>
                                    <span>Y/ </span>
                                    <span className="printable"> N</span>
                                  </>
                                ) : (
                                  <>
                                    <span>Y </span>
                                    <span>/ N</span>
                                  </>
                                )}
                              </td>

                              <td>{item.check_comments}</td>
                            </tr>
                          ))}
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="out_table">
                <div className="footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>

        <div className="header">
          <div className="print_pop_adjust">
            <div className="print_pop_adjust_header11">
              <p>{terminalById?.terminal_name}</p>
            </div>
            <div className="print_bottom_adjust_header">
              <p>Yard Check by: {terminalById?.username}</p>
              <p>
                Start Time:{" "}
                {DateTime.fromMillis(parseInt(terminalById?.startTs))
                  .toFormat("MM-dd-yyyy hh:mm")
                  .toString()}
              </p>
              <p>
                End Time:{" "}
                {terminalById?.status?.toUpperCase() === "IN PROGRESS"
                  ? ""
                  : DateTime.fromMillis(parseInt(terminalById?.endTs))
                      .toFormat("MM-dd-yyyy hh:mm")
                      .toString()}
              </p>
            </div>
          </div>
        </div>
        <div className="footer">...</div>

        {/* <>
          <div className="print_pop_adjust">
            <div className="print_pop_adjust_header11">
              <p>{terminalById?.terminal_name}</p>
            </div>
            <div className="print_bottom_adjust_header">
              <p>Yard Check by: {terminalById?.username}</p>
              <p>
                Start Time:{" "}
                {DateTime.fromMillis(parseInt(terminalById?.startTs))
                  .toFormat("MM-dd-yyyy hh:mm")
                  .toString()}
              </p>
              <p>
                End Time:{" "}
                {terminalById?.status?.toUpperCase() === "IN PROGRESS"
                  ? ""
                  : DateTime.fromMillis(parseInt(terminalById?.endTs))
                    .toFormat("MM-dd-yyyy hh:mm")
                    .toString()}
              </p>
            </div>
          </div>

        </> */}
      </div>
    </div>
  );
};

export default YardCheckList;
