import React from "react";
import Header from "../../components/header";
import "../../assets/css/app.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppBar from "../../components/appbar/index";

function Unauthorize() {
    return (
        <>
            <div id="wrapper">
                <Header />
                <div className="unauthorize-content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card card_shadow unauthorized_card">
                                <div className="card-body">
                                    <div class="unauthorized_box">                                       
                                        <div class="authorized_text">
                                            <p className="authorized_text_para">You don't have access to this page.</p>
                                            <p>Please contact to your Administrator.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Unauthorize;
