import { DateTime } from 'luxon';
import React from 'react'
import Yard from "../../assets/images/users/Terminals_Icon.png";

const yardCheckListHeader = (props) => {
    const { terminalById, allTrailers } = props

    const convertdatetime = (epoch_date, tzone) => {

        if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
            tzone = "America/New_York"
        }
        if (!tzone || tzone === "Central Standard Time" || tzone === "CST" || tzone === "CDT" || tzone === "America/Chicago") {
            tzone = "America/Chicago"
        }
        if (epoch_date > 0) {
            return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
        }
        else {
            return ""
        }
    }

    const timezone = allTrailers[0]?.terminal?.timezone;

    const startTs = parseInt(terminalById.startTs);

    const endTs = parseInt(terminalById.endTs);

    return (
        <div className="row df mt_30">
            <div className="tabs">
                <input type="radio" name="tabs" id="tabone" checked={true} />
                <label for="tabone">Yard Checks</label>
                <div className="tab yard_chk_extrapadding">


                    <div className="profile_top">
                        <div className="profile_top_left">
                            <div className="active_outer">
                                <img src={Yard} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" />
                                <div className={terminalById.status ? terminalById.status : terminalById.status != "open" ?
                                    "inactive_sign" : "active_sign"
                                }></div>
                            </div>
                            <div>
                                <p className="profile_top_left_text">{terminalById.terminal_name}</p>
                            </div>

                        </div>

                        <div className="profile_top_right">
                            <p className="profile_bottom_left_text yard_right_space">Yard Check by: {terminalById.username}</p>
                            <p className="profile_bottom_left_text yard_right_space">Start Time: {convertdatetime(startTs, timezone)}</p>
                            <p className="profile_bottom_left_text yard_right_space">End Time:
                                {/* {terminalById?.status?.toUpperCase() === "OPEN"? "" : DateTime.fromMillis(parseInt(terminalById.endTs)).toFormat("MM-dd-yyyy hh:mm").toString()} */}
                                {terminalById?.status?.toUpperCase() === "OPEN" ? "" : terminalById.endTs === null
                                    ? "No data"
                                    : convertdatetime(endTs, timezone)}
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default yardCheckListHeader
