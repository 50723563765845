import axios from 'axios';


import { BaseConfig } from "../baseConfig";

export const getDriverMessage = async (driver) => {
    
    let driverMessage = [];
    try {
        const url = `${BaseConfig.ApiEndPoint}/getdrivermessage/${driver}`;
        
         let response = await axios.get(url);
         driverMessage=response.data

       
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverMessage);
}

export const updateDriverMessageseen = async (driver) => {
    
    let driverMessage = [];
    try {
        const url = `${BaseConfig.ApiEndPoint}/updatemessagebydriverid`;
        
        let data={
            "driver_id": `${driver}`
        }
         let response = await axios.post(url,data);
         driverMessage=response.data
         console.log("ressss message",response)

       
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverMessage);
}

export const createDriverMessage=async(payload)=>{
    let driverMessage = [];
  
    try {
        const url = `${BaseConfig.ApiEndPoint}/createdrivermessage`;
        
         driverMessage = await axios.post(url,payload);
        
    } catch (err) {
        return Promise.resolve("There is a problem to create driver message. Please try again!");
    }
    return Promise.resolve(driverMessage);
}
export const sendBroadcastMessage=async(payload)=>{
    let broadcastMessage = [];
  
    try {
        const url = `${BaseConfig.ApiEndPoint}/sendbroadcastmessage`;
        
         broadcastMessage = await axios.post(url,payload);
        
    } catch (err) {
        return Promise.resolve("There is a problem to create driver message. Please try again!");
    }
    return Promise.resolve(broadcastMessage);
}
