

// Application Specific
import Driver from '../models/driverModel';
import { BaseConfig } from "../baseConfig";
import { DateTime } from "luxon";
import * as fflate from 'fflate';
import axios from 'axios';

export const getDriver = async (driverId) => {
    const driver = new Driver();
    let driverObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/drivers/" + driverId;
        const driverApiData = await axios.get(url);
        driverObject = driver.parseApiDriverObject(driverApiData.data.data);
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }

    return Promise.resolve(driverObject);
}


export const getAllActiveDrivers = async () => {
    const driver = new Driver();
    let driverObject = [];

    try {
        const url = BaseConfig.ApiEndPoint + "/activedrivers";
        const driverApiData = await axios.get(url);
        driverObject = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }

    return Promise.resolve(driverObject);
}

export const getAllDrivers = async (filterData) => {

    const driver = new Driver()
    try {
        let data = {
            "terminalId": filterData.terminalId,
        }
        const url = BaseConfig.ApiEndPoint + "/drivers";
        const driverApiData = await axios.post(url, data);
        //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
        const strData = atob(driverApiData.data);
        // Convert binary string to character-number array
        const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Use this Code to Decode the String

        //Uncompress API Call//
        let driverApiDataUnCompressed = fflate.unzlibSync(binData);
        let driverApiDataUnCompressedString = fflate.strFromU8(driverApiDataUnCompressed)
        let driverIntermediateObject = JSON.parse(driverApiDataUnCompressedString)
        // allDrivers = driverIntermediateObject.data.map(data => driver.parseApiDriverObject(data));
        // console.log("driverIntermediateObject: ", driverIntermediateObject);
        return driverIntermediateObject?.data;
    } catch (error) {
    }
}


export const getBannedDrivers = async (filterData) => {
    let allDrivers = []
    const driver = new Driver()
    try {
        let data = {
            "terminalId": filterData.terminalId,
        }
        const url = BaseConfig.ApiEndPoint + "/activebanneddrivers";
        const driverApiData = await axios.post(url, data);

        //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
        const strData = atob(driverApiData.data);
        // Convert binary string to character-number array
        const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Use this Code to Decode the String

        //Uncompress API Call//
        let driverApiDataUnCompressed = fflate.unzlibSync(binData);
        let driverApiDataUnCompressedString = fflate.strFromU8(driverApiDataUnCompressed)
        let driverIntermediateObject = JSON.parse(driverApiDataUnCompressedString)
        allDrivers = driverIntermediateObject.data.map(data => driver.parseApiDriverObject(data));

    } catch (error) {

    }
    return Promise.resolve(allDrivers);
}

export const getDriversByLocationID = async (locationId, searchData) => {
    let allDrivers = []
    const driver = new Driver()
    try {
        let data = {
            "locationId": locationId,
            "search": searchData,
            // "blocktype": isShipperOrConsignee
        }
        const url = BaseConfig.ApiEndPoint + "/getdriverbylocationid";
        const driverApiData = await axios.post(url, data);
        allDrivers = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));

    } catch (error) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(allDrivers);


}

export const blockDriverForLocation = async (driverdata) => {
    const driver = new Driver();
    let driverObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/blockdriver";
        const driverApiData = await axios.post(url, driverdata);
        driverObject = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverObject);
}
export const unBlockDriverForLocation = async (driverdata) => {
    const driver = new Driver();
    let driverObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/deletebanneddriversbylocationid";
        const driverApiData = await axios.post(url, driverdata);
        driverObject = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverObject);

}

export const getDriverPrefSetting = async () => {
    const driver = new Driver();
    let driverObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/getdriverprefsettings";
        const driverApiData = await axios.get(url);
        driverObject = driverApiData.data.data;
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverObject);
}

export const getDriverPrefData = async (driverid) => {
    const driver = new Driver();
    let driverObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/getdriverprefdata/" + driverid;
        const driverApiData = await axios.get(url);
        driverObject = driverApiData.data.data;

    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverObject);
}

export const saveDriverPreference = async (prefSettings, driverDataForSave, driverId) => {
    try {
        const data = {};
        data.driverid = driverId;
        data.pref_values = [];

        for (let loopSave = 0; loopSave < driverDataForSave.length; loopSave++) {
            const driverPref = driverDataForSave[loopSave];
            const obj = {}
            obj.id = driverPref.id !== undefined ? driverPref.id : '';
            obj.typeid = driverPref.preftypeId;
            let prefValues = "{";

            const groupInfo = prefSettings.filter(it => it.groupId === driverPref.prefgroupId);

            if (groupInfo.length) {
                const typeInfo = groupInfo[0].type.filter(it => it.typeId === driverPref.preftypeId);
                if (typeInfo.length) {
                    const typeData = typeInfo[0];

                    for (let loopPref = 0; loopPref < typeData.possibleValues.length; loopPref++) {
                        const selectedData = driverPref.selectedValues.filter(it => it.column
                            === typeData.possibleValues[loopPref].column);
                        if (selectedData.length) {

                            prefValues = prefValues + "'" + selectedData[0].column + "' : '" + selectedData[0].value + "', ";
                        }
                    }
                }
            }
            obj.prefvalues = prefValues.substr(0, prefValues.length - 2) + "}";
            data.pref_values.push(obj);
        }
        const url = BaseConfig.ApiEndPoint + "/savedriverprefdata";
        const driverApiData = await axios.post(url, data);
    }
    catch (err) {
        return Promise.reject("There is a problem on saving driver preference. Please try again!");
    }
    return Promise.resolve("Driver Preference is saved successfully!");
}

export const getDriverSchedule = async (driverid, date) => {
    const driver = new Driver()
    let driverScheduleObj = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/getdriverschedulesbydriverid";
        const dateData = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate());
        let data = {
            driver_id: driverid.toString(),
            // startdate: DateTime.local().startOf("week").minus({days : 1}).toMillis(),
            // weekday: 1
        }
        const driverSchedule = await axios.post(url, data);
        return Promise.resolve(driverSchedule.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}

export const addDriverSchedule = async (data) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/adddriverschedule";
        const addDriverScheduleResponse = await axios.post(url, data);
        return Promise.resolve(addDriverScheduleResponse.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}
export const updateDriverSchedule = async (data) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/updatedriverschedule";
        const updateDriverScheduleResponse = await axios.post(url, data);
        return Promise.resolve(updateDriverScheduleResponse.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}

export const addOverrideSchedule = async (data) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/addoverrideschedule";
        const overrideScheduleResponse = await axios.post(url, data);
        return Promise.resolve(overrideScheduleResponse.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}
export const UpdateOverrideSchedule = async (data) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/updateoverrideschedule";
        const overrideScheduleResponse = await axios.post(url, data);
        return Promise.resolve(overrideScheduleResponse.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}

export const addOverrideEditSchedule = async (data) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/adddriveroverrideschedulevalues";
        const overrideScheduleResponse = await axios.post(url, data);
        return Promise.resolve(overrideScheduleResponse.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on retrieving schedule data. Please try again!");
    }
}

export const getBannedDriversByLocation = async (locationId,filterData) => {
    let bannedDrivers = [];
    const driver = new Driver();
  
    try {
        let dataObj = {
            location_id: locationId,
          };
        const url = BaseConfig.ApiEndPoint + "/getbanneddriversbylocationid";
        const driverApiData = await axios.post(url, dataObj);
        bannedDrivers = driverApiData.data.data
    } catch (err) {
    }
    return Promise.resolve(bannedDrivers);
}

export const addBannedDriver = async (driverData) => {
    let bannedDriversAdd = [];
    const driver = new Driver();

    try {
        const url = BaseConfig.ApiEndPoint + "/addbanneddriversbylocation";
        const driverApiData = {
            driver_id: driverData.driver_id,
            location_id: driverData.location_id,
            reason: driverData.reason
        }
        const sApiData = await axios.post(url, driverApiData);
        bannedDriversAdd = sApiData.data.data
    } catch (err) {
    }
}
export const getoverridescheduledriverid = async (driver_id) => {
    let overridedriverid = []
    let data = {
        driver_id: driver_id,
    };
    const url = BaseConfig.ApiEndPoint + "/getscheduleoverridesbydriverid";
    const overridescheduleobj = await axios.post(url, data);
    var getoverrideschedule = overridescheduleobj.data.data;
    overridedriverid = getoverrideschedule;

    return Promise.resolve(overridedriverid);
}

export const deleteoverrideschedulebyid = async (data) => {
    const url = BaseConfig.ApiEndPoint + "/deletedriverschedule";
    let payload = {
        "id": data
    }
    const addApiData = await axios.post(url, payload);
    return Promise.resolve(addApiData.data.data);
}
export const getOverridesbyoverridelistid = async (id) => {

    const driver = new Driver();
    let driverObject = []
    try {

        // API object call.
        const url = BaseConfig.ApiEndPoint + "/getoverridesbyoverridelistid/" + id;
        const drivergetApiData = await axios.get(url);
        driverObject = drivergetApiData.data.data

    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }

    return Promise.resolve(driverObject);
}

export const addDriverSchedulePreferences = async (data) => {
    let addSchedulePref = []
    const driver = new Driver();
    try {
        const url = BaseConfig.ApiEndPoint + "/adddriverschedulepreferences";
        const payload = {
            driver_id: data.driver_id,
            nights_out: data.nights_out,
            day: data.day,
            created_by: "febba33b-8ad3-43a0-901f-83737e663ac4"
        }
        const addApiData = await axios.post(url, payload);
        addSchedulePref = addApiData.data.data
    } catch (err) {
    }
}
export const getSchedulePreferencesbyId = async (id) => {
    let SchedulePref = []
    try {
        const payload = {
            driver_id: id
        }
        const url = BaseConfig.ApiEndPoint + "/getdriverschedulepreferencesbybydriverid";
        const addApiData = await axios.post(url, payload)
        SchedulePref = addApiData.data.data;
    } catch (err) {
    }
    return Promise.resolve(SchedulePref);
}
export const updateSchedulePreferences = async (data) => {
    let SchedulePref = []
    const driver = new Driver();
    try {
        const url = BaseConfig.ApiEndPoint + "/updatedriverschedulepreferences";
        const payload = {
            id: data.id,
            driver_id: data.driver_id,
            nights_out: data.nights_out,
            day: data.day
        }
        const addApiData = await axios.post(url, payload)
        SchedulePref = addApiData.data.data
    } catch (err) {
    }
}

// summary api for driver section
export const getDriverSummaryData = async (driverid) => {
    console.log(driverid)
    let driverdata=[];
    try {
        const url = BaseConfig.ApiEndPoint + "/driverpaySummary";
        const payload={
            "driver_id": driverid.toString() 
        }
        const driverApiData = await axios.post(url,payload);
        driverdata=driverApiData.data.data;

    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverdata);
}


export const getDriverpayDetails = async (driverid,pay_date) => {
    console.log(driverid)
    let driverdata=[];
    try {
        const url = BaseConfig.ApiEndPoint + "/driverpayDetail";
        const payload={
            "driver_id": driverid.toString(),
            "pay_date":pay_date
        }
        const driverApiData = await axios.post(url,payload);
        driverdata=driverApiData.data.data;

    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverdata);
}
export const driverPayPdf = async function (driverid, date) {
    try {
      const url = BaseConfig.ApiEndPoint +`/driverpayPdf?driverid=${driverid}&date=${date}`;
      const response = await axios.get(url);
     return response
    } catch (error) {
      return error;
    }
  };
