import { useEffect, useState, useContext } from "react";
import {getAssignedDrivers, filterTrailerByTerminalID} from "../../services/userService";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import ProfileHeader from "../Profile/profileheader";
import Userfiltertable from "../../components/userfiltertable/userfiltertable";
import Userrolefiltertable from "../../components/userrolefiltetable/userrolefiltertable";
import { ContextData } from "../../components/appsession";
import { ProgressBar } from "react-bootstrap";

const Profile = () => {
  const [profileData, setprofileData] = useContext(ContextData);
  const [isUserClicked, setisUserClicked] = useState(false);
  const [userProfileTabclicked, setuserProfileTabclicked] = useState(true);
  const [filterType, setFilterType] = useState({});

  const [driver, setDriver] = useState([]);
  const [trailer, setTrailer] = useState({});

  const handelcallbackFromUserHeader = (childdata) => {
    if (childdata === "profile") {
      setuserProfileTabclicked(true);
    } else {
      setuserProfileTabclicked(false);
    }
  };
  useEffect( () => {
    if(profileData) {
      getDriverDetails()
      getTrailerDetails()
    }
  }, [profileData]);

  const getDriverDetails=async()=>{
    // 
   
    const userTerminalIds = profileData?.planningprofiles?.map(profile=>{return profile?.userPlanningprofile?.terminal_id});

    const data = await getAssignedDrivers(profileData.id, userTerminalIds);
    setDriver(data);
  }
  const getTrailerDetails=async()=>{
     const userTerminalIds = profileData?.planningprofiles?.map(profile=>{return profile?.userPlanningprofile?.terminal_id});;
    
    const data = await filterTrailerByTerminalID(userTerminalIds);
    setTrailer(data);
  }
  
  const refreshUserFilterTable = (
    type,
    searchData,
    action,
    currentSelection
  ) => {
    setFilterType({
      type: type,
      action: action,
      filterText: searchData,
      checkedData: currentSelection,
    });
  };

  useEffect(() => {
    setuserProfileTabclicked(true);
  }, [isUserClicked]);
  
  
  return (
    <div id="wrapper">
      <Header></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="card-body">
            <>
              <ProfileHeader                
                parentcallback={handelcallbackFromUserHeader}
                user={profileData} 
              />
              <div className="row special_row_flex">
                {userProfileTabclicked ? (
                  <>
                    <Userrolefiltertable user={profileData} />
                  </>
                ) : (
                  driver.length && trailer.length>0?
                  <>
                    <Userfiltertable
                      parentHandler={refreshUserFilterTable}
                      type={"Drivers"}
                      user={profileData}
                      dataObject={driver}
                    />
                    <Userfiltertable
                      parentHandler={refreshUserFilterTable}
                      type={"Trailers"}
                      user={profileData}
                      dataObject={trailer}
                    />
                  </>:(
                <div>
                  <ProgressBar animated now={100}
                  />
                  <div class='loader'>
                    <div class='loader--text'></div>
                  </div>
                </div>
              ))}
              
              </div>
            </>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
