import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";
import { useMsal, useAccount } from "@azure/msal-react";
import Switch from '@mui/material/Switch'
import appConfig from '../../Config.json'


const PlannerHeader = (props) => {
  const { instance, accounts } = useMsal();
  const { searchData, setSearchData, searchHandler } = props
  const location = useLocation();
  const history = useNavigate();

  const [tabSelected, setTabSelected] = useState({
    planner: true,
    driver: true,
    trailer: false,
    carrier: false,
    tractor: false,
  });
  const [callNewPlanningApi,setNewPlanningApi] = useState(false)


  useEffect(() => {
    let params = getDecodedURI(location.search);
    let selectedTabFRomParams = tabSelected
    if (params?.view) {
      for (let key in tabSelected) {
        if (key === params?.view) {
          selectedTabFRomParams[key] = true
        } else if (key !== 'planner') {
          selectedTabFRomParams[key] = false
        }
      }
      setTabSelected(selectedTabFRomParams)
    }
    localStorage.removeItem('callNewPlanningAPI')
  }, [])

  const tabClickHandler = (tabname) => {
    if (tabname === "driver") {
      setTabSelected({
        driver: true,
        trailer: false,
        carrier: false,
        tractor: false,
      });
    }
    if (tabname === "trailer") {
      setTabSelected({
        driver: false,
        trailer: true,
        carrier: false,
        tractor: false,
      });
    }
    if (tabname === "carrier") {
      setTabSelected({
        driver: false,
        trailer: false,
        carrier: true,
        tractor: false,
      });
    }
    if (tabname === "tractor") {
      setTabSelected({
        driver: false,
        trailer: false,
        carrier: false,
        tractor: true,
      });
    }
    let params = getDecodedURI(location.search);
    params.view = tabname
    history({ pathname: location.pathname, search: `${getEncodedURI(params)}` });
    props.parrentCallBackForTab(tabname);
  };



  const SearchHandlerInput = (event) => {
    setSearchData(event.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  const handleCallNewPlanningAPI=(event)=>{
    setNewPlanningApi(event.target.checked);
    localStorage.setItem('callNewPlanningAPI',event.target.checked)
  }

  return (
    <div className='plannerTop'>
      <div className='tabs'>
        <input
          type='radio'
          name='tabs'
          id='tab1'
          checked={tabSelected.driver}
          onChange={() => tabClickHandler("driver")}
        />
        <label htmlFor='tab1'>Driver View</label>
        <input
          type='radio'
          name='tabs'
          id='tab2'
          checked={tabSelected.trailer}
          onChange={() => tabClickHandler("trailer")}
        />
        <label htmlFor='tab2'>Trailer View</label>
       
        <input
          type='radio'
          name='tabs'
          id='tab3'
          checked={tabSelected.carrier}
          onChange={() => tabClickHandler("carrier")}
        />
        <label htmlFor='tab3'>Carrier View</label>
        <input
          type='radio'
          name='tabs'
          id='tab4'
          checked={tabSelected.tractor}
          onChange={() => tabClickHandler("tractor")}
        />
        <label htmlFor='tab4'>Tractor View</label>
        

        
      </div>
      <div className='planner_topSearch'>
      {appConfig?.environment?.toUpperCase() == 'DEV' ? 
      <Switch  checked={callNewPlanningApi} onChange={handleCallNewPlanningAPI}/> :""
      }
          <div className='card-body_planner'>
            <div className='row top_adjust planneretasection'>
              <div className='input-group w-31 mt_26'>
                <input
                  type='search'
                  className='form-control place_back'
                  placeholder='Search...'
                  id='top-search'
                  value={searchData}
                  style={{ zIndex: "1" }}
                  onChange={(e) => SearchHandlerInput(e)}
                  onKeyDown={handleKeyPress}
                />

                <button
                  className='btn input-group-text search_btn'
                  type='submit'
                  onClick={(e) => searchHandler()}
                >
                  <i
                    className='fa fa-search f_color_white'
                    aria-hidden='true'
                  ></i>
                </button>

              </div>
            </div>
          </div>
        </div>

    </div>
  );
};

export default PlannerHeader;
