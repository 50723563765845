import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { DateTime } from "luxon";

const TrailerBodyForHistory = (props) => {
  const { trailerById,trailerHistory,isLoading:loading } = props;
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
  });
  const [dataResult, setDataResult] = useState(
    process(trailerHistory, dataState)
  );
  const [totalCount,setTotalCount] = useState(0);
  
  const gridWidth = 1800;

  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };
 
  const dataStateChange = (event) => {
    let arr;
    if(event?.dataState?.filter && event?.dataState?.filter?.filters?.length>0){
       let filters=[...event?.dataState?.filter?.filters]
       for(let i=0;i<filters.length;i++){
         if(["actual_departure_utc","actual_arrival_utc","planned_depart_utc","planned_arrive_utc"].includes(filters[i].field)){
          if(filters[i].operator=='eq'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=> DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).toFormat("MM-dd-yyyy") == DateTime.fromJSDate(filters[i].value).toFormat("MM-dd-yyyy"));
            arr=filteredValue;
          }else if(filters[i].operator=='neq'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=> DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).toFormat("MM-dd-yyyy") !== DateTime.fromJSDate(filters[i].value).toFormat("MM-dd-yyyy"));
            arr=filteredValue;
          }else if(filters[i].operator=='gt'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=>DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).startOf('day').toMillis() > DateTime.fromJSDate(filters[i].value).startOf('day').toMillis());
            arr=filteredValue;
          }else if(filters[i].operator=='gte'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=>DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).startOf('day').toMillis() >= DateTime.fromJSDate(filters[i].value).startOf('day').toMillis());
            arr=filteredValue;
          }else if(filters[i].operator=='lt'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=>DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).startOf('day').toMillis() < DateTime.fromJSDate(filters[i].value).startOf('day').toMillis());
            arr=filteredValue;
          }else if(filters[i].operator=='lte'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=>DateTime.fromISO(DateTime.fromMillis(parseInt(el[filters[i].field])).setZone(checkTimeZone(el?.location_timezone)).toISODate()).startOf('day').toMillis() <= DateTime.fromJSDate(filters[i].value).startOf('day').toMillis());
            arr=filteredValue;
          }else if(filters[i].operator=='isnull'){
            let checkFilterValueArr=arr?.length>0 ? [...arr] : [...trailerHistory];
            let filteredValue=checkFilterValueArr?.filter((el)=>el[filters[i].field] == null);
            arr=filteredValue;
          }
         }
       }
    }
    if(event.dataState.sort?.length>0){
      let {dir,field}=event.dataState.sort[0]
      let checkFilter=arr?.length>0 ? arr : [...trailerHistory]
      if(dir=='asc'){
      arr=checkFilter.sort((a,b)=>{
          if (a[field] == null && b[field] == null) {
            return 0;
          } else if (a[field] == null) {
            return 1;
          } else if (b[field] == null) {
            return -1;
          }  
          const firstFeild = !isNaN(a[field]);
          const secondFeild = !isNaN(b[field]);
        
          if (firstFeild && secondFeild) {
            return a[field] - b[field];
          } else if (!firstFeild && !secondFeild) {
            return String(a[field]).localeCompare(String(b[field]), undefined, { numeric: true, sensitivity: 'base' });
          } else if (firstFeild) {
            return -1;
          } else {
            return 1;
          }
                })
      } else if (dir == 'desc') {
        arr = checkFilter.sort((a, b) => {
          if (a[field] === null && b[field] === null) {
            return 0;
          } else if (a[field] === null) {
            return 1;
          } else if (b[field] === null) {
            return -1;
          }
          const firstFeild = !isNaN(a[field]);
          const secondFeild = !isNaN(b[field]);

          if (firstFeild && secondFeild) {
            return b[field] - a[field];
          } else if (!firstFeild && !secondFeild) {
            return String(b[field]).localeCompare(String(a[field]), undefined, { numeric: true, sensitivity: 'base' });
          } else if (firstFeild) {
            return -1;
          } else {
            return 1;
          }
        })
      }
    }
    if(arr && arr?.length>=0){
      setDataResult(arr?.slice(event.dataState.skip)?.filter((el,i)=>i<event.dataState.take));
    }else{
    setDataResult(process(trailerHistory,event.dataState));
    }
    setDataState(event.dataState);
  };

  const checkTimeZone=(tzone)=>{
    if (!tzone || tzone === "Eastern Standard Time") {
     return "America/New_York"
    }
    if (tzone === "Central Standard Time") {
     return "America/Chicago"
    }
  }

  const formatDate = (epoch_date, tzone) => {
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(checkTimeZone(tzone)).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }
  
  }

  useEffect(() => {
    setDataResult(process(trailerHistory,dataState));
    setTotalCount(trailerHistory?.length);
  }, [trailerHistory]);

  return (
    <div className="row special_row_flex">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body">
            <h2 className="header-title">Detailed History</h2>
            <div className="container special_container_padding"></div>
           
            {trailerHistory?.length > 0 ? (
              <Grid
                filter={dataState.filter}
                filterable={true}
                sort={dataState.sort}
                sortable={true}
                pageable={{
                  pageSizes: [5, 10, 20, 25, 50, 100],
                  info: true,
                  previousNext: true,
                  buttonCount : 10
                }}
                resizable={true}
                skip={dataState.skip}
                take={dataState.take}
                data={dataResult}
                onDataStateChange={dataStateChange}
                total={totalCount}
                // onRowClick={(e) => props.parentcallback(true, e.dataItem)}
              >
                <GridColumn
                  field="trailer_id"
                  title="Trailer Id"
                  width="200px"
                />
                <GridColumn field="stop_type" title="Stop Type" width="200px" />
                <GridColumn
                  field="loaded"
                  title="Loaded"
                  width="200px"
                  filter={"boolean"}
                />
                <GridColumn
                  field="driver_load"
                  title="Driver Load Unload Flag"
                  width="200px"
                />
                <GridColumn field="commodity" title="Commodity" width="200px" />
                <GridColumn field="distance" title="Distance" width="200px" />
                <GridColumn field="order_id" title="Order Id" width="200px" />
                <GridColumn field="order_status" title="Order Status" width="200px" />
                <GridColumn field="movement_id" title="Movement ID" width="200px" />
                <GridColumn field="movement_status" title="Movement Status" width="200px" />
                <GridColumn
                  field="location_code"
                  title="Location Code"
                  width="200px"
                />
                <GridColumn
                  field="location_name"
                  title="Location Name"
                  width="200px"
                />
                <GridColumn
                  field="location_address"
                  title="Address"
                  width="200px"
                />
                <GridColumn field="location_city" title="City" width="200px" />
                <GridColumn
                  field="location_state"
                  title="State"
                  width="200px"
                />
                <GridColumn
                  field="responsible_terminal_name"
                  title="Responsible Terminal Name"
                  width="200px"
                />
                <GridColumn
                  field="planned_arrive_utc"
                  title="Planned Arrive"
                  filter={"date"}
                  format="{0:d}"
                  width={setPercentage(15)}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.planned_arrive_utc ? formatDate(e.dataItem.planned_arrive_utc,e.dataItem.location_timezone) : "No Data"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="planned_depart_utc"
                  title="Planned Depart"   
                  format="{0:d}"    
                  filter={"date"}        
                  width={setPercentage(15)}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.planned_depart_utc ? formatDate(e.dataItem.planned_depart_utc,e.dataItem.location_timezone) : "No Data"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="actual_arrival_utc"
                  title="Actual Arrive"  
                  format="{0:d}"
                  filter={"date"}  
                  width={setPercentage(15)}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.actual_arrival_utc ? formatDate(e.dataItem.actual_arrival_utc,e.dataItem.location_timezone) : "No Data"}
                      </td>
                    );
                  }}
                  />
                <GridColumn
                  field="actual_departure_utc"
                  title="Actual Depart"
                  format="{0:d}"
                  filter={"date"}
                  width={setPercentage(15)}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.actual_departure_utc ? formatDate(e.dataItem.actual_departure_utc,e.dataItem.location_timezone) : "No Data"}
                      </td>
                    );
                  }}
                />
                <GridColumn field="driver_id" title="Driver Id" width="200px" />
                <GridColumn
                  field="driver_first_name"
                  title="Driver First Name Id"
                  width="200px"
                />
                <GridColumn
                  field="driver_last_name"
                  title="Driver Last Name"
                  width="200px"
                />
                <GridColumn
                  field="tractor_id"
                  title="Tractor Id"
                  width="200px"
                />
              </Grid>
            ) : loading ? (
              <div>
                <ProgressBar animated now={100} />
                <div className="middle loader--text1"> </div>
              </div>
            ) : (
              <div className="header_paragraph">No Data Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrailerBodyForHistory;