export const getAppFilter = async () => {
    let appFilterObject = {}

    try {
        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('AppFilterObject');
        appFilterObject = JSON.parse(retrievedPlannerObject);


    } catch (err) {
        return appFilterObject;
    }

    return appFilterObject;
}

export const getUserFilter = async () => {
    let userFilterObject = {}

    try {

        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('UserFilterObject');
        userFilterObject = JSON.parse(retrievedPlannerObject);

    } catch (err) {
        return userFilterObject;
    }

    return userFilterObject;
}