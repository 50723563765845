import React from "react";

const RowRender = (properties) => {
  const { row, props, onDrop, onDragStart } = { ...properties };
  const additionalProps = {
      onDragStart: (e) => onDragStart(e, props.dataItem),
      onDragOver: (e) => {
      e.preventDefault();
      },
      onDrop: (e) => onDrop(e),
      draggable: true,
  };
  return React.cloneElement(
      row,
      { ...row.props, ...additionalProps },
      row.props.children
  );
};

const getRowDraggable = (onDrag, onDrop) => {
  const rowDraggable = (row, props) => {
    return (
      <RowRender
        props={props}
        row={row}
        onDrop={onDrop}
        onDragStart={onDrag}
      />
    );
  };

  return rowDraggable;
}

export default getRowDraggable;
