import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableRow } from "../tankwashcodetable/draggable-row";
import { DragHandleCell } from "../tankwashcodetable/drag-handle-cell";
import { useEffect } from "react";
//import products from './products.json';

const initialGroup = [
  {
    field: "category",
  },
];

export const ReorderContext = React.createContext({
  reorder: () => { },
  dragStart: () => { },
});

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);

  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

const Washprocedure_table = (props) => {
  const { washprocedureData } = props;
  const [group, setGroup] = React.useState(initialGroup);
  const [newData, setNewData] = React.useState([]);
  const [resultState, setResultState] = React.useState(
    processWithGroups(washprocedureData, initialGroup)
  );
  const [activeItem, setActiveItem] = React.useState(null);

  useEffect(() => {
    setResultState(processWithGroups(washprocedureData, initialGroup));
  }, [washprocedureData.length]);

  const reorder = (dataItem, direction) => {

    if (activeItem === dataItem) {
      return;
    }

    if (dataItem.field == "category") {
      let reorderedData = newData.slice();
      let prevIndex = reorderedData.findIndex((p) => p === activeItem);
      let nextIndex = reorderedData.findIndex((p) => p === dataItem);
      let new_data = reorderedData.map((itm, idx) => {
        if (idx == prevIndex) return dataItem;
        if (idx == nextIndex) return activeItem;
        return itm;
      });
      setNewData(new_data);



    } else {
      if (dataItem.category != activeItem.category) return;
      const category_index = newData
        .map((e) => e.groupId)
        .indexOf(dataItem.category + "category");
      let reorderedData = newData[category_index].items.slice();
      let prevIndex = reorderedData.findIndex((p) => p === activeItem);
      let nextIndex = reorderedData.findIndex((p) => p === dataItem);
      let new_data = reorderedData.map((itm, idx) => {
        if (idx == prevIndex) return dataItem;
        if (idx == nextIndex) return activeItem;
        return itm;
      });
      setNewData(
        newData.map((item, idx) => {
          if (idx == category_index) return { ...item, items: new_data };
          return item;
        })
      );

    }
  };
  const [collapsedState, setCollapsedState] = React.useState(null);

  const onGroupChange = React.useCallback((event) => {
    const newDataState = processWithGroups(washprocedureData, event.group);
    setGroup(event.group);
    setResultState(newDataState);

  }, []);

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  useEffect(() => {
    setNewData(
      setExpandedState({
        data: resultState,
        collapsedIds: [],
      })
    );
    setCollapsedState([]);

  }, [resultState.map((e) => e.items).flat().length, resultState.length]);


  useEffect(() => {
    if (collapsedState == null) return;
    setNewData(
      setExpandedState({
        data: newData,
        collapsedIds: collapsedState,
      })
    

    );
  }, [collapsedState]);
  useEffect(() => {
  let proceduressequence=newData.map((e) => e.items).flat()
  props.procedureSequence(proceduressequence)

  }, [newData])
  
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  return (
    <>
      <ReorderContext.Provider
        value={{
          reorder: reorder,
          dragStart: dragStart,
        }}
      >
        <DragAndDrop>
          <Grid
            data={newData}
            //dataItemKey={'wash_proc'}
            groupable={true}
            onGroupChange={onGroupChange}
            group={group}
            onExpandChange={onExpandChange}
            expandField='expanded'
            rowRender={(row, rowProps) => (
              <DraggableRow elementProps={row.props} {...rowProps} />
            )}
          >
            {/* <Grid groupable={true} data={newData} onGroupChange={onGroupChange} group={group} onExpandChange={onExpandChange} expandField="expanded"> */}
            <Column title='' width='80px' cell={DragHandleCell} />
            <Column field='description' width='140px' title='Description' />
            <Column field='category' width='140px' title='Category' />
            <Column field='wash_proc' width='140px' title='wash proc' />
            <Column field='active' width='160px' title='Active' />
           
            
          </Grid>
        </DragAndDrop>
      </ReorderContext.Provider>
    </>
  );
};
export default Washprocedure_table;
