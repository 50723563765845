

const DriverPayCodesMasterHeader = () => {
    return (
        <div className="row df mt_30">
            <div className="col-xl-12">
                <div className="card special_bg">
                    <div className="card-body">
                        <div className="row top_adjust">
                            <div className="col-md-12">
                                <div className="row">
                                    <h2 className="text-light">Driver Pay Codes Master</h2>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverPayCodesMasterHeader;
