import axios from 'axios';
import * as fflate from 'fflate';
import { DateTime } from 'luxon';

import { BaseConfig } from "../baseConfig";

export const searchUnplanAndPlandOrder = async (tabname, searchTerms, sortTerms, page, size, date, daysout, timezone) => {
  let decompressedObject;
  let treatedDate = date;

  if (typeof date === "number") {
    treatedDate = DateTime.fromMillis(date).toFormat('yyyy-MM-dd');
  }

  let payload_data = {
    tabname: tabname,
    date: treatedDate,
    daysOut: daysout,
    searchdata: [],
    shortData: sortTerms,
    timezone
  };
  searchTerms?.forEach((term) => {
    const { field, value, operator } = term;
    payload_data.searchdata.push({
      [field]: value,
      operator: operator,
    });
  });
  const url = `${BaseConfig.ApiEndPoint}/searchorderstab?page=${page}&size=${size}`;
  try {
    const response = await axios.post(url, payload_data);
    if (response?.data && response?.data?.compressedbase64string) {
      const strData = atob(response.data.compressedbase64string);
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    } else {
      throw new Error("Invalid response format: response.data is undefined or null");
    }

  } catch (error) {
    console.error("An error occurred while performing  search:", error);
    throw error;
  }

  return decompressedObject;

};


export const searchOrderForTrailerview = async (tabname, searchTerms, sortTerms, page, size, date, daysout, timezone) => {

  let decompressedObject;

  let payload_data = {
    tabname: tabname,
    date: date,
    daysOut: daysout,
    searchdata: [],
    shortData: sortTerms,
    timezone
  };
  searchTerms?.forEach((term) => {
    const { field, value, operator } = term;
    payload_data.searchdata.push({
      [field]: value,
      operator: operator,
    });
  });
  const url = `${BaseConfig.ApiEndPoint}/searchorderstabtrailerview?page=${page}&size=${size}`;
  try {
    const response = await axios.post(url, payload_data);
    if (response?.data && response?.data?.compressedbase64string) {
      const strData = atob(response.data.compressedbase64string);
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    } else {
      throw new Error("Invalid response format: response.data is undefined or null");
    }

  } catch (error) {
    console.error("An error occurred while performing  search:", error);
    throw error;
  }

  return decompressedObject;

};

export const removeTrailerFromOrderTab = async (payload) => {

  const url = `${BaseConfig.ApiEndPoint}/removeTrailer`;
  try {
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    console.error("An error occurred while removing trailer :", error);
    throw error;
  }


};