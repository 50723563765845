import React, { useState, useEffect, useContext } from "react";
import SearchFilter from "../../assets/images/search_filter.svg";
import Search from "../../assets/images/Search-Button.svg";
import { getTerminalByIds } from "../../services/terminalService";
import AddIcon from "../../assets/images/add_icon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getBannedDrivers, getBannedDriversByLocation, getDriversByLocationID,
  blockDriverForLocation, addBannedDriver, unBlockDriverForLocation
} from "../../services/driverService";
import { saveHoursOfOperationByLocationId, updateWashBays } from "../../services/locationService"
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, useFormik, ErrorMessage } from "formik";
import * as yup from "yup";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { MenuItem } from "@mui/material";
import { Tooltip } from "@mui/material";
import { ContextData } from "../../components/appsession";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";
import { Padding } from "@mui/icons-material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const LocationBodyForDetails = (props) => {
  const { locationById, isShipperOrConsignee, location, accessDisabled,handelcallback } = props;
  const { isTerminal, isWash } = locationById
  const [userData, setuserData] = useContext(ContextData);
  const [LocationData, setLocationData] = useState({})

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });


  const [searchtext, setSearchText] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [allDrivers, setallDrivers] = useState([]);
  const [seletedDriverForBan, setseletedDriverForBan] = useState({});
  const [allBanned, setallBanned] = useState([]);
  const [allLocationDrivers, setallLocationDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [driverID, setDriverID] = useState("");
  const [unBannedDriver, setUnBannedDriver] = useState([])
  const [daysarray, setdaysarray] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"])
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [bannedDriverforEdit, setBannedDriverforEdit] = useState({});
  const [dataResult, setDataResult] = useState(process(allBanned, dataState));
  const [openDaysArr, setopenDaysArr] = useState([])
  const [closedDayArr, setclosedDayArr] = useState([])
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const [openDataForHrs, setopenDataForHrs] = useState([])
  const [editDisabled, setEditDisabled] = useState(true);
  const [washBaysNum, setWashBaysNum] = useState();
  const daysOrder = {
    'monday': 1, 'tuesday': 2, 'wednesday': 3, 'thursday': 4, 'friday': 5, 'saturday': 6,
    'sunday': 7
  };
  const dataStateChange = (event) => {
    setDataResult(process(allBanned, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allBanned, dataState));
  }, [allBanned]);

  useEffect(() => {
    let bannerDrivers = allBanned.map(banned => {
      return banned.driver_id
    })
    const unBanDriver = [];
    allDrivers.forEach(driver => {
      if (bannerDrivers.indexOf(driver.driver_id) == -1) {
        unBanDriver.push(driver)
      }
    })
    setUnBannedDriver(unBanDriver)
  }, [allDrivers, allBanned])

  const fetchData =async () => {
    const userTerminalIds = userData.planningprofiles?.map((it) => it.userPlanningprofile?.terminal_id);
    if (userTerminalIds?.length > 0) {
      let terminalinformationlist = await getTerminalByIds(
        userTerminalIds
      );
      let default_terminal_ids = [];

      terminalinformationlist.forEach(function (terminalinformation) {
        default_terminal_ids.push(terminalinformation.code);
      });

      let filterData = {
        terminalId: default_terminal_ids,
      };

      const driverService = getBannedDrivers(filterData)
        .then(function (driversList) {
          setallDrivers(driversList);
        })
        .catch(function (err) {
          toast.error(err, "Error");
        });
    }
  }
  useEffect(() => {
    fetchData();
  }, [userData]);

  const fetchLocationData =async () => {
  // const driverService = new DriverService();

  try {
    setLoading(true);
    const allBannedDrivers = await getBannedDriversByLocation(
      locationById.id.toString(),
      searchtext
    );
    if (allBannedDrivers.length > 0) {
      setallBanned(allBannedDrivers);
    }
  } catch(err){
    console.log(err)
    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
      errorMessageDisplay(err?.response?.data?.errors);
    }else{
    toast.error('Failed to fetch banned drivers by location', { autoClose: false } );
    }
  }
  }
  useEffect( () => {
    fetchLocationData();
  }, []);

  const fetchShipperData =async () => {

    try {
      setLoading(true);
      const allLocationDrivers = await getDriversByLocationID(
        locationById.id.toString(),
        searchtext
        // isShipperOrConsignee
      );

      if (allLocationDrivers.length > 0) {
        setallLocationDrivers(allLocationDrivers);

        props.parentCallBackForBanned(allLocationDrivers);
      } else {
        setallLocationDrivers([]);
      }
      setLoading(false);
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to fetch shipper data', { autoClose: false } );
      }
      setLoading(false);
    }
  }
  useEffect( () => {
    fetchShipperData();
  }, [isShipperOrConsignee.shipper]);

  const handleWashBays = (e) => {
    setWashBaysNum(e.target.value)
  }
  const handleEdit = () => {
    setEditDisabled(false)
  }
  const createBannedDriver = async (values) => {

    const payloadData = {
      driver_id: driverID,
      reason: values.reason,
      location_id: locationById.id.toString(),
    };
    try {
      setLoading(true);
      const blockDriver = await addBannedDriver(payloadData);
      const allBannedDrivers = await getBannedDriversByLocation(
        locationById.id.toString(),
        searchtext
      );
      if (allBannedDrivers.length > 0) {
        setallBanned(allBannedDrivers);
        // setallBanned(blockDriver)
        setModalShow(false);
        setLoading(false);
        props.parentCallBackForBanned(blockDriver);
        return toast.success(
          "Banned Driver added successfully",
          "Success"
        );
      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Banned Driver not added successfully', { autoClose: false } );
      }
    }
  };
  // DELETE Driver
  const openBannedDriverModal = (driver) => {
    setBannedDriverforEdit(driver);
    setshowDeleteModal(true);
  };

  const deleteBannedDriver = async () => {
    try {
      let payloadData = {
        location_id: locationById.id.toString(),
        driver_id: bannedDriverforEdit.driver_id,
      };
      const unblockDriver = await unBlockDriverForLocation(
        payloadData
      );
      if (unblockDriver.length > 0) {
        setallBanned(
          allBanned.filter(
            (item) => item.driver_id !== bannedDriverforEdit.driver_id
          )
        );
        setshowDeleteModal(false);
        props.parentCallBackForBanned(unblockDriver);

      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Banned Driver  Not Unblocked successfully', { autoClose: false } );
      }
    }
  };

  const Deletebanneddriver = (props) => {
    return (
      <td>
        <button
          type="button"
          className="btn_blue_smadjust btn-blue ml_10"
          onClick={() => props.openBannedDriverModal(props.dataItem)}
          disabled={accessDisabled ? true : false}
        >
          Delete
        </button>
      </td>
    );
  };
  const DeleteBanned = (props) => (
    <Deletebanneddriver
      {...props}
      openBannedDriverModal={openBannedDriverModal}
    />
  );
  const handleDriverId = (e, newValue) => {
    setDriverID(newValue?.driver_id);
  };

  const convertTime = (dateconv, tzone) => {

    if (dateconv === null) {
      return "No data"
    }
    else {

      //return   ( DateTime.fromMillis(parseInt(dateconv) * 1000).setZone(tz).toFormat("HH:mm a ZZZZ ").toString())
      // let time = dateconv.split("+")[0]
      // const nowdate = DateTime.now().toFormat('yyyy-MM-dd');

      // var fulldatetime = nowdate + ' ' + time;
      // const d1 = new Date(fulldatetime);
      // let stringdate = d1.toString()

      // let timeZone = locationById.timeZone;
      // const dateObject = new Date(stringdate).toUTCString("en-US", {
      //   timeZone,
      // });

      // let dateTime = dateObject.toString().split(" ")[4]
      // return dateTime
      if (tzone) {
        const Split = dateconv?.split("+")[0]
        const timeSplit = Split?.split(":")
        const hour = parseInt(timeSplit[0] * 60 * 60)
        const min = parseInt(timeSplit[1] * 60)
        const sec = parseInt(timeSplit[2])
        const millisec = (hour + min + sec) * 1000
        return DateTime.fromMillis(parseInt(millisec))
          .setZone(tzone)
          .toFormat("HH:mm")
          .toString();
      }

    }
  }


  const closedate = Object.keys(location).filter((key) => key.includes("_close")).reduce((obj, key) => {
    return Object.assign(obj, { [key]: location[key] })
      ;
  },
    {});
  const Close_date = Object.values(closedate)
  useEffect(() => {
    if (Object.keys(location).length > 0) {

      setWashBaysNum(location.no_of_wash_bays)
      let opendayvalarr = []
      let closeddayvalarr = []
      const openDays = Object.keys(LocationData).filter((key) => key.includes("_open"))
      const closedDays = Object.keys(LocationData).filter((key) => key.includes("_close"))

      for (let index = 0; index < openDays.length; index++) {
        let day = openDays[index]
        let obj = {
          day: day,
          value: LocationData[day],
          isopen: false,
          timezone: LocationData.timezone || LocationData.timeZone
        }
        opendayvalarr.push(obj)
      }
      for (let index = 0; index < closedDays.length; index++) {
        let day = closedDays[index]
        let obj = {
          day: day,
          value: LocationData[day],
          isopen: false,
          timezone: LocationData.timezone || LocationData.timeZone
        }
        closeddayvalarr.push(obj)
      }
      setclosedDayArr(closeddayvalarr)
      setopenDaysArr(opendayvalarr)
    }

  }, [LocationData])
  useEffect(() => {
    setLocationData(location)
  }, [location])

  const editHoursHandler = (e, item, type) => {
    
    if (type === "open") {
      let hrr=item.value?.split(":")[0]
      let min=item.value?.split(":")[1]
      // let a= new Date(2016, 6, 27, hrr, min, 0);
      const dateTime = DateTime.fromISO((item?.value)?.split("+")[0]);
     
      setSelectedDate(dateTime)
      const arr = openDaysArr.filter((para1) => para1.day != item.day)
  
      // let a=  arr.map((item)=>item.isopen=false)
      let allArr = []
      for (let index = 0; index < arr.length; index++) {

        arr[index].isopen = false
        allArr.push(arr[index])
      }
      item.isopen = true
      allArr.push(item)
      // let a= arr.filter((e)=>e.day !=)

      allArr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });

      setopenDaysArr(allArr)

    }
    if (type === "close") {
     
      const dateTime = DateTime.fromISO((item?.value)?.split("+")[0]);
     
      setSelectedDate(dateTime)
  
      const arr = closedDayArr.filter((e) => e.day != item.day)
      let allArr = []
      for (let index = 0; index < arr.length; index++) {

        arr[index].isopen = false
        allArr.push(arr[index])
      }
      item.isopen = true
      allArr.push(item)
      allArr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
      setclosedDayArr(allArr)
    }

  }
  const cancelEditHandler = (e, item, type) => {
    if (type === "open") {
      const arr = openDaysArr.filter((e) => e.day != item.day)
      item.isopen = false
      arr.push(item)
      arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
      setopenDaysArr(arr)
      setEditDisabled(true)
    }
    if (type === "close") {
      const arr = closedDayArr.filter((e) => e.day != item.day)
      item.isopen = false
      arr.push(item)
      arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
      setclosedDayArr(arr)
      setEditDisabled(true)
    }

  }
  const handleDateChange = (date,item) => {
  
    
  
    let timeInhr = DateTime.fromISO(date).toFormat('HH:mm');
    let arr = [];
    setSelectedDate(date);
    let day = item.day
    let obj = {
      // [day]: (e.toString()).ISOString(),
      [day]: timeInhr
    };
    arr.push(obj)
    setopenDataForHrs(arr)
  };

  const saveHoursOfOperation = async (e, item, type) => {
    let day = item.day
    
    // const loocationservice = new LocationService();
    let payload = openDataForHrs.find((e) => e[day])
    if (payload) {
      const response = await saveHoursOfOperationByLocationId(locationById.id.toString(), payload)
      if (response.data.data) {
        setEditDisabled(true)
        setLocationData(response.data.data)
        handelcallback(true,response.data.data)
        if (type === "open") {
          const arr = openDaysArr.filter((e) => e.day != item.day)
          item.isopen = false
          arr.push(item)
          arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
          setopenDaysArr(arr)

        }
        if (type === "close") {
          const arr = closedDayArr.filter((e) => e.day != item.day)
          item.isopen = false
          arr.push(item)
          arr.sort((a, b) => { return daysOrder[a.day.split("_")[0]] - daysOrder[b.day.split("_")[0]]; });
          setclosedDayArr(arr)
        }
        return toast.success(
          `Hour of Operation is updated for ${item.day.split("_")[0]}`,
          "Success"
        );

      }
    }
    else {
      return toast.error(
        `Select the time for ${item.day.split("_")[0]}`,
        "Error"
      );
    }
  }
  const updateWashBaysNumber = async (washBays) => {
    let payload = {
      no_of_wash_bays: washBays,
      location_id: locationById.id
    }
    if (!washBays) {
      return toast.error(
        `Please enter a number`,
        "Error"
      );
    }
    try {
      const wash = await updateWashBays(payload);
      if (wash) {
        setWashBaysNum(wash[0].no_of_wash_bays)
        return toast.success(
          `Number of Wash bays updated`,
          "Success"
        );
      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Number of wash bays not updated', { autoClose: false } );
      }
    }
  }
  return (
    <>
      <div className="row special_row_flex">
        <div className="col-xl-12">
          <div className="card card_shadow">

          </div>
        </div>

        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body">
              <div className="container special_container_padding hour_operation_top">
                <div>
                  <h2 className="header-title_operation">Hours Of Operation</h2>
                </div>
                {isTerminal && isWash ? 

                <div className="hour_operatipon_top_right">
                  <label className="mr_10">Number of wash bays </label>
                  <TextField
                    type="number"
                    placeholder="Enter a number"
                    variant="outlined"
                    margin="normal"
                    value={washBaysNum?.toString()}
                    onChange={(e) => handleWashBays(e)}
                  />
                  <button
                    type="button"
                    className="btn_blue btn-blue ml_10"
                    onClick={() => updateWashBaysNumber(washBaysNum)}>
                    Save
                  </button>
                </div>

                 : null
              } 

              </div>

              <div className="container special_container_padding">
                <div className="details_wrapper">
                  <div className="main_details_sec">
                    <div className="Details_sec">
                      <div className="left_main">Week</div>
                    </div>

                    <div className="Details_sec">
                      <div className="left_main">Start</div>

                    </div>

                    <div className="Details_sec">
                      <div className="left_main">End</div>
                    </div>
                  </div>
                  <div className="main_details_sec">
                    <div className="Details_sec">
                      <div className="right_drescrition">{daysarray.map((days, index) => (

                        <p className="location_hours_text_day"> {days} </p>

                      ))}

                      </div>
                    </div>

                    <div className="Details_sec">
                      <div className="right_drescrition">
                        {openDaysArr.map((item, index) => (
                          <div className="operation_time_section" key={index}>
                            {!item.isopen ?
                              <>
                                <div className="location_hours_text">{(item?.value)?.split("+")[0]} </div>
                                <div onClick={(e) => handleEdit()}>
                                  <i
                                    className="fa fa-pencil time_edit_icon"
                                    aria-hidden="true"
                                    style={{ color: "#0066ff" }}
                                    id={index}
                                    onClick={(e) => editHoursHandler(e, item, "open")}
                                  ></i>
                                </div>
                              </>
                              :
                              <div className="operation_time_section_inner">
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                  
                                  <TimePicker
                                    defaultValue={selectedDate}
                                    onChange={(date) => handleDateChange(date, item)}
                                    clearIcon={null}
                                    format="HH:mm"
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock
                                  }}
                                    
                                  />

                                </LocalizationProvider>
                                <div className="operation_hour_button_section">
                                  <button className="btn_operation_time" onClick={(e) => saveHoursOfOperation(e, item, "open")}>Save</button>
                                  <button className="btn_operation_time ml_5" onClick={(e) => cancelEditHandler(e, item, "open")}>Cancel</button>
                                </div>
                              </div>
                            }


                            {/* <button type="button" className="btn_blue_sm btn-blue ml_10 access_save" onClick={(e) => editHoursHandler(e, item,"open")}>Edit</button> */}


                          </div>
                        ))
                        }
                      </div>
                    </div>

                    <div className="Details_sec">
                      <div className="right_drescrition">
                        {
                          closedDayArr.map((item, index) => (
                            <div className="operation_time_section" key={index}>
                              {!item.isopen ?
                                <>
                                  <div className="location_hours_text">{(item.value)?.split("+")[0]} </div>

                                  <div onClick={(e) => handleEdit()}>
                                    <i
                                      className="fa fa-pencil time_edit_icon"
                                      aria-hidden="true"
                                      style={{ color: "#0066ff" }}
                                      id={index}
                                     
                                      onClick={(e) => editHoursHandler(e, item, "close")}
                                    ></i>
                                  </div>
                                </>
                                :
                                <div className="operation_time_section_inner">
                                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    
                                    <TimePicker
                                    defaultValue={selectedDate}
                                    onChange={(date) => handleDateChange(date, item)}
                                    clearIcon={null}
                                    format="HH:mm"
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock
                                  }}
                                    
                                  />

                                  </LocalizationProvider>

                                  <div className="operation_hour_button_section">
                                    <button className="btn_operation_time" onClick={(e) => saveHoursOfOperation(e, item, "close")}>Save</button>
                                    <button className="btn_operation_time ml_5" onClick={(e) => cancelEditHandler(e, item, "close")}>Cancel</button>
                                  </div>

                                </div>
                              }

                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body">
              <div className="banned_add_button_position">
                <div>
                  <h2 className="header-title1">BANNED DRIVERS</h2>
                </div>
                <div>
                  <div className="add_icon">

                    <Tooltip title={"Block driver"}>
                      <button
                        type="button"
                        className="btn_blue btn-blue mb-20"
                        onClick={() => setModalShow(true)}
                        disabled={accessDisabled ? true : false}
                      // style={{background : accessDisabled ? disabled : ""}}
                      >
                        ADD
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                {allBanned?.length > 0 ? (
                                
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      buttonCount: 10,
                      info: true,
                      previousNext: true,
                      pageSizes: true,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  >
                    <GridColumn title="Action" cell={DeleteBanned} />
                    <GridColumn field="driver_full_name" title="Driver Name" />
                    <GridColumn field="driver_id" title="Driver Id" />
                    <GridColumn field="reason" title="Reason" />

                  </Grid>
                ) : loading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className="middle loader--text1"> </div>
                  </div>
                ) : (
                  <div>No data found</div>
                )}
              </div>
            </div>
          </div>

          {/* Formik from modal  */}
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                BAN DRIVER
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  driverid: "",
                  reason: "",
                }}
                validationSchema={yup.object({
                  // driverid: yup.string().required("Driver Id is required"),
                  reason: yup.string().required("Reason is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  createBannedDriver(values);
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <label htmlFor="exampleFormControlInput1">Driver Id *</label>
                    <div className="meterial_autocomplete">
                      <Autocomplete
                        id="combo-box-demo"
                        options={unBannedDriver}
                        getOptionLabel={(option) =>
                          (option.driver_id ? " " + option.driver_id : "") + "-" + (option.first_name ? option.first_name : "") +
                          (option.name_mid_initial
                            ? " " + option.name_mid_initial
                            : "") +
                          (option.driver_name ? " " + option.driver_name : "")
                        }
                        onChange={handleDriverId}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            fullWidht={true}
                            {...params}
                            value={driverID}
                            placeholder="Driver Id..."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >

                          </TextField>
                        )}
                      />
                    </div>


                    <label htmlFor="exampleFormControlInput1">Reason *</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      name="reason"
                      multiline={true}
                      minRows={2}
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.reason && Boolean(formik.errors.reason)
                      }
                      helperText={formik.touched.reason && formik.errors.reason}
                    />
                    <div className="banfooter_btn">
                      <Modal.Footer>

                        <Button onClick={formik.handleReset}>Reset</Button>
                        <Button className="m_0" type="sumbit">Save</Button>

                      </Modal.Footer>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          <Modal
            show={showDeleteModal}
            onHide={() => setshowDeleteModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Unblock Banned Driver
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <p>
                  Are you sure you want to Unblock Banned Driver:
                  {(bannedDriverforEdit?.driver_id
                    ? " " + bannedDriverforEdit?.driver_id
                    : "") +
                    " - " +
                    (bannedDriverforEdit?.driver_full_name
                      ? bannedDriverforEdit?.driver_full_name
                      : "")}{" "}
                  ?
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => setshowDeleteModal(false)}>Close</Button>
              <Button onClick={(e) => deleteBannedDriver(e)}>Unblock</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>


    </>
  );
}
export default LocationBodyForDetails;
