import { Navbar } from "react-bootstrap";
import "./header.css";
import { useQuery } from "../../hooks";
import "../../assets/css/icons.min.css";
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import "../../components/plannerBoardTrailersTable/plannerBoardTrailersTable.css";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from '@progress/kendo-react-intl';
import likelySubtags from '../../components/plannerBoardTrailersTable/dt/likelySubtags.json';
import currencyData from '../../components/plannerBoardTrailersTable/dt/currencyData.json';
import weekData from '../../components/plannerBoardTrailersTable/dt/weekData.json';
import numbers from '../../components/plannerBoardTrailersTable/dt/numbers.json';
import currencies from '../../components/plannerBoardTrailersTable/dt/currencies.json';
import caGregorian from '../../components/plannerBoardTrailersTable/dt/ca-gregorian.json';
import dateFields from '../../components/plannerBoardTrailersTable/dt/dateFields.json';
import timeZoneNames from '../../components/plannerBoardTrailersTable/dt/timeZoneNames.json';
import '@progress/kendo-theme-default/dist/all.css';
load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);
import esMessages from '../../components/plannerBoardTrailersTable/dt/es.json';
loadMessages(esMessages, 'es-ES');
import { ContextData } from '../../components/appsession';
import { Link } from "@mui/material";
import getRowDraggable from "../../components/rowDraggable/rowDraggable";
import { sendPushNotification, updateSelectedPlanningProfileOfUser } from "../../services/userService";
import { getTrailersTabData } from '../../services/trailerService';
import debounce from 'lodash/debounce';
import filterOperators from '../../utils/filterOperators';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { getAllTerminals, upadteTerminalofOrderOrTrailer } from "../../services/terminalService";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import Modal from "react-bootstrap/Modal";
import { Autocomplete } from "@mui/lab";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";
import { createBpaAlert } from "../../services/alertService";
import { DateTime, Duration } from "luxon";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const TrailersPage = (props) => {
  const { settabSelected, tabSelected, trailerTabClickC } = props;
  const _export = React.useRef(null);


  const exportExport = async () => {
    try {
      let arr = [];
      if (searchTerms.length > 0) {
        arr = searchTerms?.map((el) => {
          let obj = {};
          obj[el?.field] = el?.value
          obj.operator = el?.operator;
          return obj
        });
      }

      const search = await getTrailersTabData(arr, pageNumber, 1000000, sortData);
      const searchData = search.data;
      if (_export.current !== null) {
        _export.current.save(searchData);
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('There is a problem in exporting trailers tab data.', { autoClose: false } );
      }
    }
  };


  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
  const [columnorder, setcolumnorder] = useState([]);
  const [columnorderfordetail, setcolumnorderfordetail] = useState([])
  const [totalResult, setTotalResult] = useState(0);
  const query = useQuery();
  const terminalid = query.get("terminal_id");
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [sortData, setSortData] = useState([{ field: "Trailer_ID", dir: "asc" }]);
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [allTerminals, setAllTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [selectedTrailerDetails, setSelectedTrailerDetails] = useState();
  const [oldTerminalDetails, setOldTerminalDetails] = useState({});
  const [searchTerms, setSearchTerms] = useState(JSON.parse(localStorage.getItem('userpreferenceTrailerstab'))?.filter || [{
    "field": "Terminal",
    "operator": "contains",
    "value": terminalid
  }]);
  const [SelectedCommodity, setSelectedCommodity] = useState(null);

  const locales = [{
    language: 'en-US',
    locale: 'en'
  }, {
    language: 'es-ES',
    locale: 'es'
  }];

  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: JSON.parse(localStorage.getItem('userpreferenceTrailerstab'))?.filter || [{
        "field": "Terminal",
        "operator": "contains",
        "value": terminalid
      }],
    },
    sort: sortData,
  });

  let columns = [
    { id: 0, name: "Status", title: "Status" },
    { id: 1, name: "Trailer_ID", title: "Trailer ID" },
    { id: 2, name: "Commodity_Group", title: "Commodity Group" },
    { id: 3, name: "Terminal", title: "Terminal" },
    { id: 4, name: "Last_Order", title: "Last Order" },
    { id: 5, name: "Last_Commodity", title: "Last Commodity" },
    { id: 6, name: "Last_Product", title: "Last Product" },
    { id: 7, name: "Loads_Since_Last_Wash", title: "Loads Since Last Wash" },
    { id: 8, name: "Loads_to_Next_Wash", title: "Loads to Next Wash" },
    { id: 9, name: "Days_to_Next_Wash", title: "Days to Next Wash" },
    { id: 10, name: "Days_Since_Last_Wash", title: "Days Since Last Wash" },
    { id: 11, name: "Last_Washed", title: "Last Washed" },
    { id: 12, name: "Last_Wash_W/O", title: "Last Wash W/O" },
    { id: 13, name: "PM_Due_Date", title: "PM Due Date" },
    { id: 14, name: "Make", title: "Make" },
    { id: 15, name: "Model", title: "Model" },
    { id: 16, name: "Year", title: "Year" },
    { id: 17, name: "Length", title: "Length" },
    { id: 18, name: "Width", title: "Width" },
    { id: 19, name: "Height", title: "Height" },
    { id: 20, name: "Volume", title: "Volume" },
    { id: 21, name: "Tare_Weight", title: "Tare Weight" },
    { id: 22, name: "Shipper_Pool", title: "Shipper Pool" },
    { id: 23, name: "Dedicated", title: "Dedicated" },
    { id: 24, name: "License_State", title: "License State" },
    { id: 25, name: "License_Plate", title: "License Plate" },
    { id: 26, name: "QR_Code", title: "QR Code" }
  ];

  useEffect(() => {
    let checkCommodity = localStorage.getItem("commodityGroup")
    if (checkCommodity) {
      setSelectedCommodity(checkCommodity);
      localStorage.removeItem("commodityGroup");
    }
    localStorage.removeItem("refreshOrderTab")
    localStorage.removeItem("draggedData");
  }, [])

  const handleStorageListen = (event) => {
    if (event.key === 'commodityGroup') {
      setSelectedCommodity(event.newValue)
      localStorage.removeItem("refreshOrderTab")
      localStorage.removeItem("draggedData");
      localStorage.removeItem("commodityGroup");
    }
  };

  window.addEventListener('storage', handleStorageListen);

  const updateColumnOrder = () => {
		if (userData?.userpreferences?.trailertabsequence && userData?.userpreferences?.trailertabsequence.length > 0) {
			const filteredColumns = userData?.userpreferences?.trailertabsequence.filter((col) => col.name !== "Action");
      setcolumnorder(filteredColumns);
			setcolumnorderfordetail(filteredColumns);
		} else {
			setcolumnorder(columns);
			setcolumnorderfordetail(columns);
		}
	};

	useEffect(() => {
		updateColumnOrder();
	}, [dataResult]);

  const dataStateChange = (event) => {

    const sortTerms = event?.dataState?.sort || [];
    const sorts = sortTerms && sortTerms.length > 0 ? sortTerms.map(({ field, dir }) => ({ field, dir })) : [];
    setSortData(sorts);

    const filters = event?.dataState?.filter?.filters || [];
    const previousCommodityGroup = dataState?.filter?.filters.find((el) => el.field === "Commodity_Group");
    const currentCommodityGroup = filters.find((el) => el.field === "Commodity_Group");
    if (previousCommodityGroup?.value !== currentCommodityGroup?.value) {
      setSelectedCommodity(null);
    }

    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    setSearchTerms(terms);

    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    let payload = {
      filter: filters ? filters.filter((e) => Object.keys(e).length !== 0) : [],
    }
    localStorage.setItem('userpreferenceTrailerstab', JSON.stringify(payload));
  };

  const debouncedSearch = useCallback(
    debounce(async (newsearchTerms, page, size, sort) => {
      setIsDataLoaded(false);

      let arr = newsearchTerms?.map((el) => {
        let obj = {};
        obj[el?.field] = el?.value
        obj.operator = el?.operator;
        return obj
      });

      try {
        const search = await getTrailersTabData(arr, page, size, sort);

        if (search.data.length <= 0) {
          setDataResult(search.data);
          setTotalResult(Number(search?.count[0]?.count));
        }
        if (search.data.length > 0) {
          setDataResult(search.data);
        }
        setTotalResult(Number(search?.count[0]?.count));
        setIsDataLoaded(true);
      }
      catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to fetch trailers tab data', { autoClose: false } );
        }
        setIsDataLoaded(true)
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (SelectedCommodity) {
      const payload = {
        field: "Commodity_Group",
        value: SelectedCommodity,
        operator: "eq"
      }

      let newsearchTerms = [];
      newsearchTerms.push(payload);

      if (searchTerms.length > 0) {
        searchTerms.forEach((el) => {
          if (el.field !== payload.field && el.value !== payload.value && el.operator !== payload.operator) {
            newsearchTerms.push(el);
          }
        })
      }

      setDataState({
        skip: skipNumberOfResult,
        take: takeNumberOfResult,
        filter: {
          logic: "and",
          filters: newsearchTerms
        },
        sort: sortData
      });

      debouncedSearch(newsearchTerms, pageNumber, takeNumberOfResult, sortData);
    } else {
      if (searchTerms.length <= 0) {

        let newsearchTerms = [];
        debouncedSearch(newsearchTerms, pageNumber, takeNumberOfResult, sortData);
      }

      // it will run when there is filter in kendo
      if (searchTerms.length > 0) {
        let searchTerm;
        searchTerm = searchTerms.filter(item => item.value !== "");

        setDataState({
          skip: skipNumberOfResult,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: searchTerm
          },
          sort: sortData
        })
        debouncedSearch(searchTerm, pageNumber, takeNumberOfResult, sortData);
      }
    }

  }, [searchTerms, pageNumber, takeNumberOfResult, sortData, SelectedCommodity]);

  const columnReorderChange = async (event) => {
    const newTrailers = [];
    event.target._columns.forEach((it) => {
      if (it.field !== "expanded" && it.field !== 'Action') {
        newTrailers.push({
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width,
        });
      }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    // If userpreferences has already some data
    if (userData.userpreferences) {
      if (userData.userpreferences.orderstabsequence) {
        payload.userpreferences.orderstabsequence = userData.userpreferences.orderstabsequence;
      }

      // If userpreferences has already trailertabsequence data and we are changing sequence.
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = newTrailers;
      } else {
        // If userpreferences has already some data, but not trailertabsequence data.
        payload.userpreferences.trailertabsequence = newTrailers;
      }

      if (userData.userpreferences.terminalid) {
        payload.userpreferences.terminalid = userData.userpreferences.terminalid;
      }
    } else {
      // If userpreferences has not any data.
      payload.userpreferences.trailertabsequence = newTrailers;
    }

    try {
      setcolumnorderfordetail(newTrailers)
      const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
      await getUserInfoMSGraph(userUniqueId);
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to handle column reorder', { autoClose: false } );
      }
    }
  };

  const expandChange = (event) => {
    let newData = dataResult.map((item) => {
      if (item.Trailer_ID === event.dataItem.Trailer_ID) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDataResult(newData);
  };
  const DetailComponent = props => {
    const dataItem = props.dataItem
    return (
      <div style={{ display: "flex", gap: "0.5em" }} >
        {
          columnorderfordetail?.map((el) => <div style={{ fontWeight: "600", width: "200px" }}>{`${el?.title} : ${dataItem[el?.name] ?? ""}`}</div>)
        }
      </div>
    )
  };

  const handleClear = () => {
    let userPrefrenceData = [{
      "field": "Terminal",
      "operator": "contains",
      "value": terminalid
    }];
    localStorage.removeItem('userpreferenceTrailerstab');
    setSearchTerms(userPrefrenceData)

    setDataState({
      skip: 0,
      take: 20,
      filter: {
        logic: "and",
        filters: userPrefrenceData,
      },
      sort: sortData
    });

    setSortData([]);
    debouncedSearch(userPrefrenceData, pageNumber, takeNumberOfResult, []);
  };

  useEffect(() => {
    (async () => {
      try {
        const allTerminalsData = await getAllTerminals();
        if (allTerminalsData.length > 0) {
          setAllTerminals(allTerminalsData);
        }
      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to get all terminals', { autoClose: false } );
        }
      }
    })();
  }, []);


  // Terminal change handler
  const terminalModalShowHandler = async (props) => {
    if (allTerminals.length > 0) {
      const filterData = allTerminals.find((data) => data.terminal_id === props.terminal_id);
      setSelectedTerminal(filterData);
      setOldTerminalDetails(props);

      // Storing order data
      setSelectedTrailerDetails(props);
      setShowTerminalModal(true);
    }
  };

  const handleTerminalChange = (e, value) => {
    setSelectedTerminal(value);
  }

  // For change button
  const terminalSaveHandler = async () => {
    try {
      const payload = {
        terminal_id: selectedTerminal?.terminal_id,
        trailer_id: selectedTrailerDetails?.Trailer_ID,
        isOrderTab: false
      };

      const response = await upadteTerminalofOrderOrTrailer(payload);

      if (response.status === 200) {
        toast.success("Terminal changed successfully.");
        setShowTerminalModal(false);
        debouncedSearch(searchTerms, pageNumber, takeNumberOfResult, sortData);

        const payload = {
          text: `Trailer Id - ${selectedTrailerDetails?.Trailer_ID} transferred from Terminal Id - ${oldTerminalDetails?.terminal_id} to Terminal Id - ${selectedTerminal?.terminal_id}`,
          // lm_order_id: selectedTrailerDetails?.Order_ID,
          // load_id: selectedTrailerDetails?.Move_ID,
          // shipper: selectedTrailerDetails.Pickup_Name.split("-")[0],
          // consignee: selectedTrailerDetails.Delivery_Name.split("-")[0],
          alert_type: "Transferred Trailers",
          terminal_id: selectedTerminal?.terminal_id,
          // commodity: selectedTrailerDetails?.['Commodity Code'],
          commodity_group_description: selectedTrailerDetails?.Commodity_Group,
          created_on: DateTime.now().toMillis(),
          created_by: userData?.email?.split('@')[0],
          updated_on: DateTime.now().toMillis(),
          updated_by: userData?.email?.split('@')[0],
          // isdismissed: "", // boolean
          // is_dismissable: "", // boolean
          // status: "",
          // alert_comments: "",
          // reason_code_id: "",
        };

        const alertResponse = await createBpaAlert(payload);

        if (alertResponse.status === 201) {
          const data = alertResponse?.data?.data;

          let payload = {
            title: `Trailer Id - ${selectedTrailerDetails?.Trailer_ID} Transferred`,
            body: `Trailer Id - ${selectedTrailerDetails?.Trailer_ID} transferred from Terminal Id - ${oldTerminalDetails?.terminal_id} to Terminal Id - ${selectedTerminal?.terminal_id}`,
            url: `/alerts?type=transferred_trailers&id=${data?.id}`,
            topic: `Terminal-${selectedTerminal?.terminal_id}`
          };

          await sendPushNotification(payload);
          debouncedSearch(searchTerms, pageNumber, takeNumberOfResult, sortData);
        }

      }
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to save terminal', { autoClose: false } );
      }
      debouncedSearch(searchTerms, pageNumber, takeNumberOfResult, sortData);
    }
  };

  const terminalModalHideHandler = () => {
    setShowTerminalModal(false);
  };

  const handleRefresh = () => {
    localStorage.removeItem("refreshOrderTab")
    localStorage.removeItem("draggedData");
    debouncedSearch(searchTerms, pageNumber, takeNumberOfResult, sortData);
  }

  return (
    <div id="wrapper">
     <Navbar expand="lg" variant="light" bg="light" className="navbar-custom">
        <div className="container-fluid">	
          <div className="logo_logout_wrapper">	
            <div className="logo-box df">	
              <img	
                className={"back_icon hide_left_icon"}	
              />	
              <p className="logo_font">	
                <Link to="/planner">BULKMATIC TRANSPORT</Link>	
              </p>	
            </div>	
            <div className="signoutsection">	
              <button	
                type="button"	
                onClick={() => window.close()}	
                className="btn_signout"	
              >	
                <i className="fa fa-sign-out log_out_icon"></i>Close Window
              </button>	
            </div>	
          </div>	

          <div className="clearfix"></div>	
        </div>	
      </Navbar>	

      <div className="row df  adjustrow mt_30">	
        <div className="col-xl-12">	
          <div className="card special_bg">	
            <div className="card-body">	
              <div className="row top_adjust">	
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>	
                  <div className="col-md-2">	
                    <h2 className="text-light">Trailers</h2>	
                  </div>	
                  <div className="tab_inside_section_top_right">	
                  </div>	
                </div>	

              </div>	
            </div>	
          </div>	
        </div>	
      </div>
      <div className="row adjustrow1">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body ">
              <div className="table-responsive">
                {!isDataLoaded && (
                  <div className="loading-overlay active" style={{ fontSize: "18px", top: "70px", height: "80%" }}>
                    <i className="fa fa-spinner fa-spin spinner-icon"></i>Loading...
                  </div>
                )}
                <div className="tab_inside_section">
                  <div className="tab_inside_section_top">
                    <div className="tab_inside_section_top_left">
                    </div>
                    <div className="tab_inside_section_top_right">
                      <div className='ordertabdropdown'>
                        <button
                          title="Export Excel"
                          className='btn_white_sm ml_10'
                          onClick={exportExport}
                        >
                          Export to Excel
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn_white_sm ml_10 '
                          onClick={handleClear}
                        >
                          Clear
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn_white_sm ml_10'
                          onClick={handleRefresh}
                        >
                          Refresh
                        </button>

                      </div>
                    </div>
                  </div>
                  <div className="tab_content without-scroll">
                    <LocalizationProvider language={currentLocale.language}>
                      <IntlProvider locale={currentLocale.locale}>
                        <ExcelExport
                          ref={_export}
                        >
                          {
                            columnorderfordetail?.map((it) =>
                              <ExcelExportColumn
                                key={it.id}
                                field={it.name}
                                title={it.title}
                                width="200px"
                              />
                            )
                          }
                        </ExcelExport>
                        <Grid
                          detail={DetailComponent}
                          filter={dataState.filter}
                          filterable={true}
                          groupable={true}
                          reorderable={true}
                          filterOperators={filterOperators}
                          expandField="expanded"
                          sort={dataState.sort}
                          sortable={true}
                          pageable={{
                            pageSizes: [25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10,
                          }}

                          resizable={true}
                          skip={dataState.skip}
                          take={dataState.take}
                          data={dataResult}
                          onDataStateChange={dataStateChange}
                          onColumnReorder={columnReorderChange}
                          rowRender={getRowDraggable(
                            (e, dataItem) => {
                              localStorage.setItem('draggedData', JSON.stringify({
                                dragFrom: "trailers",
                                dragDataItem: dataItem
                              }));
                            },
                            (e, dataItem) => {
                              localStorage.removeItem("draggedData");
                              localStorage.removeItem("refreshOrderTab");
                            }
                          )}
                          onExpandChange={expandChange}
                          total={totalResult}
                        >
                          <GridColumn
                            field="Action"
                            title="Action"
                            width="150px"
                            reorderable={false}
                            sortable={false}
                            filterable={false}
                            cell={(e) => {
                              return (
                                <td style={{ textAlign: "center" }}>
                                  <EditIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={(i) => terminalModalShowHandler(e.dataItem)}
                                  />
                                </td>
                              );
                            }}
                          />
                          {
                            columnorder.map((it) => {
                              if (it.name == 'Trailer_ID') {
                                return <GridColumn
                                  key={it.id}
                                  field={it.name}
                                  title={it.title}
                                  width="200px"
                                  cell={(e) => {
                                    return (
                                      <td>
                                        <a href={`${window.location.origin + '/trailers' + '?' + 'trailerId' + '=' + e.dataItem[e.field]}`} target='_blank' style={{ textDecoration: "underline" }}>{e.dataItem[e.field]}</a>
                                      </td>
                                    );
                                  }}
                                />
                              } else {
                                return <GridColumn
                                  key={it.id}
                                  field={it.name}
                                  title={it.title}
                                  width="200px"
                                />
                              }
                            }
                            )
                          }
                        </Grid>
                      </IntlProvider>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showTerminalModal}
        onHide={() => terminalModalHideHandler()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter margin-top-bottom" className='text-bold'>
            Change Terminal Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='meterial_autocomplete'>
            <p >Select Terminal:</p>
            <Autocomplete
              id="combo-box-demo"
              options={allTerminals}
              getOptionLabel={(option) => `${option.full_terminal_name}`}
              value={selectedTerminal}
              onChange={handleTerminalChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose a Terminal..."
                  variant="outlined"
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn_blue btn-blue" onClick={() => terminalSaveHandler()}>Change</Button>
          <Button className="btn_blue btn-blue" onClick={() => terminalModalHideHandler()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrailersPage;
