import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import PlanningProfileHeader from "../../components/planningProfileHeader/planningProfileHeader";
import PlanningProfileList from "../../components/planningProfileList/planningProfileList";
import ProfileHeader from "../../components/profileheader/profileheader";
import PlanningprofileByIdheader from "../../components/planningprofileByIdheader/planningprofileByIdheader";
import Planningtab from "../../components/planningProfileTable/planningtab";
import PlanningprofileUsers from "../../components/planningProfileTable/planningprofileusers";




function PlanningProfile() {
  const [profileClicked, setprofileClicked] = useState(false);
  const [profileById, setprofileById] = useState({});
  const [headerTabName, setheaderTabName] = useState("planning");
  const handelcallbackFromHeader = (childdata) => {
    setprofileClicked(childdata);
    setheaderTabName("planning");
  };
  const handelcallback = (childdata, profiledata) => {
    setprofileById(profiledata);
    setprofileClicked(childdata);
  };

  const handelcallbackFromLocationHeader = (childdata) => {
    setheaderTabName(childdata);
  };
  return (

    <div id="wrapper">

      <Header
       userclicked={profileClicked}
       parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
    {/* <PlanningProfileHeader /> */}
    {!profileClicked ? (
              <>
               {/* <PlanningProfileHeader /> */}
               <ProfileHeader
                parentcallback={handelcallback}
                profileById={profileById}
               />
               <PlanningProfileList
                parentcallback={handelcallback}
               />
               
              </>
            ) : (
              <>
              <PlanningprofileByIdheader
             profileById={profileById}
             parentcallback={handelcallbackFromLocationHeader}

              />
             
                {headerTabName === "planning" ? (
                  <Planningtab profileById={profileById} />
                ) : (
                 <PlanningprofileUsers profileById={profileById}/>
                )}
              </>
            )}
    </div>
    </div>

    </div>
    </div>

  );

}
export default PlanningProfile;