import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles, } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/lab";
import { Button, Modal } from "react-bootstrap";
import { ContextData } from "../appsession";
import { ToastContainer, toast } from "react-toastify";
import { DateTime } from "luxon";
import { allDriverpayRecords, allMovementDetailsForEdit, getMovmentById, updateDriverPayStatus,driverPayApproveSearch } from "../../services/driverPayServer";
import { BaseConfig } from "../../baseConfig";
import DriverPayModalEditApproval from "./DriverPayModalEditApproval";
import debounce from 'lodash/debounce';
import AuditLogModalComponent from "./auditLogModal";
import filterOperators from "../../utils/filterOperators";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const DriverPayTable = (props) => {
  const { driverPayData, isLoading, tabSelected, setDriverPayData, canApprove, selectedTerminal, canEditDriverPay, selectedTerminalIDs,refresh } = props;
  const [modalShow, setModalShow] = useState(false);
  const [userData, setuserData] = useContext(ContextData);
  const [approvalData, setApprovalData] = useState({});
  const [itemSelected, setitemSelected] = useState("");
  const [DriverPay, setDriverPay] = useState(false);
  const [isrejected, setisrejected] = useState();
  const [isrejectedapproval, setisrejectedaproval] = useState();
  const [isInfoModalShow, setIsInfoModalShow] = useState(false);
  const [imageSrc, setimageSrc] = useState('');
  const [pageLoading, setPageLoading] = useState(isLoading);
  const [approvedData, setApprovedData] = useState({});
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  
  const [payUomEditable, setPayUomEditable] = useState(
    approvalData?.driverpaybase?.pay_uom_editable
  );
  const [payRateEditable, setPayRateEditable] = useState(
    approvalData?.driverpaybase?.pay_rate_editable
  );
  const [payUomValue, setPayUomValue] = useState("");
  const [payRateValue, setPayRateValue] = useState("");
  const [unitsValue, setUnitsValue] = useState("");
  const [isPayUomEdit, setIsPayUomEdit] = useState(false);
  const [isPayRateEdit, setIsPayRateEdit] = useState(false);
  const [isUnitsEdit, setIsUnitsEdit] = useState(false);
  const [amountValue, setAmountValue] = useState(approvalData?.amount || 0);
  const [originalAmountValue, setOriginalAmountValue] = useState(approvalData?.amount || 0);

  const [confirmModal, setConfirmModal] = useState(false);
  const [isFormTouched,setFormTouched]=useState(false);

  // movement state
  const [showMovementModal, setshowMovementModal] = useState(false);
  const [isdataMovementLoading, setisdataMovementLoading] = useState(false);

  const [origin, setOrigin] = useState({
    actual_arrive_origin: null,
    actual_depart_origin: null,
    duration_at_origin_stop: null,
    drive_time: null,
    pickuptimezone: null
  });
  const [destination, setDestination] = useState({
    actual_arrive_destination: null,
    actual_depart_destination: null,
    duration_at_destination_stop: null,
    total_time: null,
    deliverytimezone: null
  });

  const [orderId, setOrderId] = useState("");
  const [movementId, Setmovementid] = useState("");
  const [MovementData, setMovementData] = useState([]);
  const [movemenatEdiatable, setmovementEditable] = useState(true);

  const [isPayinfoedited, setIsPayinfoedited] = useState(false);
  const [ismovementEdited, setidMovementEdited] = useState(false);
  const [ismovementRequired, setisMovementrequired] = useState(true);
  const [isEditedbuttonclicked, setisEditedbuttonclicked] = useState(false)
  const [isUpadted, setisUpdated] = useState(false);
  const [isSaveDisbaled, setisSaveDisbaled] = useState(false);

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0)
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerms, setSearchTerms] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [statusCode,setStatusCode] = useState("");
  const [sortData,setSortData] = useState([]);

  

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "created_on",
        dir: "asc",
      },
      {
        field: "driverFullName",
        dir: "asc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState([]);

  const fieldsToUpdate = [
    "transaction_date",
    "pay_date",
    "authorized_on",
    "approved_on",
    "transfer_on",
    "effective_date",
    "created_on",
    "updated_on"
  ];
  const fieldsToField = [
    "id",
    "driver_full_name",
    "pay_code",
    "pay_uom",
    "units",
    "pay_rate",
    "units",
    "authorized_by",
    "approved_by",
    "order_id",
    "amount"
  ];
  const clearState = () => {
    setPayUomValue("");
    setUnitsValue("");
    setPayRateValue("");
    setAmountValue("")
  };

  useEffect(()=>{
    if (tabSelected.need_approval){
      setStatusCode("Pending Approval")
    }else{
      setStatusCode("Rejected")
    }
  },[tabSelected])

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      textAlign: "center!important",
      fontWeight: "bold",
      fontSize: 15
    },
  }));

   // this function runs on every filter chnage in kendo table 
   const dataStateChange = useCallback((event) => {
    let hasUpdatedOn = false;
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
  }, []);

 // this is api calling for approved data 
  const debouncedSearchForFilters = useCallback(
    debounce(async (searchTerms, sortData, page, size, terminalName = selectedTerminalIDs, statusName = statusCode) => {
      setPageLoading(true);
      const search = await driverPayApproveSearch(searchTerms, sortData, page, size, terminalName, statusName);
      if (search.data.data.length <= 0) {
        setDriverPayData(search.data.data);
        setTotalResult(search.data.count);
      }
      setPageLoading(false);
      if (search.data.data.length > 0) {
        console.log("asbsdasdkjadkm",search.data.data)
        setDriverPayData(search.data.data);
      }
      setTotalResult(search.data.count);
    }, 1000),
    [selectedTerminalIDs, statusCode]
  );

  // this useeffect will run on adding something in filter on removing in filter
  useEffect(() => {
    // it will run when there is no filter in kendo
    if (searchTerms.length <= 0 && sortData.length<=0) {
      let newsearchTerms = [];
      let newSort=[];
      debouncedSearchForFilters(newsearchTerms, newSort, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }else if (searchTerms.length <= 0 && sortData.length>0) {
      let newsearchTerms = [];
      debouncedSearchForFilters(newsearchTerms, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }
    // it will run when there is filter in kendo
    else if (searchTerms.length > 0) {
      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
        if (
          fieldsToField.includes(obj.field) &&
          (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
          return false; // remove the object
        }
        return true; // keep the object
      });
      // remove null undefined values of date fields
      searchTerm = searchTerm.filter(obj => {
        if (
          fieldsToUpdate.includes(obj.field) &&
          (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
          return false; // remove the object
        }
        return true; // keep the object
      });
      
      debouncedSearchForFilters(searchTerm, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }
    
  }, [searchTerms, pageNumber, takeNumberOfResult, selectedTerminalIDs, statusCode,sortData]);

 // this useEffect will run on refresh and terminal change
useEffect(() => {
  let newsearchTerms = [];
  let newSort=[];
  debouncedSearchForFilters(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
  // after refresh filter must be clean 
  setDataState({
    skip: 0,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: newsearchTerms
    },
    sort: newSort
  })
}, [refresh, selectedTerminalIDs, statusCode]);


  useEffect(() => {
    setPageLoading(isLoading)
  }, [isLoading]);
  

  useEffect(() => {
    if (driverPayData?.length > 0 && driverPayData[0]?.driver?.driver_id && driverPayData[0]?.driver?.driver_name) {
      const updatedDriverPayData = driverPayData.map(item => {
        item.driverFullName = `${item.driver.driver_id} - ${item.driver.driver_name}`;
        item.payCodeDesc = `${item.pay_code} - ${item.pay_description}`;
        return item;
      });
      // setDriverPayData(updatedDriverPayData);
      setDataResult(updatedDriverPayData, dataState);
    }else{
    setDataResult(driverPayData, dataState);
  }
  }, [driverPayData, selectedTerminalIDs]);

  const convertdatetomilisecond = (duration) => {
    if (duration) {
      let date;
      if (isNaN(duration)) {
        date = Date.parse(duration);
      } else {
        date = parseInt(duration);
      }
      return DateTime.fromMillis(date).toFormat("MM/dd/yyyy").toString();
    } else {
      return "No Data";
    }
  };

  const convertDate=(duration)=>{
    if(duration){
      return DateTime.fromISO(duration).toFormat("MM/dd/yyyy").toString();
    }else{
      return "No Data";
    }
  }

  const convertMillisToString = (duration, tZone) => {
    if (duration) {
      let time = DateTime.fromMillis(parseInt(duration), { zone: "local" }).setZone(tZone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString();
      return time;
    }
    else {
      return "No Data"
    }
  }

  function convertMsToHms(milliseconds) {
    if (milliseconds) {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      seconds = seconds % 60;
      minutes = minutes % 60;

      let hrs = hours > 1 ? `${hours} hrs` : hours === 0 ? "" : `${hours} hr`;
      let mins = minutes > 1 ? `${minutes} mins` : minutes === 0 ? "" : `${minutes} mins`;
      let secs = seconds > 1 ? `${seconds} secs` : seconds === 0 ? "" : `${seconds} secs`;
      return `${hrs} ${mins} ${secs}`;
      // return `${hrs}${hours && minutes ?  "," : ""} ${mins} ${minutes && seconds ? "and" : ""} ${secs}`;
    } else {
      return "No Data";
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (movementid) => {
   
      if (movementid == "" || movementid == null || movementid == undefined) {
        setOrderId("");
        return;
      }
      try {
        const payload = {
          id: movementid
        }
        const response = await getMovmentById(payload)
         let updateddata = []
         if (response.length > 0) {
          setOrderId(response[0].order_id)
          setisSaveDisbaled(false);
          setOrigin({
            actual_arrive_origin: response[0]?.pickup.actual_arrival_utc,
            actual_depart_origin: response[0]?.pickup.actual_departure_utc,
            duration_at_origin_stop: response[0]?.pickup.actual_departure_utc - response[0]?.pickup.actual_arrival_utc,
            drive_time: response[0]?.delivery.actual_arrival_utc && response[0]?.pickup.actual_departure_utc? (response[0]?.delivery.actual_arrival_utc - response[0]?.pickup.actual_departure_utc):0 ,
            pickuptimezone: response[0]?.pickup.pickup_timezone
          });
          setDestination({           
            actual_arrive_destination: response[0]?.delivery.actual_arrival_utc,
            actual_depart_destination: response[0]?.delivery.actual_departure_utc,
            duration_at_destination_stop:response[0]?.delivery.actual_departure_utc- response[0]?.delivery.actual_arrival_utc,
            total_time:(response[0].delivery.actual_departure_utc>0&&response[0].pickup.actual_arrival_utc)?( response[0]?.delivery.actual_departure_utc-response[0].pickup.actual_arrival_utc):0,
            deliverytimezone: response[0]?.delivery.delivery_timezone
          });
        }
        else {
          setOrderId("")
          // setTransactionDate(DateTime.now())
          toast.error("Please Enter Correct Movement Id", "Error");
        }

      }catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error("Failed to debounce search", { autoClose: false } );
        }
      }
    }, 1000),
    []
  );
    
  const movementIdHandler = async (event) => { 
    setFormTouched(true)
    const value = event.target.value ?? '';
    if (value !== '') {
      setisSaveDisbaled(true);
    } else {
      setisSaveDisbaled(false);
    }
    debouncedSearch(event.target.value)
    Setmovementid(event.target.value);

  };

  const handleEditMovement = () => {
    setFormTouched(true)
    setidMovementEdited(true);
  };

  const approvedHandler = async (approved, type) => {
    if (isFormTouched) {
      await handleApproveSave();
    }


    if (type == "approve" && ismovementRequired && !movementId) {
      toast.error("Movement id is required", "Error");
      return;
    }
    if (type == "approve" && Number(amountValue) === 0 || !amountValue) {
      toast.error("Amount should not be zero.", "Error");
      return;
    }
    if (!approved && (!approvalData.approved_comment || approvalData.approved_comment.trim() === '')) {
      return toast.error("Please add a comment before rejecting driver pay.", "Error");
    }

    if (type === "approve") {
      if (Number(amountValue) === 0 || !amountValue) {
        toast.error("Amount should not be zero.", "Error");
        return;
      }
    }

    const approved_comment = type === "reject" ? `Rejected: ${approvalData.approved_comment}` : `Approved: ${approvalData.approved_comment}`;

    const approved_payload = {
      // amount: amountValue,
      approved_comment,
      approved_by: userData?.email?.split('@')[0],
      approved_on: DateTime.now(),
      status: approved ? "Approved" : "Rejected",
      updated_on: DateTime.now(),
      amount: amountValue,
      units: unitsValue,
      pay_uom: payUomValue,
      pay_rate: payRateValue,
      updated_by: userData?.email?.split('@')[0]
    };

    const approval_payload = {
      terminal_id: selectedTerminalIDs,
      status: "Pending Approval"
    };

    try {
      setPageLoading(true);
      setModalShow(false);
      const response = await updateDriverPayStatus(approvalData.id, approved_payload);
      const needApprovalDriverPayResponse = await allDriverpayRecords(approval_payload);
  
      setDriverPayData(needApprovalDriverPayResponse?.data?.data||[]);
      props.parentCallback();
      setisUpdated(true)
      setPageLoading(false);

      if (response.status === 200) {
        toast.success("Driver pay has been updated!", "Success");
      } else {
        toast.error("Failed to approve driver pay", "Error");
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to approve driver pay', { autoClose: false } );
      }
    }
  };
  
  useEffect(() => {
    try {
      if (userData?.roles?.rolespermission) {

        let permissionList = userData?.roles?.rolespermission
        permissionList.forEach(permission => {

          if (permission.permissiondetail.code.match("DriverPay")) {
            if (permission.can_edit === true) {
              setDriverPay(true)
            }
          }
        })

      }
    }
    catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to check permissions', { autoClose: false } );
      }
    }

    setitemSelected(window.location.pathname)
  }, [userData]);


  const rowClickHandler = (item) => {
    setisUpdated(false)
    // if (canViewApprove) {
    if (tabSelected.need_approval) {
      setIsPayinfoedited(false);
      setidMovementEdited(false);
      setApprovalData((prevState) => {
        return { ...prevState, ...item }
      })

      setisMovementrequired(item?.driverpaybase?.movement_required)
      setAmountValue(parseFloat(item.amount))
      setOriginalAmountValue(parseFloat(item.amount))
      setModalShow(true);
    } else if (!tabSelected.need_approval) {
      setApprovedData(item);
      setShowApprovedModal(true)
    }
  }

  const formatDate = (epoch_date, tzone) => {
    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CDT" || tzone === "CST" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }

  }

  const handleCloseModal = () => {
    setShowApprovedModal(false);

    setIsPayUomEdit(false);
    setIsUnitsEdit(false);
    setIsPayRateEdit(false);
    clearState()
    setOrigin({
      actual_arrive_origin: null,
      actual_depart_origin: null,
      duration_at_origin_stop: null,
      drive_time: null,
      deliverytimezone: null
    });
    setDestination({
      actual_arrive_destination: null,
      actual_depart_destination: null,
      duration_at_destination_stop: null,
      total_time: null,
      deliverytimezone: null
    });
    setOrderId("");
    Setmovementid("");
  };

  const handleCloseEditModal = () => {
    setModalShow(true);
    setshowMovementModal(false)
    setMovementData([])
  };

  const calculateAmount = (units, rate, uom) => {
    let amount = 0;
    if (uom === "FLAT") {
      amount = parseFloat(rate);
    } else if (uom === "MILE" || uom === "HOUR") {
      if (units == '' || rate == '') {
        amount = 0
      }
      else {
        amount = parseFloat(units) * parseFloat(rate);
      }
    }
    setAmountValue(amount);
  };

  const authorizeCommentChangeHandler = (e) => {
    setFormTouched(true)
    setApprovalData(prevState => {
      return { ...prevState, approved_comment: e.target.value }
    })
  };

  const viewInfo = (e, data) => {
    if (data.additional_details) {
      setimageSrc(data.additional_details.image_url);
    }

    setIsInfoModalShow(true);
  }

  useEffect(() => {
    let isRejected = approvalData?.authorized_comment?.includes("Declined : ")
    let Rejectedapproval = approvedData?.approved_comment?.includes("Declined : ")
    setisrejectedaproval(Rejectedapproval)
    setisrejected(isRejected)
    setPayUomValue(approvalData?.pay_uom)
    setPayRateValue(approvalData?.pay_rate)
    if (approvalData.pay_uom == "FLAT") {
      setUnitsValue("1")
    }
    else {
      setUnitsValue(approvalData?.units)
    }
    setAmountValue(approvalData?.amount)
    setPayUomEditable(approvalData?.driverpaybase?.pay_uom_editable)
    setPayRateEditable(approvalData?.driverpaybase?.pay_rate_editable)

    if (approvalData?.driverpaybase?.pay_uom_editable === true) {

      setPayUomEditable(true);
    } else if (approvalData?.driverpaybase?.pay_rate_editable === true) {

      setPayRateEditable(true);
    }
    else {
      setPayRateEditable(false);
      setPayUomEditable(false);
    }
    setOrigin({
      actual_arrive_origin: approvalData?.movement?.actual_arrive_origin,
      actual_depart_origin: approvalData?.movement?.actual_depart_origin,
      duration_at_origin_stop: approvalData?.movement?.duration_at_origin_stop,
      drive_time: approvalData?.movement?.drive_time,
      pickuptimezone: approvalData?.movement?.pickuptimezone
    });
    setDestination({
      actual_arrive_destination: approvalData?.movement?.actual_arrive_destination,
      actual_depart_destination: approvalData?.movement?.actual_depart_destination,
      duration_at_destination_stop: approvalData?.movement?.duration_at_destination_stop,
      total_time: approvalData?.movement?.total_time,
      deliverytimezone: approvalData?.movement?.deliverytimezone
    });
    setOrderId(approvalData.order_id)
    Setmovementid(approvalData.movement_id)


  }, [showApprovedModal, approvalData]);

  const handlePayUomChange = (event, newValue) => {
    setFormTouched(true)
    if (newValue == "FLAT") {
      setUnitsValue("1")
    }
    setIsPayinfoedited(true)
    setPayUomValue(newValue);
    calculateAmount(unitsValue, payRateValue, newValue);
  };

  const handlePayRateChange = (event) => {
    setFormTouched(true)
    setIsPayinfoedited(true)
    const newPayRateValue = event.target.value;
    setPayRateValue(newPayRateValue);
    calculateAmount(unitsValue, newPayRateValue, payUomValue);
  };

  const handleUnitsChange = (event) => {
    setFormTouched(true)
    setIsPayinfoedited(true)
    const newUnitsValue = event.target.value;
    setUnitsValue(newUnitsValue);
    calculateAmount(newUnitsValue, payRateValue, payUomValue);
  };

  const handleStatusReject = async (data) => {
    let status_change_payload = {
      updated_on: DateTime.now(),
      status: "Pending Approval",
      approved_by: null,
      approved_comment: null,
      approved_on: null,
      updated_by: userData?.email?.split('@')[0]
    }
    const res = await updateDriverPayStatus(data.id, status_change_payload)

    if (res.status === 200) {
      setShowApprovedModal(false)
      const payload = {
        terminal_id: selectedTerminal,
        status: data?.status
      };
      toast.success("Reverted to Pending Approval ", "Success");
      setPageLoading(true);
      const response = await allDriverpayRecords(payload)
      setConfirmModal(false)
      setDriverPayData(response?.data?.data);
      props.parentCallback();
      setPageLoading(false);

    } else {
      toast.error("Failed to handle status reject", "Error");
    }
  }

  const handleApproveSave = async () => {
    if (Number(amountValue) === 0 || !amountValue) {
      toast.error("Amount should not be zero.", "Error");
      return;
    }
    let payload = {};
    if (isPayinfoedited) {
      payload = {
        amount: amountValue,
        units: unitsValue,
        pay_uom: payUomValue,
        pay_rate: payRateValue
      };
    }
    if (ismovementEdited) {
      payload = {
        movement_id: movementId,
        order_id: orderId,
      };
    }
    if (isPayinfoedited && ismovementEdited) {
      payload = {
        amount: amountValue,
        units: unitsValue,
        pay_uom: payUomValue,
        pay_rate: payRateValue,
        movement_id: movementId,
        order_id: orderId,
        updated_by: userData?.email?.split('@')[0]
      }
    }
    try {
      // setPageLoading(true);
      // setModalShow(false);
      const approval_payload = {
        terminal_id: selectedTerminal,
        status: "Pending Approval"
      };
      const response = await updateDriverPayStatus(
        approvalData.id,
        payload
      );

      const needApprovalDriverPayResponse = await allDriverpayRecords(
        approval_payload
      );

    setDriverPayData(needApprovalDriverPayResponse?.data?.data||[]);
    props.parentCallback();
    // setPageLoading(false);

      if (response.status === 200) {
        toast.success("Driver pay has been updated!", "Success");
        setFormTouched(false);
      } else {
        toast.error("Failed to handle approve save", "Error");
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to handle approve save', { autoClose: false } );
      }
    }
    setidMovementEdited(false);
    setIsPayinfoedited(false);
  };

  const chooseMovementIdHandler = async () => {
    setFormTouched(true)
    setisEditedbuttonclicked(true);
    setUnitsValue(unitsValue)
    setAmountValue(amountValue);
    setPayUomValue(payUomValue);
    setPayRateValue(payRateValue)
    setModalShow(false);
    setshowMovementModal(true)
    // api calling with driverid
    setisdataMovementLoading(true)
    let response = await allMovementDetailsForEdit(approvalData?.driver_id);
    let updateddata = []
    let responses = response.data.data
    for (let i = 0; i < responses.length; i++) {
      let item = responses[i];
      item.origin = `${item.pickup.origin_location}`;
      item.bol1 = item?.order?.BOL;
      item.order_type_id = item?.order?.order_type_id;
      item.destination = `${item.delivery.origin_location}`;
      item.origin_actual_arrival = formatDate(item.actual_arrive_origin, item.pickuptimezone)
      item.origin_actual_depart = formatDate(item.actual_depart_origin, item.pickuptimezone)
      item.destination_actual_arrival = formatDate(item.actual_arrive_destination, item.deliverytimezone)
      item.destination_actual_depart = formatDate(item.actual_depart_destination, item.deliverytimezone)
      updateddata.push(item)
    }

    setMovementData(updateddata)
    setisdataMovementLoading(false)
    // const response;
  }

  // Create a ref to hold the child component instance
  const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay");
  };

  return (
    <>
      <div className='table-responsive'>
        {!pageLoading ? (driverPayData && driverPayData.length >= 0 ? (
          <Grid
          filter={dataState.filter}
          filterable={true}
          sortable={true}
          sort={dataState.sort}
          filterOperators={filterOperators}
          expandField="expanded"
          pageable={{
              pageSizes: [25, 50, 100],
              info: true,
              previousNext: true,
              buttonCount: 10,
          }}
          total={totalResult}
          resizable={true}
          skip={dataState.skip}
          take={dataState.take}
          data={dataResult}
          onDataStateChange={dataStateChange}
          onRowClick={(e) => rowClickHandler(e.dataItem)}
          >
            <GridColumn
              field='id'
              title='Id'
              filterable={true}
              filter="text"
              width="200px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.id ? (e.dataItem.id) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field="terminal_fullName"
              title='Terminal'
              filterable={true}
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.terminal.terminal_fullName ? e.dataItem.terminal.terminal_fullName : "No Data"}
                  </td>
                );

              }}
            />

            <GridColumn
              field='driver_full_name'
              title='Driver'
              filterable={true}
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {`${e.dataItem.driver_id ? e.dataItem.driver_id : "No Data"} - ${e.dataItem.driver.driver_name ? e.dataItem.driver.driver_name : "No Data"}`}
                  </td>
                );
              }}
            />
            <GridColumn
              field='pay_code'
              title='Pay Code'
              width="220px"
              filterable={true}
              cell={(e) => {
                const payCodeDesc = `${e.dataItem.pay_code ? e.dataItem.pay_code : "No Data"} - ${e.dataItem.pay_description ? e.dataItem.pay_description.toUpperCase() : "No Data"}`
                return (
                  <td>
                    {payCodeDesc}
                  </td>
                );
              }}
            />
            <GridColumn
              field='driver_comment'
              title='Driver Comment'
              width="220px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.driver_comment ? e.dataItem.driver_comment : "No Data"}
                  </td>
                );

              }}
            />
            <GridColumn
              field='pay_uom'
              title='Unit of Measure'
              width="150px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.pay_uom ? e.dataItem.pay_uom : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='units'
              title='Units'
              filterable={true}
              width="150px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.units ? Number(e.dataItem.units).toFixed(2) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='pay_rate'
              title='Rate'
              width="150px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.pay_rate ? parseFloat(e.dataItem.pay_rate).toFixed(2) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='amount'
              title='Amount'
              filterable={true}
              width={"150px"}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.amount ? parseFloat(e.dataItem.amount).toFixed(2) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='transaction_date'
              title='Transaction Date'
              filter='date'
              format="{0:d}"
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.transaction_date ? convertDate(e.dataItem.transaction_date) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='order_id'
              title='Order ID'
              width="150px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.order_id ? e.dataItem.order_id : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field='movement_id'
              title='Movement ID'
              width="150px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.movement_id ? e.dataItem.movement_id : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field='transfer_on'
              title='Transferred Date'
              width="250px"
              filter='date'
              format="{0:d}"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.transfer_on ? convertdatetomilisecond(e.dataItem.transfer_on) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='pay_date'
              title='Pay Date'
              filter='date'
              format="{0:d}"
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.pay_date ? convertDate(e.dataItem.pay_date) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='approved_on'
              title='Approved Date'
              filter='date'
              format="{0:d}"
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.approved_on ? convertdatetomilisecond(e.dataItem.approved_on) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='approved_by'
              title='Approved By'
              width="250px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.approved_by ? e.dataItem.approved_by : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='authorized_on'
              title='Authorized Date'
              width="250px"
              filter='date'
              format="{0:d}"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.authorized_on ? convertdatetomilisecond(e.dataItem.authorized_on) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='authorized_by'
              title='Authorized By'
              filterable={true}
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.authorized_by ? e.dataItem.authorized_by : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='created_on'
              title='Created On'
              filter='date'
              format="{0:d}"
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.created_on ? convertdatetomilisecond(e.dataItem.created_on) : "No Data"}
                  </td>
                );
              }}
            />
            <GridColumn
              field='created_by'
              title='Created By'
              width="250px"
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.created_by ? e.dataItem.created_by : "No Data"}
                  </td>
                );
              }}
              
            />
               <GridColumn
                field='Action'
                title='Action'
                width='150px'
                cell={(e) => {
                  return (
                    <td>
                      <button
                        type="button"
                        className="btn-blue_schedule "
                        onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                      >
                        Audit Log
                      </button>
                    </td>
                  );
                }}
              />
        
          </Grid>) : (
          <div className='text-center'>No data found</div>
        )) : (
          <div>
            <ProgressBar animated now={100} />
            <div className='middle loader--text1'> </div>
          </div>
        )}

      </div>

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          clearState()
        }}
        dialogClassName="Authorize_Driver_Pay_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onClose={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Approve Driver Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="authorize_pay">
            <div className="driverpay_info_general">
              <div className="driverpay_info_genera_right">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Id :</span>
                  <span className="general_info_value">{approvalData.id ? approvalData.id : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Driver :</span>
                  <span className="general_info_value">{approvalData.driver_id ? approvalData.driver_id + " - " + approvalData.driver.driver_name : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created On :</span>
                  <span className="general_info_value">{approvalData.created_on ? DateTime.fromMillis(approvalData.created_on).toFormat("MM-dd-yyyy hh:mm") : "No Data"}</span>
                </p>

              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Terminal :</span>
                  <span className="general_info_value">{`${approvalData?.terminal?.terminal_fullName}`}</span>
                </p>

                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created By :</span>
                  <span className="general_info_value">{approvalData?.created_by ? approvalData.created_by : "No Data"}</span>
                </p>

              </div>

            </div>

            <div className="driverpay_info_move">
              <div className="move_info_header">
                <p>Pay Info</p>
              </div>
              <div className="move_info_content">
                <p className="move_info_item">
                  <span className="general_info_label">Pay Description:</span>
                  <span className="general_info_value">{approvalData.pay_code ? approvalData.pay_code  : "No Data"}</span>

                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Status :</span>
                  <span className="general_info_value">{approvalData.status}</span>
                </p>
                <p className="move_info_item1">
                  <span className="general_info_label">Units:</span>
                  {(payUomEditable && canEditDriverPay) ? (
                    <div className="meterial_autocomplete11 meterial_autocomplete">
                      <TextField
                        id="outlined-number"
                        type="number"
                        variant="outlined"
                        value={unitsValue}
                        disabled={!payUomEditable && !canEditDriverPay || payUomValue == "FLAT"}
                        onChange={handleUnitsChange}
                        style={{ width: '100px' }}
                      />
                    </div>
                  ) :
                    <>
                      <span className="general_info_value">{unitsValue}</span>
                    </>
                  }
                </p>
                <p className="move_info_item1">
                  <span className="general_info_label">Units Of Measure:</span>
                  {(payUomEditable && canEditDriverPay) ? (
                    <div className="meterial_autocomplete2">
                      <Autocomplete
                        value={payUomValue}
                        options={["FLAT", "MILE", "HOUR"]}
                        onChange={handlePayUomChange}
                        disabled={!payUomEditable && !canEditDriverPay}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: '100px' }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <span className="general_info_value">{payUomValue}</span>
                  )}
                </p>

                <p className="move_info_item1">
                  <span className="general_info_label">Rate :</span>
                  <div className="meterial_autocomplete11 meterial_autocomplete">
                    {(payRateEditable && canEditDriverPay) ?
                      (
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          value={payRateValue}
                          disabled={!payRateEditable && !canEditDriverPay}
                          onChange={handlePayRateChange}
                          style={{ width: '100px' }}
                        />
                      ) :
                      <>
                        <span className="general_info_value">{payRateValue}</span>
                      </>
                    }
                  </div>
                </p>
                <p className="move_info_item1">
                  <span className="general_info_label">Amount :</span>
                  <span className="general_info_value">{amountValue ? Number(amountValue).toFixed(2) : 0}</span>
                </p>
                {
                  approvalData?.additional_details?.image_url &&
                  <p className="move_info_item view_image" onClick={(e) => viewInfo(e, approvalData)}>View Image</p>
                }
              </div>

            </div>
            <div className="driverpay_info_move">
              <div className="move_info_header"><p>Move Info</p></div>
              <div className="move_info_content">
                <p className="move_info_item">
                  <span className="general_info_label">Order:</span>
                  <span className="general_info_value">{orderId ? orderId : "No Data"}</span>
                </p>

                <p className="move_info_item1">
                  <span className="general_info_label">Movement:</span>
                  <div className="meterial_autocomplete11 meterial_autocomplete">
                    {!ismovementEdited && <span className="general_info_value">{movementId ? movementId : 'No Data'}</span>}

                    {ismovementEdited &&
                      (
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"

                          // disabled={!payRateEditable && !canEditDriverPay}
                          value={movementId}
                          onChange={movementIdHandler}
                          style={{ width: '120px' }}
                        />
                      ) 
                    }

                  </div>
                  {ismovementEdited && <Button 
                  className="btn-blue_schedule"
                    onClick={chooseMovementIdHandler}
                    style={{
                      marginLeft: '10px',

                    }}
                  >
                    Choose Movement
                  </Button>}
                  {!ismovementEdited && <span className="general_info_label">
                    <Button
                    className="btn-blue_schedule"
                      onClick={handleEditMovement}
                      style={{
                        marginLeft: '17px',
                        width: '87%'
                      }}
                      disabled={!canApprove}
                    >
                      Edit
                    </Button>
                  </span>}
                </p>
                <div className="origin_destination_section">

                  <div className="origin_destination_section_text">
                    Origin
                    <div className="line"></div>
                  </div>

                  <div className="origin_destination_section_text">
                    Destination
                    <div className="line"></div>
                  </div>
                </div>
                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{approvalData?.movement?.pickup?.stop_type ? approvalData?.movement?.pickup?.stop_type:'No Data'}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{approvalData?.movement?.delivery?.stop_type ? approvalData?.movement?.delivery?.stop_type:'No Data'}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {approvalData?.movement?.pickup?.location?.code ?
                  <span className="general_info_value">
                    {approvalData?.movement?.pickup?.location?.code ?
                      (`${approvalData?.movement?.pickup?.location?.code ? `${approvalData?.movement?.pickup?.location?.code} -` : ""}  ${approvalData?.movement?.pickup?.location?.location_name ? approvalData?.movement?.pickup?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {approvalData?.movement?.pickup?.location?.address1 ?
                      (`${approvalData?.movement?.pickup?.location?.address1 ? `${approvalData?.movement?.pickup?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {approvalData?.movement?.pickup?.location?.city ?
                      (`${approvalData?.movement?.pickup?.location?.city ? `${approvalData?.movement?.pickup?.location?.city},` : ""} ${approvalData?.movement?.pickup?.location?.state ? `${approvalData?.movement?.pickup?.location?.state},` : ""} ${approvalData?.movement?.pickup?.location?.zip ? `${approvalData?.movement?.pickup?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {approvalData?.movement?.delivery?.location?.code ?
                  <span className="general_info_value">
                    {approvalData?.movement?.delivery?.location?.code ?
                      (`${approvalData?.movement?.delivery?.location?.code ? `${approvalData?.movement?.delivery?.location?.code} -` : ""}  ${approvalData?.movement?.delivery?.location?.location_name ? approvalData?.movement?.delivery?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {approvalData?.movement?.delivery?.location?.address1 ?
                      (`${approvalData?.movement?.delivery?.location?.address1 ? `${approvalData?.movement?.delivery?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {approvalData?.movement?.delivery?.location?.city ?
                      (`${approvalData?.movement?.delivery?.location?.city ? `${approvalData?.movement?.delivery?.location?.city},` : ""} ${approvalData?.movement?.delivery?.location?.state ? `${approvalData?.movement?.delivery?.location?.state},` : ""} ${approvalData?.movement?.delivery?.location?.zip ? `${approvalData?.movement?.delivery?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{formatDate(origin?.actual_arrive_origin, origin?.pickuptimezone)}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{formatDate(destination?.actual_arrive_destination, destination?.deliverytimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{formatDate(origin?.actual_depart_origin, origin?.pickuptimezone)}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{formatDate(destination?.actual_depart_destination, destination?.deliverytimezone)}</span>
                </p>


                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(origin?.duration_at_origin_stop))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(destination?.duration_at_destination_stop))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Drive Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(origin?.drive_time))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Total Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(destination?.total_time))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Samsara Link:</span>
                  <a href={`${BaseConfig.SamsaraDriverPayLink}${approvalData?.driver?.samsara_id}`} target="_blank">
                    <i className="fa fa-external-link"></i>{" "} <u className="external_link">View Samsara HOS Details</u>
                  </a>
                </p>
              </div>
            </div>

            {approvalData?.additional_details?.formatted ?
              <>
                <div className="driverpay_info_move">
                  <div className="move_info_header"><p>Macro Info</p></div>
                  <div className="move_info_content">
                    {Object.entries(approvalData?.additional_details?.formatted).map(([label, value]) => (
                      <p className="move_info_item" key={label}>
                        <span className="general_info_label">{label}:</span>
                        <span className="general_info_value">{value}</span>
                      </p>
                    ))}
                  </div>
                </div>
              </> : <></>
            }

            <div className="driverpay_info_move">
              <div className="move_info_header2">
                <p>Authorization Information</p>
              </div>
              <div className="move_info_content">
                <div className="driverpay_info_genera_left">
                  <p className="move_info_item2">
                    <span className="general_info_label">{isrejected ? "Declined On" : "Authorized On"} :</span>
                    <span className="general_info_value">{convertdatetomilisecond(approvalData.authorized_on)}</span>
                  </p>
                  <p className="move_info_item2">
                    <span className="general_info_label">{isrejected ? "Declined Comment" : "Authorized Comment"} :</span>
                    <span className="general_info_value">{approvalData?.authorized_comment?.replace('Rejected authorize', '')?.replace('Accepted authorize', '')}</span>
                  </p>
                </div>
                <div className="driverpay_info_genera_right">
                  <p className="move_info_item2" >
                    <span className="general_info_label">{isrejected ? "Declined By" : "Authorized By"} :</span>
                    <span className="general_info_value">{approvalData.authorized_by}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="driverpay_authorize">
              <label htmlFor="authorize_comment" className="authorize_label">Comments</label>
              <textarea id="authorize_comment" className="authorize_comment" rows={5} onChange={authorizeCommentChangeHandler} disabled={!canApprove}></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={!isFormTouched || !canApprove} onClick={handleApproveSave}>
            Save
          </Button>
          <Button variant="primary" disabled={!canApprove} onClick={() => approvedHandler(true, "approve")}>
            Approve
          </Button>
          <Button variant="primary" disabled={!canApprove} onClick={() => approvedHandler(false, "reject")}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isInfoModalShow}
        onHide={() => {
          setIsInfoModalShow(false)
          setimageSrc('')
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className="info_modal">
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="driverpay_info_general">
                <div className="imageDiv">
                  <img src={imageSrc} />
                </div>
              </div>

            </>
          </Modal.Body>
        </div>
        <Modal.Footer>

          <Button variant="primary" onClick={() => {
            setIsInfoModalShow(false)
            setimageSrc('')
          }}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>

      <Modal
        show={showApprovedModal}
        onHide={() => setShowApprovedModal(false)}
        dialogClassName="Authorize_Driver_Pay_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Driver Pay Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="authorize_pay">
            <div className="driverpay_info_general">

              <div className="driverpay_info_genera_right">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Id :</span>
                  <span className="general_info_value">{approvedData.id ? approvedData.id : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Driver :</span>
                  <span className="general_info_value">{approvedData.driver_id ? approvedData.driver_id + " - " + approvedData.driver.driver_name : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created On :</span>
                  <span className="general_info_value">{approvedData?.created_on ? DateTime.fromMillis(approvedData.created_on).toFormat("MM-dd-yyyy hh:mm") : "No Data"}</span>
                </p>

              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Terminal:</span>
                  <span className="general_info_value">{`${approvedData?.terminal?.terminal_fullName}`}</span>
                </p>

                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created By :</span>
                  <span className="general_info_value">{approvedData?.created_by ? approvedData.created_by : "No Data"}</span>
                </p>

              </div>

            </div>
            <div className="driverpay_info_move">
              <div className="move_info_header"><p>Pay Info</p></div>
              <div className="move_info_content">

                <p className="move_info_item">
                  <span className="general_info_label">Pay Description:</span>
                  <span className="general_info_value">{approvedData.pay_code ? approvedData.pay_code  : "No Data"}</span>

                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Status:</span>
                  <span className="general_info_value">{approvedData.status}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Units:</span>
                  <span className="general_info_value">{approvedData.units ? `${approvedData.units}` : "No Data"}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Units Of Measure:</span>
                  <span className="general_info_value">No Data</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Rate :</span>
                  <span className="general_info_value">{parseInt(approvedData.pay_rate)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Amount :</span>
                  <span className="general_info_value">{Number(approvedData.amount).toFixed(2)}</span>
                </p>
                {
                  approvedData?.additional_details?.image_url &&
                  <p className="move_info_item view_image" onClick={(e) => viewInfo(e, approvedData)}>View Image</p>
                }

              </div>
            </div>
            <div className="driverpay_info_move">
              <div className="move_info_header"><p>Move Info</p></div>
              <div className="move_info_content">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Order:</span>
                  <span className="general_info_value">{approvedData.order_id}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Movement:</span>
                  <span className="general_info_value">{approvedData.movement_id}</span>
                </p>

                <div className="origin_destination_section">
                  <div className="origin_destination_section_text">
                    Origin
                    <div className="line"></div>
                  </div>
                  <div className="origin_destination_section_text">
                    Destination
                    <div className="line"></div>
                  </div>
                </div>
                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{approvedData?.movement?.pickup?.stop_type ? approvedData?.movement?.pickup?.stop_type:'No Data'}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{approvedData?.movement?.delivery?.stop_type ? approvedData?.movement?.delivery?.stop_type:'No Data'}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {approvedData?.movement?.pickup?.location?.code ?
                  <span className="general_info_value">
                    {approvedData?.movement?.pickup?.location?.code ?
                      (`${approvedData?.movement?.pickup?.location?.code ? `${approvedData?.movement?.pickup?.location?.code} -` : ""}  ${approvedData?.movement?.pickup?.location?.location_name ? approvedData?.movement?.pickup?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {approvedData?.movement?.pickup?.location?.address1 ?
                      (`${approvedData?.movement?.pickup?.location?.address1 ? `${approvedData?.movement?.pickup?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {approvedData?.movement?.pickup?.location?.city ?
                      (`${approvedData?.movement?.pickup?.location?.city ? `${approvedData?.movement?.pickup?.location?.city},` : ""} ${approvedData?.movement?.pickup?.location?.state ? `${approvedData?.movement?.pickup?.location?.state},` : ""} ${approvedData?.movement?.pickup?.location?.zip ? `${approvedData?.movement?.pickup?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {approvedData?.movement?.delivery?.location?.code ?
                  <span className="general_info_value">
                    {approvedData?.movement?.delivery?.location?.code ?
                      (`${approvedData?.movement?.delivery?.location?.code ? `${approvedData?.movement?.delivery?.location?.code} -` : ""}  ${approvedData?.movement?.delivery?.location?.location_name ? approvedData?.movement?.delivery?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {approvedData?.movement?.delivery?.location?.address1 ?
                      (`${approvedData?.movement?.delivery?.location?.address1 ? `${approvedData?.movement?.delivery?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {approvedData?.movement?.delivery?.location?.city ?
                      (`${approvedData?.movement?.delivery?.location?.city ? `${approvedData?.movement?.delivery?.location?.city},` : ""} ${approvedData?.movement?.delivery?.location?.state ? `${approvedData?.movement?.delivery?.location?.state},` : ""} ${approvedData?.movement?.delivery?.location?.zip ? `${approvedData?.movement?.delivery?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{convertMillisToString(approvedData?.movement?.actual_arrive_origin, approvedData?.movement?.deliverytimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{convertMillisToString(approvedData?.movement?.actual_arrive_destination, approvedData?.movement?.deliverytimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{convertMillisToString(approvedData?.movement?.actual_depart_origin, approvedData?.movement?.deliverytimezone)}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{convertMillisToString(approvedData?.movement?.actual_depart_destination, approvedData?.movement?.deliverytimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(approvedData?.movement?.duration_at_origin_stop))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(approvedData?.movement?.duration_at_destination_stop))}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Drive Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(approvedData?.movement?.drive_time))}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Total Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(approvedData?.movement?.total_time))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Samsara Link:</span>
                  <a href={`${BaseConfig.SamsaraDriverPayLink}${approvedData?.driver?.samsara_id}`} target="_blank">
                    <i className="fa fa-external-link"></i>{" "} <u className="external_link">View Samsara HOS Details</u>
                  </a>
                </p>

              </div>
            </div>
            {approvedData?.additional_details?.formatted ?
              <>
                <div className="driverpay_info_move">
                  <div className="move_info_header"><p>Macro Info</p></div>
                  <div className="move_info_content">
                    {Object.entries(approvedData?.additional_details?.formatted).map(([label, value]) => (
                      <p className="move_info_item" key={label}>
                        <span className="general_info_label">{label}:</span>
                        <span className="general_info_value">{value}</span>
                      </p>
                    ))}
                  </div>
                </div>
              </> : <></>
            }
            {tabSelected.transferred ?
              ("") : (
                <>
                  <div className="driverpay_info_move">
                    <div className="move_info_header2"><p>Authorization Information</p></div>
                    <div className="move_info_content">
                      <div className="driverpay_info_genera_left">
                        <p className="move_info_item2">
                          <span className="general_info_label">{approvedData?.authorized_comment?.startsWith("Declined") ? "Declined On" : "Authorized On"} :</span>
                          <span className="general_info_value">{convertdatetomilisecond(approvedData.authorized_on)}</span>
                        </p>
                        <p className="move_info_item2">
                          <span className="general_info_label">{approvedData?.authorized_comment?.startsWith("Declined") ? "Declined Comment" : "Authorized Comment"} :</span>
                          <span className="general_info_value">{approvedData?.authorized_comment?.replace('Rejected authorize', '')?.replace('Accepted authorize', '')}</span>
                        </p>
                      </div>
                      <div className="driverpay_info_genera_right">
                        <p className="move_info_item2" >
                          <span className="general_info_label">{approvedData?.authorized_comment?.startsWith("Declined") ? "Declined By" : "Authorized By"} :</span>
                          <span className="general_info_value">{approvedData.authorized_by}</span>
                        </p>
                      </div>

                    </div>
                  </div>

                  <div className="driverpay_info_move">
                    <div className="move_info_header2"><p>Appoved Information</p></div>
                    <div className="move_info_content">
                      <div className="driverpay_info_genera_left">
                        <p className="move_info_item2">
                          <span className="general_info_label">{approvedData?.approved_comment?.startsWith("Approved") ? "Approved On" : "Rejected On"} :</span>
                          <span className="general_info_value">{convertdatetomilisecond(approvedData.approved_on)}</span>
                        </p>
                        <p className="move_info_item2">
                          <span className="general_info_label">{approvedData?.approved_comment?.startsWith("Approved") ? "Approved Comment" : "Rejected Comment"} :</span>
                          <span className="general_info_value">{approvedData?.approved_comment ? approvedData.approved_comment.replace(/Approved: null/g, "Approved:").replace('Rejected authorize', '').replace('Accepted authorize', '') : null}</span>
                        </p>
                      </div>
                      <div className="driverpay_info_genera_right">
                        <p className="move_info_item2" >
                          <span className="general_info_label">{approvedData?.approved_comment?.startsWith("Approved") ? "Approved By" : "Rejected By"} :</span>
                          <span className="general_info_value">{approvedData.approved_by}</span>
                        </p>
                      </div>
                      <div className="move_info_item_button">
                        <Button variant="primary" onClick={() => { setConfirmModal(true) }} >
                          Reset to Pending Approval
                        </Button>
                      </div>

                    </div>
                  </div>
                </>
              )
            }
        </div>
        </Modal.Body>
        <Modal.Footer>     
          <Button variant="primary" onClick={() => { setShowApprovedModal(false) }}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
      {/* Confirmation Modal for Revert to Pending Approval */}

      <Modal show={confirmModal}
        onHide={() => { setConfirmModal(false) }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className="p_text">Are you sure to reset this pay record to Pending Approval?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setConfirmModal(false) }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleStatusReject(approvedData)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Audit log compponent */}
      <AuditLogModalComponent ref={auditLogComponentRef} />
      
      <DriverPayModalEditApproval
        setidMovementEdited={setidMovementEdited}
        setOrderId={setOrderId}
        Setmovementid={Setmovementid}
        setOrigin={setOrigin}
        setDestination={setDestination}
        setshowMovementModal={setshowMovementModal}
        MovementData={MovementData}
        handleCloseEditModal={handleCloseEditModal}
        showMovementModal={showMovementModal}
        isdataMovementLoading={isdataMovementLoading}
      />
    </>


  );
};

export default DriverPayTable;