import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ContextData } from "../../components/appsession";
import { resetLoadUnloadCommodityGroup, modifyLoadunloadForCommodityGroup } from "../../services/commoditygroupService";
import { toast } from "react-toastify";

// This component is running after clicking on commodity group row
const CgByIdHeader = (props) => {
  const { commodityGroupById, commodityGroupDataByIdForHeader,  handleCommodityGroupByCode, isRefresh } = props;
  const [userData, setuserData] = useContext(ContextData);

  const permission = userData?.roles?.rolespermission.find(commoditypermission =>
    commoditypermission?.permissiondetail.code === "CommodityGroups");
  
  // These are for storing load objs
  const [yes, setYes] = useState({});
  const [no, setNo] = useState({});
  const [tl, setTl] = useState({});
  const [nb, setNb] = useState({});
  
  // These are for storing load input values
  const [yesLoadInputValue, setYesLoadInputValue] = useState("");
  const [noLoadInputValue, setNoLoadInputValue] = useState("");
  const [tlLoadInputValue, setTlLoadInputValue] = useState("");
  const [nbLoadInputValue, setNbLoadInputValue] = useState("");


   // These are for storing unload objs
  const [yesUnLoad, setYesUnLoad] = useState({});
  const [noUnLoad, setNoUnLoad] = useState({});
  
  // These are for storing unload input values
  const [yesUnLoadInputValue, setYesUnLoadInputValue] = useState("");
  const [noUnLoadInputValue, setNoUnLoadInputValue] = useState("");
  
  // This states are using for showing Save and Cancel buttons
  const [isEditforYes, setIsEditforYes] = useState(true);
  const [isEditforNo, setIsEditforNo] = useState(true);
  const [isEditforTl, setIsEditforTl] = useState(true);
  const [isEditforNB, setIsEditforNB] = useState(true);
  const [isEditforYesUnLoad, setIsEditforYesUnLoad] = useState(true);
  const [isEditforNoUnLoad, setIsEditforNoUnLoad] = useState(true);

  const [tabSelected, settabSelected] = useState({
    details: true,
  }); 
  
  // These functions are running for showing Save and Cancel buttons
  const editInfoHandler = () => setIsEditforYes(false);

  const editforNo = () => setIsEditforNo(false);  

  const editforTl = () => setIsEditforTl(false); 

  const editforNB = () => setIsEditforNB(false); 

  const editforYesUnloadTime = () => setIsEditforYesUnLoad(false);

  const editforNoUnloadTime = () => setIsEditforNoUnLoad(false);


  // These functions are running for cancel, edited values
  const cancelSaveUser = () => {
    setYesLoadInputValue(yes.duration);
    setIsEditforYes(true);
  }

  const cancelForNO = () => {
    setNoLoadInputValue(no.duration);
    setIsEditforNo(true);
  }

  const cancelForTl = () => {
    setTlLoadInputValue(tl.duration);
    setIsEditforTl(true);
  };

  const cancelForNB = () => {
    setNbLoadInputValue(nb.duration);
    setIsEditforNB(true);
  };

  const cancelForYesUnloadTime = () => {
    setYesUnLoadInputValue(yesUnLoad.duration);
    setIsEditforYesUnLoad(true);
  };

  const cancelForNoUnloadTime = () => {
    setNoUnLoadInputValue(noUnLoad.duration);
    setIsEditforNoUnLoad(true);
  };

  const handleResetForLoadUnload = async (name) => {
    console.log(`HandleResetForLoadUnload: ${name}`);
    switch (name) {
      case "Yes": {
        const response = await resetLoadUnloadCommodityGroup(yes.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      case "No": {
        const response = await resetLoadUnloadCommodityGroup(no.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      case "TL": {
        const response = await resetLoadUnloadCommodityGroup(tl.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      case "NB": {
        const response = await resetLoadUnloadCommodityGroup(nb.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      case "YesReset": {
        const response = await resetLoadUnloadCommodityGroup(yesUnLoad.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      case "NoReset": {
        const response = await resetLoadUnloadCommodityGroup(noUnLoad.id);
        
        if (response.status === 200) {      
          await handleCommodityGroupByCode(true, commodityGroupById);
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to handle reset for load unload");
        }
        break;
      }
      default:
        break;
    }
  };

  const handleSaveForLoadUnLoad = async (saveName) =>{
    console.log(`handleSaveForLoadUnLoad ${saveName}`)
    switch (saveName) {
      case "Yes": {
        if (yes.id) {
          const payload = {
            "id": yes.id,
            "action_type": "PU",
            "driver_load_flag": "Y",
            "commoditygroup_id": commodityGroupById.code,
            "duration": yesLoadInputValue,
            "isDefault": yes.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);

          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Updated successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }
        }
        break;
      }
      case "No": {
        if (no.id) {
          const payload = {
            "id": no.id,
            "action_type": "PU",
            "driver_load_flag": "N",
            "commoditygroup_id": commodityGroupById.code,
            "duration": noLoadInputValue,
            "isDefault": no.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);

          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Updated successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }

        }
        break;
      }
      case "NB": {
        if (nb.id) {
          const payload = {
            "id": nb.id,
            "action_type": "PU",
            "driver_load_flag": "NB",
            "commoditygroup_id": commodityGroupById.code,
            "duration": nbLoadInputValue,
            "isDefault": nb.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);

          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Reset successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }
        }
        break;
      }
      case "TL": {
        if (tl.id) {
          const payload = {
            "id": tl.id,
            "action_type": "PU",
            "driver_load_flag": "TL",
            "commoditygroup_id": commodityGroupById.code,
            "duration": tlLoadInputValue,
            "isDefault": tl.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);

          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Updated successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }
        }
        break;
      }
      case "YesUnload": {
        if (yesUnLoad.id) {
          const payload = {
            "id": yesUnLoad.id,
            "action_type": "DEL",
            "driver_load_flag": "Y",
            "commoditygroup_id": commodityGroupById.code,
            "duration": yesUnLoadInputValue,
            "isDefault": yesUnLoad.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);
          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Updated successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }
        }
        break;
      }
      case "NoUnload": {
        if (noUnLoad.id) {
          const payload = {
            "id": noUnLoad.id,
            "action_type": "DEL",
            "driver_load_flag": "N",
            "commoditygroup_id": commodityGroupById.code,
            "duration": noUnLoadInputValue,
            "isDefault": noUnLoad.isDefault
          };
          const response = await modifyLoadunloadForCommodityGroup(payload);
          if (response.status === 200) {
            await handleCommodityGroupByCode(true, commodityGroupById);
            toast.success("Updated successfully.");
          } else {
            toast.error("Failed to modify load unload for commodity group");
          }
        }
        break;
      }
      default:
        break;
    }
  };


  useEffect(() => {
    let yesLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "PU" && item.driverloadunload === "Y");
    let noLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "PU" && item.driverloadunload === "N");
    let tlLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "PU" && item.driverloadunload === "TL");
    let nbLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "PU" && item.driverloadunload === "NB");
    
    // For storing load objects for the first time
    setYes(yesLoadObj);
    setNo(noLoadObj);
    setTl(tlLoadObj);
    setNb(nbLoadObj);
    
    // For storing load durations for the first time
    setYesLoadInputValue(yesLoadObj.duration);
    setNoLoadInputValue(noLoadObj.duration);
    setTlLoadInputValue(tlLoadObj.duration);
    setNbLoadInputValue(nbLoadObj.duration);

    let yesUnLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "DEL" && item.driverloadunload === "Y");
    let noUnLoadObj = commodityGroupDataByIdForHeader.find((item) => item.stoptype === "DEL" && item.driverloadunload === "N");
    
    // For storing unload objects for the first time
    setYesUnLoad(yesUnLoadObj);
    setNoUnLoad(noUnLoadObj);

 
    // For storing unload durations for the first time
    setYesUnLoadInputValue(yesUnLoadObj.duration);
    setNoUnLoadInputValue(noUnLoadObj.duration);
    
    setIsEditforYes(true);
    setIsEditforNo(true);
    setIsEditforTl(true);
    setIsEditforNB(true);
    setIsEditforYesUnLoad(true);
    setIsEditforNoUnLoad(true);

  }, [commodityGroupDataByIdForHeader.length, isRefresh]);
  
  
  return (
    <div className="row df mt_30">
      <div className="tabs">
        <input
          type="radio"
          name="tabs"
          id="tabone"
          defaultChecked={tabSelected.details}
        />
        <label htmlFor="tabone">Details</label>
        <div className="tab">
          <div className="profile_top" style={{ "paddingLeft": "0" }}>
            <div className="profile_top_left">
              <div>
                <p className="profile_top_left_text">
                  {commodityGroupById.name}
                </p>
                <p className="profile_bottom_left_text">
                  Commodity Group Code: {commodityGroupById.code}
                </p>
              </div>
            </div>
            <div className="profile_top_right">
              <div
                className="online_sign"
                style={
                  commodityGroupById.isActive
                    ? {}
                    : { backgroundColor: "#d3e3d7" }
                }
              ></div>
            </div>
          </div>
          <table className="table table-borderless table-centered m-0 special_fonts bg-light commodity_override">
            <thead className="table-light">
              <tr className="commodity_override_deep">
                <th colSpan={4}>Load Time</th>
              </tr>
              <tr>
                <th>Yes</th>
                <th>No</th>
                <th>TL</th>
                <th>NB</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="number"
                    name="yes"
                    onInput={(e) => setYesLoadInputValue(e.target.value)}
                    className={
                      !isEditforYes
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={yesLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforYes}
                  />
                  {isEditforYes ?
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editInfoHandler()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}

                            ></i>
                          </div>
                      }
                    </>
                    : (
                      <div className="save_button_sec">
                        <button
                          className="btn btn-primary btn-sm mr_10"
                          type="submit"
                          onClick={() => handleSaveForLoadUnLoad("Yes")}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => cancelSaveUser()}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  {!yes.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"
                        onClick={() => handleResetForLoadUnload("Yes")}>
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    name="no"
                    onInput={(e) => setNoLoadInputValue(e.target.value)}
                    className={
                      !isEditforNo
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={noLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforNo}
                  />
                  {isEditforNo ? (
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editforNo()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}
                            ></i>
                          </div>
                      }
                    </>
                  ) : (
                    <div className="save_button_sec">
                      <button
                        className="btn btn-primary btn-sm mr_10"
                        type="submit"
                        onClick={() => handleSaveForLoadUnLoad("No")}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={(e) => cancelForNO()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!no.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"
                        onClick={() => handleResetForLoadUnload("No")}>
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    name="Tl"
                    onInput={(e) => setTlLoadInputValue(e.target.value)}
                    className={
                      !isEditforTl
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={tlLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforTl}
                  />
                  {isEditforTl ? (
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editforTl()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}
                            ></i>
                          </div>
                      }
                    </>
                  ) : (
                    <div className="save_button_sec">
                      <button
                        className="btn btn-primary btn-sm mr_10"
                        type="submit"
                        onClick={() => handleSaveForLoadUnLoad("TL")}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={(e) => cancelForTl()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!tl.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"
                        onClick={() => handleResetForLoadUnload("TL")}>
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>

                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    name="Nb"
                    onInput={(e) => setNbLoadInputValue(e.target.value)}
                    className={
                      !isEditforNB
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={nbLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforNB}
                  />
                  {isEditforNB ? (
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editforNB()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}
                            ></i>
                          </div>
                      }
                    </>

                  ) : (
                    <div className="save_button_sec">
                      <button
                        className="btn btn-primary btn-sm mr_10"
                        type="submit"
                        onClick={() => handleSaveForLoadUnLoad("NB")}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={(e) => cancelForNB()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!nb.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"

                        onClick={() => handleResetForLoadUnload("NB")}>
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-borderless table-centered m-0 special_fonts bg-light commodity_override">
            <thead className="table-light">
              <tr className="commodity_override_deep">
                <th colSpan={3}>Unload Time</th>
              </tr>
              <tr>
                <th>
                  Yes
                </th>
                <th>
                  No
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="number"
                    name="yesUnload"
                    onInput={(e) => setYesUnLoadInputValue(e.target.value)}
                    className={
                      !isEditforYesUnLoad
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={yesUnLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforYesUnLoad}
                  />
                  {isEditforYesUnLoad ? (
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editforYesUnloadTime()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}
                            ></i>
                          </div>
                      }
                    </>
                  ) : (
                    <div className="save_button_sec">
                      <button
                        className="btn btn-primary btn-sm mr_10"
                        type="submit"
                        onClick={() => handleSaveForLoadUnLoad("YesUnload")}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => cancelForYesUnloadTime()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!yesUnLoad.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"
                        onClick={() => handleResetForLoadUnload("YesReset")}
                      >
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    name="noUnload"
                    onInput={(e) => setNoUnLoadInputValue(e.target.value)}
                    className={
                      !isEditforNoUnLoad
                        ? "after_edit_comodity"
                        : "before_edit_comodity"
                    }
                    value={noUnLoadInputValue}
                    placeholder="e.g. 30mins"
                    disabled={isEditforNoUnLoad}
                  />
                  {isEditforNoUnLoad ? (
                    <>
                      {
                        permission.can_edit ?
                          <div onClick={(e) => editforNoUnloadTime()}>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#0066ff" }}
                            ></i>
                          </div>
                          : <div>
                            <i
                              className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                              aria-hidden="true"
                              style={{ color: "#686868" }}
                            ></i>
                          </div>
                      }
                    </>
                  ) : (
                    <div className="save_button_sec">
                      <button
                        className="btn btn-primary btn-sm mr_10"
                        type="submit"
                        onClick={() => handleSaveForLoadUnLoad("NoUnload")}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => cancelForNoUnloadTime()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {!noUnLoad.isDefault ? (
                    permission.can_edit ?
                      <Button className="mt_10"
                        onClick={() => handleResetForLoadUnload("NoReset")}>
                        Reset
                      </Button> :
                      <Button disabled style={{ backgroundColor: "#686868", borderColor: "#686868" }}>
                        Reset
                      </Button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CgByIdHeader;
