
import React from "react";


const AlertHeader = ({title,allAlertData}) => {



    return (
        <div className="row df mt_30">
            <div className="col-xl-12">
                <div className="card special_bg">
                    <div className="card-body">
                        <div className="row top_adjust">
                            <div className="col-md-12">
                                <div className="row">
                                    <h2 className="text-light">{title}</h2> 
                                    <p className="user_sec_text">{`Total Alerts : ${allAlertData.length}`}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertHeader;
