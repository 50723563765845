import { useEffect, useState,useContext } from "react";
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import CommodityGroup from "./pages/commoditygroup";
import Drivers from "./pages/drivers";
import Home from "./pages/home";
import Profile from "./pages/Profile";
import PlanningProfile from "./pages/planningprofile";
import LoadUnloadDurations from "./pages/loaduploaddurations";
import Locations from "./pages/locations";
import Planner from "./pages/planner/planner";
import Roles from "./pages/roles";
import Terminals from "./pages/terminals";
import Tractors from "./pages/tractors";
import Trailers from "./pages/trailers";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import OrdersPageInTrailer from "./pages/OrdersPageInTrailer/OrdersPageInTrailer";
import YardCheck from "./pages/yardCheck/yardCheck";
import TrailersPage from "./pages/TrailersPage/TrailersPage";
import TankwashPage from "./pages/TankwashPage/TankwashPage";
import Alerts from "./pages/alerts/alert";
import TankWash from "./pages/tankWash/tankWash";
import Tankwashcode from "./pages/tankwashcode";
import Tankwashprocedure from "./pages/tankwashprocedure";
import Plannerboard from "./pages/plannerboard/plannerboard";
import DriverPay from "./pages/driverPay";
import DriverPayConfiguration from "./pages/driverPayConfiguration";
import DriverPayCodesMaster from "./pages/DriverPayCodesMaster/DriverPayCodesMaster";
import NewAlerts from "./pages/newAlerts";
import Loading from "./pages/loading/loading";
import Status from "./pages/pageStatus/status";
import { ContextData } from "./components/appsession";
import PushNotification from "./components/PushNotification/PushNotification";

const AppRoute = () => {
  const [userData, setUserData] = useState({ initialized: false });
  const [localContext] = useContext(ContextData);
  const homepageHierarchy = ["Planning", "YardCheck", "TankWash", "DriverPay"];

  useEffect(() => {
    setUserData({ ...localContext, initialized: localContext?.id?.length ? localContext?.id?.length > 0 : false });
  }, [localContext]);

  const isAuthorizedForHomePage = (routeArr) => {
    if (userData?.initialized === false) return null;

    if (!userData || !userData.roles?.rolespermission) {
      return false;
    }

    let permissionObj = {};

    for (let i = 0; i < routeArr.length; i++) {
      if (Object.keys(permissionObj).length > 0) break;

      for (let j = 0; j < userData.roles.rolespermission.length; j++) {
        if (routeArr[i] === userData.roles.rolespermission[j].permissiondetail.code && userData.roles.rolespermission[j].can_view) {
          permissionObj = userData.roles.rolespermission[j];
          break;
        }
      }
    }

    if (Object.keys(permissionObj).length === 0) {
      return { nameOfPageAccess: "", status: false }
    }

    return { nameOfPageAccess: permissionObj?.permissiondetail?.code, status: permissionObj?.can_view ?? false }
  };

  const isAuthorized = (permission) => {
    if (userData?.initialized === false) return null;

    if (!userData || !userData.roles?.rolespermission) {
      return false;
    }

    const permissionObj = userData.roles.rolespermission.find(
      (p) => p.permissiondetail.code === permission
    );
    const permissionFromDefaultRoute = isAuthorizedForHomePage(homepageHierarchy);

    return permissionFromDefaultRoute?.status && permissionObj?.can_view;
  };

  return (
    <Router basename='/'>
      <PushNotification />
      <Routes>
        <Route exact path="/unauthorize" component={Loading} />
        <Route
          exact={true}
          path='/'
          Component={(props) => {
            const hasPermission = isAuthorizedForHomePage(homepageHierarchy);

            switch (hasPermission?.nameOfPageAccess) {
              case "Planning":
                return <Planner {...props} />;
              case "YardCheck":
                return <YardCheck {...props} />;
              case "TankWash":
                return <TankWash {...props} />;
              case "DriverPay":
                return <DriverPay {...props} />;
              default:
                return <Status status={hasPermission?.status === undefined ? null : hasPermission?.status} />;
            }
          }}
        />
        <Route
          path='/planner'
          Component={(props) => {
            const isPermission = isAuthorized("Planning");
            return isPermission ? <Planner {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/planner/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Planning");
            return isPermission ? <Planner id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/plannerboard/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Planning");
            return isPermission ? <Plannerboard id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/users'
          Component={(props) => {
            const isPermission = isAuthorized("Users");
            return isPermission ? <Home {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/users/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Users");
            return isPermission ? <Home id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/tankwashcode'
          Component={(props) => {
            const isPermission = isAuthorized("WashTypes");
            return isPermission ? <Tankwashcode {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/tankwashcode/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("WashTypes");
            return isPermission ? <Tankwashcode id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path="/tankwashprocedure"
          Component={(props) => {
            const isPermission = isAuthorized("WashTypeProcedures");
            return isPermission ? <Tankwashprocedure {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/tankwashprocedure/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("WashTypeProcedures");
            return isPermission ? <Tankwashprocedure id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path="/planningprofile"
          Component={(props) => {
            const isPermission = isAuthorized("PlanningProfile");
            return isPermission ? <PlanningProfile {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/planningprofile/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("PlanningProfile");
            return isPermission ? <PlanningProfile id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/roles'
          Component={(props) => {
            const isPermission = isAuthorized("Roles");
            return isPermission ? <Roles {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/roles/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Roles");
            return isPermission ? <Roles id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/terminals'
          Component={(props) => {
            const isPermission = isAuthorized("Terminals");
            return isPermission ? <Terminals {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/terminals/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Terminals");
            return isPermission ? <Terminals id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/drivers'
          Component={(props) => {
            const isPermission = isAuthorized("Drivers");
            return isPermission ? <Drivers {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/drivers/:id'
          Component={(props) => {
            const isPermission = isAuthorized("Drivers");
            return isPermission ? <Drivers  /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/tractors'
          Component={(props) => {
            const isPermission = isAuthorized("Tractors");
            return isPermission ? <Tractors {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/tractors/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Tractors");
            return isPermission ? <Tractors  /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/trailers'
          Component={(props) => {
            const isPermission = isAuthorized("Trailers");
            return isPermission ? <Trailers {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/trailers/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Trailers");
            return isPermission ? <Trailers id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/locations'
          Component={({ match }) => {
            const isPermission = isAuthorized("Locations");
            return isPermission ? <Locations  /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/locations/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("Locations");
            return isPermission ? <Locations id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/commoditygroup'
          Component={(props) => {
            const isPermission = isAuthorized("CommodityGroups");
            return isPermission ? <CommodityGroup {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          path='/commoditygroup/:id'
          Component={({ match }) => {
            const isPermission = isAuthorized("CommodityGroups");
            return isPermission ? <CommodityGroup id={match.params.id} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/loadunloaddurations'
          Component={(props) => {
            const isPermission = isAuthorized("LoadingUnloadingDurations");
            return isPermission ? <LoadUnloadDurations {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/yardcheck'
          Component={(props) => {
            const isPermission = isAuthorized("YardCheck");
            return isPermission ? <YardCheck {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/tankwash'
          Component={(props) => {
            const isPermission = isAuthorized("TankWash");
            return isPermission ? <TankWash {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/profile'
          Component={(props) => {
            const isPermission = isAuthorized("PlanningProfile");
            return isPermission ? <Profile {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/alerts'
          Component={(props) => {
            const isPermission = isAuthorized("Alerts");
            return isPermission ? <Alerts {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/orders'
          Component={(props) => {
            return <OrdersPage {...props} />;
          }}
        />
         <Route
          exact={true}
          path='/ordersTrailersPage'
          Component={(props) => {
            return <OrdersPageInTrailer {...props} />;
          }}
        />
        <Route
          exact={true}
          path='/TrailersPage'
          Component={(props) => {
            const isPermission = isAuthorized("Trailers");
            return isPermission ? <TrailersPage {...props} /> : <Status status={isAuthorized("Trailers")} />
          }}
        />
        <Route
          exact={true}
          path='/TankwashPage'
          Component={(props) => {
            return <TankwashPage {...props} />;
          }}
        />
        <Route
          exact={true}
          path='/driverpay'
          Component={(props) => {
            const isPermission = isAuthorized("DriverPay");
            return isPermission ? <DriverPay {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/driverpayconfiguration'
          Component={(props) => {
            const isPermission = isAuthorized("DriverpayByTerminal");
            return isPermission ? <DriverPayConfiguration {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/driverpaycodesmaster'
          Component={(props) => {
            const isPermission = isAuthorized("DriverpayCodesMaster");
            return isPermission ? <DriverPayCodesMaster {...props} /> : <Status status={isPermission} />
          }}
        />
        <Route
          exact={true}
          path='/new_alert'
          Component={(props) => {
            const isPermission = isAuthorized("Alerts");
            return isPermission ? <NewAlerts {...props} /> : <Status status={isPermission} />
          }}
        />
      </Routes>
    </Router>
  );
};

export default AppRoute;