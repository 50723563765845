import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "../../components/plannerBoardOrdersTable/dt/likelySubtags.json"
import currencyData from "../../components/plannerBoardOrdersTable/dt/currencyData.json";
import weekData from "../../components/plannerBoardOrdersTable/dt/weekData.json";
import numbers from "../../components/plannerBoardOrdersTable/dt/numbers.json";
import currencies from "../../components/plannerBoardOrdersTable/dt/currencies.json";
import caGregorian from "../../components/plannerBoardOrdersTable/dt/ca-gregorian.json";
import dateFields from "../../components/plannerBoardOrdersTable/dt/dateFields.json";
import timeZoneNames from "../../components/plannerBoardOrdersTable/dt/timeZoneNames.json";
import { Grid as MGrid } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import {updateSelectedPlanningProfileOfUser} from "../../services/userService"
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import {toast} from "react-toastify";
import { Navbar } from "react-bootstrap";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

import esMessages from "../../components/plannerBoardOrdersTable/dt/es.json";
loadMessages(esMessages, "es-ES");
import { process } from "@progress/kendo-data-query";
import { ContextData } from "../../components/appsession";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "@mui/material";
import { DateTime, Duration } from "luxon";
import getRowDraggable from "../../components/rowDraggable/rowDraggable";
import { DropContext } from "../planner/planner"
import "@progress/kendo-theme-default/dist/all.css";
import "../OrdersPage/header.css";
import { searchOrderForTrailerview } from "../../services/OrderService";
import filterOperators from "../../utils/filterOperators";
import { useQuery } from "../../hooks";
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const locales = [
  {
    language: "en-US",
    locale: "en",
  },
  {
    language: "es-ES",
    locale: "es",
  },
];

const OrdersPageInTrailer = (props) => {
  const query = useQuery();
  const [orderpayload, setOrderPayload] = useState(JSON.parse(query.get("payload")));
  const [dragData, setDragData] = useState({});
  const [selectedTimezoneDate, setSelectedTimezoneDate] = useState(orderpayload?.selectedTimezoneDate);
  const [plannedOrUnplanned, setPlannedOrUnplanned] = useState("center");
  const [orderRefreshState,setOrderRefreshState]=useState(false);
  const [plannerTimezone, setPlannerTimezone] = useState("utc");
  const [tabSelected, settabSelected] = useState("orders");
  const [oderTabClickC, setOderTabClickC] = useState(0);
  const [selectedPlanningProfile, setselectedPlanningProfile] = useState([orderpayload?.selectedPlanningProfile]);
  const [isDataloaded, setIsDataLoaded] = useState(false);
  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
  const [columnorder, setcolumnorder] = useState([])
  const [columnorderfordetail, setcolumnorderfordetail] = useState([])
  const [totalResult, setTotalResult] = useState();
  const [daysOutPlanned, setDaysoutPlanned] = useState(1);
  const [alignment, setAlignment] = React.useState('center');
  const [dataToggle, setDataToggle] = useState(false);
  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const daysOut = [1, 2, 3, 4, 5, 6, 7]
  const [refreshUserPreferences, setRefreshUserPreferences] = useState(true);
  const [tabName, setTabName] = useState("trailer unassigned");
  const [data, setData] = useState(null);
  console.log("data ttt",data);
  const [searchTerm, setSearchTerm] = useState([]);
  const [sortTerms, setSortTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [allTerminals, setAllTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [oldTerminalDetails, setOldTerminalDetails] = useState({});
  const [selectedOrderDetails, setSelectedOrderDetails] = useState({});
  const [showAuthorizedModal, setAuthorizedShowModal] = useState(false)
  const [authorizedEditIcon, setAuthorizedEditIcon] = useState(false)
  const [authValue, setAuthValue] = useState(false);
  const [unsplitModal, setUnsplitModal] = useState(false);
  const [moveTypeval, setMoveTypeval] = useState(null);
  const [moveIdval, setMoveIdval] = useState(null);
  const [unsplitconfirmval, setUnsplitconfirmval] = useState(false);
  const [removeTrailer, setRemoveTrailer] = useState(false);
  const [removeTrailerData, setRemoveTrailerData] = useState(null);
  const [isViewPermission, setViewPermission] = useState(false);
  const [PlannedTab, setplannedTab]= useState(false)
  const [orders, setorders] = useState([]);

  const handleWindowClose = () => {
    window.close();
  }

  const handleDaysout = (event) => {
      setDaysoutPlanned(event.target.value)
      let userDataPrefrence=getUserpreference();
      if(userDataPrefrence!==null){
        userDataPrefrence.daysOut=event.target.value
        localStorage.setItem('userpreferenceoftrailerview', JSON.stringify(userDataPrefrence));
      }
  };

  useEffect(() => {
    setOrderPayload(JSON.parse(query.get("payload")))
    setSelectedTimezoneDate(JSON.parse(query.get("payload"))?.selectedTimezoneDate)
  }, [])

  const handleChange = (event, newAlignment) => {
    if (newAlignment === null) return;
    setPlannedOrUnplanned(newAlignment);
    setAlignment(newAlignment);
  };

  // Data state for unplanned tab
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [{
        "field": "Terminal_Full_Name",
        "operator": "contains",
        "value": orderpayload?.selectedPlanningProfile.terminal_id
      }],
    },
    sort:[{field: 'Early_Pickup_Appt2', dir: 'asc'}],
  });

  const [dataResult, setDataResult] = useState([]);

  let columns = [
    { id: 0, name: "Terminal_Full_Name", title: "Terminal", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 1, name: "Order_ID", title: "Order ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 2, name: "Order_Status", title: "Order Status", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 3, name: "Move_ID", title: "Move ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 20, name: "pcmiler_distance", title: "Distance", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },

    { id: 4, name: "Move_Status", title: "Move Status", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 5, name: "pcmilerduration", title: 'Duration', width: '200px', isSortable: true, isFilterable: true, filter: 'text' },

    { id: 6, name: "Customer", title: "Customer", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 7, name: "BOL", title: "BOL", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 8, name: "Consignee_Ref", title: "Consignee Ref", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 9, name: "PO_Lot", title: "PO Lot", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    {
      id: 10,
      name: "Commodity_Group",
      title: "Commodity Group",
      width: "280px",
      isSortable: true,
      isFilterable: true,
      filter: 'text'
    },
    { id: 11, name: "Commodity", title: "Commodity", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 12, name: "Product", title: "Product", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 13, name: "HazMat", title: "HazMat", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 14, name: "HazMat_Code", title: "HazMat Code", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 15, name: "Ordered_Wgt", title: "Ordered Weight", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 16, name: "ActualWgt", title: "Actual Weight", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 17, name: "Driver_ID", title: "Driver ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 18, name: "Trailer_ID", title: "Trailer ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 29, name: "Tractor_ID", title: "Tractor ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 21, name: "Sent_To_Driver", title: "Sent to Driver", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 22, name: "Tankwash_WO", title: "Tankwash WO", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },

    { id: 23, name: "Driver_Load", title: "Driver Load", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 24, name: "Pickup_Type", title: "Pickup Type", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 25, name: "Pickup_Name", title: "Pickup Location", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 26, name: "Pickup_Address", title: "Pickup Address", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },

    { id: 27, name: "Pickup_City", title: "Pickup City", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 28, name: "Pickup_State", title: "Pickup State", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 29, name: "Pickup_Zip", title: "Pickup Zip", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },

    {
      id: 30,
      name: "Pickup_Timezone",
      title: "Pickup Timezone",
      width: "280px",
      isSortable: true,
      isFilterable: true,
      filter: 'text'
    },
    { id: 31, name: "Driver_Unload", title: "Driver Unload", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 32, name: "Delivery_Type", title: "Delivery Type", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 33, name: "Delivery_Name", title: "Delivery Location", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    {
      id: 34,
      name: "Delivery_Address",
      title: "Delivery Address",
      width: "280px",
      isSortable: true,
      isFilterable: true,
      filter: 'text'
    },
    { id: 35, name: "Delivery_City", title: "Delivery City", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 36, name: "Delivery_State", title: "Delivery State", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 38, name: "Delivery_Zip", title: "Delivery Zip", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    {
      id: 39,
      name: "Delivery_Timezone",
      title: "Delivery Timezone",
      width: "280px",
      isSortable: true,
      isFilterable: true,
      filter: 'text'
    },
    { id: 40, name: "Drop_Flag", title: "Drop Flag", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    {
      id: 41,
      name: "Early_Pickup_Appt2",
      title: "Early Pickup Appt",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },

    {
      id: 42,
      name: "Late_Pickup_Appt2",
      title: "Late Pickup Appt",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 43,
      name: "Pickup_Planned_Arrive2",
      title: "Pickup Planned Arrive",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 44,
      name: "Pickup_Planned_Depart2",
      title: "Pickup Planned Depart",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },

    { id: 45, name: "Pickup_ETA2", title: "Pickup ETA", width: "280px", timeZone:"Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    {
      id: 46,
      name: "Pickup_Actual_Arrive2",
      title: "Pickup Actual Arrive",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 47,
      name: "Pickup_Actual_Depart2",
      title: "Pickup Actual Depart",
      width: "280px",
      timeZone:"Pickup_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },

    {
      id: 48,
      name: "Early_Delivery_Appt2",
      title: "Early Delivery Appt",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 49,
      name: "Late_Delivery_Appt2",
      title: "Late Delivery Appt",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 50,
      name: "Delivery_Planned_Arrive2",
      title: "Delivery Planned Arrive",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 51,
      name: "Delivery_Planned_Depart2",
      title: "Delivery Planned Depart",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    { id: 52, name: "Delivery_ETA2", title: "Delivery ETA", width: "280px",timeZone:"Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    {
      id: 53,
      name: "Delivery_Actual_Arrive2",
      title: "Delivery Actual Arrive",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    {
      id: 54,
      name: "Delivery_Actual_Depart2",
      title: "Delivery Actual Depart",
      width: "280px",
      timeZone:"Delivery_Timezone",
      isSortable: true,
      isFilterable: true,
      filter: 'date'
    },
    { id: 55, name: "On_Hold", title: "On Hold", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 56, name: "On_Hold_Reason", title: "On Hold Reason", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 57, name: "trailerstatus", title: "Trailer Status", width: "200px", isSortable: false, isFilterable: false, filter: 'text' },
  ];

  const dateColumns = ["Delivery_Actual_Depart2","Delivery_Actual_Arrive2","Delivery_ETA2","Delivery_Planned_Depart2","Delivery_Planned_Arrive2","Late_Delivery_Appt2","Early_Delivery_Appt2",
    "Pickup_Actual_Depart2","Pickup_Actual_Arrive2","Late_Pickup_Appt2","Pickup_ETA2","Pickup_Planned_Depart2","Pickup_Planned_Arrive2","Late_Pickup_Appt2","Early_Pickup_Appt2"];

  // On tabSelected change, this useEffect will be triggered
  useEffect(() => {
    if (oderTabClickC >= 0 && tabSelected === "orders") {
      // if (userData?.userpreferences?.orderstabsequence) {
      //   setcolumnorder(userData?.userpreferences?.orderstabsequence);
      //   setcolumnorderfordetail(userData?.userpreferences?.orderstabsequence);
      // }
      // else {
        setcolumnorder(columns);
        setcolumnorderfordetail(columns);
    //   };
     };
  }, [tabSelected,data]);

  const columnReorderChange = async function (event) {
    const newOrders =[];
     event.target._columns.forEach((it) => {
      console.log("_columns",event)
      if(it.field!=="expanded"){
        newOrders.push({
        id: it.index,
        name: it.field,
        title: it.title,
        width: it.width,
        isSortable: it.sortable,
        filterable: it.filterable,
        filter: it.filter
      });
    }
    });
  };

  // Debounce function to delay function call 
  function debounce(func, delay) {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Function for handling unplanned tab filters
  const dataStateChange = async (event) => {
    setDataState(event.dataState);
    const data = event?.dataState?.filter?.filters || [];
    const sortData=event?.dataState?.sort || [];
    const terms = data && data.length > 0 ? data.map(({ field, value, operator }) => {
     if(field=='pcmilerduration'){
      return  ({ field:'pcmiler_duration', value, operator })
     }else if(dateColumns?.includes(field)){
      return  ({ field, value: DateTime.fromJSDate(value).toISODate(), operator })
     }
     else{
      return  ({ field, value, operator })
     }
    }) : [];
    const sorts= sortData && sortData.length>0 ? sortData.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerm(terms);
    setSortTerms(sorts)
    setTakeNumberOfResult(event?.dataState?.take);
    setSkipNumberOfResult(event?.dataState?.skip);
    setPageNumber(Math.floor(event?.dataState?.skip / event?.dataState?.take) + 1);  
    let payload = {
        userid: userData.id,
        screenname: "order",
        filter: data,
        sort:sortData
      }
      const terms2=data && data.length > 0 ? data.map(({ field, value, operator }) => {
        if(dateColumns?.includes(field)){
        return  ({ field, value: DateTime.fromJSDate(value).toISODate(), operator })
       }
       else{
        return  ({ field, value, operator })
       }
      }) : [];
      localStorage.setItem('userpreferenceoftrailerview', JSON.stringify({...payload,['filter']:terms2}));
      if(terms?.length==0 && event?.dataState?.filter==null){
        if(sorts.length==0){
        debouncedSearchUnplan(tabName,[],[],pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned);
        }else{
          debouncedSearchUnplan(tabName,[],sorts,pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned);
        }
      }
  };

  const handleStorageListen = (event) => {
    if (event.key === 'refreshOrderTab' && event.newValue === 'true') {
      localStorage.removeItem("refreshOrderTab")
      localStorage.removeItem("draggedData");
      debouncedSearchUnplan(tabName,searchTerm,sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned)
    }
  };

  window.addEventListener('storage', handleStorageListen);

  useEffect(() => {
    localStorage.removeItem("refreshOrderTab")
    localStorage.removeItem("draggedData");
  }, [])

  // Getting user preferences from local storage
  const getUserpreference = () => {
    const data = JSON.parse(localStorage.getItem('userpreferenceoftrailerview'));
    return data || null;
  };
  const handleRefresh = () => {
    localStorage.removeItem("refreshOrderTab")
    localStorage.removeItem("draggedData");
    debouncedSearchUnplan(tabName,searchTerm, sortTerms,pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned);
  }
  
  useEffect(()=>{
         debouncedSearchUnplan(tabName,searchTerm, sortTerms,pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned);
  },[searchTerm,pageNumber,takeNumberOfResult,PlannedTab,daysOutPlanned,sortTerms])

  useEffect(() => {
    const userDataPrefrence = getUserpreference();
    if (userDataPrefrence !== null) {
      if(userDataPrefrence?.daysOut){
        setDaysoutPlanned(userDataPrefrence?.daysOut)
      }
      if (Object.keys(userDataPrefrence)?.length > 0 && userDataPrefrence?.filter?.length > 0) {
        const terms2=userDataPrefrence?.filter && userDataPrefrence?.filter.length > 0 ? userDataPrefrence?.filter.map(({ field, value, operator }) => {
          if(dateColumns?.includes(field)){
          return  ({ field, value: DateTime.fromISO(value).toJSDate(), operator })
         }
         else{
          return  ({ field, value, operator })
         }
        }) : [];
        setSearchTerm(userDataPrefrence?.filter);
        setSortTerms(userDataPrefrence?.sort);
        setDataState({
          skip: 0,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: terms2,
          },
          sort: userDataPrefrence?.sort,
        })
      }else{
        setSearchTerm([{
          "field": "Terminal_Full_Name",
          "operator": "contains",
          "value": orderpayload?.selectedPlanningProfile.terminal_id
        }])
        setSortTerms([{field: "Early_Pickup_Appt2", dir: "asc"}])
        setDataState({
          skip: 0,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: [{
              "field": "Terminal_Full_Name",
              "operator": "contains",
              "value": orderpayload?.selectedPlanningProfile.terminal_id
            }],
          },
          sort: [{field: "Early_Pickup_Appt2", dir: "asc"}],
        })
    }
      }else{
        setSearchTerm([{
          "field": "Terminal_Full_Name",
          "operator": "contains",
          "value": orderpayload?. selectedPlanningProfile.terminal_id
        }])
        setSortTerms([{field: "Early_Pickup_Appt2", dir: "asc"}])
        setDataState({
          skip: 0,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: [{
              "field": "Terminal_Full_Name",
              "operator": "contains",
              "value":  orderpayload?.selectedPlanningProfile.terminal_id
            }],
          },
          sort: [{field: "Early_Pickup_Appt2", dir: "asc"}],
        })
    }
  }, [tabSelected, PlannedTab, refreshUserPreferences,selectedPlanningProfile]);
  
  

  useEffect(() => {
    if (data?.length === 0) {
      setIsDataLoaded(false);
    } else {
      setIsDataLoaded(true);
    }
  }, [isDataloaded]);

  // In this useEffect, time conversions are happening
  useEffect(() => {
    var arrayOfObj = [];
    let newOrderArr=[];
    if (alignment == "center") {
      newOrderArr = data?.filter(order => order.planningstatus === "trailer unassigned")
      setTabName("trailer unassigned");
      setplannedTab(false);
      if(tabName=="trailer unassigned"){
      debouncedSearchUnplan(tabName,searchTerm,sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned)
      }
    } else if (alignment == "left") {
      if(tabName=="trailer assigned"){
      debouncedSearchUnplan(tabName,searchTerm,sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned)
      }
      setplannedTab(true)
      setTabName("trailer assigned")
      setDaysoutPlanned(1);
    }
  }, [orderRefreshState,alignment]);

    const debouncedSearchUnplan = useCallback(
      debounce(async (tabName,searchTerms,sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysout) => {
        setDataToggle(true);
        try{
        const search = await searchOrderForTrailerview(tabName,searchTerms, sortTerms,pageNumber, takeNumberOfResult, DateTime.fromMillis(selectedTimezoneDate).setZone(plannerTimezone).toISODate(), daysout,plannerTimezone);
        const searchData = search?.data?.data;
        const searchCount = Number(search?.data?.count);
        setTotalResult(searchCount)
        setData(searchData)
        setDataToggle(false)
      }
      catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to handle debounce on search unplan', { autoClose: false } );
        }
        setDataToggle(false)
      }
      }, 1000),
      []
    );

 // Step 2: Ensure dataResult updates when data changes
useEffect(() => {
    setDataResult(data); // Update dataResult when data changes
}, [data]); // Run whenever data updates

  const handleClear = () => {
    let data = getUserpreference();
    let defaultTerminal=[{
      "field": "Terminal_Full_Name",
      "operator": "contains",
      "value": orderpayload?.selectedPlanningProfile.terminal_id
    }]
    if(data!==null){
    data.filter = defaultTerminal;
    data.sort=[];
    data.daysOut=1;
    }else{
      data= {
        userid: userData.id,
        screenname: "order",
        filter:defaultTerminal,
        daysOut:1,
        sort:[]
      };
    }
    setDaysoutPlanned(1)
    localStorage.setItem('userpreferenceoftrailerview', JSON.stringify(data));
    setSearchTerm(defaultTerminal);
    setSortTerms([{field: "Early_Pickup_Appt2", dir: "asc"}])
    setDataState({
      skip: 0,
      take: takeNumberOfResult,
      filter: {
        logic: "and",
        filters: defaultTerminal,
      },
      sort: [{field: "Early_Pickup_Appt2", dir: "asc"}],
    })
  };

  const expandChange = (event) => {
    let newData = dataResult.map((item) => {
      if (item.Move_ID === event.dataItem.Move_ID) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDataResult(newData);
  };


  const DetailComponent = props => {
    const dataItem =props.dataItem
    return(
      <div style={{display:"flex",gap:"0.5em"}} >
        {
          columnorderfordetail?.map((el)=><div style={{fontWeight:"600",width:`${el?.width}`}}>{`${el?.title} : ${dataItem[el?.name] ?? ""}`}</div>)
        }
      </div>
    )
  };

  return (
    <>
    <div id="wrapper">
          <Navbar expand="lg" variant="light" bg="light" className="navbar-custom">
        <div className="container-fluid">
          <div className="logo_logout_wrapper">
            <div className="logo-box df">
              <img
                className={"back_icon hide_left_icon"}
              />
              <p className="logo_font">
                <Link to="/planner">BULKMATIC TRANSPORT</Link>
              </p>
            </div>
            <div className="signoutsection">
              <button
                type="button"
                onClick={handleWindowClose}
                className="btn_signout"
              >
                <i className="fa fa-sign-out log_out_icon"></i>Close Window
              </button>
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      </Navbar>
      <div className="row df  adjustrow mt_30">
        <div className="col-xl-12">
          <div className="card special_bg">
            <div className="card-body">
              <div className="row top_adjust">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="col-md-2">
                    <h2 className="text-light">Orders</h2>
                  </div>
                  <div className="tab_inside_section_top_right">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row adjustrow1">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body ">
              <div className="table-responsive">
     {dataToggle && (
        <div className="loading-overlay active" style={{fontSize:"18px"}}>
          <i className="fa fa-spinner fa-spin spinner-icon"></i>Loading...
        </div>
      )}
    <div className="tab_inside_section">
      <div className="tab_inside_section_top">
        <div className="tab_inside_section_top_left"></div>
        <div className="tab_inside_section_top_right">
          <div className='ordertabdropdown'>
           <form>
             <p className="ordertabdropdown_label">Days out</p>
             <select value={daysOutPlanned ? daysOutPlanned : 1} onChange={(e) => handleDaysout(e)}>
                {daysOut.map(day => (
                  <option>{day}</option>
                ))}
              </select>
            </form>
          </div>
          <div className="planning_board_tab_toggle">
            <MGrid
              container
              spacing={2}
              direction='column'
              alignItems='right'
            >
              <MGrid item>
                <ToggleButtonGroup
                  size='small'
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    '& .MuiToggleButton-root': {
                      backgroundColor: '#b53329',
                      color: 'white',
                      '&.Mui-selected': {
                        backgroundColor: '#5eab5e',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#5eab5e',
                          color: 'white',
                        },
                      },
                      '&:hover': {
                        backgroundColor: '#b53329',
                        color: 'white',
                      },
                    },
                  }}
                >
                  <ToggleButton value='left' >Trailer Assigned</ToggleButton>
                  <ToggleButton value='center' >Trailer Unassigned</ToggleButton>
                </ToggleButtonGroup>
              </MGrid>
            </MGrid>

          </div>
          <div>
            <button
              type='button'
              className='btn_white_sm ml_10 '
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
          <div>
            <button
              type='button'
              className='btn_white_sm ml_10'
              onClick={handleRefresh}
            >
              Refresh
            </button>

          </div>
        </div>
      </div>
        <div className="tab_content without-scroll">
                  <LocalizationProvider language={currentLocale.language}>
                    <IntlProvider locale={currentLocale.locale}>
                      <ExcelExport
                        data={orders}
                      >
                        <Grid
                        detail={DetailComponent}
                          sortable={true}
                          filterable={true}
                          groupable={true}
                          filterOperators={filterOperators}
                          reorderable={true}
                          position={'top'}
                          total={totalResult}
                          pageable={{
                            pageSizes: [25, 50, 100],
                            info: true,
                            previousNext: true,
                          }}
                          skip={dataState.skip}
                          take={dataState.take}
                          sort={dataState.sort}
                          resizable={true}
                          data={dataResult}
                          {...dataState}
                          onDataStateChange={dataStateChange}                  
                          onColumnReorder={columnReorderChange}
                          rowRender={!dataToggle && getRowDraggable(
                            (e, dataItem) => {
                              localStorage.setItem('draggedData', JSON.stringify({
                                dragFrom: "ordersFromTrailerView",
                                dragDataItem: dataItem,
                                plannedOrUnplanned: alignment
                              }));
                            },
                            (e, dataItem) => {
                              localStorage.removeItem("draggedData");
                              localStorage.removeItem("refreshOrderTab");
                            }
                          )}
                          expandField="expanded"
                          onExpandChange={expandChange}
                    >
                      {columnorder?.map((it) => (
                          <GridColumn
                            key={it.id}
                            field={it.name}
                            title={it.title}
                            width={it.width}
                            filterable={it.isFilterable}
                            sortable={it.isSortable}
                            filter={it.filter}
                            cell={(e) => {
                              return (
                                <>
                                  {
                                    it.filter === 'date' ? (
                                      <td >
                                        {e.dataItem[it.name] ? DateTime.fromISO(e.dataItem[it.name]).setZone(e.dataItem[it.timeZone]).toFormat("MM-dd-yyyy HH:mm ZZZZ") : ''}
                                      </td>
                                    ) : <td>
                                      {e.dataItem[it.name]}
                                    </td>
                                  }
                                </>
                              );
                            }}
                          />
                       )
                       )}
                    </Grid>
                  </ExcelExport>
                </IntlProvider>
              </LocalizationProvider>
          </div>      
       
      

    </div>
  </div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </>

);
};

export default OrdersPageInTrailer;