import React from "react";
import Header from "../../components/header";
import "../../assets/css/app.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppBar from "../../components/appsubbarleft";
import AppBarRight from "../../components/appsubbarright";
import ProgressBar from "react-bootstrap/ProgressBar";

function Loading() {
    return (
        <>
            <div id="wrapper">
                <Header />
                <div className="left-side-menu">
                    <div className="h-100">
                        <div className="sidebar-content">
                            <AppBar />
                            <AppBarRight />
                        </div>
                    </div>
                </div>

                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card card_shadow loading_card">
                                <div className="card-body">
                                    <div className="loading_box">
                                        <div className="authorized_text_1">Loading...</div>
                                        <div style={{ width: '100%' }}>
                                            <ProgressBar animated now={100} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loading;
