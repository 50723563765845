import React, { useContext, useState } from "react";
// import Tooltip from "@mui/material/Tooltip";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { DropContext } from "../../pages/planner/planner";
//import TextField from '@mui/material/TextField';

//import TextField from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: 'none',
    backgroundColor: "#4267B2",
    borderColor: "#2C4F95",
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
    padding: "12px",
    borderRadius: "10px"
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px"
  },
}));

const formatDate = (epoch_date, tzone) => {

  if (!tzone || tzone === "Eastern Standard Time") {
    tzone = "America/New_York"
  }
  if (tzone === "Central Standard Time") {
    tzone = "America/Chicago"
  }
  if (epoch_date > 0) {
    return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
  }
  else {
    return ""
  }
}

const CarrierviewTooltip = (props) => {
  const onClickHandler = 'onClick' in props ? props.onClick : () => { };
  const classes = useStyles();
  return (
    <Tooltip title={
      <React.Fragment>
        <Typography color="inherit">
          {props?.tooltip}
        </Typography>
      </React.Fragment>
    }
      onClick={onClickHandler}
      placement="top"
      classes={{
        tooltip: classes?.customTooltip,
        arrow: classes?.customArrow,
      }}
      arrow
    >
      {props?.children}
    </Tooltip>
  );
}

const OrderPBToolTip = (props) => {
    const context = useContext(DropContext);
    const plannerTimezone = context.plannerTimezone;
    const it = props.it;
    const displayfororderid = (it) => {
      if (it.orderId === null) {
  
        return it.orderId2
      }
      else {
        return it.orderId
      }
    }
  
    return (
      <span className="tooltip-adjust">
        <div className="main_tooltip_section">
          <div className="tooltip_left">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Order: </div>
              <div className="tooltip_text_right">
  

                {it.order_id?it.order_id:"No data"}
              </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Previous Move : </div>
              <div className="tooltip_text_right">No Data</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Next Move : </div>
              <div className="tooltip_text_right">{it.movement_type}</div>
            </div>
          </div>
  
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Stop Type:</div>
              <div className="tooltip_text_right">{it.pickup.stop_type}  </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Previous Move Type:</div>
              <div className="tooltip_text_right"> No Data </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Next Move Type:</div>
              <div className="tooltip_text_right"> {it.pickup.stop_type} </div>
            </div>
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right"> {it.status}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right">No data</div>
            </div>
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap">
          <div className="tooltip_left">
            <div className="tooltip_single_sec  ">
              <div className="tooltip_text_left fs_20">{it?.pickup?.stop_type ? it.pickup.stop_type.toUpperCase() : "No Data"} : </div>
            </div>
            <div className="tooltip_single_sec">
              <p className="tooltip_text_right">
              {it?.pickup?.lm_location_id ?
                (`${it?.pickup?.lm_location_id} - ${it?.pickup?.lm_location_name}`)
                : "No Data"
              }
              <br />
              {it?.pickup?.address ?
                (`${it?.pickup?.address}`)
                : null
              }

              <br />
              {it?.pickup?.city ?
                (`${it.pickup?.city}, ${it?.pickup?.state}, ${it?.pickup?.zip} `)
                : null
              }
              </p>
            </div>
          </div>
          <div className="tooltip_middle">
  
          </div>
        </div>
  
        <div className="main_tooltip_section tooltip_inner_gap1">
          <div className="tooltip_left">
  
  
  
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Early Appt: </div>
              <div className="tooltip_text_right">  {it?.pickup?.sched_arrive_early_utc ?
              formatDate(it.pickup?.sched_arrive_early_utc, it?.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Late Appt: </div>
              <div className="tooltip_text_right"> {it?.pickup?.sched_arrive_late_utc ?
              formatDate(it.pickup?.sched_arrive_late_utc, it?.pickup.pickup_timezone) : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt Rq'd:</div>
              <div className="tooltip_text_right">{it.pickup?.appt_required ? it.pickup?.appt_required  : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt confirmed:</div>
              <div className="tooltip_text_right">{it.pickup?.confirmed ? it.pickup?.confirmed  : "No Data"}  </div>
            </div>
  
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap" >
          <div className="tooltip_left">
            <div className="tooltip_single_sec mt_5 ">
              <div className="tooltip_text_left">Planned Arrive : </div>
              <div className="tooltip_text_right"> {it?.pickup.planned_arrive_utc ? formatDate(it.pickup.planned_arrive_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planned Depart : </div>
              <div className="tooltip_text_right"> {it?.pickup.planned_depart_utc ? formatDate(it.pickup.planned_depart_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            {/* <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA: </div>
              <div className="tooltip_text_right">{it?.pickup.eta_utc ? formatDate(it.pickup.eta_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div> */}
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Arrival: </div>
              <div className="tooltip_text_right">{it?.pickup.eta_arrival_utc ? formatDate(it.pickup.eta_arrival_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Departure: </div>
              <div className="tooltip_text_right">{it?.pickup.eta_departure_utc ? formatDate(it.pickup.eta_departure_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Arrival : </div>
              <div className="tooltip_text_right"> {it?.pickup.actual_arrival_utc ? formatDate(it.pickup.actual_arrival_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Depart : </div>
              <div className="tooltip_text_right"> {it?.pickup.actual_departure_utc ? formatDate(it.pickup.actual_departure_utc,it.pickup.pickup_timezone) : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
      </span>
    );
  }


  const Order2PBToolTip = (props) => {

 
  
    const context = useContext(DropContext);
    const plannerTimezone = context.plannerTimezone;
    const it = props.it;
    const displayfororderid = (it) => {
      if (it.orderId === null) {
  
        return it.orderId2
      }
      else {
        return it.orderId
      }
    }
  
    return (
      <span className="tooltip-adjust">
        <div className="main_tooltip_section">
          <div className="tooltip_left">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Order: </div>
              <div className="tooltip_text_right">
  

                {it.order_id?it.order_id:"No data"}
              </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Previous Move : </div>
              <div className="tooltip_text_right">No Data</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Next Move : </div>
              <div className="tooltip_text_right">{it.movement_type}</div>
            </div>
          </div>
  
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Stop Type:</div>
              <div className="tooltip_text_right">{it.delivery.stop_type}  </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Previous Move Type:</div>
              <div className="tooltip_text_right">{it.movement_type} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Next Move Type:</div>
              <div className="tooltip_text_right"> No Data </div>
            </div>
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right"> {it.status}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right">No data</div>
            </div>
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap">
          <div className="tooltip_left">
            <div className="tooltip_single_sec  ">
              <div className="tooltip_text_left fs_20">{it?.delivery?.stop_type ? it.delivery.stop_type.toUpperCase() : "No Data"} : </div>
            </div>
            <div className="tooltip_single_sec">
              <p className="tooltip_text_right">
              {it?.delivery?.lm_location_id ?
                (`${it?.delivery?.lm_location_id} - ${it?.delivery?.lm_location_name}`)
                : "No Data"
              }
              <br />
              {it?.delivery?.address ?
                (`${it?.delivery?.address}`)
                : null
              }

              <br />
              {it?.delivery?.city ?
                (`${it.delivery?.city}, ${it?.delivery?.state}, ${it?.delivery?.zip} `)
                : null
              }
              </p>
            </div>
          </div>
          <div className="tooltip_middle">
  
          </div>
        </div>
  
        <div className="main_tooltip_section tooltip_inner_gap1">
          <div className="tooltip_left">
  
  
  
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Early Appt: </div>
              <div className="tooltip_text_right">  {it?.delivery?.sched_arrive_early_utc ?
              formatDate(it.delivery?.sched_arrive_early_utc, it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Late Appt: </div>
              <div className="tooltip_text_right"> {it?.delivery?.sched_arrive_late_utc ?
              formatDate(it.delivery?.sched_arrive_late_utc, it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt Rq'd:</div>
              <div className="tooltip_text_right">{it.delivery?.appt_required ? it.delivery?.appt_required  : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt confirmed:</div>
              <div className="tooltip_text_right">{it.delivery?.confirmed ? it.delivery?.confirmed  : "No Data"}  </div>
            </div>
  
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap" >
          <div className="tooltip_left">
            <div className="tooltip_single_sec mt_5 ">
              <div className="tooltip_text_left">Planned Arrive : </div>
              <div className="tooltip_text_right"> {it?.delivery.planned_arrive_utc ? formatDate(it.delivery.planned_arrive_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planned Depart : </div>
              <div className="tooltip_text_right"> {it?.delivery.planned_depart_utc ? formatDate(it.delivery.planned_depart_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
            {/* <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA: </div>
              <div className="tooltip_text_right">{it?.delivery.eta_utc ? formatDate(it.delivery.eta_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div> */}
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Arrival: </div>
              <div className="tooltip_text_right">{it?.delivery.eta_arrival_utc ? formatDate(it.delivery.eta_arrival_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Departure: </div>
              <div className="tooltip_text_right">{it?.delivery.eta_departure_utc ? formatDate(it.delivery.eta_departure_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Arrival : </div>
              <div className="tooltip_text_right"> {it?.delivery.actual_arrival_utc ? formatDate(it.delivery.actual_arrival_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Depart : </div>
              <div className="tooltip_text_right"> {it?.delivery.actual_departure_utc ? formatDate(it.delivery.actual_departure_utc,it?.delivery.delivery_timezone) : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
      </span>
    );
  }

  const Order3PBToolTip = (props) => {

   
   
    const it = props.it;
  
    return (
      <span className="tooltip-adjust">
  
        <div className="main_tooltip_section_other_t">
  
          <div className="tooltip_left_pu1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Order: </div>
              <div className="tooltip_text_right"> {it.order_id?it.order_id:"No data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Move: </div>
              <div className="tooltip_text_right">{it?.load_id}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Brokerage: </div>
              <div className="tooltip_text_right">{it?.brokerage}</div>
            </div>
          </div>
          <div className="tooltip_middle1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Type: </div>
              <div className="tooltip_text_right">{}</div>
            </div>
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left"> Type: </div>
              <div className="tooltip_text_right">{it?.movement_type}</div>
            </div>
  
  
          </div>
          <div className="tooltip_rightPu1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status: </div>
              <div className="tooltip_text_right">{it?.status}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status: </div>
              <div className="tooltip_text_right">No data</div>
            </div>
  
  
          </div>
  
        </div>
  
        <div className="main_tooltip_section_other_t">
  
          <div className="tooltip_left_pu1">
            <div className="tooltip_single_sec pt_20">
              <div className="tooltip_text_left fs_20">Pickup:</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_right">
                <p className="tooltip_text_right">
  
                  {
                    (`${it?.pickup.lm_location_id} - ${it?.pickup?.lm_location_name ? it?.pickup?.lm_location_name : ""}`)
  
                  }
                  <br />
                  {it.pickup?.address ?
                    (`${it.pickup?.address}`)
                    : ""
                  }
                  <br />
                  {it.pickup?.city ?
                    (`${it.pickup?.city
                      }, ${it.pickup?.state}, ${it.pickup?.zip} `)
                    : ""
                  }
                </p>
              </div>
            </div>
  
          </div>
          <div className="tooltip_middle1">
  
          </div>
          <div className="tooltip_rightPu1">
            <div className="tooltip_single_sec pt_20">
              <div className="tooltip_text_left fs_20">Delivery: </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_right">
                <p className="tooltip_text_right">
                  {it.delivery?.lm_location_id ?
                    (`${it.delivery?.lm_location_id} - ${it.delivery?.lm_location_name
                      }`)
                    : "No Data"
                  }
                  <br />
                  {it.delivery?.address ?
                    (`${it.delivery?.address}`)
                    : null
                  }
  
                  <br />
                  {it.delivery?.city ?
                    (`${it.delivery?.city}, ${it.delivery?.state}, ${it.delivery?.zip} `)
                    : null
                  }
                </p>
              </div>
            </div>
  
  
          </div>
  
        </div>
  
        <div className="main_tooltip_section_other_t">
  
          <div className="tooltip_left_pu1">
            <div className="tooltip_single_sec pt_20">
              <div className="tooltip_text_left">Planned Arrival Time: </div>
              <div className="tooltip_text_right">{it.pickup.planned_arrive_utc ? formatDate(it?.pickup.planned_arrive_utc, it?.pickup?.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planned Depart Time: </div>
              <div className="tooltip_text_right">{it.pickup.planned_depart_utc ? formatDate(it?.pickup.planned_depart_utc,  it?.pickup?.pickup_timezone) : "No Data"}</div>
            </div>
            {/* <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA: </div>
              <div className="tooltip_text_right"> {it.pickup.eta_utc ? formatDate(it?.pickup.eta_utc, it?.pickup?.pickup_timezone) : "No Data"}</div>
            </div> */}
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Arrival: </div>
              <div className="tooltip_text_right"> {it?.pickup.eta_arrival_utc ? formatDate(it?.pickup.eta_arrival_utc, it?.pickup?.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Departure: </div>
              <div className="tooltip_text_right"> {it?.pickup.eta_departure_utc ? formatDate(it?.pickup.eta_departure_utc, it?.pickup?.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Arrival Time: </div>
              <div className="tooltip_text_right df">            
              {it.pickup.actual_arrival_utc ? formatDate(it?.pickup.actual_arrival_utc, it?.pickup?.pickup_timezone) : "No Data"}
  
            
  
                {/* <div onClick={(e) => handleEdit()}><i className="fa fa-pencil tooltip_edit_icon" type="button" aria-hidden="true"></i></div> */}
  
                
  
              </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Depart Time: </div>
              <div className="tooltip_text_right">{it.pickup.actual_departure_utc ? formatDate(it?.pickup.actual_departure_utc, it?.pickup.pickup_timezone) : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Distance: </div>
              <div className="tooltip_text_right">{it?.distance} Miles</div>
            </div>
          </div>
          <div className="tooltip_middle1">
  
  
  
          </div>
          <div className="tooltip_rightPu1">
            <div className="tooltip_single_sec pt_20">
              <div className="tooltip_text_left">Planned Arrive: </div>
              <div className="tooltip_text_right">{it?.delivery.planned_arrive_utc ? formatDate(it?.delivery.planned_arrive_utc, it?.delivery.delivery_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planned Depart: </div>
              <div className="tooltip_text_right">{it?.delivery.planned_depart_utc ? formatDate(it?.delivery?.planned_depart_utc, it?.delivery.delivery_timezone) : "No Data"}</div>
            </div>
            {/* <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA: </div>
              <div className="tooltip_text_right"> {it.delivery.eta_utc ? formatDate(it?.delivery.eta_utc, it?.delivery?.delivery_timezone) : "No Data"}</div>
            </div> */}
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Arrival: </div>
              <div className="tooltip_text_right"> {it?.delivery.eta_arrival_utc ? formatDate(it?.delivery.eta_arrival_utc, it?.delivery?.delivery_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA Departure: </div>
              <div className="tooltip_text_right"> {it?.delivery.eta_departure_utc ? formatDate(it?.delivery.eta_departure_utc, it?.delivery?.delivery_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Arrival Time: </div>
              <div className="tooltip_text_right">{it.delivery.actual_arrival_utc ? formatDate(it?.delivery.actual_arrival_utc, it?.delivery.delivery_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Depart Time: </div>
              <div className="tooltip_text_right">{it.delivery.actual_departure_utc ? formatDate(it?.delivery?.actual_departure_utc, it?.delivery?.delivery_timezone) : "No Data"}</div>
            </div>
  
  
  
          </div>
  
        </div>
      </span>
    );
  }
  const OrderTooltip = (props) => {

  
 
    const it = props.it;
    return (
      <span className="tooltip-adjust">
        <div className="main_tooltip_section1">
          <div className="tooltip_left_1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Customer: </div>
              <div className="tooltip_text_right">  {it?.customer_name ? it?.customer_name : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Order: </div>
              <div className="tooltip_text_right">  {it.order_id?it.order_id:"No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Hold: </div>
              <div className="tooltip_text_right">   {it?.on_hold ? it?.on_hold : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_2">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Type: </div>
              <div className="tooltip_text_right"> {it.unload_type?it.unload_type:"No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Terminal: </div>
              <div className="tooltip_text_right">   {it?.terminal_id} - {it?.pickup.location.city ? it?.pickup.location.city : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status: </div>
              <div className="tooltip_text_right"> {it?.status ? it?.status : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Reason: </div>
              <div className="tooltip_text_right"> {"No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
        <div className="main_tooltip_section1 tooltip_inner_gap">
          <div className="tooltip_left_1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">BOL: </div>
              <div className="tooltip_text_right">  {it?.bol?it?.bol:"No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_2">
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Consignee Ref#: </div>
              <div className="tooltip_text_right"> {it?.consignee_refno?it?.consignee_refno: "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
        <div className="main_tooltip_section1 tooltip_inner_gap">
          <div className="tooltip_left_1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left fs_20">Shipper:  </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_right">
                <p className="tooltip_text_right">
                  {it?.pickup?.lm_location_id ?
                    (`${it?.pickup?.lm_location_id} - ${it?.pickup?.lm_location_name}`)
                    : "No Data"
                  }
                  <br />
                  {it?.pickup?.address ?
                    (`${it.pickup?.address}`)
                    : null
                  }
                  <br />
                  {it?.pickup?.city ?
                    (`${it?.pickup?.city}, ${it?.pickup?.state}, ${it?.pickup?.zip} `)
                    : null
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="tooltip_left_2">
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left fs_20">Consignee: </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_right">
                <p className="tooltip_text_right">
                  {it?.delivery?.lm_location_id ?
                    (`${it.delivery?.lm_location_id} - ${it.delivery?.lm_location_name}`)
                    : "No Data"
                  }
                  <br />
                  {it?.delivery?.address ?
                    (`${it?.delivery?.address}`)
                    : null
                  }
                  <br />
                  {it?.delivery?.city ?
                    (`${it?.delivery?.city}, ${it.delivery?.state}, ${it.delivery?.zip} `)
                    : null
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
        <div className="main_tooltip_section1 tooltip_inner_gap1">
          <div className="tooltip_left_1">
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Driver Load : </div>
              <div className="tooltip_text_right">   {it?.pickup.driver_load_unload ? it?.pickup.driver_load_unload : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_2">
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Driver Unload: </div>
              <div className="tooltip_text_right">    {it?.delivery.driver_load_unload ? it?.delivery.driver_load_unload : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
        <div className="main_tooltip_section1 tooltip_inner_gap">
          <div className="tooltip_left_1">
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Early Pickup Appt:  </div>
              <div className="tooltip_text_right">
                {formatDate(it?.pickup.sched_arrive_early_utc, it?.pickup.pickup_timezone)}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Late Pickup Appt:</div>
              <div className="tooltip_text_right">
                {formatDate(it?.pickup.sched_arrive_late_utc, it?.pickup.pickup_timezone)}</div>
            </div>
          </div>
          <div className="tooltip_left_2">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Appt Rq'd: </div>
              <div className="tooltip_text_right">
                {it?.pickup.appt_required ? it.pickup.appt_required : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Appt Confirmed:</div>
              <div className="tooltip_text_right">
                {it?.pickup.confirmed ? it.pickup.confirmed : "No Data"}  </div>
            </div>
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Early Delivery Appt: </div>
              <div className="tooltip_text_right">
                {formatDate(it?.delivery?.sched_arrive_late_utc, it?.delivery?.delivery_timezone)}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Late Delivery Appt:</div>
              <div className="tooltip_text_right">
                {formatDate(it?.delivery?.actual_departure_utc, it?.delivery?.delivery_timezone)}</div>
            </div>
          </div>
          <div className="tooltip_left_4">
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Appt Rq'd: </div>
              <div className="tooltip_text_right">  {it?.delivery.appt_required ? it.delivery.appt_required : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Appt Confirmed:</div>
              <div className="tooltip_text_right"> {it?.delivery.confirmed ? it.delivery.confirmed : "No Data"} </div>
            </div>
          </div>
        </div>
        <div className="main_tooltip_section1 tooltip_inner_gap">
          <div className="tooltip_left_1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planning Comment:</div>
              <div className="tooltip_text_right">   {"No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_2">
          </div>
          <div className="tooltip_left_3">
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
        <div className="main_tooltip_section1 ">
          <div className="tooltip_left_1">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Commodity Group:</div>
              <div className="tooltip_text_right">
                {it?.commoditygroupid || it?.commoditygroup_description ?
                  `${it?.commoditygroupid}- ${it?.commoditygroup_description}` : "No Data"
                }
                
              </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Product:</div>
              <div className="tooltip_text_right">   {it?.commodity_desc ? it?.commodity_desc : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_2">
          </div>
          <div className="tooltip_left_3">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Commodity:</div>
              <div className="tooltip_text_right">  {it?.commodity ? it?.commodity : "No Data"} - {it?.commodity ? it?.commodity : "No Data"} </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Ordered Weight: </div>
              <div className="tooltip_text_right">   {it?.weight ? it?.weight : "No Data"} </div>
            </div>
          </div>
          <div className="tooltip_left_4">
          </div>
        </div>
      </span>
    );
  }
  const TrailerPBToolTip = (props) => {
    const it = props.it;
    return (
      <span className="tooltip-adjust">
        <div className="main_tooltip_section_other">
          <div className="tooltip_left">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Trailer : </div>
              <div className="tooltip_text_right">{it?.trailer_id?it?.trailer_id: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Type : </div>
              <div className="tooltip_text_right">{(it?.equip_types || it?.type) ?? "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Commodity Group : </div>
              <div className="tooltip_text_right">{it?.commodity_group}{it.commoditygroup_description} </div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status: </div>
              <div className="tooltip_text_right">
                {it.stat === "A" ? "Active" :
                  it.stat === "S" ? "Sold" :
                    it.stat === "I" ? "Inactive" :
                      it.stat === "T" ? "Sale-pending" :
                        it.stat === "O" ? "On-order" :
                          it.stat === "C" ? "Collision" :
                            it.stat === "In shop" ? "In shop" : "No Data"
  
                }
              </div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Terminal : </div>
              {/* <div className="tooltip_text_right">{it.terminalId} - {it.terminal_city}</div> */}
              <div className="tooltip_text_right">{it.terminal_id} - {it.pickup.location.city}</div>
            </div>
          </div>
  
        </div>
  
        <div className="main_tooltip_section_other">
          <div className="tooltip_left">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">Last Order : </div>
              <div className="tooltip_text_right">{it?.Last_Order?it?.Last_Order: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Last Commodity : </div>
              <div className="tooltip_text_right">{it?.Last_Commodity?it?.Last_Commodity: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Last Product : </div>
              <div className="tooltip_text_right">{it?.Last_Product ?it?.Last_Product: "No Data"}</div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">Shipper Pool : </div>
              <div className="tooltip_text_right">{it?.shipper_pool?it?.shipper_pool: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Dedicated : </div>
              <div className="tooltip_text_right">{it?.dedicated ?it?.dedicated: "No Data"}</div>
            </div>
          </div>
  
        </div>
  
        <div className="main_tooltip_section_other">
          <div className="tooltip_left">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">Loads since last wash : </div>
              <div className="tooltip_text_right">{it?.Loads_Since_Last_Wash ?it?.Loads_Since_Last_Wash: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Days since last wash : </div>
              <div className="tooltip_text_right">{it?.Days_Since_Last_Wash ?it?.Days_Since_Last_Wash: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Last Wash Date : </div>
              <div className="tooltip_text_right">{it?.Last_Washed ?it?.Last_Washed: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Last Wash W/O : </div>
              <div className="tooltip_text_right">{it?.Last_Wash_WO ?? "No Data"}</div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">Loads to next wash: </div>
              <div className="tooltip_text_right">{it?.Loads_to_Next_Wash ?it?.Loads_to_Next_Wash: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Day to Next wash: </div>
              <div className="tooltip_text_right">{it?.Days_to_Next_Wash ?? "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Next Wash Date: </div>
              <div className="tooltip_text_right">{"No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">PM Due Date: </div>
              <div className="tooltip_text_right">
                {it?.pm_due_date_utc != null ? formatDate(it?.pm_due_date_utc) : "No Data"}
              </div>
            </div>
          </div>
  
        </div>
  
        <div className="main_tooltip_section_other">
          <div className="tooltip_left">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">License : </div>
              <div className="tooltip_text_right">{ it?.license?it?.license: "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">State : </div>
              <div className="tooltip_text_right">{(it?.stat || it?.state) ?? "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Year : </div>
              <div className="tooltip_text_right">{(it?.model_year || it?.year) ?? "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Make : </div>
              <div className="tooltip_text_right">{it?.make ?? "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Model : </div>
              <div className="tooltip_text_right">{it?.model ?? "No Data"}</div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec trailer_gap">
              <div className="tooltip_text_left">Length: </div>
              <div className="tooltip_text_right">{"No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Width: </div>
              <div className="tooltip_text_right">{"No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Height: </div>
              <div className="tooltip_text_right">{"No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Volume: </div>
              <div className="tooltip_text_right">{"No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Tare Weight: </div>
              <div className="tooltip_text_right">{it?.tare_weight ?? "No Data"}</div>
            </div>
          </div>
  
        </div>
      </span>
    );
  }
  export {
    OrderTooltip,
    OrderPBToolTip,
    Order2PBToolTip,
    Order3PBToolTip,
    TrailerPBToolTip
    
  };
  export default CarrierviewTooltip;