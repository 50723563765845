import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { blue } from "@mui/material/colors";


const DriverPayConfigHeader = (props) => {
	const [tabSelected, setTabselected] = useState({
		driverPayByTerminal: true,
		driverPayOverrides: false,
		driverPayException: false,
		driverPayDistances: false
	});

	const handleChange = (e, newValue) => {
		let obj = {
			driverPayByTerminal: false,
			driverPayOverrides: false,
			driverPayException: false,
			driverPayDistances: false
		}
		setTabselected({ ...obj, [newValue]: true });
   props.parenCallback(newValue)
	}


	return (
		<div className="row df mt_30">
			<div className="tabs">
				<input
					type="radio"
					name="tabs"
					id="tab1"
					checked={tabSelected.driverPayByTerminal}
					readOnly
					onClick={(e) => handleChange(e, "driverPayByTerminal")}
				/>
				<label htmlFor="tab1">Terminal</label>
				<input
					type="radio"
					name="tabs"
					id="tab2"
					checked={tabSelected.driverPayOverrides}
					readOnly
					onClick={(e) => handleChange(e, "driverPayOverrides")}
				/>
				<label htmlFor="tab2">Pay Overrides</label>
				<input
					type="radio"
					name="tabs"
					id="tab3"
					checked={tabSelected.driverPayException}
					readOnly
					onClick={(e) => handleChange(e, "driverPayException")}
				/>
				<label htmlFor="tab3">Pay Exceptions</label>
				<input
					type="radio"
					name="tabs"
					id="tab4"
					checked={tabSelected.driverPayDistances}
					readOnly
					onClick={(e) => handleChange(e, "driverPayDistances")}
				/>
				<label htmlFor="tab4">Pay Distances</label>
			</div>
		</div>
	);
};

export default DriverPayConfigHeader;
