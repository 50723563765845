import Header from "../../components/header";
import AppBar from "../../components/appbar";
import React, { useState, useEffect } from "react";
import { getAllLUTRules,getDefaultLutRules,getAllDefaultLoadUnload } from "../../services/loadunloadruleService";
import LoadUnloadruleByIdHeader from "../../components/loadunloadruleByIdHeader/loadunloadruleByIdHeader";
import { toast } from 'react-toastify';
import LoadUnloadDurationsByLocation from "../../components/Loadunloaddurationsbylocation/LoadUnloadDurationsByLocation";
import LoadUnloadDurationsByCommodityGroups from "../../components/loadunloaddurationsbycommoditygroups/LoadUnloadDurationsByCommodityGroups";
import LoadUnloadDurationsByCommodity from "../../components/loadunloaddurationsbycommodity/LoadUnloadDurationsByCommodity";
import Loadunloaddurationsdefault from "../../components/loadunloaddurationsdefault/Loadunloaddurationsdefault";
import { getAllLocations } from "../../services/locationService";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import "bootstrap/dist/css/bootstrap.min.css";

function LoadUnloadDurations() {
  const [lutClicked, setlutClicked] = useState(false);
  const [lutById, setlutById] = useState({});
  const [headerTabName, setheaderTabName] = useState("default");
  const [commodities, setCommodities] = useState([]);
  const [tablelist, setTablelist] = useState([]);
  const [isDataloaded, setIsDataLoaded] = useState(false);

  const [allLocation, setallLocation] = useState([]);

  useEffect(() => {
    const locationService = getAllLocations()
      .then(function (locations) {
        setallLocation(locations);
      })
      .catch(function (err) {
        toast.error(err, "Error");
      });
  }, []);

  const handelcallbackFromHeader = (childdata) => {
    
    setlutClicked(childdata);
    setheaderTabName("default");
  };

  const handelcallbackFromLUTHeader = (childdata) => {
    setheaderTabName(childdata);
  };

  const getRuleData = async () => {
    const lutService =await getAllLUTRules().then(function (rules) {
      // setallLoadUnloadTimes(rules);
      // setCommodityDropdown(rules);
      // setloadUnloadTimesCount(rules.length);
    })
    .catch(function (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Banned Driver not added successfully', { autoClose: false } );
      }
    });
  }

  useEffect(() => {
    getRuleData();
  }, []);

  
 const parentCallBackForDuration = (data) => {
    setTablelist(data);
  }

  const getAllDefaultLoadUnloadFunc = async () => {
    const data = await getAllDefaultLoadUnload();
    const modifiedData = data?.map((item) => ({
      ...item,
      duration: parseInt(item.duration),
    }));
    setTablelist(modifiedData);
    setIsDataLoaded(true);
  };

  useEffect(() => {
    getAllDefaultLoadUnloadFunc()
  }, []);

  return (
    <div id="wrapper">
      <Header
        userclicked={lutClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!lutClicked ? (
              <>
                <LoadUnloadruleByIdHeader
                  cg={lutById}
                  allLocation={allLocation}
                  parentcallback={handelcallbackFromLUTHeader}
                  commodities={commodities}
                  tablelist={tablelist}
                  setTablelist={setTablelist}
                />
                {headerTabName === "default" ? (
                  <Loadunloaddurationsdefault
                    tablelist={tablelist}
                    isDataloaded={isDataloaded}
                    setTablelist={setTablelist}
                    parentCallBackForDuration={parentCallBackForDuration}
                  />
                ) : headerTabName === "location" ? (
                  <LoadUnloadDurationsByLocation allLocation={allLocation} />
                ) : headerTabName === "commoditygroup" ? (
                  <LoadUnloadDurationsByCommodityGroups />
                ) : headerTabName === "commodity" ? (
                  <LoadUnloadDurationsByCommodity />
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadUnloadDurations;
