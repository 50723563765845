import React, { useEffect, useState, useContext, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import DriverPayTable from "./DriverPayTable";
import { Buffer } from "buffer";
import { driverPayImport, allDriverpayRecords, transferredDriverPayStatus, getWeekOptions, getPayRate, getMovmentById, driverPayExport, getAllMovements } from "../../services/driverPayServer";
import DriverPayAuthorizeTable from "./DriverPayAuthorizeTable";
import DriverPayTransferTable from "./DriverPayTransferTable";
import { FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material"
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormControl from '@mui/material/FormControl';
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { ContextData } from "../appsession";
import { getUser } from "../../services/userService";
import { toast } from "react-toastify";
import DriverPayApproveTable from "./DriverPayApproveTable";
import { getBannedDrivers } from "../../services/driverService";
import { getalldriverspaybase } from "../../services/driverPayCodesMasterService.js";
import { createDriverPay } from "../../services/driverPayServer.js";
import debounce from 'lodash/debounce';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DriverPayModalEditAuthrization from "./DriverPayModalEditAuthrization";
import errorMessageDisplay from "../../utils/errorMessageDisplay.js";

const DriverPay = (props) => {
  const { selectedTerminal, refresh, handleRefresh, selectedTerminalIDs } = props;
  const [isSaveDisbaled, setisSaveDisbaled] = useState(false);
  const [userData, setuserData] = useContext(ContextData);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoodalOpen, setIsMoodalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [driver_pay_data, setDriverPayData] = useState([]);
  const [tabSelected, settabSelected] = useState({
    need_authorize: true,
    need_approval: false,
    approved: false,
    rejected: false,
    transferred: false,
  });
  const [lastFriday, setLastFriday] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [canAuthorize, setCanAuthorize] = useState(false);
  const [canImportExport, setcanImportExport] = useState({
    can_edit: false,
    can_view: false
  });
  const [canViewAuthorize, setCanViewAuthorize] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const [canViewApprove, setCanViewApprove] = useState(false);
  const [showDriverPay, setShowDriverPay] = useState(false);
  const [clearButtonHover, setClearButtonHover] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [addDriverPayModal, setAddDriverPayModal] = useState(false);
  const [allDriver, setAllDriver] = useState();
  const [driver, setDriver] = useState(null);
  const [payCode, setPayCode] = useState();
  const [payDescription, setPayDescription] = useState();
  const [unitMeasure, setUnitMeasure] = useState();
  const [unit, setUnit] = useState();
  const [rate, setRate] = useState();
  const [order, setOrder] = useState();
  const [movement, setMovement] = useState();
  const [driverPayConfigData, setDriverPayConfigData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [allTerminal, setAllTerminal] = useState([])
  const [terminal, setTerminal] = useState()
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isTransferEnabled, setIsTransferEnabled] = useState(false);
  const [isExported, setIsExported] = useState(0);
  const [dateValue, setDateValue] = useState("")
  const [movemenstsEdited, setidMovementEdited] = useState(false);
  const [addDriverPayData, setAddDriverPayData] = useState({
    pay_uom: '',
    status: "",
    pay_code: '',
    pay_description: '',
    pay_uom: '',
    units: "",
    pay_rate: '',
    order_id: "",
    movement_id: "",
    driver_id: "",
    amounts: 0
  })
  const [driverType, setDriverType] = useState()
  const [storedWeeks, setStoredWeeks] = useState([])
  const [xlsBase64Data, setXLSBase64Data] = useState("");
  const [transactionDate, setTransactionDate] = useState(DateTime.now);
  const [searchResult, setSearchResult] = useState([]);
  const [allMovements, setAllMovements] = useState([]);
  const [driverFullName, setDriverFullName] = useState(null);
  const [showMovementModal, setShowMovementModal] = useState(false);
  const [movementId, Setmovementid] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectedDriversTerminal, setselectedDriversTerminal] = useState("")
  const [origin, setOrigin] = useState({
    actual_arrive_origin: null,
    actual_depart_origin: null,
    duration_at_origin_stop: null,
    drive_time: null,
    pickuptimezone: null
  });
  const [destination, setDestination] = useState({
    actual_arrive_destination: null,
    actual_depart_destination: null,
    duration_at_destination_stop: null,
    total_time: null,
    deliverytimezone: null
  });
  const [EditClicked, setEditClicked] = useState(false);
  // terminal to show on add driver pay 
  const [terminals, setterminals] = useState("");

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      textAlign: "center!important",
      fontWeight: "bold",
      fontSize: 15
    },
    buttonstyle: {
      height: '0px',
      width: '200px',
    }
  }));

  const buttonStyle = {
    height: "33px",// Add the desired width
    padding: '2px 20px',
    fontSize: '16px', // Added font-size
    borderRadius: '4px',
  };

  const classes = useStyles();

  const unitsOfMeasure =
    [
      {
        pay_uom: 'FLAT'
      },
      {
        pay_uom: 'MILE'
      },
      {
        pay_uom: 'HOUR'
      }

    ]
  const status = [
    {
      id: 0,
      status: "Pending Authorization",
    },
    {
      id: 1,
      status: "Pending Approval"
    },
    {
      id: 2,
      status: "Approved"
    },
    {
      id: 3,
      status: "Rejected"
    },
    {
      id: 4,
      status: "Transferred"
    },
    {
      id: 5,
      status: "Paid"
    }]


  useEffect(() => {
    (async () => {
      try {
        const weeksResponse = await getWeekOptions();
        const data = weeksResponse.data;
        const lastPayDate = data[0].pay_date;

        let weekOptions = [];

        for (let i = 0; i < data.length; i++) {
          const weekNumber = DateTime.fromISO(data[i].pay_date).weekNumber;
          const date = DateTime.fromISO(data[i].pay_date).toFormat("MM-dd-yyyy");

          const amountValue = data[i].amount;
          const optionValue = `Week ${weekNumber} (${date}) - ${amountValue}`;
          weekOptions.push(optionValue);
        };

        const lastFridayDate = DateTime.fromISO(lastPayDate).toMillis();
        const currentDate = DateTime.now().toMillis();


        if (currentDate < lastFridayDate) {
          setIsTransferEnabled(false);
        } else {
          setIsTransferEnabled(true);
        };

        setWeekDays(weekOptions);
      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failure in getting week options', { autoClose: false } );
        }
      }
    })();
  }, [isExported, refresh]);


  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission
      .map(permit => {
        if (permit?.permissiondetail.code == "DriverPay" && permit?.can_edit == false) {
          setIsDisabled(true)
        }
      });
  }

  useEffect(() => {
    (async () => {
      const userNewData = await getUser(userData.id);
      setUserName(userNewData[0].email.split("@")[0]);
      setUserEmail(userNewData[0].email);
      const authorizeData = userNewData[0]?.roles.rolespermission.find(item => item.permissiondetail.code == "Authorize");
      setCanAuthorize(authorizeData?.can_edit);

      const approvedData = userNewData[0]?.roles.rolespermission.find(item => item.permissiondetail.code == "Approve");
      setCanApprove(approvedData?.can_edit);

      const isDriverPay = userNewData[0]?.roles.rolespermission.find(item => item.permissiondetail.code == "DriverPay");
      setShowDriverPay(isDriverPay?.can_edit)


      const import_export = userNewData[0]?.roles.rolespermission.find(item => item.permissiondetail.code == "Import/Export");
      setcanImportExport({ ...canImportExport, can_view: import_export.can_view, can_edit: import_export.can_edit })

    })()
    checkAccess()
  }, [userData]);


  useEffect(() => {
    if (selectedTerminal) {
      (async () => {
        setIsLoading(true)
        const driverList = await getBannedDrivers({ terminalId: selectedTerminal })

        const activeDriverList = driverList?.filter((item) => item.is_active)
        setIsLoading(false)
        setAllDriver(activeDriverList);
      })()
    }

  }, [selectedTerminal])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const driverPayCodesMasterData = await getalldriverspaybase();
      setIsLoading(false)
      setDriverPayConfigData(driverPayCodesMasterData);
    })()
  }, [])

  useEffect(() => {
    if (addDriverPayData.pay_code) {
      const payCodeData = driverPayConfigData.find(item => item.pay_code == addDriverPayData.pay_code);
      setAddDriverPayData(payCodeData);
      setAddDriverPayData((prevState) => {
        return { ...prevState, status: "Approved" }
      })
    }
  }, [addDriverPayData.pay_code]);

  useEffect(() => {

    const calculateAmount = (units, rate, uom) => {
      let amounts = 0;
      if (uom === "FLAT") {
        amounts = parseFloat(rate);

      } else if (uom === "MILE" || uom === "HOUR") {

        if (units == '') {
          amounts = 0
        }
        else {
          amounts = parseFloat(units) * parseFloat(rate);
        }
      }

      setAddDriverPayData((prevState) => {
        return {
          ...prevState,
          amounts
        }
      });
    }

    if (!isLoading) {
      calculateAmount(addDriverPayData?.units, addDriverPayData?.pay_rate, addDriverPayData?.pay_uom)
    }


  }, [addDriverPayData?.units, addDriverPayData?.pay_uom, addDriverPayData?.pay_rate])

  const handleOpenImport = () => {
    setImportModalOpen(true);
  }

  const handleCloseImport = () => {
    setSelectedFile(null);
    setImportModalOpen(false);
  };

  const handleCloseRecord = () => {
    setAddDriverPayModal(false);
    setShowMovementModal(false);
    setEditClicked(false);
    clearState();
    setOrigin({
      actual_arrive_origin: null,
      actual_depart_origin: null,
      duration_at_origin_stop: null,
      drive_time: null,
      deliverytimezone: null
    });
    setDestination({
      actual_arrive_destination: null,
      actual_depart_destination: null,
      duration_at_destination_stop: null,
      total_time: null,
      deliverytimezone: null
    });
    setOrderId("");
    Setmovementid("");
    setDriverFullName(null);
    setDriver("");
  }

  const handleDriverChange = async (e, value) => {

    Setmovementid("");
    setOrderId("");
    setterminals("");
    setTransactionDate(DateTime.now())
    if (!value) {
      setDriverType(null);
      setDriver(null);
      setDriverFullName(null)
    } else {
      setterminals(value.terminal.terminal_id)
      setDriverType(value.equipment_type_id);
      setDriver(value.driver_id);
      setselectedDriversTerminal(value.terminal.terminal_id)
      const { driver_id, driver_name } = value;
      setDriverFullName({ driver_id, driver_name })
    }
  };

  const handlePaytypeChange = async (e, value) => {
    if (value) {
      setAddDriverPayData((preState) => {
        return {
          ...preState,
          pay_code: value?.pay_code,
          pay_description: value?.pay_description,
          units: value?.pay_uom == "FLAT" ? "1" : ""
        }
      })

      if (value?.pay_code && driver) {
        const payRatePaycode = {
          "terminal_id": selectedTerminal,
          "driver_type": driverType,
          "pay_code": value?.pay_code
        }
        try {
          const payRate = await getPayRate(payRatePaycode);
          setAddDriverPayData((prevState) => {
            return {
              ...prevState,
              pay_rate: payRate[0]?.pay_rate,
              units: value?.pay_uom == "FLAT" ? "1" : ""

            }
          })
        } catch(err){
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          }else{
          toast.error('Failed to handle pay type change', { autoClose: false } );
          }
        }
      }

    } else {
      setAddDriverPayData((preState) => {
        return {
          ...preState,
          pay_code: "",
          pay_description: "",
          units: value?.pay_uom == "FLAT" ? "1" : "",
          pay_uom:"",
          pay_rate:""
        }
      })
    }

  }
  const handleUnitMeasureChange = (e, value) => {
    if (value) {
      setAddDriverPayData((preState) => {
        return {
          ...preState,
          pay_uom: value?.pay_uom,
          units: value?.pay_uom == "FLAT" ? "1" : ""
        }
      })
    }else{
      setAddDriverPayData((preState) => {
        return {
          ...preState,
          pay_rate:"",
          pay_uom:"",
          units:'',
          amount:0,
        }
      })
    }

  }
  const handleRateChange = (e) => {
    setAddDriverPayData((prevState) => {
      return {
        ...prevState,
        pay_rate: e.target.value
      }
    })
  }


  const handleUnitChange = (e) => {
    let units = e.target.value
    setAddDriverPayData((prevState) => {
      return {
        ...prevState,
        units,

      }
    })
  };

  const handleStatusChange = (e, value) => {
    setAddDriverPayData((prevState) => {
      return {
        ...prevState,
        status: value.status
      }
    })
  };

  const handleDateChange = (e) => {
    setTransactionDate(e);
  };

  const addPayRecord = async () => {
    const {
      pay_code,
      pay_description,
      pay_uom,
      pay_rate,
      units,
      amounts,
      movement_required,
    } = addDriverPayData;

    const payload = {
      terminal_id: selectedDriversTerminal,
      order_id: orderId,
      movement_id: movementId,
      driver_id: driver,
      pay_code,
      pay_description,
      pay_uom,
      pay_rate,
      units,
      amount: amounts,
      // status: addDriverPayData.status,
      transaction_date: transactionDate.toFormat("yyyy-MM-dd"),
      created_by: userName,
      created_on: DateTime.now().toMillis(),
      updated_by: userName
    };

    if (!driver) {
      return toast.error("Driver is required", "Error");
    }

    if (!pay_code) {
      return toast.error("Pay code is required", "Error");
    }

    if (!pay_uom) {
      return toast.error("Pay Unit is required", "Error");
    }

    if (movement_required && (!movementId || movementId === "" || movementId === 0)) {
      return toast.error("Movement Id is required", "Error");
    }

    if (!amounts || amounts === 0) {
      return toast.error("Amount cannot be zero", "Error");
    }

    try {
      const driverPayRecord = await createDriverPay(payload);

      if (driverPayRecord.status === 201) {
        setAddDriverPayModal(false);
        clearState();
        setIsCreated(true);
        if (tabSelected.need_authorize) {
          settabSelected({
            need_authorize: true,
            need_approval: false,
            approved: false,
            rejected: false,
          });
        } else if (tabSelected.need_approval) {
          settabSelected({
            need_authorize: false,
            need_approval: true,
            approved: false,
            rejected: false,
          });
        } else if (tabSelected.approved) {
          settabSelected({
            need_authorize: false,
            need_approval: false,
            approved: true,
            rejected: false,
          });
          handleRefresh();
        } else {
          settabSelected({
            need_authorize: false,
            need_approval: false,
            approved: false,
            rejected: true,
          });
        }
        setAddDriverPayModal(false);
        toast.success("Successfully Created.", "Success");
      } else {
        toast.error("Duplicate pay record cannot be added", "Error");
      }
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('An error occurred while creating the driver pay record', { autoClose: false } );
      }
    }
  };

  // check for import that file is excel file or not
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split('.').pop();

      // Check if the file extension is 'xlsx' or 'xls'
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {

        const reader = new FileReader();

        reader.onload = () => {
          const base64Data = reader.result.split(',')[1];
          setXLSBase64Data(base64Data);
        };

        reader.onerror = (error) => {

        };

        reader.readAsDataURL(file);

        setSelectedFile(file);

      } else {
        // Invalid file type selected
        toast.error("Allowed file type .xlsx or .xls", "Error");
        // Reset the file input
        event.target.value = null;
      }
    }
  };


  const handleCancel = () => {
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];

    fileInput.value = null;
    setSelectedFile(null);
  };

  const importHandler = () => {
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];


    if (file) {
      const fileURL = URL.createObjectURL(file);
      let data = fileURL;

      function convertXLSXToUint8Array(url) {
        return fetch(url)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => new Uint8Array(arrayBuffer))
          .catch(error => {
            throw error;
          });
      }
      convertXLSXToUint8Array(data)
        .then(async () => {
          let payload = {
            "importedBy": userEmail.split('@')[0],
            "data": xlsBase64Data
          }
          // Do something with the Uint8Array
          let response = await driverPayImport(payload);

          if (response) {
            setImportModalOpen(false);
            toast.success("Successfully imported.", "Success");
          } else {
            toast.error("Please select correct file.", "Error");

            setImportModalOpen(false);
          }
        })
        .catch(error => {
          console.log(error)
          toast.error("Failed to convert XLSX into uint8Array.", "Error");
        });
    } else {
      toast.error("No file selected.", "Error");
    }
  }

  const handleOpenAdd = () => {
    // beacuse movement id and orderid we need to retain we are clearing it on first render
    Setmovementid("");
    setOrderId("")
    if (!isLoading) {

      setAddDriverPayModal(true);
    }

  }

  const exportHandler = () => {
    setIsMoodalOpen(true);
  }

  const handleModalClose = () => {
    setIsMoodalOpen(false);
    setLastFriday(null);
  }


  const handleWeekValue = (item) => {
    console.log(item)
    // Week 47 (11-24-2023) - 100
    if (item) {
      // const weekNumber = item.trim().split(" ")[1];
      const treatedDate = item.trim().split(" ")[2].replace('(', '').replace(')', '');
      const parsedDate = DateTime.fromFormat(treatedDate, "MM-dd-yyyy");
      const selectedDate = parsedDate.toFormat("yyyy-MM-dd");  
      setLastFriday(selectedDate);
    } else {
      setLastFriday(null);
    }
  };

  // It will call on export button
  const handleModalExport = async () => {
    setIsMoodalOpen(false);

    try {
      const driverpay = await driverPayExport(lastFriday);
      const fileBlob = new Blob([Buffer.from(driverpay)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileUrl = window.URL.createObjectURL(fileBlob);
      
      let link = document.createElement('a');
      link.href = fileUrl;
      link.download = `PayExport-${lastFriday}.xlsx`;
  
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(fileUrl);
  
      setLastFriday(null);
    } catch (err) {
      console.log(err)
      setLastFriday(null);
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to export excel for driver pay', { autoClose: false } );
        }
      }
  };


  useEffect(() => {
    const rejected_payload = {
      terminal_id: selectedTerminalIDs,
      status: "Rejected"
    };
  
    const fetchData = async (payload) => {
      try {
        setIsLoading(true);
        let response = await allDriverpayRecords(payload);
        setIsLoading(false);
        setDriverPayData(response?.data?.data||[]);
      } catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to fetch all driver pay records', { autoClose: false } );
        }
        setDriverPayData([]);

        setIsLoading(false);
      }
    };
  
    if (tabSelected.need_approval) {
      const payload = {
        terminal_id: selectedTerminalIDs,
        status: "Pending Approval"
      };
      fetchData(payload);
    } else if (tabSelected.rejected) {
      fetchData(rejected_payload);
    }
  }, [tabSelected, selectedTerminalIDs, isUpdated, refresh, isExported]);
  

  const transferredHandler = async () => {
    try {
      const response = await transferredDriverPayStatus({
        "transfer_by": userData?.email?.split('@')[0]
      })
      if (response.status === 200) {
        toast.success("Transferred successfully")
        setConfirmModal(false);
        setIsExported((prev) => prev + 1);
      }
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to transfer handler', { autoClose: false } );
      }
    }

  }

  const formatDate = (epoch_date, tzone) => {

    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CDT" || tzone === "CST" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }

  }
  // Function to check if a date is in the future

  const isDateInFuture = (date) => {
    const today = DateTime.now();
    return date > today;
  };


  useEffect(() => {
    if (!EditClicked) {

      const firstMovement = allMovements[0];
      setOrigin({
        actual_arrive_origin: firstMovement?.actual_arrive_origin,
        actual_depart_origin: firstMovement?.actual_depart_origin,
        duration_at_origin_stop: firstMovement?.duration_at_origin_stop,
        drive_time: firstMovement?.drive_time,
        pickuptimezone: firstMovement?.pickuptimezone
      });
      setDestination({
        actual_arrive_destination: firstMovement?.actual_arrive_destination,
        actual_depart_destination: firstMovement?.actual_depart_destination,
        duration_at_destination_stop: firstMovement?.duration_at_destination_stop,
        total_time: firstMovement?.total_time,
        deliverytimezone: firstMovement?.deliverytimezone
      });

    }
    else {
      setisSaveDisbaled(false)
      if (destination.actual_depart_destination !== null) {
        let date = DateTime.fromMillis(destination.actual_depart_destination).setZone(destination.deliverytimezone);
        setTransactionDate(date)
      }
      else {
        setTransactionDate(DateTime.now())
      }
    }
  }, [addDriverPayModal, allMovements, EditClicked]);



  const tabClickHandler = (e, tabname) => {


    if (tabname === "need_authorize") {
      settabSelected({
        need_authorize: true,
        need_approval: false,
        approved: false,
        rejected: false,
        transferred: false,
      });
    }
    if (tabname === "need_approval") {
      settabSelected({
        need_authorize: false,
        need_approval: true,
        approved: false,
        rejected: false,
        transferred: false,
      });
    }
    if (tabname === "approved") {
      settabSelected({
        need_authorize: false,
        need_approval: false,
        approved: true,
        rejected: false,
        transferred: false,
      });
    }
    if (tabname === "rejected") {
      settabSelected({
        need_authorize: false,
        need_approval: false,
        approved: false,
        rejected: true,
        transferred: false,
      });
    }
    if (tabname === "transferred") {
      settabSelected({
        need_authorize: false,
        need_approval: false,
        approved: false,
        rejected: false,
        transferred: true
      });
    }
    // props.parentcallback(tabname)
  };


  const parentCallback = () => {
    setIsUpdated(true)
  }

  let clearState = () => {
    setAddDriverPayData({
      pay_uom: "",
      status: "",
      pay_code: "",
      pay_description: "",
      pay_uom: "",
      units: "",
      pay_rate: "",
      order_id: "",
      movement_id: "",
      driver_id: "",
      amounts: ""
    });
    setAllMovements([]);
    setEditClicked(false);
    setDriverFullName(null);
    setTransactionDate(DateTime.now())
    setDriver(null);
    setterminals("")
  }

  const handleMovementClick = async () => {
    setAddDriverPayModal(false);
    setShowMovementModal(true);
    if (!driver) return;

    const payload = {
      driver_id: driver,
    };

    try {
      const response = await getAllMovements(payload);
      let updateddata = []
      const movements = response?.data?.data || [];
      for (let i = 0; i < movements.length; i++) {
        let item = movements[i];
        item.origin = `${item.pickup.origin_location}`;
        item.bol1 = item?.order?.BOL;
        item.destination = `${item.delivery.origin_location}`;
        item.origin_actual_arrival = formatDate(item.actual_arrive_origin, item.pickuptimezone)
        item.origin_actual_depart = formatDate(item.actual_depart_origin, item.pickuptimezone)
        item.destination_actual_arrival = formatDate(item.actual_arrive_destination, item.deliverytimezone)
        item.destination_actual_depart = formatDate(item.actual_depart_destination, item.deliverytimezone)

        updateddata.push(item)
      }
      setAllMovements(updateddata);
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to handle movement click', { autoClose: false } );
      }
    }
  };

  const handleCloseDriverMovementModal = () => {
    setAddDriverPayModal(true);
    setShowMovementModal(false);
    setAllMovements([]);
  }
  // this debounce function will be called on add driver pay when user enters any input
  const debouncedSearch = useCallback(
    debounce(async (movementid) => {
      if(movementid==""||movementid==null){
        return;
      }
      try {
        const payload = {
          id: movementid
        }
        const response = await getMovmentById(payload)
        if (response.length > 0) {
          setOrderId(response[0].order_id)
          setisSaveDisbaled(false);
          if (response[0].delivery.actual_departure_utc !== null) {
            let date = DateTime.fromMillis(response[0].delivery.actual_departure_utc).setZone(response[0].delivery.delivery_timezone)
            setTransactionDate(date);
          }
          else {
            setTransactionDate(DateTime.now())
          }
        }
        else {
          setOrderId("")
          setTransactionDate(DateTime.now())
          toast.error("Please Enter Correct Movement Id", "Error");
        }

      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to perform debounce on search', { autoClose: false } );
        }
      }
    }, 1000),
    []
  );

  // driverpay movement on add button
  const handleChangeMovements = async (event) => {
    const value = event.target.value ?? '';
    if (value !== '') {
      setisSaveDisbaled(true);
    } else {
      setisSaveDisbaled(false);
    }
    debouncedSearch(event.target.value)
    Setmovementid(event.target.value);
  }

  return (
    <>
      <div className='driverpay_table_header'>
        <div className="row df driverpaytab">
          <div className="tabs">
            <input
              type="radio"
              name="tabs"
              id="tabone"
              checked={tabSelected.need_authorize}
              readOnly
              onClick={(e) => tabClickHandler(e, "need_authorize")}
            />
            <label className="driverpaytabtext" htmlFor="tabone">Pending Authorization</label>
            {/* <DriverHeaderInfo driverById={driverById} /> */}
            <input
              type="radio"
              name="tabs"
              id="tabtwo"
              checked={tabSelected.need_approval}
              readOnly
              onClick={(e) => tabClickHandler(e, "need_approval")}
            />
            <label className="driverpaytabtext" htmlFor="tabtwo">Pending Approval</label>

            <input
              type="radio"
              name="tabs"
              id="tab3"
              checked={tabSelected.approved}
              readOnly
              onClick={(e) => tabClickHandler(e, "approved")}
            />
            <label className="driverpaytabtext" htmlFor="tab3">Approved</label>

            <input
              type="radio"
              name="tabs"
              id="tab4"
              checked={tabSelected.rejected}
              readOnly
              onClick={(e) => tabClickHandler(e, "rejected")}
            />
            <label className="driverpaytabtext" htmlFor="tab4">Rejected</label>

            <input
              type="radio"
              name="tabs"
              id="tab5"
              checked={tabSelected.transferred}
              readOnly
              onClick={(e) => tabClickHandler(e, "transferred")}
            />
            <label className="driverpaytabtext" htmlFor="tab5">Transferred</label>
          </div>
        </div>
        <div className="driverpay_import_export">
          <div className="planning_board_refresh_button">
            {canImportExport.can_view == false && canImportExport.can_edit == false ? <></> :
              <button type="button"
                className="btn_blue btn-blue mb_0 access_save changeLocationButton"
                style={buttonStyle}
                disabled={canImportExport.can_edit && isTransferEnabled ? false : true}
                onClick={() => { setConfirmModal(true) }}
              >
                Transfer
              </button>
            }
          </div>
          {!showDriverPay ? <></> :
            <button type="button"
              className="btn_blue btn-blue mb_0 access_save changeLocationButton"
              style={buttonStyle}
              onClick={handleOpenAdd}
            >Add</button>
          }

          <div className="planning_board_refresh_button">
            <button type="button"
              className="btn_blue btn-blue mb_0 access_save changeLocationButton"
              style={buttonStyle}
              onClick={handleRefresh}
            >Refresh</button>
          </div>
          {
            canImportExport.can_view == false && canImportExport.can_edit == false ? <></> : (<> <div>
              <button type="button"
                className="btn_blue btn-blue mb_0 access_save changeLocationButton"
                style={buttonStyle}
                onClick={handleOpenImport} disabled={!canImportExport.can_edit}><FileUploadOutlined />Import</button>
            </div>
              <div>
                <button type="button"
                  className="btn_blue btn-blue mb_0 access_save changeLocationButton"
                  style={buttonStyle}
                  onClick={exportHandler}
                  disabled={!canImportExport.can_edit}>
                  <FileDownloadOutlined />Export
                </button>
              </div></>)
          }

        </div>
      </div>

      <div className='row special_row_flex'>
        <div className='col-xl-12'>
          <div className='card card_shadow card_border'>
            <div className='card-body'>
              {tabSelected.need_authorize ?
                (<DriverPayAuthorizeTable
                  selectedTerminal={props.selectedTerminal}
                  isplanningProfileChange={props.isplanningProfileChange}
                  showDriverPay={showDriverPay}
                  canAuthorize={canAuthorize}
                  canViewAuthorize={canViewAuthorize}
                  statusCode={"Pending Authorization"}
                  refresh={refresh}
                  isCreated={isCreated}
                  selectedTerminalIDs={selectedTerminalIDs}
                />)
                : ("")
              }
              
              {tabSelected.approved &&
                (<DriverPayApproveTable
                  tabSelected={tabSelected}
                  showDriverPay={showDriverPay}
                  selectedTerminal={props.selectedTerminal}
                  isplanningProfileChange={props.isplanningProfileChange}
                  refresh={refresh}
                  statusCode={"Approved"}
                  selectedTerminalIDs={selectedTerminalIDs}
                />)
              }

              {tabSelected.transferred ?
                (<DriverPayTransferTable
                  showDriverPay={showDriverPay}
                  selectedTerminal={props.selectedTerminal}
                  isplanningProfileChange={props.isplanningProfileChange}
                  refresh={refresh}
                  statusCode={"Transferred"}
                  selectedTerminalIDs={selectedTerminalIDs}
                />) :
                ("")
              }

              {!tabSelected.need_authorize && !tabSelected.transferred && !tabSelected.approved ?
                (<DriverPayTable
                  refresh={refresh}
                  selectedTerminal={props.selectedTerminal}
                  driverPayData={driver_pay_data}
                  isLoading={isLoading}
                  tabSelected={tabSelected}
                  setDriverPayData={setDriverPayData}
                  parentCallback={parentCallback}
                  canApprove={canApprove}
                  canViewApprove={canViewApprove}
                  canEditDriverPay={showDriverPay}
                  selectedTerminalIDs={selectedTerminalIDs}
                />) :
                ("")
              }
            </div>
          </div>
        </div>
      </div>

      {/* Modal for adding pay record */}
      <Modal show={addDriverPayModal}
        onHide={() => {
          setAddDriverPayModal(false)
          clearState()
        }}
        centered
        onClose={handleCloseRecord}
        size='lg' >
        <Modal.Header closeButton>
          <Modal.Title>Add Pay Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add_driver_outer">
            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Driver*
              </label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={allDriver}
                  getOptionLabel={(option) =>
                    `${option.driver_id} - ${option.driver_name}`
                  }
                  value={driverFullName || null}
                  onChange={handleDriverChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Driver..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Pay Code*
              </label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={driverPayConfigData}
                  getOptionLabel={(option) => {
                    if (option && option.pay_code && option.pay_description) {
                      return `${option?.pay_code} : ${option?.pay_description}`
                    } else {
                      return ""
                    }

                  }}
                  value={addDriverPayData}
                  onChange={handlePaytypeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Pay Code..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="add_driver_outer">
            <div className="col-md-6 six_pad">
            <label for="typeHeadCommodityGroup">
              Terminal*
            </label>
            <div className="meterial_autocomplete">
            <TextField
            type="text"
            fullWidth
            margin="normal"             
            placeholder="Terminal"
            size="small"
            disabled
            value={terminals || ""}
            onChange={()=>{}}
          />
            </div>
            </div>
            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Units Of Measure*
              </label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={unitsOfMeasure}
                  getOptionLabel={(option) => option?.pay_uom}
                  onChange={handleUnitMeasureChange}
                  value={addDriverPayData}
                  disabled={!addDriverPayData?.pay_uom_editable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Unit Of Measure..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>

          </div>
          <div className="add_driver_outer">
            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Rate*
              </label>
              <div className="meterial_autocomplete">
                <TextField
                  type='Milenumber'
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="loadtime"
                  placeholder="Rate"
                  size="small"
                  value={addDriverPayData?.pay_rate}
                  disable={!addDriverPayData?.pay_rate_editable}
                  onChange={handleRateChange}
                />
              </div>
            </div>

            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Unit*
              </label>
              <div className="meterial_autocomplete">
                <TextField
                  type='number'
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="loadtime"
                  placeholder="Unit"
                  size="small"
                  disabled={addDriverPayData?.pay_uom == 'FLAT'}
                  value={addDriverPayData?.units}
                  onChange={handleUnitChange}
                />
              </div>
            </div>
          </div>

          <div className="add_driver_outer">
            <div className="col-md-6 six_pad">

              <label for="typeHeadCommodityGroup">
                Movement Id
              </label>
              <div className="meterial_autocomplete">
                <TextField
                  type="text"
                  variant="outlined"
                  margin="normal"
                  className="width-198"
                  name="loadtime"
                  size="small"
                  value={movementId || ""}
                  onChange={handleChangeMovements}
                  inputProps={{
                    inputMode: "numeric",
                    autoComplete: "off",
                    step: "any", // To allow decimals if needed
                  }}
                />

                <Button
                  variant="primary"
                  className="mt_3 ml_10"
                  onClick={handleMovementClick}
                // disabled={!addDriverPayData.movement_required}
                >Choose a Movement</Button>
              </div>
            </div>

            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Amount*
              </label>
              <div className="meterial_autocomplete">
                <TextField
                  type='number'
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="loadtime"
                  placeholder="Amount"
                  size="small"
                  disabled
                  value={addDriverPayData?.amounts}
                />
              </div>
            </div>
          </div>
          <div className="add_driver_outer">

            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Status*
              </label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={status}
                  onChange={handleStatusChange}
                  getOptionLabel={(option) => option?.status}
                  disabled
                  value={addDriverPayData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Status..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 six_pad">

              <label for="typeHeadCommodityGroup">
                Order Id
              </label>
              <div className="meterial_autocomplete">
                <TextField
                  type='number'
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="loadtime"
                  placeholder="Order Id"
                  size="small"
                  value={orderId || ""}
                  disabled
                />
              </div>
            </div>
          </div>


          <div className="add_driver_outer">

            <div className="col-md-6 six_pad">
              <label for="typeHeadCommodityGroup">
                Transaction Date*
              </label>
              <div className="meterial_autocomplete">
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableToolbar
                    className="driver_pay_add_transaction_date"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={transactionDate}
                    shouldDisableDate={isDateInFuture} // Disable future dates
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRecord}>
            Cancel
          </Button>
          <Button variant="primary" className="mr_12" onClick={addPayRecord} disabled={isSaveDisbaled}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DriverPayModalEditAuthrization modal */}
      <DriverPayModalEditAuthrization
        showMovementModal={showMovementModal}
        handleCloseEditModal={handleCloseDriverMovementModal}
        MovementData={allMovements}
        setDestination={setDestination}
        setOrigin={setOrigin}
        Setmovementid={Setmovementid}
        setOrderId={setOrderId}
        setEditClicked={setEditClicked}
        setidMovementEdited={setidMovementEdited}
      />

      {/* Modal for selecting week number */}
      <div className="export_modal">
        <Modal className="export_modal" show={isMoodalOpen} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Export Driver Pay</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="driverPayDropDown_label">Select Week Number*</label>
            <div className="driverPayWeekDropdown export_dropdown">
              <FormControl variant="outlined" className={classes.formControl}>
                <div className="meterial_autocomplete">
                  <Autocomplete
                    id="combo-box-demo"
                    options={weekDays}
                    getOptionLabel={(item) => item}
                    onChange={(event, newValue) => handleWeekValue(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Week"
                        variant="outlined"
                      />

                    )}

                  />
                </div>
              </FormControl>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" disabled={lastFriday ? false : true} onClick={handleModalExport}>
              Export
            </Button>
          </Modal.Footer>

        </Modal>
      </div>

      {/* IMPORT MODAL */}
      <Modal className="export_modal" show={importModalOpen} onHide={handleCloseImport} centered>
        <Modal.Header closeButton>
          <Modal.Title>Import Driver Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="import_choose_button_sec">
            <input type="file" id="fileInput" name="myfile" onChange={handleFileChange} />
            {selectedFile && (
              <button className="import_close"
                onMouseEnter={() => setClearButtonHover(true)}
                onMouseLeave={() => setClearButtonHover(false)}
                onClick={handleCancel}
              >
                &#x2715;
              </button>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImport}>
            Close
          </Button>
          <Button variant="primary" onClick={importHandler}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal for transfer */}
      <Modal show={confirmModal}
        onHide={() => { setConfirmModal(false) }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className="p_text">You are about to transfer the pay records and create a new pay period. This is a non reversible action.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setConfirmModal(false) }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={transferredHandler}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default DriverPay;
