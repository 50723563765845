import axios from 'axios';

// Application Specific
import Driver from '../models/driverModel';
import Contact from "../models/contactModel";
import { BaseConfig } from "../baseConfig";

export const searchContacts = async (filtertext) => {
    const driver = new Driver();
    let driverObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/filterdrivers";
        const data = {
            "search": filtertext
        }
        const driverApiData = await axios.post(url, data);
        driverObject = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(driverObject);
}

export const getContactsByTerminalId = async (terminalId, searchText) => {
    const contact = new Contact();
    let contactObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/filtercontactbyterminalid";
        const data = {
            "terminalId": terminalId,
            "search": searchText
        }
        const contactApiData = await axios.post(url, data);

        contactObject = contactApiData.data.data.map(data => contact.parseApiContactObject(data));
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
    }
    return Promise.resolve(contactObject);
}

export const createContact = async (data) => {
    const createcontact = new Contact();
    // Generate the model using map method.
    let contactObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/createContact";
        const contactpApiData = await axios.post(url, data);
        contactObject = contactpApiData.data.data.map(data => createcontact.parseApiContactObject(data));

    } catch (error) {
        return Promise.reject("Error: Unable to add the Terminal Contact.");
    }
    return Promise.resolve(contactObject);


}

export const removeContact = async (data) => {
    const deletecontact = new Contact();
    // Generate the model using map method.
    let contactObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/deleteContact";
        const contactpApiData = await axios.post(url, data);
        //contactObject = contactpApiData.data.data;
        contactObject = contactpApiData.data.data.map(data => deletecontact.parseApiContactObject(data));

    } catch (error) {
        return Promise.reject("Error: Unable to delete the Terminal Contact.");
    }
    return Promise.resolve(contactObject);
}