import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import { resetLoadUnloadCommodityGroup, editCommodityLoadUnloadDuration } from "../services/commoditygroupService";
import { toast } from "react-toastify";
import { CommodityGroupContext } from "../pages/commoditygroup";

const ModalInput = ({ commodityObj, setModalShow ,isSearched,setiseSearchrefresh,setisrefresh}) => {
  const commodityGroupContextData = useContext(CommodityGroupContext);
  
  // These are for storing load objs
  const [yes, setYes] = useState({});
  const [no, setNo] = useState({});
  const [tl, setTl] = useState({});
  const [nb, setNb] = useState({});  

  // These are for storing load input values
  const [yesLoadInputValue, setYesLoadInputValue] = useState("");
  const [noLoadInputValue, setNoLoadInputValue] = useState("");
  const [tlLoadInputValue, setTlLoadInputValue] = useState("");
  const [nbLoadInputValue, setNbLoadInputValue] = useState("");


  // These are for storing unload objs
  const [yesUnLoad, setYesUnLoad] = useState({});
  const [noUnLoad, setNoUnLoad] = useState({});

  // These are for storing unload input values
  const [yesUnLoadInputValue, setYesUnLoadInputValue] = useState("");
  const [noUnLoadInputValue, setNoUnLoadInputValue] = useState("");


  // This IsEdit state for Load Time
  const [isEditforYes, setIsEditforYes] = useState(true);
  const [isEditforNo, setIsEditforNo] = useState(true);
  const [isEditforTl, setIsEditforTl] = useState(true);
  const [isEditforNB, setIsEditforNB] = useState(true);

  // This IsEdit state for Unload Time
  const [isEditforYesUnLoad, setIsEditforYesUnLoad] = useState(true);
  const [isEditforNoUnLoad, setIsEditforNoUnLoad] = useState(true);

  const editInfoHandler = () => setIsEditforYes(false);

  const editforNo = () => setIsEditforNo(false);

  const editforTl = () => setIsEditforTl(false);

  const editforNB = () => setIsEditforNB(false);

  const editforYesUnloadTime = () => setIsEditforYesUnLoad(false);

  const editforNoUnloadTime = () => setIsEditforNoUnLoad(false);

  // These functions are running for cancel, edited values
  const cancelSaveUser = () => {
    setYesLoadInputValue(yes.duration);
    setIsEditforYes(true);
  }

  const cancelForNO = () => {
    setNoLoadInputValue(no.duration);
    setIsEditforNo(true);
  }

  const cancelForTl = () => {
    setTlLoadInputValue(tl.duration);
    setIsEditforTl(true);

  };

  const cancelForNB = () => {
    setNbLoadInputValue(nb.duration);
    setIsEditforNB(true);
  };

  const cancelForYesUnloadTime = () => {
    setYesUnLoadInputValue(yesUnLoad.duration);
    setIsEditforYesUnLoad(true);
  };

  const cancelForNoUnloadTime = () => {
    setNoUnLoadInputValue(noUnLoad.duration);
    setIsEditforNoUnLoad(true);
  };

  const handleResetForLoadUnload = async (name) => {
    switch (name) {
      case "Yes": {
        const response = await resetLoadUnloadCommodityGroup(yes.id);
        
        if (response.status === 200) {
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
          setModalShow(false);
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          }
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': YES");
        }
        break;
      }
      case "No": {
        const response = await resetLoadUnloadCommodityGroup(no.id);
        
        if (response.status === 200) {
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
          setModalShow(false);  
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          }   
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': NO");
        }
        break;
      }
      case "TL": {
        const response = await resetLoadUnloadCommodityGroup(tl.id);
        
        if (response.status === 200) { 
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);     
          setModalShow(false);   
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          } 
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': TL");
        }
        break;
      }
      case "NB": {
        const response = await resetLoadUnloadCommodityGroup(nb.id);
        
        if (response.status === 200) {
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);  
          setModalShow(false);  
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          }  
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': NB");
        }
        break;
      }
      case "YesReset": {
        const response = await resetLoadUnloadCommodityGroup(yesUnLoad.id);
        
        if (response.status === 200) { 
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
          setModalShow(false);   
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          }  
          toast.success("Reset successfully."); 

        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': YesReset");
        }
        break;
      }
      case "NoReset": {
        const response = await resetLoadUnloadCommodityGroup(noUnLoad.id);
        
        if (response.status === 200) {  
          await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
          setModalShow(false);  
          if(isSearched){
            setiseSearchrefresh((prev) => !prev)
          }else{
            setisrefresh((prev) => !prev)
          }  
          toast.success("Reset successfully.");
        } else {
          toast.error("Failed to reset load/unload commodity group with 'name': NoReset");
        }
        break;
      }
      default:
        break;
    }
  };
 
  
  const handleSaveForLoadUnLoad = async (saveName) => {
    switch (saveName) {
      case "Yes": {
        if (yes.id) {
          const payload = {
            "id": yes.id,
            "commodity_id": commodityObj.commodity_id,
            "action_type": "PU",
            "driver_load_flag": "Y",
            "duration": yesLoadInputValue,
            "isDefault": yes.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);

          if (response.status === 200) {
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
            if(isSearched){
              setiseSearchrefresh((prev) => !prev)
            }else{
              setisrefresh((prev) => !prev)
            }   
            toast.success("Updated successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      case "No": {
        if (no.id) {
          const payload = {
            "id": no.id,
            "action_type": "PU",
            "commodity_id": commodityObj?.commodity_id,
            "driver_load_flag": "N",
            "duration": noLoadInputValue,
            "isDefault": no.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);

          if (response.status === 200) {  
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
              if(isSearched){
                setiseSearchrefresh((prev) => !prev)
              }else{
                setisrefresh((prev) => !prev)
              } 
            toast.success("Updated successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      case "NB": {
        if (nb.id) {
          const payload = {
            "id": nb.id,
            "action_type": "PU",
            "commodity_id": commodityObj?.commodity_id,
            "driver_load_flag": "NB",
            "duration": nbLoadInputValue,
            "isDefault": nb.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);

          if (response.status === 200) {
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
              if(isSearched){
                setiseSearchrefresh((prev) => !prev)
              }else{
                setisrefresh((prev) => !prev)
              } 
            toast.success("Reset successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      case "TL": {
        if (tl.id) {
          const payload = {
            "id": tl.id,
            "action_type": "PU",
            "commodity_id": commodityObj?.commodity_id,
            "driver_load_flag": "TL",
            "duration": tlLoadInputValue,
            "isDefault": tl.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);

          if (response.status === 200) {
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
              if(isSearched){
                setiseSearchrefresh((prev) => !prev)
              }else{
                setisrefresh((prev) => !prev)
              } 
            toast.success("Updated successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      case "YesUnload": {
        if (yesUnLoad.id) {
          const payload = {
            "id": yesUnLoad.id,
            "action_type": "DEL",
            "commodity_id": commodityObj?.commodity_id,
            "driver_load_flag": "Y",
            "duration": yesUnLoadInputValue,
            "isDefault": yesUnLoad.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);
          if (response.status === 200) {
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
              if(isSearched){
                setiseSearchrefresh((prev) => !prev)
              }else{
                setisrefresh((prev) => !prev)
              } 
            toast.success("Updated successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      case "NoUnload": {
        if (noUnLoad.id) {
          const payload = {
            "id": noUnLoad.id,
            "action_type": "DEL",
            "commodity_id": commodityObj?.commodity_id,
            "driver_load_flag": "N",
            "duration": noUnLoadInputValue,
            "isDefault": noUnLoad.isDefault
          };
          const response = await editCommodityLoadUnloadDuration(payload);
          if (response.status === 200) {
            await commodityGroupContextData.handleCommodityGroupByCode(true, commodityGroupContextData.commodityGroupById);
            setModalShow(false);  
              if(isSearched){
                setiseSearchrefresh((prev) => !prev)
              }else{
                setisrefresh((prev) => !prev)
              } 
            toast.success("Updated successfully.");
          } else {
            toast.error(`Failed to edit commodity load/unload duration`);
          }
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const arrayOfLoadUnload = [...commodityObj.loadtime, ...commodityObj.unloadtime];

    let yesLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "PU" && item.driverloadunload === "Y");
    let noLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "PU" && item.driverloadunload === "N");
    let tlLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "PU" && item.driverloadunload === "TL");
    let nbLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "PU" && item.driverloadunload === "NB");


    // For storing load objects for the first time
    setYes(yesLoadObj);
    setNo(noLoadObj);
    setTl(tlLoadObj);
    setNb(nbLoadObj);

    // For storing load durations for the first time
    setYesLoadInputValue(yesLoadObj.duration);
    setNoLoadInputValue(noLoadObj.duration);
    setTlLoadInputValue(tlLoadObj.duration);
    setNbLoadInputValue(nbLoadObj.duration);

    let yesUnLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "DEL" && item.driverloadunload === "Y");
    let noUnLoadObj = arrayOfLoadUnload.find((item) => item.stoptype === "DEL" && item.driverloadunload === "N");

    // For storing unload objects for the first time
    setYesUnLoad(yesUnLoadObj);
    setNoUnLoad(noUnLoadObj);


    // For storing unload durations for the first time
    setYesUnLoadInputValue(yesUnLoadObj.duration);
    setNoUnLoadInputValue(noUnLoadObj.duration);

    setIsEditforYes(true);
    setIsEditforNo(true);
    setIsEditforTl(true);
    setIsEditforNB(true);
    setIsEditforYesUnLoad(true);
    setIsEditforNoUnLoad(true);

  }, [commodityObj, commodityGroupContextData.isRefresh]);

  return (
    <div className="row df">
      <table className="table table-borderless table-centered m-0 special_fonts bg-light commodity_override">
        <thead className="table-light">
          <h5
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#212529",
            }}
          >
            {commodityObj.commodity_id}
          </h5>
          <tr className="commodity_override_deep">
            <th colSpan={4}>Load Time</th>
          </tr>
          <tr>
            <th>Yes</th>
            <th>No</th>
            <th>TL</th>
            <th>NB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                name="yes"
                onInput={(e) => setYesLoadInputValue(e.target.value)}
                className={
                  !isEditforYes ? "after_edit_comodity" : "before_edit_comodity"
                }
                value={yesLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforYes}
              />
              {isEditforYes ? (
                <div onClick={(e) => editInfoHandler()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("Yes")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => cancelSaveUser()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!yes.isDefault ? (
                <Button className='btn_blue_sm btn-blue btn btn-primary' onClick={() => handleResetForLoadUnload("Yes")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                type="number"
                name="no"
                onInput={(e) => setNoLoadInputValue(e.target.value)}
                className={
                  !isEditforNo ? "after_edit_comodity" : "before_edit_comodity"
                }
                value={noLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforNo}
              />
              {isEditforNo ? (
                <div onClick={(e) => editforNo()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("No")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => cancelForNO()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!no.isDefault ? (
                <Button className='btn_blue_sm btn-blue btn btn-primary' onClick={() => handleResetForLoadUnload("No")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                type="number"
                name="Tl"
                onInput={(e) => setTlLoadInputValue(e.target.value)}
                className={
                  !isEditforTl ? "after_edit_comodity" : "before_edit_comodity"
                }
                value={tlLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforTl}
              />
              {isEditforTl ? (
                <div onClick={(e) => editforTl()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("TL")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => cancelForTl()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!tl.isDefault ? (
                <Button onClick={() => handleResetForLoadUnload("TL")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                type="number"
                name="Nb"
                onInput={(e) => setNbLoadInputValue(e.target.value)}
                className={
                  !isEditforNB ? "after_edit_comodity" : "before_edit_comodity"
                }
                value={nbLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforNB}
              />
              {isEditforNB ? (
                <div onClick={(e) => editforNB()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("NB")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => cancelForNB()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!nb.isDefault ? (
                <Button onClick={() => handleResetForLoadUnload("NB")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-borderless table-centered special_fonts bg-light commodity_override mt_10">
        <thead className="table-light">
          <tr className="commodity_override_deep">
            <th colSpan={3}>Unload Time</th>
          </tr>
          <tr>
            <th>
              Yes
            </th>
            <th>
              No
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                name="yesUnload"
                onInput={(e) => setYesUnLoadInputValue(e.target.value)}
                className={
                  !isEditforYesUnLoad
                    ? "after_edit_comodity"
                    : "before_edit_comodity"
                }
                value={yesUnLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforYesUnLoad}
              />
              {isEditforYesUnLoad ? (
                <div onClick={() => editforYesUnloadTime()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("YesUnload")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => cancelForYesUnloadTime()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!yesUnLoad.isDefault ? (
                <Button onClick={() => handleResetForLoadUnload("YesReset")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                type="number"
                name="noUnload"
                onInput={(e) => setNoUnLoadInputValue(e.target.value)}
                className={
                  !isEditforNoUnLoad
                    ? "after_edit_comodity"
                    : "before_edit_comodity"
                }
                value={noUnLoadInputValue}
                placeholder="e.g. 30mins"
                disabled={isEditforNoUnLoad}
              />
              {isEditforNoUnLoad ? (
                <div onClick={() => editforNoUnloadTime()}>
                  <i
                    className="fa fa-pencil-square edit_icon_blue_sec_commodity"
                    aria-hidden="true"
                    style={{ color: "#0066ff" }}
                  ></i>
                </div>
              ) : (
                <div className="save_button_sec">
                  <button
                    className="btn btn-primary btn-sm mr_10"
                    type="submit"
                    onClick={() => handleSaveForLoadUnLoad("NoUnload")}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => cancelForNoUnloadTime()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {!noUnLoad.isDefault ? (
                <Button onClick={() => handleResetForLoadUnload("NoReset")}>
                  Reset
                </Button>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ModalInput;
