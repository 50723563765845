import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import LocationHeader from "../../components/locationHeader/locationHeader";
import LocationTable from "../../components/locationTable/locationTable";
import React, { useState, useEffect, useContext,useCallback } from "react";
import { filterLocation, getAllLocations,getLocation } from "../../services/locationService";
import LocationByIdHeader from "../../components/locationByIdHeader/locationByIdHeader";
import LocationBodyForDetails from "../../components/locationBodyForDetails/locationBodyForDetails";
import BannedDriverForLocation from "../../components/bannedDriverForLocation/bannedDriverForLocation";
import LoadTimesForLocation from "../../components/loadTimesForLocation/loadTimesForLocation";
import LocationDedicatedTrailersBody from "../../components/locationDedicatedTrailersBody/locationDedicatedTrailersBody";
import LocationShipperPoolBody from "../../components/locationShipperPollBody/locationShipperPollBody";
import {getAllTerminals} from "../../services/terminalService";
import {getAppFilter} from "../../services/appFilterService";
import { ContextData } from "../../components/appsession";
import { DateTime } from "luxon";
import debounce from 'lodash/debounce';
import {searchlocation} from "../../services/terminalService";
import { toast } from "react-toastify";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

function Locations() {
  const [userData, setuserData] = useContext(ContextData);
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [allLocation, setallLocation] = useState([]);
  const [allLocationsearch, setallLocationsearch] = useState([]);
  const [locationCount, setlocationCount] = useState("");
  const [locationClicked, setlocationClicked] = useState(false);
  const [locationById, setlocationById] = useState({});
  const [allDedicatedTrailers, setallDedicatedTrailers] = useState([]);
  const [allShipperPool, setallShipperPool] = useState([]);
  const [allShipper, setallShipper] = useState([]);
  const [allBanned, setallBanned] = useState([]);
  const [terminalsOptions, setTerminalsOptions] = useState([]);

  const [headerTabName, setheaderTabName] = useState("details");
  const [regionOptions, setregionOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isSelected, setIsSelected] = useState(0);
  const [isDisabled, setIsDisabled] = useState();
  const [locationPageData, setLocationPageData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission.find(permission => permission?.permissiondetail?.code === "Locations")
    if(permission?.can_edit === false)
          setIsDisabled(true)
  }
  const handelcallbackFromHeader = async(childdata) => {
    setIsLocationLoaded(false);
    setlocationClicked(childdata);
    setheaderTabName("details");
    await allLocations()
  };
  const parentCallBackForLocationFilter = async (filterData) => {
    const locations = await filterLocation(filterData);
    setallLocation(locations);
    
  };

  const handelcallback = async(childdata, terminal) => {
   const getlocationid = await getLocation(terminal.id)
    setlocationById(getlocationid);
    setlocationClicked(childdata);
  };
  const handelcallbackFromLocationHeader = (childdata) => {
    setheaderTabName(childdata);
  };
  const allLocations = async() => {
    try{
      // const allResponse = await getAllLocations();

      const searchData = [
        {field: 'isActive', value: true, operator: 'eq'}
      ];

      const search = await searchlocation(searchData, 1, 25);
      setLocationPageData(search.data.data);
      setTotalResult(search.data.count);

        // setallLocation(allResponse);
        // setlocationCount(allResponse.length);
        // setLocationData(allResponse);
        setIsLocationLoaded(true);
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to search locations', { autoClose: false } );
      }
    }
  }
  const updateTotalResult = (newTotalResult) => {
    setTotalResult(newTotalResult);
  };
  useEffect(() => {
      allLocations()
  }, []);

  useEffect(() => {
    const getAllTerminal = getAllTerminals().then((res) => {
      setTerminalsOptions(res);
    });
  }, []);

  useEffect(() => {
    const appFilter = getAppFilter();
    setStateOptions(appFilter.state);
    setregionOptions(appFilter.region);
  }, []);

  useEffect(()=>{
    checkAccess();
  },[allLocation])
  const parentCallBackFromLocationBodyForDedicatedTrailer = (data) => {
    setallDedicatedTrailers(data);
  };
  const parentCallBackFromShipperPoll = (data) => {
    setallShipperPool(data);
  };
  const parentCallBackForBanned = (data) => {
    setallBanned(data);
  };

  const parentCallBackForShipperLoadTimes = (data) => {
    setallShipper(data);
  };

  const convertDateTime = (epoch_date) => {
    return DateTime.fromMillis(parseInt(epoch_date *1000)).toFormat("MM-dd-yyyy").toString()
  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerms, page, size, sort) => {
      const search = await searchlocation(searchTerms, page, size, sort);
      if (search.data.data.length <= 0) {
          setIsPageLoading(false);
          setLocationPageData(search.data.data);
          setTotalResult(search.data.count);               
      }
      if (search.data.data.length > 0) {
        setLocationPageData(search.data.data);
      }
      setTotalResult(search.data.count);
      setIsPageLoading(false);
      setIsLoading(false);
    }, 1000),
    []
  );


  return (
    <div id="wrapper">
      <Header
        userclicked={locationClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!locationClicked ? (
              <>
                <LocationHeader
                  locationCount={locationCount}
                  terminalsOptions={terminalsOptions}
                  // allLocation={allLocation}
                  totalResult={totalResult}
                  // setallLocation={setallLocation}
                  // locationData={locationData}
                  regionOptions={regionOptions}
                  stateOptions={stateOptions}
                  updateTotalResult={updateTotalResult}
                  setLocationPageData={setLocationPageData}
                  parentCallBackForLocationFilter={
                    parentCallBackForLocationFilter
                  }
                />
                <LocationTable
                  // allLocation={allLocation}
                  parentcallback={handelcallback}
                  isLocationLoaded={isLocationLoaded}
                  locationPageData={locationPageData}
                  debouncedSearch={debouncedSearch}
                  totalResult={totalResult}
                />
              </>
            ) : (
              <>
                <LocationByIdHeader
                  locationById={locationById}
                  parentcallback={handelcallbackFromLocationHeader}
                  allDedicatedTrailers={allDedicatedTrailers}
                  allShipperPool={allShipperPool}
                  allBanned={allBanned}
                  allShipper={allShipper}
                />
                {headerTabName === "details" ? (
                  <LocationBodyForDetails location={locationById}
                  locationById={locationById}
                  parentCallBackForBanned={parentCallBackForBanned}
                  accessDisabled={isDisabled}
                  isShipperOrConsignee={{
                    shipper: true,
                    consignee: false,
                  }}
                  handelcallback={handelcallback}
                  />
                ) : headerTabName === "shipper" ? (
                  <div className="row special_row_flex">
                    {/* <BannedDriverForLocation
                      locationById={locationById}
                      parentCallBackForBanned={parentCallBackForBanned}
                      isShipperOrConsignee={{
                        shipper: true,
                        consignee: false,
                      }}
                    /> */}
                    <LoadTimesForLocation
                      locationById={locationById}
                      allShipper={allShipper}
                      accessDisabled={isDisabled}
                      parentCallBackForShipperLoadTimes={
                        parentCallBackForShipperLoadTimes
                      }
                      isShipperOrConsignee={{
                        shipper: true,
                        consignee: false,
                      }}
                    />
                  </div>
                ) : headerTabName === "dedicatedTrailers" ? (
                  <LocationDedicatedTrailersBody
                    locationById={locationById}
                    parentCallBackFromLocationBodyForDedicatedTrailer={
                      parentCallBackFromLocationBodyForDedicatedTrailer
                    }
                    accessDisabled={isDisabled}
                    convertDateTime={convertDateTime}
                  />
                ) : headerTabName === "shipperPool" ? (
                  <LocationShipperPoolBody
                    locationById={locationById}
                    parentCallBackFromShipperPoll={
                      parentCallBackFromShipperPoll
                    }
                    accessDisabled={isDisabled}
                    convertDateTime={convertDateTime}
                  />
                ) : headerTabName === "consigee" ? (
                  <div className="row special_row_flex">
                    {/* <BannedDriverForLocation
                      locationById={locationById}
                      isShipperOrConsignee={{
                        shipper: false,
                        consignee: true,
                      }}
                    /> */}
                    <LoadTimesForLocation
                      locationById={locationById}
                      parentCallBackForShipperLoadTimes={
                        parentCallBackForShipperLoadTimes
                      }
                      accessDisabled={isDisabled}
                      isShipperOrConsignee={{
                        shipper: false,
                        consignee: true,
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locations;
