import '../../assets/css/bootstrap.min.css'
import '../../assets/css/app.min.css'
import '../../assets/css/responsive.css'
import AppSubBarLeft from '../appsubbarleft'
import AppSubBarRight from '../appsubbarright'
import React, { useState, useEffect } from 'react';

function AppBar(props) {        
  const { unreadCount }= props;
  const [itemSelected, setitemSelected] = useState(window.location.pathname);
 
  const parentCallbackHandler=(href)=>{
    setitemSelected(href)
  };

  return (
    <div className='left-side-menu'>
      <div className='h-100'>
        <div className='sidebar-content'>
        {
          itemSelected === "/orders"||itemSelected === "/TrailersPage"||itemSelected === "/TankwashPage" ? (
            null // Don't render anything if itemSelected is "/orders"
          ) : (
            window.screen.availWidth > 768 || props.hamburgerMenuClicked ? (
              <AppSubBarLeft unreadCount={unreadCount} parentCallbackHandler={parentCallbackHandler} />
            ) : (
              null // Don't render anything if none of the conditions are met
            )
          )
       }

          {itemSelected === "/yardcheck" ||
          itemSelected === "/planner" ||
          itemSelected === "/" ||
          itemSelected === "/driverpay" ||
          itemSelected==="/orders"||
          itemSelected === "/TrailersPage" ||
          itemSelected === "/tankwash" ? null : (
            <AppSubBarRight parentCallbackHandler={parentCallbackHandler}></AppSubBarRight>
          )}

          <div className='clearfix'></div>
        </div>
      </div>
    </div>
  );
}

export default AppBar;
