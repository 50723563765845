
import React, { useContext } from "react";
import { Button as MUIButton, FormControl, Select } from "@mui/material";
import { useState, useEffect } from "react";
import { ContextData } from '../../components/appsession';
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

const DriverPayHeader = ({ planningprofile, usersPlanningProfile, setisplanningProfileChange, isplanningProfileChange, onSelectedTerminalIDsChange }) => {
	const [unassignedPlanningProfileOptions, setUnassignedPlanningProfileOptions] = useState([]);
	const [assignedPlanningProfileOptions, setAssignedPlanningProfileOptions] = useState([]);
	const [selectedTerminalList, setSelectedTerminalList] = useState([]);
	const [selectAllChecked, setSelectAllChecked] = useState(false);

	useEffect(() => {
		let assigned = [];
		let unassigned = [];
		let assignedProfile = [...planningprofile].filter((profile) =>
			usersPlanningProfile.map((assign) => assign.planningprofile_id).includes(profile.id)
		);
		
		assignedProfile.map((select) => {
			let assignedProfileObj = {
				label: select?.name,
				value: select?.terminal_id,
				id: select?.id,
				timezone: select?.terminal?.timezone,
			};
			assigned.push(assignedProfileObj);
		});

		setAssignedPlanningProfileOptions(assigned);

		// Checking if there are any assigned terminals, if so, set the initially selected terminal(s)
		if (assigned.length > 0) {
			const initialAssignedTerminalIDs = assigned.map((terminal) => terminal.value);

			setSelectedTerminalList(assigned);
			onSelectedTerminalIDsChange(initialAssignedTerminalIDs);
		}

		const unassignedProfile = planningprofile.filter((el) => {
			return !usersPlanningProfile.find((element) => {
				return element.planningprofile_id === el.id;
			});
		});

		unassignedProfile.map((select) => {
			let unassignedProfileObj = {
				label: select?.name,
				value: select?.terminal_id,
				id: select?.id,
				timezone: select?.terminal?.timezone,
			};
			unassigned.push(unassignedProfileObj);
		});

		setUnassignedPlanningProfileOptions(unassigned);

	}, [planningprofile, usersPlanningProfile]);


	const uniqueobjectsFromData=(data)=>{
		const propertyName = "value";
        const uniqueData = {};
        data.forEach((item) => {
          if(!uniqueData[item[propertyName]]) {
            uniqueData[item[propertyName]] = item;
          }
        });
       const filteredData = Object.values(uniqueData);
	   return filteredData;
	};

	const planningProfileChangeHandler = (selectedTerminals) => {

		const totalprofiles = uniqueobjectsFromData([...assignedPlanningProfileOptions, ...unassignedPlanningProfileOptions])
		if (totalprofiles.length == selectedTerminals.length) {
			setSelectAllChecked(true);
		}
		else {
			setSelectAllChecked(false);
		}

		setSelectedTerminalList(selectedTerminals);
		const terminalIDs = selectedTerminals.map((terminal) => terminal.value);
		const uniqueSelectedTerminal = [...new Set(terminalIDs)];

		
		onSelectedTerminalIDsChange(uniqueSelectedTerminal);
		setisplanningProfileChange(isplanningProfileChange + 1);
	};


	const handleSelectAllChange = (event) => {
		const checked = event.target.checked;
		setSelectAllChecked(checked);
		// If "Select all" is checked, all checkboxes in both assigned and unassigned lists to be checked
		if (checked) {
			// remove duplicates from terminalids
			const allTerminalIDs = [
				...assignedPlanningProfileOptions,
				...unassignedPlanningProfileOptions,
			].map((terminal) => terminal.value);

			setSelectedTerminalList([...assignedPlanningProfileOptions, ...unassignedPlanningProfileOptions]);
		} else {

			setSelectedTerminalList([]);
		}
		// Calling the callback function
		let allSelectedTerminal = [
			...assignedPlanningProfileOptions,
			...unassignedPlanningProfileOptions
		].map((terminal) => terminal.value);
		const uniqueSelectedTerminal = [...new Set(allSelectedTerminal)];
		onSelectedTerminalIDsChange(checked ? uniqueSelectedTerminal : []); 
		setisplanningProfileChange(isplanningProfileChange + 1);
	};



	const debounce = (func, delay) => {
		let timer;
		return function (...args) {
			clearTimeout(timer);
			timer = setTimeout(() => func.apply(this, args), delay);
		};
	};

	const debouncedChangeHandler = debounce(planningProfileChangeHandler, 500);

	const handleClearAll = () => {
		setSelectedTerminalList([]);
		setSelectAllChecked(false);
		onSelectedTerminalIDsChange([]);
		setisplanningProfileChange(isplanningProfileChange + 1);
	};



	return (
		<div className="row df mt_30">
			<div className="col-xl-12">
				<div className="card special_bg">
					<div className="card-body">
						<div className="row top_adjust">
							<div className="col-md-12">
								<div className="row">
									<h2 className="text-light">Driver Pay</h2>
									<div style={{ backgroundColor: 'white', width: '25%' }}>
										<div className="dropdownadjust">
											<Picky
												options={[
													{
														label: 'Select all',
														value: 'select-all',
													},												
													{
														label: 'Assigned Planning Profiles',
														options: assignedPlanningProfileOptions,
													},
													{
														label: 'Unassigned Planning Profiles',
														options: unassignedPlanningProfileOptions,
													},
												]}
												labelKey="label"
												valueKey="id"
												placeholder="Planning Profiles"				
												multiple={true}
												includeSelectAll={false}
												value={selectedTerminalList}
												numberDisplayed={1}
												manySelectedPlaceholder="Planning Profiles: %s"
												allSelectedPlaceholder="Planning Profiles: All"
												onChange={debouncedChangeHandler}											
												render={({ style, item, selectValue, labelKey, valueKey, isSelected, multiple }) => {
													const { label, options } = item;													

													return (
														<div style={{ ...style, padding: '8px' }}>
															{label === 'Select all' ? (
																<div className="driverPay_dropdown_buttons">
																	<div className="option" onClick={(e) => e.stopPropagation()}>
																		<input
																			type="checkbox"
																			checked={selectAllChecked}
																			onChange={handleSelectAllChange}
																		/>
																		<label>{label}</label>
																	</div>
																	<div className="option" onClick={(e) => e.stopPropagation()}>																		
																		<label onClick={() => handleClearAll()}>Clear All</label>
																	</div>
																</div>																
															) : (
																	<>
																		<strong>{label}</strong>
																		{															
																				options && options.map((option) => (
																					<div
																						key={option[valueKey]}
																						className={`option ${selectedTerminalList.some((selected) => selected[valueKey] === option[valueKey]) ? 'selected' : ''}`}
																						onClick={() => {
																							selectValue(option);
																						}}
																					>
																						<input
																							type="checkbox"
																							checked={selectedTerminalList.some((selected) => selected[valueKey] === option[valueKey])}
																							readOnly
																						/>
																						<label>{option[labelKey]}</label>
																					</div>
																				))

																		}
																	</>
															)}
														</div>
													);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DriverPayHeader;
