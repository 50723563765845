import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';
import axios from 'axios';


export const genericSearch = async (tableName,searchTerms) => {
    let decompressedObject;
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforsearch/${tableName}`
        let data={
            searchdata:searchTerms,
        }
        const response = await axios.post(url,data);
        if (response?.data && response?.data?.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
        return decompressedObject?.data
    }
    catch (err) {
        throw err
    }
}

export const getAllDataByGenericAPI = async (tabname, payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforgetall/${tabname}`;
        const response = await axios.post(url, payload);
        return response.data.data;
    }
    catch (err) {
        throw err
    }
}