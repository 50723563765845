import React, { useState, useEffect } from "react";

import Search from "../../assets/images/Search-Button.svg";
import {getAllTrailerByCommodityGroupId} from "../../services/commoditygroupService";
import Spinner from "react-bootstrap/Spinner";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { Box, TablePagination } from "@mui/material";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles, useTheme } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";

const CommoditygroupBodyForTrailersInDetails = (props) => {
  const { commoditygroup, convertDateTime } = props;
  const [allTrailersDetails, setallTrailersDetails] = useState([]);
  const [searchData, setsearchData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount,setTotalCount] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [allDataAfterSearch, setallDataAfterSearch] = useState([]);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "is_active",
          operator: "eq",
          value: true
        }

      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const filterOperators = {
    text: [
      {
        text: 'grid.filterContainsOperator',
        operator: 'contains',
      },
    ],
    numeric: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
    date: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
    boolean: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
  };
  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      // display: "inline-block",
      // marginLeft: "35px",
      // marginTop: "10px",
      // fontWeight: "bold",
      // fontSize: 15,
      fontWeight: "bold",
      textAlign:"center !important",
      fontSize: 15,
    },
  }));

  const classes = useStyles();
  const [dataResult, setDataResult] = useState(process(allTrailersDetails, dataState));
  // const [terminalNames, setTerminalNames] = useState()
  const dataStateChange = (event) => {
    if(event.dataState.sort?.[0]?.field=='trailer_id'){
      let trailerRes = [...allTrailersDetails];

      if (event.dataState.sort[0].dir === 'asc') {
          trailerRes.sort((a, b) => parseInt(a.trailer_id, 36) - parseInt(b.trailer_id, 36));
      } else {
          trailerRes.sort((a, b) => parseInt(b.trailer_id, 36) - parseInt(a.trailer_id, 36));
      }

      let filter=event.dataState.filter
      if (filter) {
          trailerRes = process(trailerRes, { filter })?.data;
      }

      setDataResult(trailerRes?.slice(event.dataState.skip,event.dataState.skip+event.dataState.take));
      setDataState(event.dataState);
      setTotalCount(trailerRes?.length);
    }else{
      setDataResult(process(allTrailersDetails, event.dataState));
      setDataState(event.dataState);
      setTotalCount(null);
    }
  };
  

  useEffect(() => {
    setDataResult(process(allTrailersDetails, dataState));
  }, [allTrailersDetails]);


  const searchInputHandler = (e) => {
    setsearchData(e.target.value.toUpperCase());
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  const searchHandler = async (e) => {
    let allTrailers = allTrailersDetails;
    if (searchData) {
      setallDataAfterSearch(
        allTrailers.filter(
          (item) =>
            item.trailer_id.toUpperCase().indexOf(searchData.toUpperCase()) >
              -1 ||
            item.eqtype.toUpperCase().indexOf(searchData.toUpperCase()) > -1 ||
            item.eqmake.toUpperCase().indexOf(searchData.toUpperCase()) > -1 || 
            item.eqmodel.toUpperCase().indexOf(searchData.toUpperCase()) > -1
        )
      );
    } else {
      setallDataAfterSearch([]);
    }
  };

  const fetchAllTrailers = async () => {
    try {
      const allcommoditytrailers = await getAllTrailerByCommodityGroupId(
        commoditygroup.code,
        searchData
      );
      const trailerRes = allcommoditytrailers.map((trailer) => ({
        ...trailer,
        eqstat: trailer.eqstat === "A" ? "Active": "Inactive",
      }));
  
      setallTrailersDetails(trailerRes);
  
      if (trailerRes.length >= 0) {
        setIsLoading(false);
      }
      props.parentCallBackFromTrailerGroup(trailerRes);
  }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }
    }
  };

  useEffect(() => {
    fetchAllTrailers();
  }, []); 


  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  };
  
  return (
    <div className="col-xl-12">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body special_card_padding">
            <h2 className="header-title">Commodity Group Trailers</h2>
            <h2 className="header-title"> Total: {totalCount ? totalCount : dataResult.total}</h2>

            <div className="table-responsive">
              {allTrailersDetails?.length > 0 ? (
                <Grid
                  filter={dataState.filter}
                  sort={dataState.sort}
                  sortable={true}
                  filterable={true}
                  //filterOperators={filterOperators}
                  pageable={{
                    pageSizes: [25, 50, 100],
                    buttonCount: 2,
                    info: true,
                    previousNext: true,             
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  total={totalCount ? totalCount : dataResult.total}
                  // onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                >
                  <GridColumn 
                    field="trailer_id" 
                    title="Trailer Id" 
                    width="200px"                    
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.trailer_id ? e.dataItem.trailer_id : ""}
                        </td>
                      );
                    }} />
                    <GridColumn 
                    field="eqstat" 
                    title="Status" 
                    width="200px"                    
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqstat? e.dataItem.eqstat: ""}
                        </td>
                      );
                    }} />
                 
                  <GridColumn
                    field="eqmake"
                    title="Make"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqmake ? e.dataItem.eqmake : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="eqmodel"
                    title="Model"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqmodel ? e.dataItem.eqmodel : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="eqlicenseplate"
                    title="License Plate"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqlicenseplate ? e.dataItem.eqlicenseplate : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="driver_side_tag"
                    title="QR Code"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driver_side_tag ? e.dataItem.driver_side_tag : ""}
                        </td>
                      );
                    }}
                  />
                  
                  <GridColumn
                    field="region"
                    title="Region"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.region ? e.dataItem.region : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="terminal_full_name_cg"
                    title="Terminal"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.terminal_full_name_cg ? e.dataItem.terminal_full_name_cg : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="commoditygroup_full_name_cg"
                    title="Commodity Group"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.commoditygroup_full_name_cg ? e.dataItem.commoditygroup_full_name_cg : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="pm_due_date_utc"
                    title="Next PM Date"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td
                          style={{
                            padding: e.dataItem.pm_due_date_utc ? "8px 1px" : "8px 10px"
                          }}
                        >
                          {e.dataItem.pm_due_date_utc ? convertDateTime(e.dataItem.pm_due_date_utc) : ""}
                        </td>
                      );
                    }}
                  />
                </Grid>
              ) : (
                isLoading ? (
                  <div style={{ padding: "15px" }}>
                    <ProgressBar animated now={100} />
                    <div class='loader'>
                      <div class='loader--text'></div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4 className="contactdata">No Data</h4>
                  </div>
                )
              )}
            </div>         
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommoditygroupBodyForTrailersInDetails;
