import React, { useState, useEffect, useContext } from 'react';
import {getAllPlanningProfiles, getPlanningProfileById, updatePlanningProfileUsers} from '../../services/userService';
import ProgressBar from "react-bootstrap/ProgressBar";
import { ContextData } from "../../components/appsession";
import config from "../../Config.json"
import { toast } from 'react-toastify';
import Spinner from "react-bootstrap/Spinner";


const UserPlanningFiltertable = (props) => {
  // const [userData, setuserData] = useContext(ContextData);
  const { user, accessDisabled, userInfo, updateUser } = props
  const [userRadioCheck, setUserCheck] = useState([]);
  const [userData, setuserData] = useState({});
  const [userData1, setuserData1] = useContext(ContextData);

  const [isDataLoaded, setisDataLoaded] = useState(false)
  const [isDisabled, setIsDisabled] = useState(null)
  const [planningProfile, setPlanningProfile] = useState([]);
  //   const [checked, setChecked] = useState(false);
  //   const [userId, setuserId] = useState(user.userId);
  const [planningChecked, setPlanningChecked] = useState([])
  const [userAccountData, setuserAccountData] = useContext(ContextData)

  const permission = userData1?.roles?.rolespermission?.find(userpermission => 
    userpermission?.permissiondetail.code === "Users")

    const fetchData = async () => {
      if (Object.keys(user).length > 0) {
        setuserData(user)
        setisDataLoaded(true)
        const planning_profiles_listing = await getAllPlanningProfiles()
          setPlanningProfile(planning_profiles_listing)
          setPlanningChecked(planning_profiles_listing)
      }  
    }
  
  useEffect(() => {
    fetchData();
  }, [Object.keys(user).length]);

  const captureChangeData = function (evt) {
    setBtnSearch(evt.target.value);
  }
 

  const handlePlanningProfileChange = (e, item) => {
    let ids = []
    ids = [...userRadioCheck]
    if (e.target.checked) {
      ids.push(item.id)
      setUserCheck(ids);
      const tempArr = [...planningProfile]
      let checkedarr = planningChecked.filter((e) => ids.includes(e.id))
      let uncheckarr = planningChecked.filter((e) => !ids.includes(e.id))
      let arr = [...checkedarr, ...uncheckarr]
      setPlanningChecked(arr)
    
    } else {
      const updated = ids.filter(el => el.id != item.id);
      const index = ids.indexOf(item.id);
      if (index > -1) { // only splice array when item is found
        ids.splice(index, 1); // 2nd parameter means remove one item only
      }
      setUserCheck(ids);
    }
  }

  const fetchPlanData = async () => {
    let id = await getPlanningProfileById(user.id);
    let ids = [...new Set(id.map(planningId => planningId.planningprofile_id))];
    setUserCheck(ids)
    // Add planning profile IDs to user context
    let planning_terminal_ids = [...new Set(id.map(elem=>elem.userPlanningprofile.terminal_id))]
  }
  useEffect( () => {
    fetchPlanData();
  },[]);

  const updateUserData = async (event, id) => { /** @audit-ok Save function call */
  // userAccountData.planningprofiles=[]
  

  
    setisDataLoaded(false)
    userData.roles = [userRadioCheck];
    const obj = {
      user_id: user.id,
      planningprofiles: userRadioCheck
    }

    const updateuserResponse = await updatePlanningProfileUsers(obj);
    if (updateuserResponse) {
      await getAllPlanningProfiles();
      setuserData(updateuserResponse)

      // Add planning profile IDs to user context
      let planningProfile = await getPlanningProfileById(userAccountData.id)
      let planning_terminal_ids = [...new Set(planningProfile.map(elem=>elem.userPlanningprofile.terminal_id))]
      userAccountData.planningprofiles=planningProfile
      setuserAccountData(userAccountData)

      setisDataLoaded(true)
      toast.success("Assigned planning Profile updated", "Success");
    } else {
      await getAllPlanningProfiles();
      toast.error("Assigned planning Profile not updated", "error");
    }
  }
  // useEffect(() => {
  //   if (userInfo?.Email == config.adminUsers) {
  //     setIsDisabled(false)
  //   } else if (userInfo?.Email != config.adminUsers) {
  //     if (user?.Email == config.adminUsers) {
  //       setIsDisabled(true)
  //     } else if (user?.Email != config.adminUsers) {
  //       setIsDisabled(false)
  //     }
  //   }

  // }, [userInfo, user, config])
  useEffect(() => {
    if (planningProfile.length>0 && userRadioCheck.length>0) {
      let checkedarr = planningProfile.filter((e) => userRadioCheck.includes(e.id))
      let uncheckarr = planningProfile.filter((e) => !userRadioCheck.includes(e.id))
      let arr = [...checkedarr, ...uncheckarr]
      setPlanningChecked(arr)
    }

  }, [planningProfile.length, userRadioCheck.length])

  return (
    <div className="col-xl-88">
      <div className="card card_shadow">
        <div className="card-body special_card_padding">
          <div className="access_header">
            <h2 className="header-title">Planning Profiles</h2>
            {permission?.can_edit?(
              <button
              type="button"
              className="btn_blue_sm btn-blue ml_10 access_save"
              onClick={(e) => updateUserData(e, userData.userId)}
            >
              SAVE
            </button>
            ):(
              <button
              type="button"
              className="btn_blue_sm btn-blue ml_10 access_save"
              disabled
            >
              SAVE
            </button>
            )
          }
           
          </div>

          <div className="table-responsive Assigned_Roles_scroll">
            {
              isDataLoaded ?
                <table className="table table-striped user_table_adjust mb-0 planning_profile_table">
                  <tbody>
                    <tr>
                      <th className="w-27" scope="row">Planning Profile Name</th>
                      <th scope="row">Terminal</th>
                      <th scope="row">Default Planner</th>
                      <th scope="row">Assigned</th>
                    </tr>
                    <>
                      {planningChecked && planningChecked.length > 0 && planningChecked.map((item, i) => (
                        <tr key={i}>
                          <td id={item.id} scope="row" key={i}>{item.name}</td>
                          <td>{item?.terminal?.terminal}</td>
                          <td>
                            <div className="squareDisabled">
                              <>
                                <input
                                  name="roleradio"
                                  id={item?.id}
                                  type="checkbox"
                                  checked={item?.is_default_planner ? true : false}
                                  disabled
                                />
                              </>

                            </div>
                          </td>
                          <td>
                            

                            <div className="square" onChange={(e)=>handlePlanningProfileChange(e,item)}>
                              <>
                                <input
                                  name="roleradio"
                                  id={item.id}
                                  type="checkbox"
                                  checked={userRadioCheck && userRadioCheck.includes(item.id)}
                                  disabled={accessDisabled ? true : false}
                                  style={{ border: accessDisabled ? "1px solid #dddddd" : "" }}
                                />
                                <label name="ppcheckbox" />
                              </>

                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
                :
                (<div className='planning_profile_bar'>
                  <ProgressBar animated now={100} />
                </div>)
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default UserPlanningFiltertable
