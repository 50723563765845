
import { BaseConfig } from "../baseConfig";
import axios from 'axios';

// Application Specific

export const getNotDismissedAlerts = async () => {
  try {
    const url = BaseConfig.ApiEndPoint + "/getnotdismissedalerts";
    const alertsdismissedResponse = await axios.get(url);
    let dismissAlerts = alertsdismissedResponse.data
    return dismissAlerts;
  } catch (err) {
    throw err;
  }
}

export const filterAlert = async (filterData) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/alertswithfilter";
    const data = {
      terminal_id: filterData.terminals,
      region: filterData.region,
      commodity: filterData.commoditygroup,
    }
    const alertsResponse = await axios.post(url, data);

    return alertsResponse;
  } catch (err) {
    throw err;
  }
}

export const getAllAlert = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/getallbpaalert";
    const response = await axios.post(url,payload);
    let allNewAlert = response.data.data
    return allNewAlert;
  } catch (err) {
    throw err;
  }
}
export const getAllAlertType = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/allalerttype";
    const response = await axios.post(url,payload);
    let all_alert_type = response.data.data
    return all_alert_type;
  } catch (err) {
    throw err;
  }
}




export const updateAlert = async (id,payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + `/updatebpaalert/${id}`;
    const response = await axios.put(url,payload);
   
    let allNewAlert = response
    return allNewAlert;
  } catch (err) {
    throw err;
  }
};

export const createBpaAlert = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/alerts";
    const responseData = await axios.post(url, payload);
    return responseData;
  }
  catch (err) {
    throw err;
  }
};



